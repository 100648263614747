<template>
  <el-card class="hotel-page">
    <el-form class="content" label-width="80px">
      <el-upload class="avatar-uploader" name="files" :data="uploadData" :headers="uploadHeaders" :action="action + 'dinner/reserve/attachment/upload'" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <img v-if="photoUrl" :src="photoUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="hint">点击图片跟换头像</div>
    </el-form>
  </el-card>
</template>

<script>
import { baseUrl, imageUrl } from '@/utils/api'
let token = ''
if (window.localStorage.getItem('token')) {
  token = window.localStorage.getItem('token').toString()
}
export default {
  name: 'Avatar',
  data() {
    return {
      photoUrl:
        'https://cdn.pixabay.com/photo/2021/10/01/03/46/goat-6671275__340.jpg',
      action: baseUrl,
      uploadHeaders: {
        'Access-Control-Allow-Origin': '*',
        accessToken: 'Bearer ' + token
      },
      uploadData: {
        num: 1
      }
    }
  },
  mounted() {
    this.hotelQuery()
  },
  methods: {
    // 查看个人信息
    async hotelQuery() {
      const res = await this.getRequest('ogz/user/query')
      if (res.status !== 200) {
        return false
      }
      const records = res.pageModel.records[0]
      //  console.log(';records', records)
    },
    async handleAvatarSuccess(res, file) {
      if (res.status !== 200) {
        return false
      }
      const url = res.pageModel.records[0].previewUrl
      this.logoUrl = imageUrl + url
      const data = {
        dataCode: 'account',
        bizId: res.pageModel.records[0].bizId,
        previewUrl: res.pageModel.records[0].previewUrl
      }
      const row = await this.postRequest(
        'ogz/user/update/command',
        this.qs.stringify(data)
      )
      if (row.status !== 200) {
        return false
      }
      await this.hotelQuery()
      this.$message({
        duration: 10000,
        type: 'success',
        message: '设置成功,重新登录后生效'
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.avatar-uploader {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  background: #ccc;
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
.hotel-page {
  width: 100%;
  height: 100%;
  .hint {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    color: #999;
  }
  .content {
    width: 500px;
    //margin: 0 auto;
  }
}
</style>
