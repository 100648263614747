<template>
  <div>
    增服务统计
  </div>
</template>

<script>
export default {
  name: 'AppreciationService'
}
</script>

<style scoped>
</style>
