<template>
  <div class="salespersons-page">
    <div class="head">
      <el-select style="width: 150px" @change="onRoomboxChange" v-model="form.dinnerZoneBizId" placeholder="餐区">
        <el-option v-for="item in dinnerZoneList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select style="width: 150px" @change="onRoomboxChange" v-model="form.rangePersonBizId" placeholder="人数">
        <el-option v-for="item in rangePersonlist" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div class="date">
        <i class="el-icon-arrow-left" @click="onDateChange(-1)"></i>
        <span class="text">
          {{ date.year }}年{{ date.month }}月{{ date.day }}日
          <span :style="[{marginLeft: '20px'}, date.week === '周日' || date.week === '周六' ? {color: 'red'}: {}]">{{date.week}}</span>
        </span>
        <i class="el-icon-arrow-right" @click="onDateChange(1)"></i>
      </div>
      <el-radio-group @change="radioChange" v-model="form.radio">
        <el-radio-button :label="0">中午</el-radio-button>
        <el-radio-button :label="1">晚上</el-radio-button>
      </el-radio-group>
      <el-switch @change="flageChange" v-model="form.flage" inactive-text="只看空台">
      </el-switch>
    </div>
    <div class="ballroom">
      <div v-for="item in recordslist" :key="item.dinnerZoneBizId">
        <div class="roombox">
          <h3>{{ item.dinnerZoneName }}</h3>
          <div class="roomlist">
            <div v-for="j in item.datas" :key="j.bizId">
              <div class="room-iten">
                <div class="info">
                  <div style="font-size: 14px">{{j.name}}</div>
                  <div style="color: #999; font-size:14px;">{{j.rangePersonName}}人</div>
                </div>
                <div class="times">
                  <div v-for="m in j.timeQuantums" :key="m.bizId">
                    <div :class="['active', 'active'+ m.orderStatus]">
                      <span v-if="!m.datas">{{m.name}}</span>
                      <b v-else-if="m.orderStatus === 8" class="b4">
                        <i class="el-icon-s-goods"></i>
                      </b>
                      <div class="active-div" style="text-align: center" v-else>
                        <b v-if="m.datas.referRoomBizIds && m.datas.referRoomBizIds.length > 0" class="b3"></b>
                        <b v-else-if="m.orderStatus === 9" class="b2"></b>
                        <b v-else-if="m.datas.description !== null" class="b1"></b>
                        <div>
                          <span>{{m.datas.customerName.slice(0,1)}}</span>
                          <span>{{m.datas.genderName==='男' ? '先生' : '女士'}}</span>
                          <span>{{m.datas.customerPhoneNumber.slice(-4)}}</span>
                        </div>
                        <div>{{m.datas.hotelName}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lock" v-if="false">
                  <i class="el-icon-s-goods"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="state">
      <div class="state-group">
        <div class="group-item state-hint">状态提示:</div>
        <div class="group-item state-0">未定</div>
        <div class="group-item state-1">预留</div>
        <div class="group-item state-2">入坐</div>
        <div class="group-item state-3">
          <b style="background: red"></b>
          有备注
        </div>
        <div class="group-item state-3">
          <b style="background: #ffdd00"></b>
          有翻台
        </div>
        <div class="group-item state-3">
          <b style="background: #4005FE"></b>
          有关联
        </div>
      </div>
    </div>
    <zeropoint-details ref="details"></zeropoint-details>
  </div>
</template>

<script>
import { getQueryData } from '@/utils/postForm'
import ZeropointDetails from '@/components/order/ZeropointDetails'
const roomboxShow = []
const roomItemShow = []
let dateTime = null
let remainingRoom = {}
let recordsAll = []
export default {
  name: 'SalesControlTable',
  data() {
    return {
      roomboxShow: [],
      roomItemShow: [],
      flageShow: [null, 1, 2, 3],
      recordslist: [],
      form: {
        radio: 0,
        flage: false,
        dinnerZoneBizId: 'all',
        rangePersonBizId: 'all'
      },
      date: {
        year: '',
        month: '',
        day: '',
        week: ''
      },
      dinnerZoneList: [],
      rangePersonlist: [],
      value: true
    }
  },
  components: {
    ZeropointDetails
  },
  provide() {
    return {
      initAria: this.initAria
    }
  },
  methods: {
    radioChange(e) {
      if (this.form.flage) {
        if (this.form.radio === 0) {
          this.flageShow = [null, 2]
        } else {
          this.flageShow = [null, 1]
        }
      } else {
        this.flageShow = [null, 1, 2, 3]
      }
    },
    onRoomboxChange() {
      let recordslist = []
      if (this.form.dinnerZoneBizId !== 'all') {
        recordslist = recordsAll.filter(o => {
          return o.dinnerZoneBizId === this.form.dinnerZoneBizId
        })
      } else {
        recordslist = recordsAll
      }
      recordslist.forEach(o => {
        if (this.form.rangePersonBizId !== 'all') {
          o.datas = o.datas.filter(m => {
            return m.rangePersonBizId === this.form.rangePersonBizId
          })
        }
      })
      // console.log('recordslist', recordslist)
    },
    flageChange(e) {
      if (e) {
        if (this.form.radio === 0) {
          this.flageShow = [null, 2]
        } else {
          this.flageShow = [null, 1]
        }
      } else {
        this.flageShow = [null, 1, 2, 3]
      }
    },
    // 获取当前时间
    initDay() {
      const date = new Date()
      if (date.getHours() >= 0 && date.getHours() <= 14) {
        this.form.radio = 0
      } else {
        this.form.radio = 1
      }
      this.flageShow = [null, 1, 2, 3]
    },
    // 初始化日期，选择日期
    initDate() {
      this.initDay()
      this.date = {
        year: dateTime.getFullYear().toString(),
        month: (dateTime.getMonth() + 1).toString().padStart(2, '0'),
        day: dateTime
          .getDate()
          .toString()
          .padStart(2, '0'),
        week: '周' + '日一二三四五六'.charAt(dateTime.getDay())
      }
    },
    // 日期改变事件
    onDateChange(val) {
      dateTime = new Date(dateTime.setDate(dateTime.getDate() + val))
      this.initDate()
      this.initDay()
      this.initAria()
    },
    // 点击事件
    onDayClick(row, time) {
      if (time.status === 1) {
        this.$refs.details.getDetails(time.datas, this.recordslist)
      } else {
        const data = {
          hotelBizId: row.hotelBizId,
          roomBizId: row.bizId,
          reserveTime: time.bizId,
          date: this.date,
          remainingRoom
        }
        // 加入keepalive缓存
        this.$store.commit('addKeepAliveCache', '零点预定')
        // 添加tags标签
        const submenu = {
          path: '/zeropoint/reserve',
          name: '零点预定',
          bizId: '/zeropoint/reserve'
        }
        // 修改选中菜单
        this.$store.commit('selectMenu', submenu)
        this.$store.commit('setZeropointReservation', data)
        this.$router.push('/zeropoint/reserve')
      }
    },
    // 初始化下拉框
    async init() {
      const dinnerZone = await getQueryData('dinnerZone')
      this.dinnerZoneList = dinnerZone.map(o => {
        roomboxShow.push(o.bizId)
        this.roomboxShow = roomboxShow
        return {
          value: o.bizId,
          label: o.name,
          bizId: o.bizId
        }
      })
      this.dinnerZoneList.unshift({
        value: 'all',
        label: '全部',
        bizId: 'all'
      })
      const rangePerson = await getQueryData('rangePerson')
      this.rangePersonlist = rangePerson.map(o => {
        roomItemShow.push(o.bizId)
        this.roomItemShow = roomItemShow
        return {
          value: o.bizId,
          label: o.name,
          bizId: o.bizId
        }
      })
      this.rangePersonlist.unshift({
        value: 'all',
        label: '全部',
        bizId: 'all'
      })
    },
    // 初始化销控表数据
    async initAria() {
      this.recordslist = []
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId
      }
      const res = await this.getRequest('room/all', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.recordslist = records
      recordsAll = records
      //  console.log('recordsAll', recordsAll)
      loading.close()
      await this.queryAria()
    },
    // 查询销控表数据
    async queryAria() {
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        reserveDate:
          this.date.year + '-' + this.date.month + '-' + this.date.day
      }
      const res = await this.getRequest('dinner/reserve/calendars', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      records.forEach(o => {
        for (let i = 0; i < this.recordslist.length; i++) {
          for (let j = 0; j < this.recordslist[i].datas.length; j++) {
            const zhi = this.recordslist[i].datas[j]
            if (zhi.bizId === o.roomBizId) {
              for (let k = 0; k < zhi.timeQuantums.length; k++) {
                const time = zhi.timeQuantums[k]
                if (time.bizId === o.reserveTime) {
                  if (!remainingRoom[o.reserveTime]) {
                    remainingRoom[o.reserveTime] = []
                  }
                  remainingRoom[o.reserveTime].push(o.roomBizId)
                  time.datas = o
                  time.orderStatus = o.orderStatus
                  time.status = 1
                  zhi.status += k + 1
                  return
                }
              }
            }
          }
        }
      })
    }
  },
  mounted() {
    remainingRoom = {}
    dateTime = new Date()
    this.initAria()
    this.init()
    this.initDate()
  }
}
</script>

<style lang="scss" scoped>
.salespersons-page {
  width: 98%;
  height: 100%;
  background: #f7f7f7;
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  .head {
    display: flex;
    height: 80px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10%;
    box-sizing: border-box;
    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #666;
      i {
        text-shadow: 2px 2px 2px #ccc;
        margin: 0px 30px 0;
        cursor: pointer;
      }
      .text {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
  .ballroom {
    width: 100%;
    overflow: auto;
    height: 68vh;
    border-radius: 5px;
    padding: 0 20px;
    .roombox {
      margin-bottom: 20px;
      h3 {
        margin-bottom: 10px;
        padding-bottom: 5px;
        padding-left: 5px;
        font-size: 16px;
        border-bottom: 1px solid #eeeeeeee;
      }
      .roomlist {
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        .room-iten {
          width: 80px;
          height: 100px;
          margin-right: 20px;
          border-radius: 3px;
          position: relative;
          border: 1px solid #eeeeee;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          overflow: hidden;
          .times {
            .active {
              width: 100%;
              height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              padding: 0;
              margin: 0;
              background: #d7d3cf;
              cursor: pointer;
              color: #666;
              font-size: 12px;
              text-align: center;
              position: relative;
              b {
                position: absolute;
                top: 2px;
                right: 2px;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
              .b1 {
                background: #f9040f;
              }
              .b2 {
                background: #ffdd00;
              }
              .b3 {
                background: #4005fe;
              }
              .b4 {
                background: #000;
              }
            }
            .active3 {
              background: #ffdd00;
              color: #555;
            }
            .active7 {
              background: #37d15d;
              color: #555;
            }
          }
          .info {
            width: 100%;
            margin: 0 auto;
            border-radius: 5px;
            height: 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .lock {
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.5);
            z-index: 10;
            color: #fff;
            font-size: 30px;
            text-align: center;
            line-height: 100px;
          }
        }
      }
    }
  }
  .state {
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    .state-group {
      width: 600px;
      display: flex;
      font-size: 13px;
      justify-content: space-around;
      .group-item {
        padding: 2px 10px;
        border-radius: 3px;
        color: #333;
        bdisplay: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 5px;
      }
      .state-hint {
        color: #ffdd00;
      }
      .state-0 {
        background: #e5e5e5;
      }
      .state-1 {
        background: #ffdd00;
      }
      .state-2 {
        background: #15d37d;
      }
      .state-3 {
        background: #f6f6f6;
      }
    }
  }
}
</style>
