<template>
  <div class="rolemanage-page">
    <div class="buttongroup">
      <!-- <el-input style="width:300px;margin-left:10px" maxlength="50" placeholder="角色名称" clearable v-model="search.name"></el-input> -->
      <el-select style="width:300px;margin-left:10px" v-model="search.id" placeholder="选择角色" filterable clearable>
        <el-option v-for="item in roleArry" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-button style="margin-left:10px" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
      <el-button @click="onAddClick(null)" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table height="85%" :data="tableData" style="width: 100%" ref="multipleTable" v-loading="Loading" border>
      <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
      <el-table-column align="center" prop="name" label="角色名称">
        <template slot-scope="scope">
          {{scope.row.name=='角色-零点'?'酒店管理员':scope.row.name=='角色-宴会'?'酒店管理员':scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="description" label="描述">
      </el-table-column>
      <el-table-column align="center" prop="createTimeR" label="创建日期">
        <template slot-scope="scope">
          {{parseTime(scope.row.createTimeR,'{y}-{m}-{d}')}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.name!='角色-零点'&&scope.row.name!='角色-宴会'" icon="el-icon-edit" size="mini" type="primary" @click="onAddClick(scope.row)"></el-button>
          <el-button v-if="scope.row.name!='角色-零点'&&scope.row.name!='角色-宴会'" icon="el-icon-delete" size="mini" type="danger" @click="postDeleteRole(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Position',
  data() {
    return {
      trueLabel: [],
      data: [],
      tableData: [],
      form: {
        id: null,
        type: '',
        name: '',
        code: '',
        area: '',
        system: ''
      },
      authorityData: [],
      total: 0, // 总条数
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotel_id: '',
        hotelName: ''
      },
      Loading: false,
      roleArry: []
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    if (orgs != undefined && orgs != null) {
      var hotel = JSON.parse(orgs)
      this.search.hotel_id = hotel[0].id
      this.getRoleData()
      this.bindAll()
    } else {
      // 跳转登录
      // -- 没有默认酒店，跳转到登录页面
      this.$router.push('/login')
    }
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 1. 查询
    query() {
      this.search.pageNumber = 1
      this.getRoleData()
    },
    // 2. 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getRoleData()
    },
    // 3. 查询所有角色
    getRoleData() {
      this.Loading = true
      this.getRequest('sys/role/getPage', this.search)
        .then(res => {
          this.Loading = false
          //   console.log('角色：' + JSON.stringify(res))
          if (res.code == 200) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.Loading = false
        })
    },
    // 4. 打开添加或修改--跳转页面
    onAddClick(row) {
      this.$store.commit('setPositionAuthority', row)
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '权限设置')
      // 添加tags标签
      const submenu = {
        path: '/setup/authority-authority',
        name: '权限设置',
        bizId: '/setup/authority-authority'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$router.push(submenu.path)
    },
    // 5. 删除角色
    postDeleteRole(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('sys/permission/delPosition/' + row.id)
          .then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功!'
              })
              this.getRoleData()
            }
          })
          .catch(() => {
            this.$message({
              duration: 10000,
              type: 'info',
              message: '已取消删除'
            })
          })
      })
    },
    // 6. 查询所有角色
    bindAll() {
      this.getRequest('sys/role/getByHotel/' + this.search.hotel_id, {
        hotelid: this.search.hotel_id
      }).then(res => {
        // console.log('角色22：' + JSON.stringify(res))
        if (res.code == 200 && res.data && res.data.length > 0) {
          for (var i = 0; i < res.data.length; i++) {
            if (
              res.data[i].name == '角色-零点' ||
              res.data[i].name == '角色-宴会'
            ) {
              res.data[i].name = '酒店管理员'
              break
            }
          }
          this.roleArry = res.data
        } else {
          this.roleArry = []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rolemanage-page {
  padding: 10px;
  box-sizing: border-box;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .authorityData {
    width: 100%;
    height: 500px;
    overflow: auto;
  }
}
</style>
