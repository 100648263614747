<template>
  <div class="account-page">
    <el-card class="card">
      <div class="buttongroup">
        <el-input style="width:300px;margin-left:10px" maxlength="50" placeholder="企业名称" clearable v-model="search.name"></el-input>
        <el-input style="width:200px;margin-left:10px" maxlength="50" placeholder="电话" clearable v-model="search.phone"></el-input>
        <el-button style="margin-left:10px" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
      </div>
      <el-table
        height="65vh"
        v-loading="showLoad"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
        style="width: 100%"
        ref="multipleTable"
        border
      >
        <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="name" label="酒店名称"></el-table-column>
        <el-table-column align="center" prop="contactUserName" label="酒店联系人"></el-table-column>
        <el-table-column align="center" prop="phoneNumber" label="联系电话"></el-table-column>
        <el-table-column align="center" prop="type" label="注册类型" :formatter="typeFormatter"></el-table-column>
        <el-table-column align="center" prop="createTime" label="申请时间">
          <template slot-scope="scope">
            {{ parseTime(scope.row.createTime, '{y}-{m}-{d} {h}:{i}:{s}') }}
          </template>
        </el-table-column>
        <el-table-column width="100px" align="center" prop="status" label="状态" :formatter="statusFormatter"></el-table-column>
        <el-table-column align="center" prop="address" width="250" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="show(scope.row)">查看</el-button>
            <el-button size="mini" type="text"  v-if="scope.row.status != 1" @click="del(scope.row)">删除</el-button>
            <el-button size="mini" type="text" @click="monitor(scope.row)" v-if="scope.row.status === 0">激活账户</el-button>
            <!-- <el-button size="mini" type="text" @click="info(scope.row)" v-if="scope.row.status !== 0">详情</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange"></el-pagination>
    </el-card>

    <!-- 修改酒店状态 -->
    <el-dialog :close-on-click-modal="false" title="激活账户" :visible.sync="stateDialogVisible" width="40%" center>
      <el-form ref="stateForm" :rules="rules" label-width="120px" :model="stateForm">
        <el-form-item label="酒店名称" prop="name">
          {{ stateForm.name }}
        </el-form-item>
        <el-form-item label="注册类型" prop="name">
          {{ stateForm.type === 'dinner' ? '零点' : '宴会' }}
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="stateForm.status">
            <el-radio :label="1">激活试用</el-radio>
            <el-radio :label="2">驳回申请</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="有效期" prop="effectiveTime" v-if="stateForm.status === 1">
          <el-date-picker v-model="stateForm.effectiveTime" type="datetime" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="选择有效截止日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="驳回原因" v-if="stateForm.status === 2" prop="content">
          <el-input v-model="stateForm.content" placeholder="请输入驳回原因"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" v-loading="showLoad" class="dialog-footer">
        <el-button @click="stateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postState">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="信息查看" :visible.sync="centerDialogVisible" width="40%" center>
      <el-form ref="stateForm" :rules="rules" label-width="120px" :model="stateForm">
        <el-form-item label="酒店名称" prop="name">
          {{ stateForm.name }}
        </el-form-item>
        <el-form-item label="酒店联系人" prop="contactUserName">
          {{ stateForm.contactUserName }}
        </el-form-item>
        <el-form-item label="联系电话" prop="phoneNumber">
          {{ stateForm.phoneNumber }}
        </el-form-item>
        <el-form-item label="账号类型">
          {{ typeFormatter(stateForm) }}
        </el-form-item>
        <el-form-item label="状态" prop="status">
          {{ statusFormatter(stateForm) }}
        </el-form-item>
        <el-form-item label="有效期" v-if="stateForm.status === 1">
          {{ stateForm.effectiveTime }}
        </el-form-item>
        <el-form-item label="审核时间" v-if="stateForm.updateTime">
          {{ stateForm.updateTime }}
        </el-form-item>
        <el-form-item label="驳回原因" v-if="stateForm.status === 2" prop="content">
          {{ stateForm.content }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Account',
  data() {
    return {
      showLoad: false,
      data: [],
      status: -1,
      typeOptions: [
        {
          value: 4,
          label: '零点'
        },
        {
          value: 5,
          label: '宴会'
        },
        {
          value: -1,
          label: '其他'
        }
      ],
      options: [
        // {
        //   value: -1,
        //   label: '全部'
        // },
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '禁用'
        }
      ],
      isSettingvalue: '',
      userSelection: [],
      dialogVisible: false,
      centerDialogVisible: false,
      stateDialogVisible: false,
      dialogDataForm: {
        dialog: false,
        title: '详情',
        visible: false,
        loading: false,
        timer: null,
        right: 'right',
        data: {},
        content: [
          { label: '注册酒店名称:', prop: 'name' },
          { label: '申请人:', prop: 'contactUserName' },
          { label: '联系电话:', prop: 'phoneNumber' },
          { label: '申请类型:', prop: 'typec' },
          { label: '状态:', prop: 'statusc' },
          { label: '备注:', prop: 'content' },
          { label: '注册审核时间:', prop: 'updateTime' }
        ]
      },
      stateForm: {
        effectiveTime: '',
        status: '',
        content: ''
      },
      title: '添加',
      accountForm: {
        name: '',
        userName: '',
        phone: '',
        state: 4
      },
      form: {},
      total: 0, // 总条数
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotel_id: '',
        hotelName: ''
      },
      tableData: [],
      messageAccountVisble: false,
      rules: {
        name: [{ required: true, message: '酒店名称不能为空', trigger: 'blur' }],
        userName: [{ required: true, message: '管理员登录名不能为空', trigger: 'blur' }],
        state: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [{ required: true, message: '管理员电话不能为空', trigger: 'blur' }],
        effectiveTime: [{ required: true, message: '有效截止日期不能为空', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    // 查询翻页
    this.getData()
  },
  methods: {
    statusFormatter(row) {
      switch (row.status) {
        case 0:
          return '未审核'
        case 1:
          return '已审核'
        case 2:
          return '审核未通过'
      }
    },
    typeFormatter(row) {
      switch (row.type) {
        case 'dinner':
          return '零点'
        case 'banquet':
          return '宴会'
      }
    },
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 1. 搜索事件
    query() {
      this.search.pageNumber = 1
      this.getData()
    },
    // 2. 翻页查询--查询所有用户
    getData() {
      this.showLoad = true
      this.getRequest('register/getPage', this.search)
        .then(res => {
          //  console.log('res:', res)
          this.showLoad = false
          if (res.code == 200 && res.data && res.data.total > 0) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.showLoad = false
        })
    },
    // 3.1 打开修改状态
    monitor(row) {
      this.stateForm = {
        ...row
      }
      this.stateDialogVisible = true
    },
    info(row) {
      const typec = this.typeFormatter(row)
      const statusc = this.statusFormatter(row)
      this.dialogDataForm.data = {
        typec,
        statusc,
        ...row
      }
      console.log(row)
      this.dialogDataForm.visible = true
    },
    // 激活
    postState() {
      this.showLoad = true
      console.log(this.stateForm)
      this.post('register/activate', this.stateForm)
        .then(res => {
          this.showLoad = false
          this.stateDialogVisible = false
          //   console.log('修改：' + JSON.stringify(res))
          if (res.code == 200) {
            this.getData()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(err => {
          this.showLoad = false
        })
    },

    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getData()
    },
    show(row) {
      this.stateForm = ''
      this.getRequest(`register/getById/${row.id}`).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.stateForm = res.data
        }
      })
      this.centerDialogVisible = true
    },
    del(row) {
      this.$confirm('请确认是否要删除当前餐区, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('register/delByIds/' + row.id)
          .then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            })
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-page {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    .buttongroup {
      margin-bottom: 10px;
      //   display: flex;
      //   justify-content: space-between;
    }
    .first-pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .elpagination {
      padding: 0;
      margin-right: -5px;
    }
  }
}
/* 弹窗 */
.formgeo {
  text-align: left;
}

.formgeo /deep/ .el-form-item__content {
  width: 135px !important;
}

*/deep/.view-dialog > .el-dialog {
  margin-top: 15vh !important;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 2px 2px 5px #d0d0d0;
  width: 1439px;
}

*/deep/.view-dialog > .el-dialog > .el-dialog__header {
  width: 100%;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #f4f5f7;
  border-radius: 12px 12px 0px 0px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #252b3a;
  padding: 0 0 0 40px;
  padding-left: 0;
}

*/deep/.view-dialog > .el-dialog > .el-dialog__footer {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

*/deep/.dialog-form > .el-form-item {
  margin-bottom: 30px;
}

*/deep/.dialog-form > .el-form-item > .el-form-item__content > .el-input > .el-input__inner {
  width: 220px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #dcdee2;
  border-radius: 3px;
}

*/deep/.dialog-form > .el-form-item > .el-form-item__content > .el-select > .el-input > .el-input__inner {
  width: 220px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #dcdee2;
  border-radius: 3px;
}

.view-dialog /deep/ .el-form-item__content {
  width: 250px;
}
</style>
