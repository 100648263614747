<template>
  <div class="menumanage-page">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>菜单管理</span>
      </div>
      <div class="card">
        <div class="leftcard">
          <!-- <el-button @click="append(data, 0)" type="primary" icon="el-icon-plus">子菜单菜单</el-button> -->
          <div class="trees">
            <el-tree class="tree" ref="tree" :data="data" :default-checked-keys="checkedArray" node-key="bizId" :default-expand-all="true" :props="defaultProps" :expand-on-click-node="true" :render-content="renderContent" @check-change="threeCheck" @node-click="handleNodeClick" @check="selectCheck">
            </el-tree>
          </div>
        </div>
        <div class="rightcard">
          <el-card>
            <div slot="header" class="clearfix">
              <span>{{ form.tit }}</span>
            </div>
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
              <el-form-item label="上级菜单">
                {{form.parentName}} <span v-if="(form.parentName==undefined||form.parentName==null||form.parentName=='')">请从左侧选择父级别菜单</span>
                <!-- <el-input v-model="form.name"></el-input> -->
              </el-form-item>
              <el-form-item label="权限名称" prop="name">
                <el-input style="width:400px" maxlength="50" v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="权限标识" prop="codes">
                <el-input style="width:400px" maxlength="50" v-model="form.codes"></el-input>
              </el-form-item>
              <el-form-item label="描述" prop="describe">
                <el-input style="width:400px" maxlength="50" v-model="form.describe"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button v-if="this.form.tit != '菜单编辑'" @click="onMenuAdd" type="primary">提交添加</el-button>
                <el-button v-if="this.form.tit == '菜单编辑'" @click="onMenuUpdate" type="primary">保存修改</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  name: 'PermissionTemplate',
  data() {
    return {
      data: [],
      form: {
        tit: '权限模板配置',
        id: '',
        name: '',
        parentName: '',
        parentId: '', // 父id
        codes: '',
        describe: ''
      },
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: (data, node) => {
          if (node.level === 2) {
            return true
          }
        }
      },
      checkedArray: [],
      rules: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        codes: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getMentTree()
  },
  methods: {
    // 1. 加载菜单
    getMentTree() {
      this.getRequest('app/permissions/authTree').then(res => {
        // console.log('加载菜单：' + JSON.stringify(res))
        if (res.code == 200) {
          this.data = res.data.data
        }
      })
    },
    // 2. 打开添加
    append(data) {
      //  console.log(data)
      this.form = {
        tit: '添加权限',
        name: '',
        parentName: data.label,
        parentId: data.id, // 父id
        codes: '',
        describe: ''
      }
    },
    clear() {
      this.form = {
        tit: '权限模板配置',
        id: '',
        name: '',
        parentName: '',
        parentId: '', // 父id
        codes: '',
        describe: ''
      }
    },
    // 2.1 选择上级菜单
    handleNodeClick(data) {
      if (this.form.tit == '添加子级权限') {
        this.form.parentName = data.label
        this.form.parentId = data.id
        this.form.level = Number(data.level) + 1
      }
    },
    // 3. 提交添加
    onMenuAdd() {
      // console.log('提交：' + JSON.stringify(this.form))
      if (
        this.form.parentId == undefined ||
        this.form.parentId == null ||
        this.form.parentId == ''
      ) {
        this.$notify({
          title: '提示',
          message: '请先从左侧选择父级菜单',
          type: 'error',
          duration: 2000
        })
        return false
      }
      // 执行添加
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.post('app/permissions/add', this.form).then(res => {
            if (res.code == 200) {
              // reset
              // this.append(null, this.form.tit == '添加权限组' ? 1 : 0)
              this.$message({
                duration: 10000,
                message: '添加成功',
                type: 'success'
              })
              // 刷新左侧菜单
              this.getMentTree()
              this.clear()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    },
    // 4. 打开修改
    edit(data) {
      // 加载选择项目
      this.$refs.tree.setCheckedNodes([data])
      console.log('选择修改：' + JSON.stringify(data))
      data.tit = '菜单编辑'
      if (data.parentId == '0') {
        data.parentName = '无'
      }
      // this.form.name = data.label
      this.form = {
        name: data.label,
        codes: data.code,
        ...data
      }
    },
    // 5. 提交修改菜单
    onMenuUpdate() {
      if (
        this.form.parentId == undefined ||
        this.form.parentId == null ||
        this.form.parentId == ''
      ) {
        this.$notify({
          title: '提示',
          message: '请先从左侧选择父级菜单',
          type: 'error',
          duration: 2000
        })
        return false
      }
      // 执行修改
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.post('app/permissions/edit', this.form).then(res => {
            if (res.code == 200) {
              this.clear()
              this.$message({
                duration: 10000,
                message: '修改成功',
                type: 'success'
              })
              // 刷新左侧菜单
              this.getMentTree()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    },
    // 6. 执行删除
    remove(node, data) {
      if (data.children != undefined && data.children.length > 0) {
        this.$notify({
          title: '提示',
          message: '请先删除当前菜单下属的所有子菜单',
          type: 'error',
          duration: 2000
        })
        return
      }
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // console.log('data:' + JSON.stringify(data))
          this.deleteRequest('app/permissions/del/' + data.id, {
            id: data.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功!'
              })
              this.getMentTree()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 加载树子节点
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span class="node-li">{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.append(data)}
            >
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button size="mini" type="text" on-click={() => this.edit(data)}>
              <i class="el-icon-edit"></i>
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </span>
        </span>
      )
    },
    // 复选框选择事件--暂无用
    selectCheck(data, type) {
      this.checkedArray = type.checkedKeys
    },
    // 树单选--暂无用
    threeCheck(data, checked, node) {
      if (checked) {
        // this.$refs.tree.setCheckedNodes([data])
        // this.edit(data)
      }
    }
  }
}
</script>

<style lang="scss">
.menumanage-page {
  .box-card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .clearfix {
      font-size: 16px;
    }
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 20px;
      .node-li {
        display: inline-block;
        margin-right: 20px;
      }
    }
    .card {
      display: flex;
      justify-content: space-between;
      height: calc(100% - 30px);
      padding-bottom: 20px;
      .leftcard {
        width: 20%;
        height: 100%;
        margin-right: 20px;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 2px 2px 10px #ccc;
        .trees {
          width: calc(100% + 30px);
          height: 66vh;
          overflow: auto;
          padding: 10px 0;
          padding-right: 10px;
        }
      }
      .rightcard {
        flex: 1;
        height: auto;
      }
    }
  }
  .pagination {
    margin-top: 20px;
    margin-bottom: -20px;
    display: flex;
    justify-content: flex-end;
    .elpagination {
      padding: 0;
      margin-right: -5px;
    }
  }
  .search {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: -30px;
    .search-input {
      width: 30%;
      margin-right: 10px;
    }
  }
  /deep/ {
    .is-leaf::before {
      // 隐藏或加透明都可以
      // display: none;
      opacity: 0;
    }
  }
}
</style>
