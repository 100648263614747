<template>
  <div class="create-barrage-page">
    <div class="box">
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="活动名称" prop="name">
          <el-input style="width: 350px" v-model="form.name" placeholder="活动名称"></el-input>
        </el-form-item>
        <el-form-item label="生效时间" prop="arrayDate">
          <el-date-picker v-model="form.arrayDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="宴会举办日期" prop="banquetDate">
            <el-date-picker v-model="form.banquetDate" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="20px" prop="timeQuantumCode">
            <el-radio-group v-model="form.timeQuantumCode">
              <el-radio label="AM">中午</el-radio>
              <el-radio label="PM">晚上</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="所属宴会厅" prop="hallBizId">
          <el-select @change="onBallroomChange" v-model="form.hallBizId" placeholder="请选择">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.hallBizId" label="宴会类型" prop="categoryBizId">
          <el-select v-model="form.categoryBizId" placeholder="请选择">
            <el-option v-for="item in categories" :key="item.bizId" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="弹幕" prop="barrageGroupName">
          <el-select v-model="form.barrageGroupName" placeholder="请选择">
            <el-option v-for="item in barrageGroupData" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片上传" prop="attachments">
          <el-upload :multiple="true" name="files" :data="uploadData" :file-list="fileList" :headers="uploadHeaders" :action="action + 'attachment/upload'" list-type="picture-card" :on-success="handleSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i style="font-size: 20px" class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :close-on-click-modal='false' :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="互动关键词" prop="keyword">
          <el-input style="width: 350px" v-model="form.keyword" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color: red">注：如数字1，客人关注公众号后输入此数字，可调出本场活动。</span>
        </el-form-item>
        <el-form-item label="背景选择" prop="backgroundCode">
          <vue-select-tu v-model="form.backgroundCode" :require="false" :multiple="false" @change="changeData" :imgData="imgData" label="">
          </vue-select-tu>
        </el-form-item>
        <el-form-item label="备注">
          <span style="color: red">还在举办的活动关键词不允许重复，已经举办完毕的活动不算数</span>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSetData" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { baseUrl, imageUrl } from '@/utils/api'
import rules from '@/utils/rules'
import store from '@/store'
let token = ''
if (window.localStorage.getItem('token')) {
  token = window.localStorage.getItem('token').toString()
}
export default {
  name: 'CreateBarrage',
  data() {
    return {
      rules,
      action: baseUrl,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadHeaders: {
        'Access-Control-Allow-Origin': '*',
        accessToken: 'Bearer ' + token
      },
      uploadData: {},
      fileList: [],
      form: {
        hotelBizId: store.state.hotellist[0].bizId,
        hotelName: store.state.hotellist[0].name,
        orgBizId: '',
        name: '',
        arrayDate: [],
        banquetDate: '',
        timeQuantumCode: '',
        hallBizId: '',
        hallName: '',
        categoryBizId: '',
        categoryName: '',
        barrageGroupBizId: '',
        barrageGroupName: '',
        backgroundCode: [],
        backgroundUrl: '',
        attachments: [],
        keyword: ''
      },
      imgData: [
        {
          name: '浪漫主义',
          value: '1',
          url: 'http://res.luxiaoyi.vip/static/images/active/bg1.png',
          alt: '标签1'
        },
        {
          name: '唯美主义',
          value: '2',
          url: 'http://res.luxiaoyi.vip/static/images/active/bg2.png',
          alt: '标签2'
        },
        {
          name: '唯美简约',
          value: '3',
          url: 'http://res.luxiaoyi.vip/static/images/active/bg3.png',
          alt: '标签3'
        }
      ],
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      barrageGroupData: [] // 弹幕类型
    }
  },
  watch: {
    'form.arrayDate': function() {
      this.form.startDate = this.form.arrayDate[0]
      this.form.endDate = this.form.arrayDate[1]
    },
    'form.timeQuantumCode': function() {
      if (this.form.timeQuantumCode === 'AM') {
        this.form.timeQuantumName = '中午'
      } else {
        this.form.timeQuantumName = '晚上'
      }
    },
    'form.hallBizId': function() {
      for (let i = 0; i < this.ballroomList.length; i++) {
        if (this.form.hallBizId === this.ballroomList[i].bizId) {
          this.form.hallName = this.ballroomList[i].label
          this.form.categoryBizId = ''
          this.form.categoryName = ''
          this.onBallroomChange(this.form.hallBizId)
          break
        }
      }
    },
    'form.categoryBizId': function() {
      for (let i = 0; i < this.categories.length; i++) {
        if (this.form.categoryBizId === this.categories[i].bizId) {
          this.form.categoryName = this.categories[i].label
          break
        }
      }
    },
    'form.backgroundCode': function() {
      for (let i = 0; i < this.imgData.length; i++) {
        if (this.form.backgroundCode[0] === this.imgData[i].value) {
          this.form.backgroundUrl = this.imgData[i].url
          break
        }
      }
    }
  },
  methods: {
    // 获取宴会厅类型
    async getBallroomType() {
      const res = await this.getRequest('hall/all', {
        hotelBizId: this.$store.state.hotellist[0].bizId
      })
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.ballroomList = records.map(o => {
        return {
          value: o.code,
          label: o.name,
          bizId: o.bizId,
          categories: o.categories
        }
      })
      const row = this.$store.state.activitylist
      // console.log('row', row)
      if (row) {
        this.form = {
          ...row,
          backgroundCode: [row.backgroundCode] || [],
          arrayDate: [row.startDate, row.endDate]
        }
        let fileList = [...row.attachments]
        if (fileList && fileList.length > 0) {
          fileList = fileList.map(o => {
            return {
              name: o.name,
              url: o.previewUrl
            }
          })
          this.fileList = fileList
        }
        for (let i = 0; i < this.imgData.length; i++) {
          if (row.backgroundUrl === this.imgData[i].url) {
            this.form.backgroundCode = [this.imgData[i].value]
            break
          }
        }
      }
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.categories = []
      const records = this.ballroomList.filter(i => {
        return i.bizId === e
      })
      if (records.length === 0) {
        return false
      }
      const { categories } = records[0]
      if (categories && categories.length > 0) {
        this.categories = categories.map(o => {
          return { value: o.code, label: o.name, bizId: o.bizId }
        })
      }
      const row = this.$store.state.activitylist
      if (row) {
        this.form.categoryBizId = row.categoryBizId
      }
    },
    changeData(val) {
      // console.log(val)
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      const data = {
        bizId: records[0].bizId,
        suffix: records[0].suffix,
        name: records[0].originalFileName,
        previewUrl:
          imageUrl.substr(0, imageUrl.length - 1) + records[0].previewUrl
      }
      if (!this.form.attachments.includes(data)) {
        this.form.attachments.push(data)
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      const attachments = []
      for (let i = 0; i < this.form.attachments.length; i++) {
        for (let j = 0; j < fileList.length; j++) {
          if (
            this.form.attachments[i].name === fileList[j].name &&
            imageUrl + this.form.attachments[i].previewUrl === fileList[j].url
          ) {
            attachments.push(this.form.attachments[i])
            break
          }
        }
      }
      this.form.attachments = attachments
    },
    // 设置数据
    onSetData() {
      this.$refs.form.validate(async flage => {
        if (flage) {
          let type = 'add'
          if (this.form.bizId) {
            type = 'update'
          }
          const data = { ...this.form }
          let info = {}
          if (window.localStorage.getItem('info')) {
            info = JSON.parse(window.localStorage.getItem('info'))
          }
          data.createdBy = info.loginUsername || ''
          delete data.arrayDate
          data.backgroundCode = data.backgroundCode[0]
          data.attachments = JSON.stringify(data.attachments)
          const res = await this.postRequestShort(
            `screen/${type}/command`,
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message(res.message)
            return false
          }
          this.$message({
            type: 'success',
            message: '创建成功'
          })
          setTimeout(e => {
            const submenu = {
              path: '/screen/activity-list',
              name: '活动列表',
              bizId: '/screen/activity-list'
            }
            // 加入keepalive缓存
            this.$store.commit('addKeepAliveCache', '活动列表')
            // 修改选中菜单
            this.$store.commit('selectMenu', submenu)
            this.$router.push('/screen/activity-list')
          }, 300)
        }
      })
    },
    // 查询弹幕组数据
    async getQueryCategoryData() {
      const data = {
        dataCode: 'barrage'
        // pageNumber: 1,
        // pageSize: 100,
        // status: '-1'
      }
      const res = await this.getRequestShort(
        'data/metadata/barrage/group/pageable',
        data
      )
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      if (pageModel.records.length === 0) return false
      this.barrageGroupData = pageModel.records
    }
  },
  mounted() {
    this.getQueryCategoryData()
    this.getBallroomType()
  }
}
</script>

<style lang="scss" scoped>
.create-barrage-page {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .box {
    overflow: auto;
    height: 100%;
    width: calc(100% + 30px);
    padding-bottom: 100px;
  }
}
</style>
