<template>
  <div>
    <el-dialog title="日历" top="3%" :visible.sync="dialogVisible" width="1500px">
      <el-date-picker style="position: absolute" v-model="value" format="yyyy 年 MM 月" value-format="yyyy-MM" type="month" placeholder="选择月"></el-date-picker>
      <el-calendar v-model="value" id="calendar">
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template slot="dateCell" slot-scope="{ date, data }">
          <!--自定义内容-->
          <div style="height: 80px; overflow: auto">
            <div style="text-align: left">
              {{
                data.day
                  .split('-')
                  .slice(1)
                  .join('-')
              }}
            </div>
            <div v-for="(item, index) in calendarData" :key="index + 200">
              <div v-if="item.month.indexOf(data.day.split('-').slice(1)[0]) != -1">
                <div
                  v-if="
                    item.day.indexOf(
                      data.day
                        .split('-')
                        .slice(2)
                        .join('-')
                    ) != -1
                  "
                >
                  <el-tooltip v-for="(i, index) in item.festivals" :key="index + i" :content="i" class="item" effect="dark" placement="right">
                    <div style="margin: 3px" class="is-selected">{{ i }}</div>
                  </el-tooltip>
                  <div>
                    <span style="color: #15D37D">宜</span>
                    <el-tooltip v-for="(i, index) in item.dayJis" :key="index + i" :content="i" class="item" effect="dark" placement="right">
                      <span style="margin: 3px; color: #666" class="is-selected">{{ i }}</span>
                    </el-tooltip>
                  </div>
                  <div>
                    <span style="color: red">忌</span>
                    <el-tooltip v-for="(i, index) in item.dayYis" :key="index + i" :content="i" class="item" effect="dark" placement="right">
                      <span style="margin: 3px; color: #666" class="is-selected">{{ i }}</span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-calendar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onChangeDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCalendar } from '@/utils/lunar.js'
export default {
  name: 'Calendar',
  data() {
    return {
      dialogVisible: false,
      calendarData: [],
      value: new Date()
    }
  },
  watch: {
    value: function(val) {
      this.init()
    }
  },
  inject: ['changeDate'],
  methods: {
    async init() {
      this.calendarData = []
      if (typeof this.value === 'object') {
        this.value = this.value.getFullYear() + '-' + (this.value.getMonth() + 1)
      }
      const date = this.value.split('-')
      const data = {
        year: date[0],
        month: date[1]
      }

      // const res = await this.getRequest('calendar/days', data)
      // if (res.status !== 200) {
      //   this.$message(res.message)
      //   return false
      // }
      // const records = res.pageModel.records

      const records = getCalendar(data)
      records.forEach(o => {
        o.month < 10 ? (o.month = '0' + o.month) : (o.month = '' + o.month)
        o.day < 10 ? (o.day = '0' + o.day) : (o.day = '' + o.day)
      })
      this.calendarData = records
    },
    getDetails(date) {
      if (date) {
        this.value = date
      }
      this.init()
      this.dialogVisible = true
    },
    onChangeDate() {
      this.dialogVisible = false
      const date = this.value.split('-')
      if (date[1].length == 1) {
        date[1] = '0' + date[1]
      }
      const year = date[0]
      const month = date[1]
      this.changeDate(year, month)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-calendar__title {
  opacity: 0;
}
/deep/ .el-calendar-table thead th {
  text-align: center;
}
/deep/ .el-calendar-table__row > td {
  height: 84px;
  padding: 5px 0;
  box-sizing: border-box;
}
.is-selected {
  color: #f00;
  font-size: 10px;
  margin-top: 5px;
}
#calendar .el-button-group > .el-button:not(:first-child):not(:last-child):after {
  content: '当月';
}
</style>
