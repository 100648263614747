<template>
  <div class="salespersons-page">
    <div class="date">
      <i class="el-icon-d-arrow-left" @click="onDateChange(-1, 'year')"></i>
      <i class="el-icon-arrow-left" @click="onDateChange(-1, 'month')"></i>
      <span @click="selectDate" class="text">{{ date.year }}-{{ date.month }}</span>
      <i class="el-icon-arrow-right" @click="onDateChange(1, 'month')"></i>
      <i class="el-icon-d-arrow-right" @click="onDateChange(1, 'year')"></i>
    </div>
    <div class="ballroom">
      <div class="ballroom-data week">
        <div class="week-title">会议厅</div>
        <div class="week-list">
          <div :class="item.class" class="week-list-name" v-for="item in weekList" :key="item.id">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ballroom-data datacenter">
        <div class="ballroom-name">
          <div class="ballroom-name-item" v-for="item in recordslist" :key="item.bizId"> {{ item.name }}</div>
        </div>
        <div class="ballroom-list">
          <div class="ballroom-list-item" v-for="item in recordslist" :key="item.bizId">
            <div class="uponday">
              <div v-for="i in item.calendars" :key="i.bizId">
                <div v-for="(j, index) in i.timeQuantums" :key="j.bizId" @click="onDayClick(item.bizId, i, j, index)" :class="[(i.weekDay === '六' || i.weekDay === '日') ? 'redcolor' : '', 'itemuponday', 'color' + j.reserveStatus]">
                  <el-tooltip :content="item.name + '' + i.month + '月' + i.day + '日(农历:' + i.chineseMonth + '月' + i.chineseDay + ')周' + i.weekDay + j.name" placement="top">
                    <span v-if="index === 0 && j.selected === 0">{{ i.day }}</span>
                    <span v-if="index === 0 && j.selected === 1 && j.reserveStatus === 2">{{ i.day }}</span>
                    <span v-if="index === 0 && j.selected === 1 && j.reserveStatus !== 2" class="el-icon-goods" style="font-size: 20px"></span>
                    <span v-if="index === 1 && j.selected === 0">{{ i.chineseDay }}</span>
                    <span v-if="index === 1 && j.selected === 1 && j.reserveStatus === 2">{{ i.chineseDay }}</span>
                    <span v-if="index === 1 && j.selected === 1 && j.reserveStatus !== 2" class="el-icon-goods" style="color: #000; font-size: 20px"></span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="state">
      <div class="state-group">
        <div class="group-item state-hint">状态提示:</div>
        <div class="group-item state-0">未定</div>
        <div class="group-item state-1">预留</div>
        <div class="group-item state-2">已订</div>
      </div>
    </div>
    <el-dialog title="预留详情列表" :visible.sync="reservedDialogVisible" width="1000px">
      <h3 style="text-align: center">{{ reservedDate }}</h3>
      <div class="reserved-list">
        <div class="list-item" v-for="(item, index) in retainsList" :key="item.bizId">
          <div class="number">{{ index + 1 }}</div>
          <div class="center">
            <div>【客户姓名】{{ item.customerName }} <span>{{ item.categoryName }}</span></div>
            <div>【预留时间】{{ item.createdDt }}</div>
            <div>【客户经理】{{ item.customerManagerName }} <el-button @click="getDetails(item)" class="btn" type="text">详情</el-button>
            </div>
          </div>
          <div v-show="item.customerManagerName === info.loginUsername" class="sign">我</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reservedDialogVisible = false">取消</el-button>
        <el-button @click="booking" type="primary">预定</el-button>
      </span>
    </el-dialog>
    <MeetingDetails ref="child"></MeetingDetails>
    <Calendar ref="calendar"></Calendar>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import MeetingDetails from '@/components/order/MeetingDetails'
import Calendar from '@/components/order/Calendar'
import { socketUrl, wss } from '../../utils/api'
const date = new Date()
let data = {}
let loading
let query = {}
export default {
  name: 'SalesControlTable',
  data() {
    return {
      info: {},
      reserved: {},
      reservedDialogVisible: false,
      reservedDate: '',
      date: {
        year: '',
        month: '',
        day: ''
      },
      details: {},
      weekList: [], // 被选择月星期列表，默认当前月
      recordslist: [], // 数据列表
      retainsList: [], // 预留列表
      hallBizId: '',
      // websocket相关
      socketObj: '', // websocket实例对象
      // 心跳检测
      heartCheck: {
        vueThis: this, // vue实例
        timeout: 10000, // 超时时间
        timeoutObj: null, // 计时器对象——向后端发送心跳检测
        serverTimeoutObj: null, // 计时器对象——等待后端心跳检测的回复
        // 心跳检测重置
        reset: function() {
          clearTimeout(this.timeoutObj)
          clearTimeout(this.serverTimeoutObj)
          return this
        },
        // 心跳检测启动
        start: function() {
          this.timeoutObj && clearTimeout(this.timeoutObj)
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
          this.timeoutObj = setTimeout(() => {
            // 这里向后端发送一个心跳检测，后端收到后，会返回一个心跳回复
            this.vueThis.socketObj.send('HeartBeat')
            // console.log('发送心跳检测')
            this.serverTimeoutObj = setTimeout(() => {
              // 如果超过一定时间还没重置计时器，说明websocket与后端断开了
              // console.log('未收到心跳检测回复')
              // 关闭WebSocket
              this.vueThis.socketObj.close()
            }, this.timeout)
          }, this.timeout)
        }
      },
      socketReconnectTimer: null, // 计时器对象——重连
      socketReconnectLock: false, // WebSocket重连的锁
      socketLeaveFlag: false // 离开标记（解决 退出登录再登录 时出现的 多次相同推送 问题，出现的本质是多次建立了WebSocket连接）
    }
  },
  components: {
    MeetingDetails,
    Calendar
  },
  provide() {
    return {
      initAria: this.initAria,
      changeDate: this.changeDate
    }
  },
  mounted() {
    if (window.localStorage.getItem('info')) {
      this.info = JSON.parse(window.localStorage.getItem('info'))
    }
    this.initDate()
    this.initWeek()
    // this.initAria()
    // this.getBallroomType()
    // // websocket启动
    // this.createWebSocket()
  },
  methods: {
    // 选择日期
    selectDate() {
      const data = this.date.year + '-' + this.date.month
      this.$refs.calendar.getDetails(data)
    },
    getDetails(details) {
      const row = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        hallBizId: this.hallBizId,
        bizId: details.reservedBizId,
        retainBizId: details.bizId
      }
      this.$refs.child.getDetails(row)
    },
    // 查看详情列表
    getBallroomData(row) {
      this.$refs.child.getDetails(row)
    },
    // 初始化日期，选择日期
    initDate() {
      if (data && data.year) {
        this.date = {
          ...data
        }
      } else {
        this.date = {
          year: date.getFullYear().toString(),
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          day: date
            .getDate()
            .toString()
            .padStart(2, '0')
        }
      }
    },
    // 日期改变事件
    onDateChange(val, type) {
      if (type === 'year') {
        this.date.year = (parseInt(this.date.year) + parseInt(val)).toString()
      } else {
        if (parseInt(this.date.month) === 12 && val === 1) {
          this.date.year = (parseInt(this.date.year) + 1).toString()
          this.date.month = '01'
        } else if (parseInt(this.date.month) === 1 && val === -1) {
          this.date.year = (parseInt(this.date.year) - 1).toString()
          this.date.month = '12'
        } else {
          this.date.month = (parseInt(this.date.month) + parseInt(val))
            .toString()
            .padStart(2, '0')
        }
      }
      data = {
        year: this.date.year,
        month: this.date.month,
        day: this.date.day
      }
      this.initWeek()
      this.initAria()
    },
    // 日历日期改变
    changeDate(year, month) {
      this.date.year = year
      this.date.month = month
      this.initWeek()
      this.initAria()
    },
    // 初始化当月或者被选择的星期
    initWeek() {
      this.weekList = []
      const weekArray = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const week = new Date(
        this.date.year,
        parseInt(this.date.month) - 1,
        1
      ).getDay()
      const days = new Date(this.date.year, this.date.month, 0).getDate()
      for (let i = 0; i < days; i++) {
        this.weekList.push({
          id: uuid(),
          name: weekArray[(i + week) % 7],
          class:
            weekArray[(i + week) % 7] === '周六' ||
            weekArray[(i + week) % 7] === '周日'
              ? 'red'
              : 'block'
        })
      }
    },
    // 初始化销控表数据
    async initAria() {
      this.reservedDialogVisible = false
      loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        reservedCategory: 'meeting',
        year: this.date.year,
        month: this.date.month
      }
      const res = await this.getRequest('banquet/reserve/calendars', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      records.forEach(i => {
        if (i.reservedCalendars && i.reservedCalendars.length > 0) {
          i.reservedCalendars.forEach(o => {
            const newObj = i.calendars[o.day - 1].timeQuantums.find(
              m => m.code === o.timeQuantumCode
            )
            newObj.selected = 1
          })
        }
      })
      this.recordslist = records
      loading.close()
    },
    // 获取宴会厅类型
    async getBallroomType() {
      const res = await this.getRequest('hall/all', {
        reservedCategory: 'meeting',
        hotelBizId: this.$store.state.hotellist[0].bizId
      })
      if (res.status !== 200) {
        return false
      }
      let { records } = res.pageModel
      records = records.map(o => {
        return { value: o.code, label: o.name, bizId: o.bizId }
      })
      this.$store.commit('setBallroomList', records)
    },
    // 点击事件
    onDayClick(hallBizId, val, startTimeQuantumSelected, index) {
      const { reserveStatus } = val.timeQuantums[index]
      // val.month = (val.month + '').padStart(2, '0')
      // val.day = (val.day + '').padStart(2, '0')
      const date = `${val.year}-${val.month}-${val.day}`
      const ckTime = new Date(date).getTime()
      const dqTime = new Date().getTime() - 86400000
      if (ckTime <= dqTime && startTimeQuantumSelected.reserveStatus === 0) {
        this.$message('已经过去的日期不允许预订哦！')
        return false
      }
      if (
        startTimeQuantumSelected.selected === 1 &&
        startTimeQuantumSelected.reserveStatus !== 2
      ) {
        this.$message('该宴会厅不可预订')
        return false
      }
      // 修改选中菜单
      query = {
        hallBizId: hallBizId,
        bizId: startTimeQuantumSelected.reservedBizId,
        startDate: date,
        startTimeQuantumSelectedBizId: startTimeQuantumSelected.bizId
      }
      this.reservedDate =
        query.startDate +
        '(' +
        val.chineseMonth +
        '月' +
        val.chineseDay +
        ')周' +
        val.weekDay +
        '-' +
        startTimeQuantumSelected.name
      if (startTimeQuantumSelected.name === '中午') {
        this.reservedDateTime = 2
      } else {
        this.reservedDateTime = 3
      }
      this.$store.commit('setBanquetReservation', query)
      if (reserveStatus === 0) {
        // 添加tags标签
        const submenu = {
          path: '/meeting/reserve',
          name: '会议预定',
          bizId: '/meeting/reserve'
        }
        this.selectMenu(submenu)
        this.$router.push({
          path: submenu.path,
          query: {
            q: 1
          }
        })
      } else if (reserveStatus === 1) {
        if (val.retains && val.retains.length > 0) {
          // this.retainsList = val.retains.filter(o => {
          //   return o.startTimeQuantumReserved === this.reservedDateTime
          // })
          this.retainsList = val.retains
          this.reservedDialogVisible = true
          this.hallBizId = hallBizId
        }
        this.retainsList = val.retains
      } else if (reserveStatus === 2) {
        this.elegantRoomVisible = true
        data = {
          hotelBizId: this.$store.state.hotellist[0].bizId,
          hallBizId: hallBizId,
          bizId: startTimeQuantumSelected.reservedBizId
        }
        this.getBallroomData(data)
      }
    },
    // 点击菜单 - 传入name，添加到keepalive缓存页面
    selectMenu(item) {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', item.name)
      // 添加tags标签
      const submenu = {
        path: item.path,
        name: item.name,
        bizId: item.path
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
    },
    booking() {
      const querys = {
        hallBizId: query.hallBizId,
        startDate: query.startDate,
        startTimeQuantumSelectedBizId: query.startTimeQuantumSelectedBizId
      }
      this.$store.commit('setBanquetReservation', querys)
      // 添加tags标签
      const submenu = {
        path: '/meeting/reserve',
        name: '宴会预定',
        bizId: '/meeting/reserve'
      }
      this.selectMenu(submenu)
      this.$router.push({
        path: submenu.path,
        query: {
          q: 1
        }
      })
    },
    async hatFresh() {
      this.reservedDialogVisible = false
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        reservedCategory: 'meeting',
        year: this.date.year,
        month: this.date.month
      }
      const res = await this.getRequest('banquet/reserve/calendars', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      records.forEach(i => {
        if (i.reservedCalendars && i.reservedCalendars.length > 0) {
          i.reservedCalendars.forEach(o => {
            const newObj = i.calendars[o.day - 1].timeQuantums.find(
              m => m.code === o.timeQuantumCode
            )
            newObj.selected = 1
          })
        }
      })
      this.recordslist = records
    },
    // websocket启动
    createWebSocket() {
      const webSocketLink =
        socketUrl +
        '?id=' +
        window.localStorage.getItem('applicationName').toString() +
        '_v' +
        String(Math.random()).slice(0, 10) // webSocket地址
      // console.log(webSocketLink);
      if (!wss) {
        return
      }
      try {
        let token = ''
        if (window.localStorage.getItem('token')) {
          token = window.localStorage.getItem('token').toString()
        }
        if ('WebSocket' in window) {
          this.socketObj = new WebSocket(webSocketLink)
        } else if ('MozWebSocket' in window) {
          this.socketObj = new MozWebSocket(webSocketLink)
        }
        // websocket事件绑定
        this.socketEventBind()
      } catch (e) {
        // console.log('catch' + e)
        // websocket重连
        this.socketReconnect()
      }
    },
    // websocket事件绑定
    socketEventBind() {
      // 连接成功建立的回调
      this.socketObj.onopen = this.onopenCallback
      // 连接发生错误的回调
      this.socketObj.onerror = this.onerrorCallback
      // 连接关闭的回调
      this.socketObj.onclose = this.oncloseCallback
      // 向后端发送数据的回调
      this.socketObj.onsend = this.onsendCallback
      // 接收到消息的回调
      this.socketObj.onmessage = this.getMessageCallback

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        this.socketObj.close()
      }
    },
    // websocket重连
    socketReconnect() {
      if (this.socketReconnectLock) {
        return
      }
      this.socketReconnectLock = true
      this.socketReconnectTimer && clearTimeout(this.socketReconnectTimer)
      this.socketReconnectTimer = setTimeout(() => {
        //   console.log('WebSocket:重连中...')
        this.socketReconnectLock = false
        // websocket启动
        this.createWebSocket()
      }, 4000)
    },
    // 连接成功建立的回调
    onopenCallback: function(event) {
      // console.log('WebSocket:已连接')
      // 心跳检测重置
      this.heartCheck.reset().start()
    },
    // 连接发生错误的回调
    onerrorCallback: function(event) {
      // console.log('WebSocket:发生错误')
      // websocket重连
      this.socketReconnect()
    },
    // 连接关闭的回调
    oncloseCallback: function(event) {
      //  console.log('WebSocket:已关闭')
      // 心跳检测重置
      this.heartCheck.reset()
      if (!this.socketLeaveFlag) {
        // 没有离开——重连
        // websocket重连
        this.socketReconnect()
      }
    },
    // 向后端发送数据的回调
    onsendCallback: function() {
      // console.log('WebSocket:发送信息给后端')
    },
    // 接收到消息的回调
    getMessageCallback: function(msg) {
      // console.log(msg);
      //  console.log(msg.data)
      if (msg.data.indexOf('HeartBeat') > -1) {
        // 心跳回复——心跳检测重置
        // 收到心跳检测回复就说明连接正常
        // console.log('收到心跳检测回复')
        // 心跳检测重置
        this.heartCheck.reset().start()
      } else {
        // 普通推送——正常处理
        // console.log('收到推送消息,开始更新数据')
        // this.queryAria()
        if (msg.data === '2') {
          this.hatFresh()
          this.$forceUpdate()
        }
        const data = JSON.parse(msg.data)
        // 相关处理
        // console.log(data)
      }
    }
  },
  // 最后在beforeDestroy()生命周期内清除定时器：
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  destroyed() {
    // 离开标记
    this.socketLeaveFlag = true
    // 关闭WebSocket
    this.socketObj.close()
  },
  created() {
    //  console.log('离开标记', this.socketLeaveFlag)
  }
}
</script>

<style lang="scss" scoped>
.salespersons-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: #f7f7f7;
  position: relative;

  .date {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;

    //margin-top: 10px;
    i {
      text-shadow: 2px 2px 2px #ccc;
      margin: 5px 30px 0;
      cursor: pointer;
    }

    .text {
      font-weight: bold;
      cursor: pointer;
      font-size: 28px;
    }
  }

  .ballroom {
    width: 100%;
    overflow: hidden;
    height: 80%;
    overflow-y: auto;
    border-radius: 5px;
    margin-top: 10px;
    padding-right: 20px;

    .ballroom-data {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
    }

    .datacenter {
      max-height: 600px;
      height: 65vh;
      overflow-y: auto;
      width: calc(100% + 37px);
      padding-right: 20px;

      .ballroom-name {
        width: 100px;
        height: auto;
        box-sizing: border-box;
        background: #fff;

        .ballroom-name-item {
          width: 100%;
          text-align: center;
          height: 77px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          padding-right: 10px;
          box-sizing: border-box;
          border-bottom: 1px dashed #ccc;
          color: #666;
          font-weight: bold;
        }
      }

      .ballroom-list {
        flex: 1;
        height: auto;

        .ballroom-list-item {
          border-bottom: 1px dashed #ccc;
          padding: 5px 0;

          .uponday,
          .downday {
            display: flex;
            flex: 1;
            justify-content: space-between;
            font-size: 12px;
            cursor: pointer;

            .itemuponday {
              width: 30px;
              height: 30px;
              line-height: 30px;
              margin-bottom: 3px;
              text-align: center;
              background-color: #e5e5e5;
              color: #333;
              cursor: pointer;
            }

            .itemuponday:hover {
              background: #15d37d;
              color: #fff;
            }

            .itemdownday {
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background-color: #e5e5e5;
            }

            .itemdownday:hover {
              background: #15d37d;
              color: #fff;
            }

            .redcolor {
              color: red;
            }

            .color0 {
              background-color: #e9e9e9;
            }

            .color1 {
              background-color: #ffca00;
            }

            .color2 {
              background-color: #15d37d;
            }
          }
        }
      }
    }

    .week {
      height: 30px;
      line-height: 30px;
      width: calc(100% + 17px);

      .week-title {
        width: 100px;
        font-size: 13px;
        border-right: 1px solid #fff;
      }

      .week-list {
        flex: 1;
        display: flex;
        justify-content: space-between;

        .red {
          color: red;
        }

        .week-list-name {
          width: 30px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .state {
    width: 100%;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 17px;

    .state-group {
      width: 260px;
      display: flex;
      font-size: 13px;
      justify-content: space-between;

      .group-item {
        padding: 2px 10px;
        border-radius: 3px;
        color: #333;
      }

      .state-hint {
        color: #ffdd00;
      }

      .state-0 {
        background: #e5e5e5;
      }

      .state-1 {
        background: #ffdd00;
      }

      .state-2 {
        background: #15d37d;
      }
    }
  }

  .reserved-list {
    width: 90%;
    height: auto;
    min-height: 300px;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .list-item {
      width: 400px;
      height: 100px;
      background: #f9f9f9;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      display: flex;
      position: relative;
      overflow: hidden;
      border-radius: 5px;

      .number {
        width: auto;
        padding: 0 10px;
        height: 100%;
        text-align: center;
        line-height: 100px;
        font-size: 50px;
        background: #15d37d;
        color: #f9f9f9;
      }

      .center {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .btn {
          float: right;
          padding-right: 30px;
        }
      }

      .sign {
        position: absolute;
        right: 0;
        background: #ffdd00;
        transform: rotate(45deg) translate(16px, -24px);
        font-size: 20px;
        color: #f9f9f9;
        padding: 2px 30px;
      }
    }
  }
}
</style>
