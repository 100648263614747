<template>
  <div>
    <el-dialog title="订单详情信息" :close-on-click-modal='false' width="1300px" :visible.sync="elegantRoomVisible">
      <div v-if="details" class="order-details" ref="imageWrapper">
        <el-card el-card class="customer-details">
          <h4 class="title">客户信息</h4>
          <el-row>
            <el-col :span="3">【客户姓名】</el-col>
            <el-col :span="4"> {{ details.customerName }} <i class="el-icon-user-solid" :style="details.genderName === '先生' ? 'color: #00B7FF' : 'color: pink'"></i></el-col>
            <el-col :span="9">{{ details.customerPhoneNumber }}</el-col>
          </el-row>
        </el-card>
        <el-card el-card class="customer-details">
          <h4 class="title">预定信息</h4>
          <el-row>
            <el-col :span="9">【雅间信息】{{ details.roomName }}</el-col>
            <el-col :span="7">【预计人数】{{ details.estimatedPersonNum }}人</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">【到店日期】<span>{{ details.reserveDate }}</span><span style="margin-left: 10px">{{ details.reserveTimeName }}</span></el-col>
            <!--            {{details.arrivedDatenl}}-->
            <!--            <el-col :span="16">{{ details.reserveTimeName }}</el-col>-->
          </el-row>
          <el-row>
            <el-col :span="8">【到店时间】<span>{{ details.arrivedTimeName }}</span></el-col>
          </el-row>
        </el-card>
        <el-card el-card class="customer-details">
          <h4 class="title">其他信息</h4>
          <el-row>
            <el-col :span="24">【关联雅间】{{ details.referRoomNames && details.referRoomNames.length > 0  ? details.referRoomNames : '' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="9">【客户经理】{{ details.customerManager }}</el-col>
            <el-col :span="7">【客户来源】{{ details.customerSources }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="24">【详情备注】{{ details.remark }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="24">【预定时间】{{ details.createTime }}</el-col>
          </el-row>
        </el-card>
      </div>
      <div class="btn no-print" v-if="displayBtn">
        <el-button @click="retiredRoom" type="primary">退定</el-button>
        <el-button @click="revise" type="primary">修改</el-button>
        <el-button @click="dialogVisible = true" type="primary">换台</el-button>
        <el-button :disabled="details.orderStatus !== 7" @click="overturn" type="primary">翻台</el-button>
        <el-button :disabled="details.orderStatus !== 3 " @click="takeSeat" type="primary">入座</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onPrint" style="color: #409EFF" type="text">导出</el-button>
        <el-button type="primary" @click="elegantRoomVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="换台" :close-on-click-modal='false' width="1300px" :visible.sync="dialogVisible">
      <el-card class="box-card ballroom">
        <div class="roombox" v-for="item in roomList" :key="item.dinnerZoneBizId">
          <div v-if="item.datass.length > 0">
            <h3>{{ item.dinnerZoneName }}</h3>
            <div class="roomlist">
              <div class="room-iten" v-for="j in item.datass" :key="j.bizId">
                <div @click="activeIdClick(j.bizId)" class="info" :style="activeId === j.bizId ? 'background: #15D37D; color: #ffffff' : false">
                  <div style="font-size: 14px; overflow: hidden; height: 20px;">{{j.name}}</div>
                  <div style="font-size:14px; overflow: hidden; height: 20px;">{{j.rangePersonName}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeChannel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import sloarToLunar from '@/utils/rili'
import zeropointData from './details/zerpointDeat'
let remainingRoom = {}
export default {
  name: 'ZeropointDetails',
  data() {
    return {
      data: {},
      activeId: '',
      elegantRoomVisible: false,
      dialogVisible: false,
      details: {},
      roomList: [],
      displayBtn: false
    }
  },
  inject: ['initAria'],
  methods: {
    // 选择退定雅间
    activeIdClick(bizId) {
      this.activeId = bizId
    },
    // 换台
    async changeChannel() {
      const data = {
        toRoomBizId: this.activeId,
        bizId: this.data.bizId,
        hotelBizId: this.data.hotelBizId
      }
      const res = await this.postRequest(
        'dinner/reserve/change/command',
        this.qs.stringify(data)
      )
      if (res.status !== 200) {
        this.$message.error(res.message)
        return false
      }
      this.$message({
        duration: 10000,
        message: '换台成功',
        type: 'success'
      })
      this.dialogVisible = false
      this.initAria()
      this.elegantRoomVisible = false
    },
    // 退定方法
    retiredRoom() {
      this.$confirm('确定退定吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: this.data.bizId,
            hotelBizId: this.data.hotelBizId
          }
          const res = await this.postRequest(
            'dinner/reserve/unreserved/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message.error(res.message)
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '退定成功!'
          })
          this.elegantRoomVisible = false
          this.initAria()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消退定'
          })
        })
    },
    // 翻台
    overturn() {
      this.$confirm('确定翻台吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: this.data.bizId,
            hotelBizId: this.data.hotelBizId
          }
          const res = await this.postRequest(
            'dinner/reserve/complete/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message.error(res.message)
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '已翻台!'
          })
          this.elegantRoomVisible = false
          this.initAria()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消翻台'
          })
        })
    },
    // 修改
    revise() {
      const data = {
        ...this.data,
        remainingRoom
      }
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '零点预定')
      // 添加tags标签
      const submenu = {
        path: '/zeropoint/reserve',
        name: '零点预定',
        bizId: '/zeropoint/reserve'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$store.commit('setZeropointReservation', data)
      this.$router.push('/zeropoint/reserve')
    },
    // 入座
    takeSeat() {
      this.$confirm('确定顾客已入座吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: this.data.bizId,
            hotelBizId: this.data.hotelBizId
          }
          const res = await this.postRequest(
            'dinner/reserve/seated/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message.error(res.message)
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '客户已入座!'
          })
          this.elegantRoomVisible = false
          this.initAria()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消入座'
          })
        })
    },
    // 打印
    onPrint() {
      this.$print(this.$refs.imageWrapper)
      this.elegantRoomVisible = false
    },
    // 查询零点详情
    getDetails(row, roomList, times, remainingRooms) {
      console.log('row:' + JSON.stringify(row))
      remainingRoom = remainingRooms
      this.details = {}
      this.elegantRoomVisible = true
      this.getRequest('business/dinner/getDinnerReservedDetail', {
        id: row.id
      })
        .then(res => {
          // console.log('res:' + JSON.stringify(res))
          if (res.code !== 200) {
            return false
          } else {
            console.log('detail:' + JSON.stringify(res.data))
            this.data = res.data
            const records = res.data
            this.details = {
              customerName: records.customerName || '',
              customerPhoneNumber: records.customerPhoneNumber || '',
              genderName: records.genderName || '',
              roomName: records.room || '',
              remark: records.remark || '',
              orderStatusName: records.orderStatusName || '',
              reserveTimeName: records.reserveTimeName || '',
              estimatedPersonNum: records.estimatedPersonNum || '',
              arrivedDate: records.arrivedDate,
              arrivedTime: records.arrivedTime,
              arrivedTimeName: records.arrivedTimeName,
              referRoomNames: '',
              reserveDate: records.reserveDate,
              orderStatus: records.orderStatus,
              referRoomBizIds: records.referRoomBizIds || [],
              createTime: records.createTime,
              customerManager: records.customerManager || '',
              customerSources: records.customerSources || '',
              tagName: records.tagName || ''
            }

            const date = records.reserveDate.split('-')
            const date1 = sloarToLunar(date[0], date[1], date[2])
            this.details.reserveDatenl =
              date1.lunarYear + '年' + date1.lunarMonth + '月' + date1.lunarDay
            // --【关联雅间】--------------------------------------------------
            this.details.referRoomNames = []
            if (records.referList && records.referList.length > 0) {
              for (let i = 0; i < records.referList.length; i++) {
                this.details.referRoomNames.push(records.referList[i].room)
              }
              this.details.referRoomNames = this.details.referRoomNames.join(
                ' / '
              )
            }
            // --【客户来源】--------------------------------------------------
            // if (records.customerSources) {
            //   const customerSources = records.customerSources.filter(i => {
            //     return i.selected === 1
            //   })
            //   customerSources.length > 0
            //     ? (this.details.customerSources = customerSources[0].name)
            //     : (this.details.customerSources = '')
            // }
            // --【客户经理】--------------------------------------------------
            // if (records.customerManagers) {
            //   const customerManagers = records.customerManagers.filter(i => {
            //     return i.selected === 1
            //   })
            //   customerManagers.length > 0
            //     ? (this.details.customerManagers = customerManagers[0].name)
            //     : (this.details.customerManagers = '')
            // }
            // - 其他所有房间----------------------------------------------
            // roomList.forEach(o => {
            //   o.datass = []
            //   if (o.datas && o.datas.length > 0) {
            //     o.datas.forEach(m => {
            //       if (!remainingRoom[records.reserveTime].includes(m.bizId)) {
            //         o.datass.push(m)
            //       }
            //     })
            //   }
            // })
            // this.roomList = roomList

            // 判定是否开放操作
            // let newDate
            // if (this.details.reserveTimeName === '中午') {
            //   newDate = new Date(
            //     this.details.reserveDate + ' ' + times.jzHoursMonth
            //   ).getTime()
            // } else {
            //   newDate = new Date(
            //     this.details.reserveDate + ' ' + times.jzHoursMonthNight
            //   ).getTime()
            //   if (times.dinnerNightDay === 2) {
            //     newDate = newDate + 1000 * 60 * 60 * 24
            //   }
            // }
            // this.displayBtn = false
            // if (newDate > Date.now()) {
            //   this.displayBtn = true
            // }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details {
  height: auto;
  .customer-details {
    height: auto;
    margin-bottom: 20px;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
      background: #f7f6f6;
    }
  }
  .reserved-details {
    height: auto;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
    }
    .box-card {
      border-radius: 5px;
      padding: 0px 5px;
      .box-card-img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 5px;
      }
    }
  }
}
.btn {
  width: 100%;
  padding: 10px 0;
  background: #f9f9f9;
  margin: 20px auto;
  display: flex;
  justify-content: space-evenly;
  button {
    padding: 10px 40px;
  }
}
.ballroom {
  width: 100%;
  overflow: auto;
  border-radius: 5px;
  padding: 0 20px;
  .roombox {
    margin-bottom: 20px;
    h3 {
      margin-bottom: 10px;
      padding-bottom: 5px;
      padding-left: 5px;
      font-size: 16px;
      border-bottom: 1px solid #eeeeeeee;
    }
    .roomlist {
      display: flex;
      flex-wrap: wrap;
      justify-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .room-iten {
        width: 80px;
        height: 50px;
        margin-right: 20px;
        border-radius: 3px;
        position: relative;
        border: 1px solid #eee;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        overflow: hidden;
        .info {
          width: 100%;
          margin: 0 auto;
          height: 50px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .info:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
