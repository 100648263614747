<!--设置-- 权限设置--添加修改权限设置 【dinner_ok】 -->
<template>
  <div class="authority-page">
    <el-form class="form" ref="roleForm" :rules="rules" label-width="80px" :model="roleForm">
      <el-form-item label="职位名称" prop="name">
        <el-input v-model="roleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="权限说明">
        <el-input type="textarea" v-model="roleForm.description"></el-input>
      </el-form-item>
      <el-form-item label="参考职位">
        <el-select @change="defaultCheckedSelect" v-model="roleForm.referenceRole" placeholder="请选择">
          <el-option v-for="item in optionsList" :key="item.name" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <el-popover placement="top-start" width="500" trigger="hover" content="默认选中参考角色的所有权限点，便于快速配置新角色的权限点。">
          <i slot="reference" class="el-icon-question" style="color: #999; margin-left: 10px; font-size: 14px"></i>
        </el-popover>
      </el-form-item>
    </el-form>
    <div class="authority">
      <div class="authority-tree">
        <el-tree style="margin-left: 10px" ref="tree" :default-checked-keys="defaultCheckedKeysPc" :data="authorityDataPc" :props="defaultProps" :default-expand-all="true" :check-on-click-node="true" :check-strictly="true" show-checkbox node-key="id">
        </el-tree>
        <el-tree style="margin-left: 200px;" ref="tree1" :default-checked-keys="defaultCheckedKeysApp" :data="authorityDataApp" :default-expand-all="true" :check-on-click-node="true" :check-strictly="true" show-checkbox node-key="id">
        </el-tree>
      </div>
    </div>
    <div class="btn">
      <el-button @click="getCheckedNodes" style="padding: 10px 30px" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import rules from '@/utils/rules'
let AuthorityDefaultData = []
export default {
  name: 'Authority',
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      type: [],
      val: {},
      rules,
      optionsList: [],
      defaultCheckedKeysPc: [],
      defaultCheckedKeysApp: [],
      authorityDataPc: [],
      authorityDataApp: [],
      roleForm: {
        name: '',
        description: '',
        referenceRole: []
      },
      dataPc: {},
      dataApp: {}
    }
  },
  mounted() {
    this.getAuthorityData()
    this.getDefaultRoleModel()
    const row = this.$store.state.positionAuthority
    if (row) {
      this.val.roleCode = row.code
      this.roleForm = {
        name: row.name || '',
        description: row.description || '',
        roleId: row.id || '',
        referenceRole: []
      }
      this.getRoleSelectList(row)
    }
  },
  methods: {
    // 获取已选择权限信息
    async getRoleSelectList(row) {
      this.getRequest('sys/role/get/' + row.id).then(res => {
        if (res.code == 200) {
          this.$refs.tree.setCheckedKeys([])
          this.$refs.tree1.setCheckedKeys([])
          var str = res.data.authorityJson
          var arra = JSON.parse(str)
          this.defaultCheckedKeysApp = arra
          var str2 = res.data.permissoinJson
          var arra2 = JSON.parse(str2)
          this.defaultCheckedKeysPc = arra2
        }
      })
    },
    // 初始化参考角色
    async getDefaultRoleModel() {
      this.getRequest('sys/permission/getPermissionModel').then(res => {
        if (res.code == 200) {
          this.optionsList = res.data
        }
      })
    },
    // 选择参考角色
    defaultCheckedSelect() {
      // console.log('roleForm.referenceRole', this.roleForm.referenceRole)
      const name = this.roleForm.referenceRole
      this.optionsList.forEach(r => {
        if (r.name == name) {
          this.$refs.tree.setCheckedKeys([])
          this.$refs.tree1.setCheckedKeys([])
          this.defaultCheckedKeysApp = r.authorityJson
          this.defaultCheckedKeysPc = r.permissionJson
        }
      })
    },
    // 获取默认权限信息
    async getAuthorityData() {
      this.getRequest('sys/permission/getMenuList').then(res => {
        if (res.code == 200) {
          this.authorityDataPc = res.data
        }
      })

      this.getRequest('app/permissions/getUserHavePermission').then(res => {
        if (res.code == 200) {
          this.authorityDataApp = res.data
        }
      })
    },
    // 设置权限
    async permission() {
      const data = {
        ...this.roleForm,
        hotelBizId: this.$store.state.hotellist[0].bizId,
        permissionList: this.defaultCheckedKeysPc,
        authorityList: this.defaultCheckedKeysApp,
        accountType: window.localStorage.getItem('accountType')
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      if (
        data.roleId == undefined ||
        data.roleId == null ||
        data.roleId == ''
      ) {
        console.log('提交权限：' + JSON.stringify(data))
        // insert
        this.post('sys/permission/savePosition', data)
          .then(res => {
            loading.close()
            if (res.code == 200) {
              // 关闭页面
              this.reBack()
              this.$message.success({
                duration: 2000,
                message: '添加成功!'
              })
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
          .catch(() => {
            loading.close()
          })
      } else {
        // update
        this.post('sys/permission/editPosition', data)
          .then(res => {
            loading.close()
            if (res.code == 200) {
              this.reBack()
              this.$message.success({
                duration: 2000,
                message: '修改成功'
              })
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
          .catch(() => {
            loading.close()
          })
      }
    },
    // 设置角色
    // 通过 node 获取
    getCheckedNodes() {
      this.$refs.roleForm.validate(async flage => {
        if (flage) {
          const list = [
            ...this.$refs.tree.getCheckedNodes(),
            ...this.$refs.tree.getHalfCheckedNodes()
          ]
          const list1 = [
            ...this.$refs.tree1.getCheckedNodes(),
            ...this.$refs.tree1.getHalfCheckedNodes()
          ]
          // const list1 = this.$refs.tree1.getCheckedNodes()
          if (list.length === 0 && list1.length === 0) {
            this.$message('请选择权限')
            return
          }
          if (list && list.length > 0) {
            this.defaultCheckedKeysPc = []
            list.forEach(o => {
              if (o.id) {
                this.dataPc[o.id] = 1
                this.defaultCheckedKeysPc.push(o.id)
              }
            })
          }
          if (list1 && list1.length > 0) {
            this.defaultCheckedKeysApp = []
            list1.forEach(o => {
              if (o.code) {
                this.dataApp[o.code] = 1
                this.defaultCheckedKeysApp.push(o.id)
              }
            })
          }
          await this.permission()
        }
      })
    },
    reBack() {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '职位权限设置')
      // 添加tags标签
      const submenu = {
        path: '/setup/authority-position',
        name: '权限设置',
        bizId: '/setup/authority-position'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$router.push(submenu.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.authority-page {
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .form {
    height: 160px;
    width: 500px;
  }
  .authority {
    width: calc(100% + 30px);
    overflow: hidden;
    height: 70%;
    max-height: 500px;
    overflow-y: auto;
    border-radius: 5px;
    margin-top: 10px;
    padding-right: 20px;
    padding-bottom: 100px;
    .authority-tree {
      display: flex;
      justify-content: flex-start;
    }
  }
  .btn {
    position: absolute;
    width: 100%;
    height: 40px;
    padding: 5px 0;
    bottom: 5px;
    display: flex;
    justify-content: center;
  }
}
</style>
