<template>
  <div class="page">
    <div style="height: 100%; overflow: auto;">
      <el-table v-loading="loading" show-summary :data="tableData" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
        <el-table-column prop="name" align="center" label="时段">
        </el-table-column>
        <el-table-column prop="number_seat" align="center" label="入座数">
        </el-table-column>
        <el-table-column prop="number_unreserve" align="center" label="退定数">
        </el-table-column>
        <el-table-column prop="number_complete" align="center" label="翻台数">
        </el-table-column>
        <el-table-column align="center" label="空台率">
          <template slot-scope="scope">
            <span v-if="scope.row.number_reserve && scope.row.number_reserve!==0">{{Number((scope.row.number_reserve - scope.row.number_unreserve) / scope.row.number_reserve).toFixed(2) * 100}}%</span>
            <span v-else>100%</span>
          </template>
        </el-table-column>
        <el-table-column prop="newCustomer" align="center" label="新客">
        </el-table-column>
        <el-table-column prop="customer" align="center" label="老客">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DinnerDaily',
  data() {
    return {
      tableData: [], // 宴会预留订单数据
      loading: true
    }
  },
  methods: {
    _currentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString()
      const day = date.getDate().toString()
      this.date = {
        year,
        month,
        day
      }
      return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
    },
    // 查询预定订单数据
    async getReserveOrders() {
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        reserveDate: this._currentDate()
      }
      const res = await this.getRequest(
        'statistics/dinner/day/statistics',
        data
      )
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      this.loading = false
      const dataList = []
      pageModel.records = pageModel.records.forEach(o => {
        const data = {
          ...o.datas[0],
          name: o.name
        }
        dataList.push(data)
      })
      this.tableData = dataList
      // this.tableData = pageModel.records
    }
  },
  mounted() {
    this.getReserveOrders()
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 20px;
  box-sizing: border-box;
}
</style>
