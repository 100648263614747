<template>
  <div>
    收入月报统计
  </div>
</template>

<script>
export default {
  name: 'MonthlyIncome'
}
</script>

<style scoped>
</style>
