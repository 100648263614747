import {Solar,SolarUtil,Lunar} from './lunar/index'


export const getCalendar=(time)=>{
    // 获得本月天数
    const  Days  = SolarUtil.getDaysOfMonth(time.year,time.month)
    //日历 列表
    const dayList=[ ]
    for(let i = 0;i<Days;i++){
       let day = i+1
       let d=Lunar.fromDate(new Date(time.year+'-'+time.month+'-'+day));
       let weekDays = ['日', '一', '二', '三', '四', '五', '六'];
       let ds = Solar.fromYmd(time.year,time.month,day)
       let dr = Lunar.fromYmd(d.getYear(),d.getMonth(),d.getDay());
        // 宜
       let l= d.getTimeYi()
       // 忌
       let j = d.getDayJi();
       let obj = {
        Notime:d.getYear()+"-"+d.getMonth()+'-'+d.getDay(),
        year:time.year,
        month:time.month,
        day:day,
        dayYis:l,
        dayJis:j,
        chineseYear:d.getYearInChinese(),
        chineseMonth:d.getMonthInChinese(),
        chineseDay:d.getDayInChinese(),
        weekDay:weekDays[new Date(time.year+'-'+time.month+'-'+day).getDay()],
        festivals:Array.from(ds.getFestivals()).concat(Array.from(dr.getFestivals())),
        jieQi:dr.getJieQi()
       }
       dayList.push(obj)
    }
    return dayList
}
