<template>
  <div class="reserve-page">
    <el-form v-if="form" :model="form" ref="form" :rules="rules" label-width="100px" class="form">
      <div class="form-box">
        <el-form-item label="雅间名称" prop="roomBizId">
          <el-select style="width: 150px" v-model="form.roomBizId" placeholder="雅间名称" @change="onBallroomChange">
            <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预定日期" prop="reserveDate">
          <el-date-picker style="width: 150px" :picker-options="pickerOptions" @input="changeDayDate()" v-model="form.reserveDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="预定时间" prop="reserveTimeType">
          <el-radio-group @change="reserveTimeChange" v-if="timeQuantums && timeQuantums.length > 0" v-model="form.reserveTimeType">
            <el-radio v-for="item in timeQuantums" :key="item.bizId" :label="item.bizId">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="预计人数" prop="estimatedPersonNum">
          <el-input type="number" style="width: 150px" maxlength="3" v-model="form.estimatedPersonNum"></el-input>
        </el-form-item>
        <el-form-item label="到店时间" prop="arrivedTime">
          <el-select @change="arrivedTimeChange" style="width: 150px" v-model="form.arrivedTime" placeholder="选择时间">
            <el-option v-for="item in arrivedTimeList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="联系电话" prop="customerPhoneNumber">
          <el-input style="width: 150px" maxlength="13" @change="findCusName" v-model="form.customerPhoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="客户性别" prop="genderSelectedBizId">
          <el-radio-group v-model="form.genderSelectedBizId">
            <el-radio v-for="item in genders" :key="item.bizId" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="客户姓名" prop="customerName">
          <el-input style="width: 150px" maxlength="10" v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="关联雅间">
          <!--          <el-button @click="elegantRoomVisible = true" type="text">关联</el-button>-->
          <el-select multiple style="min-width: 350px" v-model="form.referRoomBizIds" placeholder="雅间名称">
            <el-option v-for="item in roomLists" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户经理" prop="customerManagerSelectedBizId">
          <el-select style="width: 150px" v-model="form.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.bizId" :label="item.label" :value="item.bizId">{{item.label}}
            </el-option>
          </el-select>
        </el-form-item>

      </div>
      <div class="form-box">
        <el-form-item label="订餐类型" prop="tagId">
          <el-radio-group style="width: 80vw" v-model="form.tagId">
            <el-radio style="margin: 5px 10px; width: 150px" v-for="item in mealTypes" :key="item.bizId" :label="item.bizId">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="form-box form-box-margintop">
        <el-form-item label="客户来源" prop="customerSourceSelectedBizId">
          <el-radio-group style="width: 80vw" v-model="form.customerSourceSelectedBizId">
            <el-radio style="margin: 5px 10px; width: 150px" v-for="item in customerSources" :key="item.bizId" :label="item.bizId">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="详情备注">
          <el-input type="textarea" maxlength="255" style="width: 800px" :rows="2" v-model="form.description"></el-input>
        </el-form-item>
      </div>
      <div style=" width: 100%; margin-top: 50px">
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="command(1)">预定并短信</el-button>
          <el-button type="warning" @click="command(0)">预定</el-button>
          <el-button type="warning" @click="fitClick">散客入座</el-button>
        </el-form-item>
      </div>
      <br /><br />
    </el-form>
  </div>
</template>

<script>
import rules from '../../utils/rules'

// import resrevedata from './json/reserve'
let remainingRoom = []
let tables = []
let roomList = []
let referRoomBizIds = []
export default {
  name: 'Reserve',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      },
      rules,
      form: null,
      roomList: [],
      roomOptions: [],
      oldTableData: [],
      arrivedTimeList: [],
      roomLists: [],
      customerManagers: [],
      customerSources: [],
      genders: [
        {
          value: 1,
          label: '男',
          bizId: 1
        },
        {
          value: 2,
          label: '女',
          bizId: 2
        }
      ],
      timeQuantums: [],
      date: '',
      reservedDay: '',
      mealTypes: [] // 订餐类型
    }
  },
  methods: {
    // 根据手机号码，查询客户姓名
    findCusName(e) {
      //  console.log('手机号：' + e)
      const reg = /^[1][3-9][0-9]{9}$/
      if (!reg.test(e)) {
        this.$message('联系电话格式不正确！')
        return false
      }
      this.getRequest('business/dinner/getCusName/' + e, { phone: e }).then(
        res => {
          // console.log('res:' + JSON.stringify(res))
          if (res.code == 200 && res.data) {
            this.form.customerName = res.data.name
            this.form.genderSelectedBizId = res.data.gender
          }
        }
      )
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.roomLists = this.roomList.filter(o => {
        return o.id !== e
      })
    },
    // 查询到店时间
    async getQueryData(type) {
      this.getRequest('dinner-metamodel/getAll').then(res => {
        if (res.code !== 200) {
          return false
        }
        var rc = ''
        if (type === 1) {
          rc = res.data.dinnerNoonArriveTimeQuantum
        } else {
          rc = res.data.dinnerNightArriveTimeQuantum
        }
        this.arrivedTimeList = rc.map(o => {
          return {
            bizId: o.id,
            label: o.name,
            value: o.name
          }
        })
      })
    },
    // 选择预定时间
    async reserveTimeChange(e) {
      if (e === 0) {
        await this.getQueryData(1)
      } else {
        await this.getQueryData(2)
      }
      // this.form.roomBizId = ''
      this.flushData()
    },
    // 选择到店时间
    arrivedTimeChange(e) {
      for (let i = 0; i < this.arrivedTimeList.length; i++) {
        if (this.arrivedTimeList[i].bizId === this.form.arrivedTime) {
          this.form.arrivedTimeName = this.arrivedTimeList[i].label
        }
      }
    },
    // ---------------------------------------------------
    // 初始化元数据
    initMeta() {
      // 1. 初始化雅间
      //   this.getRequest('dinner-room/loadRoom').then(res => {
      //     const list = res.data.res
      //     this.oldTableData = []
      //     for (let i = 0; i < list.length; i++) {
      //       // 雅间数据
      //       for (let j = 0; j < list[i].datas.length; j++) {
      //         const zhi = list[i].datas[j] // 每个雅间信息
      //         this.oldTableData.push(zhi)
      //       }
      //     }
      //     this.changeDayDate()
      //   })

      // 2. 获取宴会厅客户经理
      this.getRequest('sys/user/loadCostumerManager').then(res => {
        if (res.code == 200) {
          this.customerManagers = res.data.map(o => {
            if (o.id === window.localStorage.getItem('bizId')) {
              this.form.customerManagerSelectedBizId = window.localStorage.getItem(
                'bizId'
              )
            }
            return { value: o.id, label: o.username, bizId: o.id }
          })
        }
      })

      // 3. 获取客户来源
      this.getRequest('data-metamodel/getList', {
        dataCode: 'customerSource'
      }).then(res => {
        if (res.code == 200 && res.data) {
          this.customerSources = res.data.map(o => {
            return {
              value: o.id,
              label: o.name,
              bizId: o.id
            }
          })
        }
      })

      // 4. 预定时间-上下无
      this.getRequest('config/getTimeQuantums').then(res => {
        if (res.code == 200) {
          var json = JSON.parse(res.data)
          this.timeQuantums = json.map(o => {
            return {
              value: o.code,
              label: o.label,
              bizId: o.value
            }
          })
        }
      })

      // 6. 订餐类型
      this.getRequest('data-metamodel/getList', {
        dataCode: 'mealType'
      }).then(res => {
        // console.log('res类型:' + JSON.stringify(res))
        if (res.code == 200 && res.data) {
          this.mealTypes = res.data.map(o => {
            return {
              value: o.id,
              label: o.name,
              bizId: o.id
            }
          })
        }
      })
    },
    // 变更预定日期--预定日期
    changeDayDate() {
      // 当前时期的预定数据
      const param = {
        time: this.form.reserveDate
      }
      this.getRequest('business/dinner/findDinnerReserved', param).then(res => {
        //  console.log('变更预定日期:' + JSON.stringify(res))
        if (res.code == 200) {
          this.reservedDay = res.data
        }
        this.flushData()
      })
    },
    // 更新选择上下午显示的雅间信息
    flushData() {
      this.roomList = []
      let sp = []
      const recordslist = JSON.parse(JSON.stringify(this.oldTableData))
      this.reservedDay.forEach(o => {
        // 订单数据
        recordslist.forEach(element => {
          if (element.id === o.roomId) {
            if (o.reserveTimeType == this.form.reserveTimeType) {
              sp.push(element.id)
            }
          }
        })
      })
      this.roomList = recordslist.filter(element => {
        return !sp.includes(element.id)
      })
      var flg = this.roomList.some(o => {
        return o.id == this.form.roomBizId
      })
      if (!flg) {
        this.form.roomBizId = ''
      }
      this.form.referRoomBizIds = ''
      const roomListCopy = JSON.parse(JSON.stringify(this.roomList))
      this.roomLists = roomListCopy.filter(o => {
        return o.id !== this.form.roomBizId
      })
    },
    // ------------------- 提交操作 --------------------------------
    // 1.1 提交预定-提交
    command(v) {
      this.$refs.form.validate(async flage => {
        if (flage) {
          const data = {
            ...this.form,
            sendMessage: v
          }
          if (data.referRoomBizIds == null || data.referRoomBizIds == '') {
            data.referRoomBizIds = []
          }
          if (data.estimatedPersonNum.length > 3) {
            this.$message('预计人数最大不允许超过999！')
            return false
          }
          const reg = /^[1][3-9][0-9]{9}$/
          if (!reg.test(data.customerPhoneNumber)) {
            this.$message('请输入正确的联系电话！')
            return false
          }
          if (data.id !== undefined) {
            // 修改
          } else {
            // 处理订餐类型名称
            for (var i = 0; i < this.mealTypes.length; i++) {
              if (this.mealTypes[i].bizId == data.tagId) {
                data.tagName = this.mealTypes[i].label
                break
              }
            }

            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            // 添加
            this.post('business/dinner/reserved', data)
              .then(res => {
                loading.close()
                if (res.code == 200) {
                  this.jumpTable()
                } else {
                  this.$message(res.msg)
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        }
      })
    },
    // 1.2 提交预定-跳转
    jumpTable() {
      this.$message({
        duration: 10000,
        message: '预定成功，跳转到销控表',
        type: 'success'
      })
      setTimeout(e => {
        const submenu = {
          path: '/zeropoint/salescontrol-table',
          name: '零点预定',
          bizId: '/zeropoint/salescontrol-table'
        }
        // 加入keepalive缓存
        this.$store.commit('addKeepAliveCache', '零点销控表')
        // 修改选中菜单
        this.$store.commit('selectMenu', submenu)
        this.$router.push('/zeropoint/salescontrol-table')
      }, 300)
    },
    // 2. 散客入座
    async fitClick() {
      const data = {
        hotelId: this.form.hotelId,
        roomBizId: this.form.roomBizId,
        reserveDate: this.form.reserveDate,
        reserveTimeType: this.form.reserveTimeType,
        createdBy: window.localStorage.getItem('bizId'),
        customerManagerSelectedBizId: window.localStorage.getItem('bizId') // 当前登录用户的bizId
      }
      this.post('business/dinner/quickSeat', data).then(res => {
        //  console.log(res)
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            message: '预定成功，跳转到销控表',
            type: 'success'
          })
          setTimeout(e => {
            const submenu = {
              path: '/zeropoint/salescontrol-table',
              name: '零点预定',
              bizId: '/zeropoint/salescontrol-table'
            }
            // 加入keepalive缓存
            this.$store.commit('addKeepAliveCache', '零点销控表')
            // 修改选中菜单
            this.$store.commit('selectMenu', submenu)
            this.$router.push('/zeropoint/salescontrol-table')
          }, 300)
        } else {
          this.$message(res.msg)
        }
      })
    }
  },
  created() {
    // 初始化其他信息
    this.initMeta()
  },
  mounted() {
    const row = this.$store.state.zeropointReservation
    remainingRoom = row.remainingRoom
    tables = row.recordslist
    // ----------------------------------------
    this.form = {
      id: row.id,
      hotelId: row.hotelBizId,
      roomBizId: row.roomBizId,
      reserveDate: '', // 预定日期
      customerName: '', // 客户姓名
      customerPhoneNumber: '', // 联系方式
      reserveTimeType: row.reserveTimeType, // 预定时间
      customerSourceSelectedBizId: '', // 客户来源
      customerManagerSelectedBizId: '', // 客户经理
      genderSelectedBizId: '', // 客户性别
      arrivedTime: '', // 到店时间
      estimatedPersonNum: '', // 预计人数
      referRoomBizIds: '',
      description: ''
    }
    // 初始化到店时间
    if (this.form.reserveTimeType === 0) {
      this.getQueryData(1)
    } else {
      this.getQueryData(2)
    }
    if (row.date) {
      this.form.reserveDate =
        row.date.year + '-' + row.date.month + '-' + row.date.day
      this.date = row.date
    }
    // 初始化预定时间
    this.changeDayDate()
    // 初始化房间
    this.oldTableData = row.rommList
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-form-item--small {
  margin: 0;
}
.reserve-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .form {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .form-box {
      display: flex;
      padding: 15px 0;
      background: #f7f5f6;
      align-items: center;
    }
    .form-box-margintop {
      margin-top: 20px;
    }
  }
}
</style>
