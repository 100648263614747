<!-- 此页已不用 -->
<template>
  <el-card class="main-set-page">
    <div class="setbox">1</div>
  </el-card>
</template>

<script>
export default {
  name: 'PaymentMain',
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
</style>
