<template>
  <div class="reservation-order-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item>
          <el-input v-model="ruleForm.name" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.hallId" placeholder="宴会厅">
            <el-option v-for="item in ballroomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="categorySelectedBizId">
          <el-select style="width: 150px" v-model="ruleForm.category" placeholder="类型">
            <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManager" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="排序" style="width: 260px">
          <el-radio-group v-model="ruleForm.sort">
            <el-radio label="1">预留时间</el-radio>
            <el-radio label="2">宴会时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item style="width: 300px">
          <el-button size="mini" @click="getReserveOrders" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px" size="mini" @click="exportExcel">导出</el-button>
          <el-button type="info" @click="resetForm" size="mini">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" id="selectTable" :data="tableData" height="66vh" :default-sort="{ prop: 'date', order: 'descending' }" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="retainCreateTime" sortable align="center" label="预定时间"></el-table-column>
      <el-table-column prop="customerName" align="center" label="客户姓名"></el-table-column>
      <el-table-column prop="customerPhoneNumber" align="center" label="联系电话"></el-table-column>
      <el-table-column prop="categoryName" align="center" label="宴会类型"></el-table-column>
      <el-table-column prop="reserveDate" sortable align="center" label="宴会时间"></el-table-column>
      <el-table-column prop="hallName" align="center" label="宴会厅"></el-table-column>
      <el-table-column prop="reserveTableNumber" align="center" label="桌数">
        <template slot-scope="scope">
          {{ scope.row.reserveTableNumber }}桌
          <span v-if="scope.row.backupTableNumber">/备{{ scope.row.backupTableNumber }}桌</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerManager" label="客户经理" align="center"></el-table-column>
      <el-table-column align="center" label="状态" prop="orderStatus" :formatter="formatStatus" ></el-table-column>
      <el-table-column align="center" label="详情">
        <template slot-scope="scope">
          <el-button size="mini" @click="getBallroomData(scope.row)" type="text">详情</el-button>
          <el-button size="mini" @click="onReserve(scope.row)" type="text">转预定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange"></el-pagination>
    <Details ref="child"></Details>
  </div>
</template>

<script>
import htmlToExcel from '@/utils/htmlToExcel'
import Details from '@/components/order/Details'
import { export_json_to_excel } from '@/utils/Export2Excel'

import reservation from './json/reservation'

const pageSize = 10 // 每页的数量
let pageNum = 1 // 当前页
let org = {}
if (window.localStorage.getItem('orgs')) {
  org = JSON.parse(window.localStorage.getItem('orgs'))[0]
}
export default {
  name: 'ReservationOrder',
  data() {
    return {
      loading: true,
      ruleForm: {
        // 查询表单
        hotelId: org.id,
        name: '',
        hallId: '',
        category: '',
        startDate: '',
        endDate: '',
        customerManager: '',
        orderStatus: '1',
        sort: '1',
      },
      details: {},
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  components: {
    Details
  },
  provide() {
    return {
      initAria: this.initAria
    }
  },
  methods: {
    initAria() {
      // console.log('1')
    },
    // 重置按钮
    resetForm() {
      this.ruleForm = {
        hotelId: org.id,
        name: '',
        hallId: '',
        category: '',
        startDate: '',
        endDate: '',
        customerManager: '',
        orderStatus: '1',
        sort: '1',
      }
      pageNum = 1
      this.getReserveOrders()
    },
    // 导出
    // exportExcel() {
    //   htmlToExcel.getExcel('#selectTable', '宴会预留订单')
    // },
    // 导出数据--打开确认对话框
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
        })
        .catch(() => {})
    },
    // 导出数据-- 执行导出
    getExpportData: function() {
      var self = this
      // --页面查询条件
      const data = {
        reservedCategory: 'banquet',
        sortField: this.ruleForm.start,
        status: 1,
        pageSize: 100000,
        pageNumber: 1,
        ...this.ruleForm
      }
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.getRequest('banquet/reserve/orders', data)
        .then(res => {
          // 关闭等待
          loading.close()
          // --查询完成
          if (res.status === 200 && res.pageModel.records.length > 0) {
            require.ensure([], () => {
              const tHeader = [
                '序号',
                '预定时间',
                '客户姓名',
                '联系电话',
                '宴会类型',
                '宴会时间',
                '宴会厅',
                '桌数',
                '备桌数',
                '餐标',
                '押金方式',
                '担保人',
                '押金金额',
                '客户经理',
                '状态',
                '收费服务',
                '收费服务小计',
                '备注'
              ]
              const filterVal = [
                'index',
                'createdAt',
                'customerName',
                'customerPhoneNumber',
                'categoryName',
                'banquetDate',
                'hallName',
                'reserveTableNumber',
                'backupTableNumber',
                'mealStandard',
                'paytype',
                'customerGuarantor',
                'amount',
                'customerManagerName',
                'orderStatusName',
                'provider',
                'price',
                'description'
              ]
              var templist = res.pageModel.records
              var i = 1
              templist.forEach(element => {
                element.index = i++
                // if (element.reserveTableNumber) {
                //   if (element.backupTableNumber !== undefined || element.backupTableNumber !== null || element.backupTableNumber !== '') {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌/备' + element.backupTableNumber + '桌'
                //   } else {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌'
                //   }
                // } else {
                //   element.reserveTableNumber = ''
                // }
                if (element.deposit != null) {
                  element.paytype = '定金'
                  element.amount = element.deposit.amount
                } else if (element.guarantor != null) {
                  element.paytype = '担保'
                  element.amount = element.guarantor.amount
                  element.customerGuarantor = element.guarantor.customerGuarantor
                }
                var provider = ''
                var price = 0
                if (element.providerServices != undefined && element.providerServices != null) {
                  element.providerServices.forEach(pro => {
                    if (pro.num != undefined && pro.num != null && pro.num > 0) {
                      provider = provider + pro.name + ':' + pro.price + '*' + pro.num + ';'
                      price = price + pro.amount
                    }
                  })
                }
                element.provider = provider
                element.price = price
              })
              const data = self.formatJson(filterVal, templist)
              export_json_to_excel(tHeader, data, '宴会预留订单')
            })
          } else {
            this.$message({
              message: '数据出錯，请联系管理员',
              duration: 2000,
              type: 'warning'
            })
          }
        })
        .catch(res => {
          // 关闭等待
          this.$message('请与管理员联系')
          loading.close()
        })
    },
    // 导出数据-- 处理数据
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 查询预定订单数据
    async getReserveOrders() {
      const param  = {
        pageSize: this.pageSize,
        pageNumber: pageNum,
        ...this.ruleForm
      }
      this.tableData = []
      const res = await this.getRequest('banquet/statistics/getPage', param)
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.records
        this.total = res.data.total
        console.log(res.data.total);
      }
    },
    // 分页事件
    onCurrentChange(val) {
      pageNum = val
      this.getReserveOrders()
    },
    // 查看详情
    getBallroomData(row) {
      this.$refs.child.getDetails(row, '')
    },
    // 转为预定
    onReserve(row) {
      if (row.retain && row.retain.bizId) {
        row.retainBizId = row.retain.bizId
      }
      const data = {
        hotelBizId: row.hotelId,
        bizId: row.id
      }
      // 添加tags标签
      const submenu = {
        path: '/banquet/reserve',
        name: '宴会预定',
        bizId: '/banquet/reserve'
      }
      this.selectMenu(submenu)
      this.$store.commit('setBanquetReservation', data)
      this.$router.push({
        path: '/banquet/reserve'
      })
    },
    // 点击菜单 - 传入name，添加到keepalive缓存页面
    selectMenu(item) {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', item.name)
      // 添加tags标签
      const submenu = {
        path: item.path,
        name: item.name,
        bizId: item.path
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
    },
    async initMeta() {
      const res = await this.getRequest('banquet/loadmeta', { hotelId: org.id })
      this.ballroomList = []
      this.categories = []
      if (res.code == 200) {
        console.log(res)
        this.ballroomList = res.data.halls
        this.categories = res.data.categories
      }
    },
    async initCustomerManager() {
      const res = await this.getRequest('sys/user/loadCostumerManager', {})
      this.customerManagers = []
      if (res.code == 200) {
        console.log(res)
        this.customerManagers = res.data
      }
    },
    formatStatus(row){
      var status = ''
      switch(row.orderStatus){
        case 1:
          status = '预留'
          break;
        case 2:
          status = '预定'
          break;
        case 3:
          status = '关联锁定'
          break;
        case -1:
          status = '退订'
          break;
      }

      return status
    }
  },
  mounted() {
    this.getReserveOrders()
    this.initMeta()
    this.initCustomerManager()
  }
}
</script>

<style lang="scss" scoped>
.reservation-order-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;

  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;

    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }

  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .order-details {
    height: auto;
    margin-top: -30px;
    margin-bottom: -20px;

    .customer-details {
      height: 120px;
      margin-bottom: 20px;

      .title {
        //margin: 5px 0;
        //margin-top: -10px;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
        background: #f7f6f6;
      }
    }

    .reserved-details {
      height: auto;

      .title {
        //margin: 5px 0;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
      }

      .box-card {
        //border: 1px solid #ccc;
        border-radius: 5px;
        //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 0px 5px;

        .box-card-img {
          width: 100px;
          height: 100px;
          margin-right: 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
