<template>
  <div>
    <!-- 宴会详情信息-->
    <el-dialog :close-on-click-modal="false" title="订单详情信息" width="80%" :visible.sync="elegantRoomVisible" v-loading="loading">
      <div class="order-details" ref="imageWrapper">
        <el-card class="customer-details">
          <h4 class="title">客户信息</h4>
          <el-row>
            <el-col :span="2">【客户姓名】</el-col>
            <el-col :span="2">
              {{ details.customerName }}
              <i class="el-icon-user-solid" v-if="details.genderName === '先生'" style="color: #00B7FF"></i>
              <i class="el-icon-user-solid" v-if="details.genderName === '女士'" style="color: pink"></i>
            </el-col>
            <el-col :span="10">{{ details.customerPhoneNumber }}</el-col>
            <el-col :span="10">【客户来源】 {{ details.customerSources }}</el-col>
          </el-row>
          <el-row style="margin-top: 10px" v-if="details.attributes && details.attributes.length >= 1">
            <el-col :span="5" style="padding-left: 10px" v-for="(item, key) in details.attributes" :key="key">
              <span>
                {{ item.name !== 'undefined' ? item.name + ':' : '' }}
                {{ item.value !== 'undefined' ? item.value : '' }}
                <!-- {{ item.bindAttributeValue2 !== 'null' ? item.bindAttributeValue2 : '' }} -->
              </span>
              &nbsp;
            </el-col>
            <el-col :span="10">【客户经理】 {{ details.customerManager }}</el-col>
          </el-row>
        </el-card>
        <el-card class="reserved-details">
          <h4 class="title">预定信息</h4>
          <el-row>
            <el-col :span="7">【宴会厅占用】 {{ details.hallsName }}</el-col>
            <el-col :span="7">【宴会信息】 {{ details.categoryName }} / {{ details.reserveTableNumber }}桌 / 浮动 {{ details.backupTableNumber }}桌</el-col>
            <el-col :span="7">【宴会时间】 {{ parseTime(details.reserveDate, '{y}-{m}-{d}') }} {{ details.reserveTimeName }}</el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="14">
              <h4 style="font-weight: normal">
                【餐标信息】
                <span v-if="details.mealStandardName">{{ details.mealStandardName }}</span>
              </h4>
            </el-col>
            <!--            <el-col :span="7">【预定时间】{{ details.createdAt }}</el-col>-->
            <!--            <el-col :span="1" style="opacity: 0">-</el-col>-->
            <el-col :span="10">
              <h4 style="font-weight: normal">【收费服务】</h4>
              <div class="box-card">
                <div style="display: inline-block; margin-right: 10px;: " v-for="item in details.services" :key="item.id">{{ item.providerservice }}：{{ item.servicePrice }}元*{{ item.num }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="14">【详情备注】 {{ details.contentBack }}</el-col>
            <el-col :span="10">
              <h4 style="font-weight: normal">【定金方式】</h4>
              <div class="box-card">
                <div v-for="(item, index) in details.paymentList" :key="index">
                  <div v-if="item.verifyMode === 'deposits'" :key="index + 101">
                    {{ shou[index] }}次定金方式：定金/定金金额：{{ item.amount }}元/支付项目：{{ item.paymentItem }}/{{ item.paymentMethod }}/订单编号:{{ item.receiptCode }}
                  </div>
                  <div v-if="item.verifyMode === 'guarantors'" :key="index + 201">
                    {{ shou[index] }}次定金方式：担保/担保金额：{{ item.amount }}元/支付项目：{{ item.paymentItem }}/担保人：{{ item.guarantor }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px;">
            <el-col :span="24">
              <h4 style="font-weight: normal">【合同信息】</h4>
              <div class="box-card">
                <viewer :images="details.images">
                  <img class="box-card-img" v-for="item in details.files" :key="item.id" :src="imageUrl + item.url" />
                </viewer>
              </div>
            </el-col>
            <el-col v-if="details.createTime" :span="7">【预留时间】{{ details.createTime }}</el-col>
            <el-col v-if="details.reserveCreatedAt" :span="7">【预定时间】{{ details.reserveCreatedAt }}</el-col>
          </el-row>
        </el-card>
        <el-card class="reserved-details" v-if="details.referBanquets">
          <h4 class="title">关联信息</h4>
          <el-row v-for="item in details.referBanquets" :key="item.bizId" align="middle" type="flex">
            <el-col :span="7">【关联宴会信息】 {{ item.hallName }}</el-col>
            <el-col :span="4">【宴会时间】 {{ details.startDate }} {{ parseTime(details.reserveDate, '{y}-{m}-{d}') }}</el-col>
            <el-col :span="3">
              <el-button v-if="details.orderStatus === 1" @click="retiredRoom(item)" type="text">取消预留</el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: flex-end">
        <div v-if="details.orderStatus !== 5">
          <!-- <el-button @click="selectHallRoom(details)" type="primary">更换宴会厅</el-button> -->
          <el-button v-if="details.orderStatus === 1" @click="retiredRoom(details)" type="primary">取消预留</el-button>
          <el-button v-if="details.orderStatus === 1" @click="revise" type="primary">转预定</el-button>
          <el-button v-if="details.orderStatus === 2" @click="revise" type="primary">修改</el-button>
          <el-button v-if="details.orderStatus === 2" @click="unReserved(details)" type="primary">退定</el-button>
        </div>
        <el-button style="margin-left: 15px" type="primary" @click="elegantRoomVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="更换宴会厅" :close-on-click-modal="false" width="1300px" :visible.sync="dialogVisible">
      <el-card class="box-card ballroom">
        <div class="roombox">
          <div class="roomlist">
            <div class="room-iten" v-for="j in roomList" :key="j.value">
              <div @click="activeIdClick(j.value)" class="info" :style="reservedHall.includes(j.value) ? 'background: #15D37D; color: #ffffff' : false">
                <div style="font-size: 14px; overflow: hidden; height: 20px;">{{ j.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer" v-loading="r2r_loading">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeChannel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { imageUrl } from '@/utils/api'
import datas from './details/detalis.json'
// import html2canvas from 'html2canvas'
export default {
  name: 'Details',
  data() {
    return {
      r2r_loading: false,
      roomList: [],
      local_hall: [],
      reservedHall: [],
      atHall: '',
      dialogVisible: false,
      shou: ['首', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      elegantRoomVisible: false,
      details: {},
      imageUrl,
      data: {},
      row: {},
      loading: false
    }
  },
  props: {
    zhi: Object
  },
  inject: ['initAria'],
  mounted() {},
  methods: {
    // 查看详情
    async getDetails(row, atHall) {
      this.atHall = atHall
      this.row = {
        ...row
      }
      this.load()
    },
    async load() {
      this.details = ''
      this.elegantRoomVisible = true
      this.data = {
        hotelBizId: this.row.hotelId,
        bizId: this.row.id
      }
      // 查询用户信息
      const res = await this.getRequest('banquet/loadDetails', this.data)
      if (res.code !== 200) {
        return false
      }
      const names = res.data.halls.map(r => {
        return r.name
      })
      res.data.hallsName = names.join('/')
      this.details = res.data
    },
    async selectHallRoom(row) {
      console.log(row)
      const res = await this.getRequest('banquet/loadmeta', { hotelId: row.hallId })
      if (res.code !== 200) {
        this.$message.error('宴会厅信息获取失败!')
        return
      }
      this.local_hall = res.data.halls
      const hall_ids = row.halls.map(r => {
        return r.id
      })
      this.onBallRoomTagChange(hall_ids)
      this.dialogVisible = true
    },
    onBallRoomTagChange(e) {
      const arr2 = e.slice(0)
      const rooms = this.BallRoomPop(arr2, this.local_hall)
      this.reservedHall = []
      this.roomList = rooms.map(r => {
        if (e.includes(r.id)) {
          this.reservedHall.push(r.id)
        }
        return {
          label: r.name,
          value: r.id
        }
      })
    },
    BallRoomPop(e, rooms) {
      if (e.length > 0) {
        var key = e.pop()

        const hall = this.local_hall.find(r => {
          if (r.id === key) {
            return r
          }
        })
        const repels = hall.repels.split(',')
        const ci = rooms.filter(i => {
          if (!repels.includes(i.id)) {
            return true
          }
        })
        return this.BallRoomPop(e, ci)
      } else {
        return rooms
      }
    },
    activeIdClick(val) {
      if (this.reservedHall.includes(val)) {
        this.reservedHall = this.reservedHall.filter(item => {
          return item !== val
        })
      } else {
        this.reservedHall.push(val)
      }
      this.onBallRoomTagChange(this.reservedHall)
    },
    // 修改宴会厅
    changeChannel() {
      this.$confirm('确定取消预留吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          this.r2r_loading = true
          const data = {
            halls: this.reservedHall,
            hotelBizId: this.row.hotelId,
            bizId: this.row.id
          }
          const res = await this.post('banquet/replaceHallRoom', data)
          if (res.code !== 200) {
            this.r2r_loading = false
            this.$message.error(res.msg)
            return false
          }
          setTimeout(() => {
            this.dialogVisible = false
            this.initAria()
            this.load()
            this.$message({
              duration: 10000,
              type: 'success',
              message: '更改宴会厅成功!'
            })
          }, 500)
          this.r2r_loading = false
        })
        .catch(() => {
          this.r2r_loading = false
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消更改'
          })
        })
    },
    // 取消预留
    retiredRoom(row) {
      this.$confirm('取消后预留订单将关闭!是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          this.loading = true
          const { id, hotelId } = row
          const data = {
            id,
            hallId: this.atHall,
            hotelId
          }
          console.log(row)
          console.log(data)
          const res = await this.post('banquet/retired', data)
          this.loading = false
          if (res.code !== 200) {
            this.$message(res.message)
            return false
          }
          setTimeout(() => {
            this.$message({
              duration: 10000,
              type: 'success',
              message: '取消预留成功!'
            })
            this.elegantRoomVisible = false
            this.initAria()
          }, 500)
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消预留'
          })
        })
    },
    // 修改
    revise() {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '宴会预定')
      const submenu = {
        path: '/banquet/reserve',
        name: '宴会预定',
        bizId: '/banquet/reserve'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$store.commit('setBanquetReservation', this.data)
      this.$router.push({
        path: '/banquet/reserve'
      })
    },
    // 退订
    unReserved(row) {
      this.$confirm('您确定要退订吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.loading = true
          const { id, hotelId } = row
          const data = {
            id,
            hallId: this.atHall,
            hotelId
          }
          const res = await this.post('banquet/retired', data)
          this.loading = false
          if (res.code !== 200) {
            this.$message(res.message)
            return false
          }
          setTimeout(() => {
            this.$message({
              duration: 10000,
              type: 'success',
              message: '退订成功!'
            })
            this.elegantRoomVisible = false
            this.initAria()
          }, 500)
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消退订'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details {
  height: auto;
  margin-top: -30px;
  margin-bottom: -20px;
  .customer-details {
    height: 120px;
    margin-bottom: 20px;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
      background: #f7f6f6;
    }
  }
  .reserved-details {
    height: auto;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
    }
    .box-card {
      border-radius: 5px;
      padding: 0px 5px;
      .box-card-img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 5px;
      }
    }
  }
}

.ballroom {
  width: 100%;
  overflow: auto;
  border-radius: 5px;
  padding: 0 20px;
  .roombox {
    margin-bottom: 20px;
    h3 {
      margin-bottom: 10px;
      padding-bottom: 5px;
      padding-left: 5px;
      font-size: 16px;
      border-bottom: 1px solid #eeeeeeee;
    }
    .roomlist {
      display: flex;
      flex-wrap: wrap;
      justify-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .room-iten {
        width: 80px;
        height: 50px;
        margin-right: 20px;
        border-radius: 3px;
        position: relative;
        border: 1px solid #eee;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        overflow: hidden;
        .info {
          width: 100%;
          margin: 0 auto;
          height: 50px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .info:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
