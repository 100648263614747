<template>
  <div class="salespersons-page">
    <div class="head">
      <div style="margin-right: 20px">
        <span style="margin-right: 10px; font-size: 14px">餐区</span>
        <el-select style="width: 100px" @change="onRoomboxChange" v-model="form.dinnerZoneBizId" placeholder="餐区">
          <el-option v-for="item in dinnerZoneList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 20px">
        <span style="margin-right: 10px; font-size: 14px">人数区间</span>
        <el-select style="width: 150px" @change="onRoomitemChange" v-model="form.rangePersonBizId" placeholder="人数">
          <el-option v-for="item in rangePersonlist" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 20px">
        <span style="margin-right: 20px; font-size: 14px">日期</span>
        <el-date-picker v-model="date1" type="date" @change="changeDate" :clearable="false" :editable="false"></el-date-picker>
      </div>
      <el-radio-group style="margin-right: 20px" @change="radioChange" v-model="form.radio">
        <el-radio-button :label="0">中午</el-radio-button>
        <el-radio-button :label="1">晚上</el-radio-button>
      </el-radio-group>
      <el-switch @change="flageChange" v-model="form.flage" inactive-text="只看空台">
      </el-switch>
    </div>
    <div class="ballroom">
      <div v-for="(item,index) in recordslist" :key="index">
        <!-- 楼层 -->
        <div class="roombox" v-show="roomboxShow.includes(item.dinnerZoneBizId)">
          <h3>{{ item.dinnerZoneName }}</h3>
          <div class="roomlist">
            <!-- 雅间 -->
            <div v-for="(j,indexs) in item.datas" :key="indexs">
              <div class="room-iten" v-if="roomItemShow.includes(j.rangeId) &&  flageShow.includes(j.statusSelect)">
                <div class="info">
                  <div style="font-size: 14px;overflow: hidden; height: 20px;">{{j.name}}</div>
                  <div style="color: #999; font-size:14px;">{{j.rangeName}}</div>
                </div>
                <div class="times">
                  <div v-for="(m, index) in j.timeQuantums" :key="m.bizId">
                    <div :class="['active', 'active'+ m.orderStatus]" v-show="form.radio === index" @mousedown.prevent="touchstart(j, m)" @mouseup.prevent="goTouchend(j, m)">
                      <!--  普通状态 -->
                      <span v-if="!m.datas">{{m.name}}</span>

                      <!-- 图标 -->
                      <b v-else-if="m.isLock">
                        <i class="el-icon-s-goods" style="font-size: 20px"></i>
                      </b>

                      <!-- 预定状态 -->
                      <div class="active-div" style="text-align: center" v-else>
                        <div class="bb">
                          <b v-if="m.datas.referRoomIds !== '[]'" class="b3"></b>
                          <b v-if="m.datas.remark && m.datas.remark !== null" class="b1"></b>
                          <b v-if="m.datas.returnVisit" class="b6"></b>
                        </div>
                        <div>
                          <span>{{m.datas.customerName ? m.datas.customerName.slice(0,1) : ''}}</span>
                          <span>{{m.datas.genderName}}</span>
                          <span>{{m.datas.customerPhoneNumber ? m.datas.customerPhoneNumber.slice(-4) : ''}}</span>
                        </div>
                        <div style="width: 100%; height: 20px; overflow: hidden">{{m.datas.customerManager}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lock" v-if="false">
                  <i class="el-icon-s-goods"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="state">
      <div class="state-group">
        <div class="group-item state-hint">状态提示:</div>
        <div class="group-item state-0">未定</div>
        <div class="group-item state-1">预留</div>
        <div class="group-item state-2">入座</div>
        <div class="group-item state-3">
          <b style="background: red"></b>
          有备注
        </div>
        <div class="group-item state-3">
          <b style="background: darkgreen"></b>
          已回访
        </div>
        <div class="group-item state-3">
          <b style="background: #055cfb"></b>
          有关联预定
        </div>
      </div>
    </div>
    <zeropoint-details ref="details"></zeropoint-details>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ZeropointDetails from '@/components/order/DinnerZeropointDetails'
const roomboxShow = []
const roomItemShow = []
let dateTime = null
let flage = false
let remainingRoom = {}
let jzHoursMonth = ''
let jzHoursMonthNight = ''
let dinnerNightDay = 1
let selectDate = {
  year: '',
  month: '',
  day: '',
  week: '',
  radio: ''
}
export default {
  name: 'SalesControlTable',
  data() {
    return {
      org: null,
      timer: null,
      roomboxShow: [],
      roomItemShow: [],
      flageShow: [null, 1, 2, 3],
      recordslist: [],
      oldTableData: [],
      form: {
        radio: 0,
        flage: false,
        dinnerZoneBizId: 'all',
        rangePersonBizId: 'all'
      },
      date: {
        year: '',
        month: '',
        day: '',
        week: ''
      },
      date1: null,
      dinnerZoneList: [],
      rangePersonlist: [],
      rommList: [],
      value: true,
      tables: []
    }
  },
  components: {
    ZeropointDetails
  },
  computed: {
    ...mapState({
      // 从 state 中的到的计算属性
      noticeDinner: state => state.noticeDinner // 已选中菜单
    })
  },
  watch: {
    // socket传入刷新
    noticeDinner: {
      deep: true,
      handler(val) {
        //  console.log('您有新订单，刷新了！')
        this.initAria()
      }
    }
  },
  provide() {
    return {
      initAria: this.initAria
    }
  },
  methods: {
    radioChange(e) {
      if (this.form.flage) {
        if (this.form.radio === 0) {
          this.flageShow = [null, 2]
        } else {
          this.flageShow = [null, 1]
        }
      } else {
        this.flageShow = [null, 1, 2, 3]
      }
    },
    onRoomboxChange(e) {
      if (e === 'all') {
        this.roomboxShow = roomboxShow
        return false
      }
      this.roomboxShow = [e]
    },
    onRoomitemChange(e) {
      if (e === 'all') {
        this.roomItemShow = roomItemShow
        return false
      }
      this.roomItemShow = [e]
    },
    flageChange(e) {
      if (e) {
        if (this.form.radio === 0) {
          this.flageShow = [null, 2]
        } else {
          this.flageShow = [null, 1]
        }
      } else {
        this.flageShow = [null, 1, 2, 3]
      }
    },
    // 初始化日期，选择日期
    initDay() {
      if (!this.date1) {
        dateTime = new Date()
      }
      // 保存上一次
      if (selectDate && selectDate.year !== '') {
        this.date = {
          ...selectDate
        }
        dateTime = new Date(
          selectDate.year + '-' + selectDate.month + '-' + selectDate.day
        )
        this.form.radio = selectDate.radio
      }
      // 页面加载
      else {
        // 1. 结转时间
        // 1.1 零点--中午结束时间【系统设置】
        jzHoursMonth = this.org.dinnerNoonDayEndTime || '16:00'
        const jzHoursMonths = jzHoursMonth.split(':')
        // 1.2 零点--当天        【系统设置】
        dinnerNightDay = this.org.dinnerNightDay || '1'
        // 1.3 零点--晚上结束时间【系统设置】
        jzHoursMonthNight = this.org.dinnerNightDayEndTime || '23:00'
        const jzHoursMonthNights = jzHoursMonthNight.split(':')

        // 1.4 根据当前时间-判定中午晚上
        const date_now = new Date()
        if (
          (date_now.getHours() >= 0 &&
            date_now.getHours() < jzHoursMonths[0]) ||
          (date_now.getHours() - jzHoursMonths[0] === 0 &&
            date_now.getMinutes() < jzHoursMonths[1])
        ) {
          if (dinnerNightDay === 2) {
            if (jzHoursMonthNights[0] - date_now.getHours() >= 0) {
              this.form.radio = 1
              // 第二天转结，查上一天
              dateTime = new Date(date_now.setDate(date_now.getDate() - 1))
            } else {
              this.form.radio = 0
            }
          } else {
            this.form.radio = 0
          }
        } else {
          this.form.radio = 1
        }

        // 1.5 加载当前日期
        this.date = {
          year: dateTime.getFullYear().toString(),
          month: (dateTime.getMonth() + 1).toString().padStart(2, '0'),
          day: dateTime
            .getDate()
            .toString()
            .padStart(2, '0'),
          week: '周' + '日一二三四五六'.charAt(dateTime.getDay()),
          radio: this.form.radio
        }
      }
      this.date1 = dateTime
      // 1.6 显示所有状态订单
      this.flageShow = [null, 1, 2, 3]
    },
    // -- 头部查询--变更日期
    changeDate(val) {
      dateTime = val
      this.date = {
        year: dateTime.getFullYear().toString(),
        month: (dateTime.getMonth() + 1).toString().padStart(2, '0'),
        day: dateTime
          .getDate()
          .toString()
          .padStart(2, '0'),
        week: '周' + '日一二三四五六'.charAt(dateTime.getDay()),
        radio: this.form.radio
      }
      this.initAria()
    },
    // --锁台和解锁。
    touchstart(row, time) {
      clearTimeout(this.timeOutEvent)
      flage = false
      this.timeOutEvent = setTimeout(() => {
        flage = true
        if (!time.isLock) {
          this.$confirm('确定锁台吗, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              let reserveTimeType = this.form.radio
              const data = {
                roomBizId: row.id,
                reserveDate:
                  this.date.year + '-' + this.date.month + '-' + this.date.day,
                reserveTimeType,
                hotelId: this.$store.state.hotellist[0].bizId,
                userCode: window.localStorage.getItem('bizId')
              }
              this.post('business/dinner/lockTable', data).then(res => {
                //  console.log(res)
                if (res.code == 200) {
                  this.$message({
                    duration: 10000,
                    type: 'success',
                    message: '锁台成功!'
                  })
                  this.queryAria()
                } else {
                  this.$message(res.msg)
                }
              })
            })
            .catch(() => {
              this.$message({
                duration: 10000,
                type: 'info',
                message: '您已取消锁台'
              })
            })
        } else {
          this.$confirm('确定解锁吗, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              this.deleteRequest(
                'business/dinner/unLockTable/' + time.datas.id
              ).then(res => {
                if (res.code == 200) {
                  this.$message({
                    duration: 10000,
                    type: 'success',
                    message: '解锁成功!'
                  })
                  this.queryAria()
                } else {
                  this.$message(res.msg)
                }
              })
            })
            .catch(() => {
              this.$message({
                duration: 10000,
                type: 'info',
                message: '您已取消解锁'
              })
            })
        }
      }, 1000)
    },
    // -- 点击选择预定
    goTouchend(row, time) {
      clearTimeout(this.timeOutEvent)
      if (flage === false) {
        //  处理单击事件
        this.onDayClick(row, time)
      }
    },
    // -- 执行点击预定事件
    onDayClick(row, time) {
      if (time.orderStatus === 8) {
        this.$message('已锁台，不可预定')
        return false
      }
      // console.log('remainingRoom:' + JSON.stringify(remainingRoom))
      // 判定是否已经预定
      if (time.select === 1) {
        const times = {
          jzHoursMonth,
          dinnerNightDay,
          jzHoursMonthNight
        }
        // 打开查看
        this.$refs.details.getDetails(
          time.datas,
          this.recordslist,
          times,
          remainingRoom
        )
      }
      // 没有预定,跳转预定页面
      else {
        // 判定预定时间是否过期
        const date =
          this.date.year + '-' + this.date.month + '-' + this.date.day
        const dateTime =
          this.form.radio === 0 ? jzHoursMonth : jzHoursMonthNight
        let newDate = new Date(date + ' ' + dateTime).getTime()
        if (dinnerNightDay === 2 && this.form.radio === 1) {
          newDate = newDate + 1000 * 60 * 60 * 24
        }
        if (newDate < Date.now()) {
          this.$message({
            duration: 10000,
            message: '过去的时间不可预定'
          })
          return false
        }

        // -- 跳转零点预定页面
        const data = {
          hotelBizId: row.hotelId,
          roomBizId: row.id,
          reserveTimeType: this.form.radio,
          date: this.date,
          remainingRoom,
          recordslist: this.recordslist,
          rommList: this.rommList
        }
        selectDate = {
          ...this.date,
          radio: this.form.radio
        }
        // console.log('selectDate', JSON.stringify(selectDate))
        // console.log('dattt', data)
        // 加入keepalive缓存
        this.$store.commit('addKeepAliveCache', '零点预定')
        // 添加tags标签
        const submenu = {
          path: '/zeropoint/reserve',
          name: '零点预定',
          bizId: '/zeropoint/reserve'
        }
        // 修改选中菜单
        this.$store.commit('selectMenu', submenu)
        this.$store.commit('setZeropointReservation', data)
        this.$router.push('/zeropoint/reserve')
      }
    },
    // 初始化下拉框
    init() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.getRequest('dinner-room/loadRoom')
        .then(res => {
          loading.close()
          if (res.code == 200) {
            // 餐区1
            this.dinnerZoneList = res.data.zones.map(o => {
              roomboxShow.push(o.zoneId)
              this.roomboxShow = roomboxShow
              return {
                id: o.zoneId,
                value: o.zoneId,
                label: o.zoneName
              }
            })
            // 餐区2
            this.dinnerZoneList.unshift({
              value: 'all',
              label: '全部',
              bizId: 'all'
            })
            // 人数区间1
            this.rangePersonlist = res.data.ranges.map(o => {
              roomItemShow.push(o.rangeId)
              this.roomItemShow = roomItemShow
              return {
                id: o.rangeId,
                value: o.rangeId,
                label: o.rangeName
              }
            })
            // 人数区间2
            this.rangePersonlist.unshift({
              value: 'all',
              label: '全部',
              bizId: 'all'
            })
            // 房间
            this.rommList = []
            for (let i = 0; i < res.data.res.length; i++) {
              // 雅间数据
              for (let j = 0; j < res.data.res[i].datas.length; j++) {
                const zhi = res.data.res[i].datas[j] // 每个雅间信息
                this.rommList.push(zhi)
              }
            }
            // --
            this.tables = res.data.res
            this.initAria()
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    // 初始化销控表数据
    initAria() {
      remainingRoom = {}
      this.recordslist = []
      var records = this.tables
      records.forEach(o => {
        o.datas.forEach(m => {
          m.statusSelect = null
          m.timeQuantums.forEach(n => {
            n.select = 0 // 初始未预定
            n.isLock = false // 初始未锁台
          })
        })
      })
      // 保存底图
      this.oldTableData = records
      // 加载销控
      this.queryAria()
    },
    // 查询销控表数据
    queryAria() {
      const recordslist = JSON.parse(JSON.stringify(this.oldTableData))
      if (
        this.date.year === '' ||
        this.date.month === '' ||
        this.date.day === ''
      ) {
        const dates = new Date()
        this.date = {
          year: dates.getFullYear().toString(),
          month: (dates.getMonth() + 1).toString().padStart(2, '0'),
          day: dates
            .getDate()
            .toString()
            .padStart(2, '0'),
          week: '周' + '日一二三四五六'.charAt(dates.getDay()),
          radio: this.form.radio
        }
      }
      // 2.1 请求订单表数据
      const param = {
        time: this.date.year + '-' + this.date.month + '-' + this.date.day,
        section: this.form.radio
      }
      this.getRequest('business/dinner/findDinnerReserved', param).then(res => {
        res.data.forEach(o => {
          // 订单数据
          for (let i = 0; i < recordslist.length; i++) {
            // 雅间数据
            for (let j = 0; j < recordslist[i].datas.length; j++) {
              const zhi = recordslist[i].datas[j] // 每个雅间信息
              if (zhi.statusSelect === undefined) {
                // 空值 未预定
                zhi.statusSelect = 0
              }
              if (zhi.id === o.roomId) {
                // 找到当前雅间
                for (let k = 0; k < zhi.timeQuantums.length; k++) {
                  // 检查数据是上午还是下午
                  const times = zhi.timeQuantums[k]
                  if (o.reserveTimeType == k) {
                    if (o.orderStatus === 8) {
                      times.isLock = true
                    }
                    if (!remainingRoom[o.reserveTimeType]) {
                      remainingRoom[o.reserveTimeType] = []
                    }
                    remainingRoom[o.reserveTimeType].push(o.roomId)
                    times.datas = o
                    times.orderStatus = o.orderStatus
                    times.select = 1
                    zhi.statusSelect = k + 1 + zhi.statusSelect
                    return
                  }
                }
              }
            }
          }
        })
      })
      this.recordslist = [...recordslist]
      //  console.log('recordslist', this.recordslist)
      this.$forceUpdate()
    }
  },
  created() {
    // console.log('noticeDinner:' + this.noticeDinner)
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    // console.log('酒店：' + JSON.stringify(this.org))
  },
  mounted() {
    this.init()
    this.initDay()
  }
}
</script>

<style lang="scss" scoped>
.salespersons-page {
  width: 98%;
  height: 100%;
  background: #f7f7f7;
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  .head {
    display: flex;
    height: 80px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0;
    box-sizing: border-box;
    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #666;
      i {
        text-shadow: 2px 2px 2px #ccc;
        margin: 0px 20px 0;
        cursor: pointer;
      }
      .text {
        font-weight: bold;
        font-size: 22px;
      }
    }
  }
  .ballroom {
    width: 100%;
    overflow: auto;
    height: 68vh;
    border-radius: 5px;
    padding: 0 20px;
    padding-bottom: 100px;
    .roombox {
      margin-bottom: 20px;
      h3 {
        margin-bottom: 10px;
        padding-bottom: 5px;
        padding-left: 5px;
        font-size: 16px;
        border-bottom: 1px solid #eeeeeeee;
      }
      .roomlist {
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        .room-iten {
          width: 100px;
          height: 100px;
          margin: 10px 0;
          margin-right: 20px;
          border-radius: 3px;
          position: relative;
          border: 1px solid #eeeeee;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          overflow: hidden;
          .times {
            .active {
              width: 100%;
              height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              padding: 0;
              margin: 0;
              background: #d7d3cf;
              cursor: pointer;
              color: #666;
              font-size: 12px;
              text-align: center;
              position: relative;
            }
            .bb {
              position: absolute;
              width: 100%;
              height: 12px;
              line-height: 12px;
              top: 0;
              right: 0;
              text-align: right;
              b {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin: 0 2px;
              }
              .b1 {
                background: #f9040f;
              }
              .b2 {
                background: #ffdd00;
              }
              .b3 {
                background: #055cfb;
              }
              .b6 {
                background: darkgreen;
              }
            }
            .active3 {
              background: #ffdd00;
              color: #555;
            }
            .active7 {
              background: #37d15d;
              color: #555;
            }
          }
          .info {
            width: 100%;
            margin: 0 auto;
            border-radius: 5px;
            height: 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .lock {
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.5);
            z-index: 10;
            color: #fff;
            font-size: 30px;
            text-align: center;
            line-height: 100px;
          }
        }
      }
    }
  }
  .state {
    width: 100%;
    position: absolute;
    height: 50px;
    padding-bottom: 25px;
    bottom: 0px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    background: #ffffff;
    box-sizing: border-box;
    .state-group {
      width: 600px;
      display: flex;
      font-size: 13px;
      justify-content: space-around;
      .group-item {
        padding: 2px 10px;
        border-radius: 3px;
        color: #333;
        b {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-left: 5px;
        }
      }
      .state-hint {
        color: #666;
      }
      .state-0 {
        background: #e5e5e5;
      }
      .state-1 {
        background: #ffdd00;
      }
      .state-2 {
        background: #15d37d;
      }
      .state-3 {
        background: #f6f6f6;
      }
    }
  }
}
</style>
