<template>
  <!-- 机构--角色管理 -->
  <div class="rolemanage-page">
    <!-- 左侧 -->
    <el-card class="left-card">
      <el-tree ref="tree" :data="jiuDian" :props="props" show-checkbox check-strictly icon-class="form" node-key="id" default-expand-all :expand-on-click-node="false" @check-change="threeCheck">
      </el-tree>
    </el-card>
    <!-- 右侧 -->
    <el-card class="right-card">
      <div class="buttongroup">
        <el-input style="width:300px" maxlength="50" placeholder="酒店名称" clearable v-model="search.hotelName"></el-input>

        <el-input style="width:200px;margin-left:10px" maxlength="50" placeholder="角色名称" clearable v-model="search.name"></el-input>
        <el-input style="width:200px;margin-left:10px" maxlength="50" placeholder="类型" clearable v-model="search.dataType"></el-input>
        <el-button style="margin-left:10px" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
        <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
      </div>
      <el-table :data="tableData" v-loading="Loading" style="width: 100%" border>
        <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
        <!-- <el-table-column type="selection" align="center" width="55">
        </el-table-column> -->
        <el-table-column align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="dataType" label="类型">
        </el-table-column>
        <el-table-column align="center" prop="hotelName" label="酒店">
        </el-table-column>
        <el-table-column align="center" prop="state" label="状态">
          <template slot-scope="scope">
            {{scope.row.state == 1 ? '启用' : '禁用'}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="description" label="描述">
        </el-table-column>
        <el-table-column align="center" prop="address" width="450px" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.name!='管理员'" icon="el-icon-edit" size="mini" type="primary" @click="postEditRole(scope.row)">修改</el-button>
            <el-button v-if="isDel(scope.row)" icon="el-icon-delete" size="mini" type="danger" @click="postDeleteRole(scope.row)">删除</el-button>
            <el-button v-if="scope.row.name!='管理员'" icon="el-icon-edit" size="mini" type="warning" @click="setMenu(scope.row)">分配权限</el-button>
            <el-button v-if="scope.row.name!='管理员'" icon="el-icon-edit" size="mini" type="success" @click="setAppAuth(scope.row)">APP分配权限</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </el-card>

    <!-- 添加角色 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" :title="title + '角色'" :visible.sync="centerDialogVisible" width="30%" center>
      <el-form ref="dataForm" label-width="80px" :model="roleForm" :rules="rules">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="roleForm.name" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="dataType">
          <el-input v-model="roleForm.dataType"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用" prop="state">
          <!-- <el-switch v-model="roleForm.state"></el-switch> -->
          <el-select style="width:212px" v-model="roleForm.state" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="title=='添加'" label="是否全局" prop="defaultRole">
          <el-switch v-model="roleForm.defaultRole"></el-switch>
        </el-form-item>
        <el-form-item v-if="title=='添加'&& roleForm.defaultRole==false" label="所属酒店" prop="hotelId">
          <el-select v-model="roleForm.hotelId" placeholder="请选择酒店" clearable>
            <el-option v-for="item in jiuDian" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" maxlength="255" v-model="roleForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span v-loading="FormLoading" slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddRole">{{ title }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal='false' class="updatecode" :title="roletitle + 'App权限分配'" :visible.sync="appAuth" width="60%" center>
      <el-form ref="authForm" label-width="100px" :model="authForm">
        <el-form-item label="角色名称：" prop="name">
          <!-- <el-input v-model="menuForm.name" maxlength="50"></el-input> -->
          {{authForm.name}}
        </el-form-item>
        <el-form-item label="权限菜单：" prop="dataType">
          <el-tree :data="authTree" show-checkbox default-expand-all node-key="id" ref="tree1" highlight-current :props="defaultProps2" @check-change="checkChange2">
          </el-tree>
        </el-form-item>
      </el-form>
      <span v-loading="FormLoading" slot="footer" class="dialog-footer">
        <el-button @click="appAuth = false">取 消</el-button>
        <el-button type="primary" @click="postAddAppAuth">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal='false' class="updatecode" :title="roletitle + '权限分配'" :visible.sync="menuDialogVisible" width="60%" center>
      <el-form ref="menuForm" label-width="100px" :model="menuForm">
        <el-form-item label="角色名称：" prop="name">
          <!-- <el-input v-model="menuForm.name" maxlength="50"></el-input> -->
          {{menuForm.name}}
        </el-form-item>
        <el-form-item label="权限菜单：" prop="dataType">
          <el-tree :data="menuTree" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current :props="defaultProps" @check-change="checkChange">
          </el-tree>
        </el-form-item>
      </el-form>
      <span v-loading="FormLoading" slot="footer" class="dialog-footer">
        <el-button @click="menuDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddNenu">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RoleManage',
  data() {
    return {
      Loading: false,
      FormLoading: false,
      data: [],
      tableData: [],
      props: {
        id: 'id',
        label: 'name',
        children: 'children'
      },
      form: {
        id: null,
        type: '',
        name: '',
        code: '',
        area: '',
        system: ''
      },
      centerDialogVisible: false,
      appAuth: false,
      title: '添加',
      roleForm: {
        bizid: null,
        code: '',
        name: '',
        scope: '',
        tabIndex: '',
        state: true,
        description: ''
      },
      total: 0, // 总条数
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotel_id: '',
        hotelName: ''
      },
      dataTypelist: [
        { title: 'dinnerAdmin', value: 'dinnerAdmin' },
        { title: 'hotelAdmin', value: 'hotelAdmin' },
        { title: 'operationAdmin', value: 'operationAdmin' },
        { title: 'dinnerAdmin', value: 'dinnerAdmin' },
        { title: 'banquetAdmin', value: 'banquetAdmin' },
        { title: 'meetingAdmin', value: 'meetingAdmin' }
      ],
      jiuDian: [],
      roletitle: '',
      menuDialogVisible: false,
      menuTree: [],
      authTree: [],
      roleKeys: [],
      roleSelectKeys: [],
      authSelectKeys: [],
      permissoinJson: '',
      authJson: '',
      options: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '禁用'
        }
      ],
      menuForm: {
        id: '',
        name: '',
        menuVos: []
      },
      authForm: {
        id: '',
        name: '',
        authVos: []
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      defaultProps2: {
        children: 'children',
        label: 'label'
      },
      rules: {
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ],
        dataType: [
          { required: true, message: '角色类型不能为空', trigger: 'blur' }
        ],
        state: [{ required: true, message: ' ', trigger: 'blur' }],
        defaultRole: [{ required: true, message: ' ', trigger: 'blur' }],
        hotelId: [{ required: true, message: '请选择酒店', trigger: 'change' }]
      }
    }
  },
  mounted() {
    // 查询角色
    this.getRoleData()
    // 查询酒店
    this.bindJiuDian()
    // 查询菜单
    this.getMentTree(1)
  },
  methods: {
    isDel(row) {
      var show = true
      switch (row.name) {
        case '管理员':
          show = false
          break
        case '运营管理员':
          show = false
          break
        case '酒店':
          show = false
          break
        case '角色-零点':
          show = false
          break
        case '零点管理员':
          show = false
          break
        case '角色-宴会':
          show = false
          break
        case '角色-会议':
          show = false
          break
      }
      return show
    },
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 1. 获取机构信息--暂不用
    getOgzData() {
      this.getRequest('sys/department/getlist').then(res => {
        // console.log('部门结果：' + JSON.stringify(res))
        if (res.code !== 200) {
          return false
        }
        this.data = res.data
      })
    },
    // 1. 查询酒店
    bindJiuDian() {
      this.getRequest('data-hotel/getList').then(res => {
        // console.log('查询酒店结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.jiuDian = res.data
        }
      })
    },
    // 2. 树单选
    threeCheck(data, checked, node) {
      if (checked) {
        this.$refs.tree.setCheckedNodes([data])
        this.form = Object.assign(this.form, data)
        //  console.log('form:' + JSON.stringify(this.form))
        // 执行左侧查询
        // this.search.hotel_id = this.form.id
        this.search.hotelName = this.form.name
        this.getRoleData()
      }
    },
    // 3.1 查询角色
    query() {
      this.search.pageNumber = 1
      this.getRoleData()
    },
    // 3.2 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getRoleData()
    },
    // 4. 查询所有角色
    getRoleData() {
      this.Loading = true
      this.getRequest('sys/role/getPage', this.search)
        .then(res => {
          this.Loading = false
          // console.log('校色：' + JSON.stringify(res))
          if (res.code == 200) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.Loading = false
        })
    },
    // 5. 打开添加
    onAddClick() {
      this.title = '添加'
      this.roleForm = {
        name: '', // 角色名称
        description: '', // 角色描述
        dataType: '', // 校色类型
        state: 1, // 默认启用
        defaultRole: true // 默认全局
      }
      this.centerDialogVisible = true
      if (this.$refs.dataForm) {
        this.$refs.dataForm.resetFields()
      }
    },
    // 6. 打开修改
    postEditRole(row) {
      this.title = '修改'
      this.getRequest('sys/role/get/' + row.id, { id: row.id }).then(res => {
        // console.log('查询酒店结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.roleForm = res.data
        }
      })
      this.centerDialogVisible = true
      if (this.$refs.dataForm) {
        this.$refs.dataForm.resetFields()
      }
    },
    // 7. 提交添加、修改
    postAddRole() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (this.roleForm.defaultRole == false) {
            if (
              this.roleForm.hotelId == undefined ||
              this.roleForm.hotelId == null ||
              this.roleForm.hotelId == ''
            ) {
              this.$notify({
                title: '提示',
                message: '非全局角色，必须选择酒店',
                type: 'error',
                duration: 2000
              })
              return false
            }
          }
          let url = ''
          if (this.title === '添加') {
            url = 'sys/role/save'
          } else {
            url = 'sys/role/edit'
          }
          this.FormLoading = true
          //   console.log('提交：' + JSON.stringify(this.roleForm))
          this.post(url, this.roleForm).then(res => {
            this.FormLoading = false
            if (res.code == 200) {
              this.$notify({
                title: '提示',
                message: this.title + '成功',
                type: 'success',
                duration: 2000
              })
              this.getRoleData()
              this.centerDialogVisible = false
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    },
    // 8. 删除
    postDeleteRole(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(response => {
          this.Loading = true
          this.deleteRequest('sys/role/delByIds/' + row.id, {
            ids: row.id
          })
            .then(res => {
              this.Loading = false
              if (res.code == 200) {
                this.$message({
                  duration: 2000,
                  type: 'success',
                  message: '删除成功!'
                })
                this.getRoleData()
              } else {
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
            .catch(err => {
              this.Loading = false
            })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 9. 加载菜单
    getMentTree(id) {
      // 管理员有所有菜单权限，查询管理员
      this.getRequest('sys/permission/getRoleMenuList/' + id, { id: id }).then(
        res => {
          //   console.log('加载菜单：' + JSON.stringify(res))
          if (res.code == 200) {
            this.menuTree = res.data
          }
        }
      )
    },
    // 10. 打开分配权限菜单
    setMenu(row) {
      this.roletitle = '【' + row.name + '】'
      this.menuForm.id = row.id
      this.menuForm.name = row.name
      this.menuDialogVisible = true
      // 加载原有权限
      if (
        row.permissoinJson != undefined &&
        row.permissoinJson != null &&
        row.permissoinJson != ''
      ) {
        this.permissoinJson = JSON.parse(row.permissoinJson)
      }
      // 清空选择项目
      this.$refs.tree.setCheckedKeys([])
      // 加载原有权限
      this.getRoleMenu(row.id, this.permissoinJson)
    },
    // 11. 加载原有权限
    getRoleMenu(roleid, permissoinJson) {
      var _this = this
      // 查询用户拥有的菜单id
      this.getRequest('sys/permission/getRoleMenuKyes/' + roleid, {
        id: roleid
      }).then(res => {
        // console.log('菜单结果getRoleMenu：' + JSON.stringify(res))
        if (res.code == 200) {
          // 记录全路径
          this.roleSelectKeys = res.data
          // 加载到树
          //   console.log('加载到树：' + JSON.stringify(permissoinJson))
          if (res.data) {
            const loading = this.$loading({
              lock: true,
              text: '读取权限，请稍等......',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            setTimeout(function() {
              // this.authJson = res.data.authJson
              //  console.log('加载到树：' + JSON.stringify(res.data))
              if (res.data) {
                _this.$refs.tree.setCheckedKeys(res.data)
              }
              _this.$forceUpdate()
              loading.close()
            }, 300)
          }
        }
      })
    },
    // 12.选择权限事件
    checkChange(node) {
      // 不包含父选项
      this.permissoinJson = this.$refs.tree.getCheckedKeys()
      // 包含父选项
      this.roleSelectKeys = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys())

      // console.log('您选择了：' + JSON.stringify(this.roleSelectKeys))
    },
    // 13. 提交菜单权限
    postAddNenu() {
      var body = {
        roleId: this.menuForm.id,
        permIds: this.roleSelectKeys,
        permissoinJson: JSON.stringify(this.permissoinJson)
      }
      // console.log('提交：' + JSON.stringify(body))
      this.post('sys/role/editRolePerm', body).then(res => {
        // console.log('菜单结果getRoleMenu：' + JSON.stringify(res))
        if (res.code == 200) {
          this.$notify({
            title: '提示',
            message: '权限分配成功',
            type: 'success',
            duration: 2000
          })
          this.menuDialogVisible = false
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 9. 加载菜单
    getAppAuthTree(id) {
      var _this = this
      // 管理员有所有菜单权限，查询管理员
      this.getRequest('app/permissions/authTree', { roleId: id }).then(res => {
        //   console.log('加载菜单：' + JSON.stringify(res))
        if (res.code == 200) {
          _this.authTree = res.data.data
          const loading = this.$loading({
            lock: true,
            text: '读取权限，请稍等......',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          setTimeout(function() {
            // this.authJson = res.data.authJson
            // console.log('加载到树：' + JSON.stringify(res.data.authJson))
            if (res.data.authJson) {
              _this.$refs.tree1.setCheckedKeys(res.data.authJson)
            } else {
              _this.$refs.tree1.setCheckedKeys([])
            }
            _this.$forceUpdate()
            loading.close()
          }, 300)
          // 加载到树
        }
      })
    },
    setAppAuth(row) {
      this.roletitle = '【' + row.name + '】'
      this.authForm.id = row.id
      this.authForm.name = row.name
      this.appAuth = true
      // 清空选择项目
      // 加载原有权限
      this.getAppAuthTree(row.id)
      // console.log(row)
    },
    // 2.3 添加app权限
    postAddAppAuth() {
      var body = {
        roleId: this.authForm.id,
        permIds: this.authSelectKeys,
        authJson: this.authJson
      }
      this.FormLoading = true
      // console.log('提交：' + JSON.stringify(body))
      this.post('app/permissions/saveAuthTree', body).then(res => {
        this.FormLoading = false
        // console.log('菜单结果getRoleMenu：' + JSON.stringify(res))
        if (res.code == 200) {
          this.$notify({
            title: '提示',
            message: '权限分配成功',
            type: 'success',
            duration: 2000
          })
          this.appAuth = false
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 2.4 选择事件
    checkChange2(node) {
      // 不包含父选项
      this.authJson = this.$refs.tree1.getCheckedKeys()
      // 包含父选项
      this.authSelectKeys = this.$refs.tree1
        .getCheckedKeys()
        .concat(this.$refs.tree1.getHalfCheckedKeys())
    }
  }
}
</script>

<style lang="scss" scoped>
.rolemanage-page {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .left-card {
    width: 15%;
  }
  .right-card {
    width: 84%;
    position: relative;
    .buttongroup {
      margin-bottom: 10px;
    }
    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}
</style>
