<!--应用-- 添加短信类型 【dinner_ok】 -->
<template>
  <div class="short-message-page">
    <div class="box-page">
      <div class="left box">
        <img src="../../assets/images/iPhone.png" alt="">
        <div class="mode">
          <p>{{text}}</p>
          <span>(退订回T)</span>
        </div>
        <div class="preview">
          <div>内容预览</div>
          <div>当前发送短信条数为{{textLength}}条</div>
        </div>
      </div>
      <div class="center box">
        <el-form ref="form" :rules="rules" label-width="80px" :model="form" class="box-form" style="width: 100%">
          <el-form-item label="模版名称" prop="name">
            <el-input style="width: 190px" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="短信类型" prop="category">
            <el-select v-model="form.category" placeholder="请选择" @change="categoryChange" clearable="">
              <div v-for="item in categoryAll" :key="item.value">
                <el-option v-if="type.includes(item.value)" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
                <!-- <el-option :key="item.value" :label="item.label" :value="item.value">
                </el-option> -->
              </div>
            </el-select>
          </el-form-item>
          <el-form-item v-if="eventValueAll.length>0 || form.eventValue" label="场景" prop="eventValue">
            <el-select v-model="form.eventValue" placeholder="请选择" @change="eventValueChange">
              <el-option v-for="item in eventValueAll" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="短信内容">
            <div class="color-list">
              <el-tag style="cursor: pointer" @close="closeClick(color)" closable class="color-item" v-for="color in colors" v-dragging="{ item: color, list: colors }" :key="color.id">{{color.name}}</el-tag>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveMessage">保存</el-button>
          </el-form-item>
          <div style="padding-top:70px">
            <span v-html="text1" style="line-height:30px"></span>
          </div>
        </el-form>
      </div>
      <div class="right box">
        <div style="margin-bottom: 20px">
          <h5>自由编辑内容</h5>
          <el-input v-model="inpvalue" style="width: 300px" placeholder="请输入"></el-input>
          <el-button @click="addBtn" style="margin-left: 20px">添加</el-button>
        </div>
        <div>
          <h5>{{symbolData.name}}</h5>
          <div>
            <el-tag @click="tagSymbolPush(i)" v-for="(i, index) in symbolData.children" :key="index + i.name" style="cursor: pointer;margin: 5px 10px">{{i.name}}</el-tag>
          </div>
          <br />
        </div>
        <div v-for="(item, index) in dataTag" :key="index">
          <h5>{{item.name}}</h5>
          <div>
            <el-tag @click="tagPush(i)" v-for="(i, index) in item.children" :key="index + i.name" v-show="dingDanType(i)" style="cursor: pointer;margin: 5px 10px">{{i.name}}</el-tag>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v1 as uuid } from 'uuid'
import rules from '@/utils/rules'
const banquet = [
  ['预定', 3],
  ['退定', 4]
]
const zeropoint = [
  ['预定', 3],
  ['退定', 4]
]
const category = [
  ['宴会', 'banquet'],
  ['零点', 'dinner'],
  ['提醒服务', 'remind']
]
export default {
  name: 'List',
  data() {
    const categoryAll = category.map(o => {
      return {
        label: o[0],
        value: o[1],
        id: uuid()
      }
    })
    return {
      org: null,
      type: [],
      rules,
      dataTag: {},
      symbolData: {},
      specialDate: {},
      colors: [],
      form: {
        name: '',
        category: '',
        eventValue: ''
      },
      old: {},
      inpvalue: '',
      categoryAll: categoryAll,
      eventValueAll: [],
      text1:
        '1、计费规则：短信字数 <=70个字，按照70个字一条短信计算。中文英文符号统一计算为一个字符。* 短信字数>70个字，占用3个字符作为分条字符，按照67个字记为一条短信计算，多条短信可能在部分手机系统上呈现为一条短信的形态。</t> 2、重要提示：如短信模板进行了修改，请记得再次提交审核，审核通过后才可正常发送。'
    }
  },
  computed: {
    text() {
      let text = ''
      this.colors.forEach(o => {
        text += o.name
      })
      return text
    },
    textLength() {
      let text = ''
      this.colors.forEach(o => {
        text += o.name
      })
      return parseInt(text.length / 70) + 1
    }
  },
  mounted() {
    // 1. 酒店
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    // 2. 角色类型
    const accountType = JSON.parse(window.localStorage.getItem('accountType'))
    // console.log('角色类型:' + JSON.stringify(accountType))
    const type_arry = []
    if (accountType) {
      if (accountType.banquet === 1) {
        type_arry.push('banquet')
      }
      if (accountType.dinner === 1) {
        type_arry.push('dinner')
      }
      if (accountType.meeting === 1) {
        type_arry.push('meeting')
      }
    }
    this.type = type_arry
    var tags_String = window.localStorage.getItem('tags')

    // 3.如果是修改，加载短信
    if (
      this.$store.state.shortmessage !== null &&
      tags_String != undefined &&
      tags_String != null
    ) {
      //   console.log('加载：' + JSON.stringify(this.$store.state.shortmessage))
      const shortmessage = this.$store.state.shortmessage

      var tag_temp = JSON.parse(tags_String)
      this.colors = tag_temp || []
      this.form.name = shortmessage.name || ''
      this.form.id = shortmessage.id
      this.form.category = shortmessage.category || ''
      this.categoryChange()
      this.form.eventValue = parseInt(shortmessage.eventValue) || ''
      this.form.eventName = shortmessage.eventName
      this.form.status = shortmessage.status ? 1 : 0
      // const tempData = Object.assign({}, row) // copy obj
      this.old = shortmessage
    }
    // 4. 加载自定义参数
    this.queryShortMessage()
    this.$dragging.$on('dragend', res => {
      //  console.log('dragend' + JSON.stringify(res))
    })
  },
  methods: {
    // 1. 获取类型
    queryShortMessage() {
      // 标点符号选择
      this.symbolData = {
        name: '标点符号选择',
        children: [
          { name: '，' },
          { name: '。' },
          { name: '：' },
          { name: '！' },
          { name: '；' },
          { name: '【' },
          { name: '】' },
          { name: '（' },
          { name: '）' },
          { name: '~' },
          { name: '--' },
          { name: '/' },
          { name: '\\' },
          { name: '“' },
          { name: '“ ' },
          { name: "'" },
          { name: "'" },
          { name: '[换行]' }
        ]
      }
      var others = []
      this.getRequest(`message-metadata/getPage`, {
        pageSize: 999,
        pageNumber: 1,
        datacode: 'messageTemplate'
      }).then(res => {
        if (res.code == 200) {
          //   console.log('查询结果：' + JSON.stringify(res))
          var groups_jiudian = {
            name: '酒店信息',
            children: []
          }
          var groups_dingdan = {
            name: '订单信息',
            children: []
          }
          var groups_jingli = {
            name: '客户经理',
            children: []
          }
          // -- 分别加载所有 ----------------------------------------------
          res.data.records.forEach(element => {
            // 酒店
            if (element.category == '酒店信息') {
              groups_jiudian.children.push(element)
            }
            // 酒店
            if (element.category == '订单信息') {
              groups_dingdan.children.push(element)
            }
            // 客户经理
            if (element.category == '客户经理') {
              groups_jingli.children.push(element)
            }
          })
          others.push(groups_jiudian)
          others.push(groups_dingdan)
          others.push(groups_jingli)
        }
      })
      this.dataTag = others
    },
    // 2.根据订单类型，筛选显示标签
    dingDanType(element) {
      if (element.category != '订单信息') {
        return true
      } else {
        // 处理订单信息标签
        var rel = false
        if (this.form.category && this.form.category != '') {
          // 零点标签
          if (
            this.form.category == 'dinner' &&
            (element.parentBizId == '22' || element.parentBizId == '20')
          ) {
            rel = true
          }
          // 宴会标签
          if (
            this.form.category == 'banquet' &&
            (element.parentBizId == '22' || element.parentBizId == '21')
          ) {
            rel = true
          }
        } else {
          rel = true
        }
        return rel
      }
    },
    // 2. 添加自定义字段
    addBtn() {
      if (
        this.form.category == undefined ||
        this.form.category == null ||
        this.form.category == ''
      ) {
        this.$message('请先选择短信类型！')
        return
      }
      if (this.inpvalue) {
        this.colors.push({
          name: this.inpvalue,
          id: uuid()
        })
        this.inpvalue = ''
      } else {
        this.$message('请输入内容')
      }
    },
    // 3. 添加符号字段
    tagSymbolPush(val) {
      if (
        this.form.category == undefined ||
        this.form.category == null ||
        this.form.category == ''
      ) {
        this.$message('请先选择短信类型！')
        return
      }
      val = {
        ...val,
        id: Date.now()
      }
      this.colors.push(val)
    },
    // 4. 添加字段
    tagPush(val) {
      if (
        this.form.category == undefined ||
        this.form.category == null ||
        this.form.category == ''
      ) {
        this.$message('请先选择短信类型！')
        return
      }
      const zhi = this.colors.includes(val)
      if (zhi === false) {
        this.colors.push(val)
      } else {
        this.$message('已添加,请勿重复添加')
      }
      // console.log('this.colors:' + JSON.stringify(this.colors))
    },
    // 5. 删除字段
    closeClick(row) {
      this.colors = this.colors.filter(i => i !== row)
    },
    // 6. 短信类型改变事件
    categoryChange() {
      let categories = []
      this.eventValueAll = []
      if (
        this.form.category === 'banquet' ||
        this.form.category === 'meeting'
      ) {
        categories = banquet
      } else if (this.form.category === 'dinner') {
        categories = zeropoint
      }
      //  console.log('categories', categories)
      if (categories && categories.length > 0) {
        this.eventValueAll = categories.map(o => {
          return {
            label: o[0],
            value: o[1],
            id: uuid()
          }
        })
      } else {
        this.eventValueAll = []
        this.form.eventValue = ''
      }
    },
    // 7. 场景改变事件(退订、预定)
    eventValueChange(e) {
      // console.log('e', e)
      for (let i = 0; i < this.eventValueAll.length; i++) {
        if (this.eventValueAll[i].value === e) {
          this.form.eventName = this.eventValueAll[i].label
        }
      }
    },
    // 8. 提交
    saveMessage() {
      if (this.colors && this.colors.length < 1) {
        this.$message({
          duration: 10000,
          message: '请添加短信内容'
        })
        return false
      }
      this.$refs.form.validate(async flage => {
        if (flage) {
          const data = {
            ...this.form,
            content: '',
            srcContent: '',
            metadataBizIds: '',
            type: 49,
            tags: [],
            hotelId: this.org.id,
            applicationName: this.org.applicationName
          }
          // 确定短信类型，汉字名称
          if (this.categoryAll && this.categoryAll.length > 0) {
            const categoryName = this.categoryAll.find(o => {
              return data.category === o.value
            })
            //  console.log('categoryName', categoryName)
            if (categoryName) {
              data.categoryName = categoryName.label
            }
          }
          // 确定短信类型名称
          if (this.eventValueAll && this.eventValueAll.length > 0) {
            const eventName = this.eventValueAll.find(o => {
              return data.eventValue === o.label
            })
            //  console.log('eventName', eventName)
            if (eventName) {
              data.eventName = eventName.label
            }
          }
          // 拼接自定义标签和模板
          this.colors.forEach(o => {
            if (o.id) {
              data.metadataBizIds += o.id + ';'
              if (o.code) {
                data.content += '$' + `{${o.code}}`
              } else {
                data.content += o.name
              }
            }
            data.srcContent += o.name
            data.tags.push(o)
          })
          data.tags = JSON.stringify(data.tags)

          // -- 判定是添加还是修改 --------------------------------------------------------
          let url = 'message-template/add'
          if (this.form.id) {
            url = 'message-template/edit'
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          this.post(url, data)
            .then(res => {
              loading.close()
              // console.log(JSON.stringify(data))
              if (res.code == 200) {
                this.$message.success('保存成功')
                this.$refs.form.resetFields()
                this.colors = []
              } else {
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
            .catch(() => {
              loading.close()
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.short-message-page {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .box-page {
    width: calc(100% + 30px);
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    .box {
      width: 32%;
      height: 100%;
    }
    .left {
      width: 400px;
      height: 800px;
      position: relative;
      overflow: hidden;
      img {
        width: 400px;
        height: 800px;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
      }
      .mode {
        width: 300px;
        height: 360px;
        overflow-y: auto;
        border-radius: 20px;
        box-sizing: border-box;
        //padding: 10px;
        margin: 0 auto;
        margin-top: 200px;
        font-size: 14px;
        text-align: justify;
        position: relative;
        z-index: 2;
      }
      .preview {
        text-align: center;
        margin-top: 10px;
        position: relative;
        z-index: 2;
      }
    }
    .center {
      display: flex;
      .box-form {
        width: 100%;
      }
      .color-list {
        width: 90%;
        overflow: auto;
        min-height: 300px;
        height: auto;
        background: #f7f6f6;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
      }
      .el-tag {
        margin: 0 5px;
      }
    }
  }
}
</style>
