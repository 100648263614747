<template>
  <div class="page1">
    <div style="height: 100%; overflow: auto;">
      <h2 class="title">
        {{currentDate}}数据看板
        <div class="block">
          <el-date-picker format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="currentDate" align="right" type="date" @change="init" placeholder="选择日期" :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </h2>
      <div class="box">
        <div class="box-item" v-for="(item, index) in list" :key="index" :style="{background: colorArray[index]}">
          <h2>{{ item[0] }}</h2>
          <div class="item-div">
            <div v-if="item[1]">{{item[1]}}</div>
            <div v-if="item[2]">{{item[2]}}</div>
          </div>
        </div>
      </div>
      <el-table v-loading="loading" :data="tableData1" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
        <el-table-column align="center" type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="createdAt" sortable align="center" label="预定日期">
        </el-table-column>
        <el-table-column prop="customerName" align="center" label="客户姓名">
        </el-table-column>
        <el-table-column prop="customerPhoneNumber" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="categoryName" align="center" label="宴会类型">
        </el-table-column>
        <el-table-column prop="banquetDate" sortable align="center" label="宴会日期">
        </el-table-column>
        <el-table-column prop="hallName" align="center" label="宴会厅">
        </el-table-column>
        <el-table-column prop="reserveTableNumber" align="center" label="桌数">
        </el-table-column>
        <el-table-column prop="customerManagerName" label="客户经理" align="center">
        </el-table-column>
        <el-table-column align="center" label="状态" prop="status">
          <template slot-scope="scope">
            {{scope.row.status===1 ? '预留' : '预定'}}
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-table v-loading="loading" :data="tableData2" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
        <el-table-column align="center" type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="createdAt" sortable align="center" label="预定日期">
        </el-table-column>
        <el-table-column prop="customerName" align="center" label="客户姓名">
        </el-table-column>
        <el-table-column prop="customerPhoneNumber" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="categoryName" align="center" label="宴会类型">
        </el-table-column>
        <el-table-column prop="banquetDate" sortable align="center" label="宴会日期">
        </el-table-column>
        <el-table-column prop="hallName" align="center" label="宴会厅">
        </el-table-column>
        <el-table-column prop="reserveTableNumber" align="center" label="桌数">
        </el-table-column>
        <el-table-column prop="customerManagerName" label="客户经理" align="center">
        </el-table-column>
        <el-table-column align="center" label="状态" prop="status">
          <template slot-scope="scope">
            {{scope.row.status===1 ? '预留' : '预定'}}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BanquetDaily',
  data() {
    const colorArray = [
      '#ffdd00',
      '#15d37d',
      '#76b7ff',
      '#1fc38f',
      '#6767ff',
      '#fb8638',
      '#f9c443',
      '#8181e0'
    ]
    return {
      list: [],
      date: {},
      colorArray,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      currentDate: '',
      tableData1: [], // 宴会预留订单数据
      tableData2: [], // 宴会预留订单数据
      loading: true
    }
  },
  methods: {
    async init() {
      const records = {
        noonBanquets: 0,
        nightBanquets: 0,
        preAmount: 0,
        amount: 0,
        nonFinishOrders: 0,
        finishedOrders: 0,
        reserves: 0,
        retains: 0,
        newCustomers: 0,
        unreserves: 0,
        bizId: null,
        applicationName: null,
        hotelBizId: null,
        hotelName: null,
        year: null,
        month: null,
        day: null
      }
      this.records = records
      this.list = [
        [
          '举办场次',
          '中午: ' + (records.noonBanquets || 0) + '场',
          '晚上: ' + (records.nightBanquets || 0) + '场'
        ],
        ['今日预收款', records.preAmount || 0 + ''],
        ['今日实收款', records.amount || 0 + ''],
        ['今日未结订单', records.nonFinishOrders || 0 + ''],
        ['今日预订订单', records.reserves || 0 + ''],
        ['今日预留订单', records.retains || 0 + ''],
        ['今日新增客户线索', records.newCustomers || 0 + ''],
        ['今日退订订单', records.unreserves || 0 + '']
      ]
    },
    _currentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString()
      const day = date.getDate().toString()
      this.date = {
        year,
        month,
        day
      }
      return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
    },
    // 查询预定订单数据
    async getReserveOrders(v) {
      this.loading = false
      // 预留
      this.tableData1 = [
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '四号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 18,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 10:51:41',
          banquetDate: '2022-03-30(中午)',
          status: 1
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '六号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 10:57:30',
          createdAtTime: 1648004250271,
          banquetDate: '2022-03-30(晚上)',
          status: 1
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '六号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 15,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:10:02',
          createdAtTime: 1648005131893,
          banquetDate: '2022-03-28(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '七号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:12:57',
          createdAtTime: 1648005177646,
          banquetDate: '2022-03-28(中午)',
          status: 1
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '八号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:13:52',
          createdAtTime: 1648005251720,
          banquetDate: '2022-03-27(中午)',
          status: 1
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '九号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:14:52',
          createdAtTime: 1648005292859,
          banquetDate: '2022-03-27(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '十一号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:34:40',
          createdAtTime: 1648006480329,
          banquetDate: '2022-03-31(中午)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '会议',
          customerName: '十二号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 18,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:39:36',
          createdAtTime: 1648006776399,
          banquetDate: '2022-03-30(中午) - 2022-03-30(晚上)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '会议',
          customerName: '新城',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:41:38',
          createdAtTime: 1648006922195,
          banquetDate: '2022-03-26(中午) - 2022-03-26(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '会议',
          customerName: '1',
          customerPhoneNumber: '15258696352',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:47:38',
          createdAtTime: 1648007258787,
          banquetDate: '2022-03-25(中午) - 2022-03-25(晚上)',
          status: 1
        },
        {
          hallName: '峨眉厅',
          categoryName: '生日宴',
          customerName: '3',
          customerPhoneNumber: '15841526352',
          reserveTableNumber: 18,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 16:37:29',
          createdAtTime: 1648024649360,
          banquetDate: '2022-03-24(中午)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: '张三',
          customerPhoneNumber: '15680089006',
          reserveTableNumber: 23,
          backupTableNumber: 1,
          customerManagerName: '学聪宴会',
          createdAt: '2022-03-23 17:38:46',
          createdAtTime: 1648028367412,
          banquetDate: '2022-03-30(中午)',
          status: 1
        },
        {
          hallName: '泰山厅',
          categoryName: '婚宴',
          customerName: '李四',
          customerPhoneNumber: '15311432512',
          reserveTableNumber: 23,
          backupTableNumber: 2,
          customerManagerName: '学聪宴会',
          createdAt: '2022-03-23 17:40:11',
          createdAtTime: 1648028411681,
          banquetDate: '2022-03-29(中午)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: '1',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-25 11:52:47',
          createdAtTime: 1648279278984,
          banquetDate: '2022-03-31(中午)',
          status: 1
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '二',
          customerPhoneNumber: '15847526963',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:13:24',
          createdAtTime: 1648278804884,
          banquetDate: '2022-03-29(晚上)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '生日宴',
          customerName: '三',
          customerPhoneNumber: '15847526988',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:16:56',
          createdAtTime: 1648279123307,
          banquetDate: '2022-03-29(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '五号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:22:01',
          createdAtTime: 1648279321115,
          banquetDate: '2022-03-29(晚上)',
          status: 1
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '六号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:22:19',
          createdAtTime: 1648279356363,
          banquetDate: '2022-03-29(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '十三',
          customerPhoneNumber: '15847526952',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:47:33',
          createdAtTime: 1648280853477,
          banquetDate: '2022-04-06(中午)',
          status: 1
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '四号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 18,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 10:51:41',
          banquetDate: '2022-03-30(中午)',
          status: 1
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '六号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 10:57:30',
          createdAtTime: 1648004250271,
          banquetDate: '2022-03-30(晚上)',
          status: 1
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '六号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 15,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:10:02',
          createdAtTime: 1648005131893,
          banquetDate: '2022-03-28(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '七号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:12:57',
          createdAtTime: 1648005177646,
          banquetDate: '2022-03-28(中午)',
          status: 1
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '八号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:13:52',
          createdAtTime: 1648005251720,
          banquetDate: '2022-03-27(中午)',
          status: 1
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '九号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:14:52',
          createdAtTime: 1648005292859,
          banquetDate: '2022-03-27(中午)',
          status: 1
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '十一号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:34:40',
          createdAtTime: 1648006480329,
          banquetDate: '2022-03-31(中午)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '会议',
          customerName: '十二号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 18,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:39:36',
          createdAtTime: 1648006776399,
          banquetDate: '2022-03-30(中午) - 2022-03-30(晚上)',
          status: 1
        },
        {
          hallName: '衡山厅',
          categoryName: '会议',
          customerName: '新城',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:41:38',
          createdAtTime: 1648006922195,
          banquetDate: '2022-03-26(中午) - 2022-03-26(中午)',
          status: 1
        }
      ]
      // 已定
      this.tableData2 = [
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '五号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 10:57:14',
          createdAtTime: 1648004269552,
          banquetDate: '2022-03-30(中午)',
          status: 0
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '张建国',
          customerPhoneNumber: '15548870706',
          reserveTableNumber: 15,
          backupTableNumber: 18,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-23 11:15:32',
          createdAtTime: 1648265550150,
          banquetDate: '2022-03-31(中午)',
          status: 2
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '尖椒',
          customerPhoneNumber: '15548870412',
          reserveTableNumber: 18,
          backupTableNumber: 0,
          customerManagerName: '简然宴会',
          createdAt: '2022-03-26 09:47:59',
          createdAtTime: 1648259279060,
          banquetDate: '2022-03-31(中午)',
          status: 2
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '一',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:13:06',
          createdAtTime: 1648278827654,
          banquetDate: '2022-03-29(中午)',
          status: 0
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '七',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:32:51',
          createdAtTime: 1648280206375,
          banquetDate: '2022-04-01(中午)',
          status: 2
        },
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: '九',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 52,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:35:33',
          createdAtTime: 1648280170025,
          banquetDate: '2022-04-03(中午)',
          status: 2
        },
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: '十',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:38:09',
          createdAtTime: 1648280306909,
          banquetDate: '2022-04-05(中午)',
          status: 2
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '十一',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:40:16',
          createdAtTime: 1648280416196,
          banquetDate: '2022-04-05(晚上)',
          status: 2
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '十二号',
          customerPhoneNumber: '15847526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:45:43',
          createdAtTime: 1648280789038,
          banquetDate: '2022-04-04(中午)',
          status: 2
        },
        {
          hallName: '五台厅',
          categoryName: '婚宴',
          customerName: '十二',
          customerPhoneNumber: '15848526958',
          reserveTableNumber: 15,
          backupTableNumber: 0,
          customerManagerName: '文静宴会',
          createdAt: '2022-03-26 15:47:05',
          createdAtTime: 1648280925102,
          banquetDate: '2022-04-06(中午)',
          status: 0
        }
      ]
      // this['tableData' + v] = pageModel.records
    }
  },
  mounted() {
    this.currentDate = this._currentDate()
    this.getReserveOrders(1)
    this.getReserveOrders(2)
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.page1 {
  padding: 20px;
  box-sizing: border-box;
  .title {
    text-align: center;
    position: relative;
    .block {
      position: absolute;
      right: 80px;
      top: 0;
    }
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 20px;
    .box-item {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12), 0 0 20px rgba(0, 0, 0, 0.09);
      text-align: center;
      width: 280px;
      margin: 30px 60px;
      height: 300px;
      padding: 20px 0;
      color: #fff;
      border-radius: 5px;
      h2 {
        height: 100px;
        line-height: 100px;
      }
      .item-div {
        font-size: 20px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }
}
</style>
