<template>
  <!-- 应用--短信配置 -->
  <div class="type-page">
    <div class="buttongroup">
      <el-button @click="updateClick(null)" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" v-loading="loading" height="85%" :header-cell-style="headFirst" style="width: 100%" ref="multipleTable" border :span-method="objectSpanMethod">
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="name" label="类型">
        <el-table-column width="150px" align="center" prop="categoryName">
        </el-table-column>
        <el-table-column width="150px" align="center" prop="eventName">
        </el-table-column>
      </el-table-column>
      <el-table-column width="100px" align="center" label="状态">
        <template slot-scope="scope">
          <!--          <span>{{scope.row.status ? '启用' : '未启用'}}</span>-->
          <el-switch @change="onStatusChange(scope.row)" v-model="scope.row.status"></el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="模版名称">
      </el-table-column>
      <el-table-column align="center" prop="cretatTime" label="创建时间">
        <template slot-scope="scope">
          {{parseTime(scope.row.createTime,'{y}-{m}-{d} {h}:{i}:{s}')}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="updatedAt" label="更新时间">
        <template slot-scope="scope">
          {{parseTime(scope.row.updateTime,'{y}-{m}-{d} {h}:{i}:{s}')}}
        </template>
      </el-table-column>
      <el-table-column align="center" width="500px" prop="srcContent" label="短信内容">
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" type="text" @click="updateClick(scope.row)">修改</el-button>
          <el-button icon="el-icon-delete" size="mini" type="text" @click="postDeleteData(scope.row)">删除</el-button>
          <!-- <el-button size="mini" v-if="scope.row.auditStatus != 1" type="text" @click="postAudit(scope.row)">提交审核</el-button> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
let category = {}
let eventValue = {}
export default {
  name: 'TypeList',
  data() {
    return {
      form: {
        dataCode: 'payCategory',
        code: '',
        name: '',
        status: true
      },
      tableData: [],
      search: {
        pageSize: 999,
        pageNumber: 1,
        name: '',
        hotelId: '',
        hotelName: ''
      },
      loading: false
    }
  },
  created() {
    var orgs = window.localStorage.getItem('orgs')
    // console.log('公司：' + JSON.stringify(orgs))
    if (orgs != undefined && orgs != null) {
      var hotel = JSON.parse(orgs)
      this.search.hotelId = hotel[0].id
      this.getQueryData()
    } else {
      // 跳转登录
      // -- 没有默认酒店，跳转到登录页面
      this.$router.push('/login')
    }
  },
  methods: {
    // 1. 表头合并单元格
    headFirst({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1) {
        return { display: 'none' }
      }
      return 'background: #fff'
    },
    // 2. 内容合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 第一列
      if (columnIndex === 1) {
        const n = category[row.category].num
        const f = category[row.category].list
        for (let i = 0; i < f.length; i++) {
          if (rowIndex === f[i]) {
            return {
              rowspan: i === 0 ? n : 0,
              colspan: 1
            }
          }
        }
      }
      // 第二列
      if (columnIndex === 2) {
        const n = eventValue[row.category + '-' + row.eventValue].num
        const f = eventValue[row.category + '-' + row.eventValue].list
        for (let i = 0; i < f.length; i++) {
          if (rowIndex === f[i]) {
            return {
              rowspan: i === 0 ? n : 0,
              colspan: 1
            }
          }
        }
      }
    },
    // 3. 查询数据
    getQueryData() {
      this.loading = true
      this.getRequest('message-template/getPage', this.search)
        .then(res => {
          this.loading = false
          // console.log('短信模板查询结果:' + JSON.stringify(res))
          if (res.code == 200 && res.data && res.data.total > 0) {
            // --
            res.data.records.forEach(o => {
              o.status == 1 ? (o.status = true) : (o.status = false)
            })
            this.tableData = res.data.records
            const categoryArray = this.tableData.map(o => {
              return o.category
            })
            const eventValueArray = this.tableData.map(o => {
              return o.category + '-' + o.eventValue
            })
            category = {}
            categoryArray.map((a, index) => {
              if (a in category) {
                category[a].num++
                category[a].list.push(index)
              } else {
                category[a] = {
                  num: 1,
                  list: [index]
                }
              }
            })
            eventValue = {}
            eventValueArray.map((a, index) => {
              if (a in eventValue) {
                eventValue[a].num++
                eventValue[a].list.push(index)
              } else {
                eventValue[a] = {
                  num: 1,
                  list: [index]
                }
              }
            })
            this.loading = false
          } else {
            this.tableData = []
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 4. 修改状态
    onStatusChange(row) {
      this.loading = true
      this.post('message-template/editstate', {
        id: row.id,
        status: row.status ? 1 : 0
      })
        .then(res => {
          this.loading = false
          // console.log(JSON.stringify(res))
          if (res.code !== 200) {
            this.$message.error({
              duration: 2000,
              message: res.msg
            })
            return false
          }
          this.$message.success({
            duration: 2000,
            message: row.status ? '启用成功' : '禁用成功'
          })
          this.getQueryData()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 5. 删除数据
    postDeleteData(row) {
      this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading = true
          this.deleteRequest('message-template/delByIds/' + row.id, {
            id: row.id
          })
            .then(res => {
              this.loading = false
              if (res.code == 200) {
                this.$message({
                  duration: 2000,
                  type: 'success',
                  message: '删除成功!'
                })
                this.getQueryData()
                // location.reload()
              } else {
                this.$message.error({
                  duration: 2000,
                  message: res.msg
                })
              }
            })
            .catch(() => {
              this.loading = true
            })
        })
        .catch(() => {
          this.$message({
            duration: 2000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 6. 跳转添加、修改页面
    updateClick(row) {
      const menu = {
        path: '/shortmessage/add-type',
        name: '添加模版',
        bizId: '/shortmessage/add-type'
      }

      if (row != null) {
        menu.name = '编辑模板'
        window.localStorage.setItem('tags', row.tags)
      }
      this.selectMenu(menu)
      this.$store.commit('setShortmessage', row)
      this.$router.push(menu.path)
    },
    // 点击菜单 - 传入name，添加到keepalive缓存页面
    selectMenu(item) {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', item.name)
      // 添加tags标签
      const submenu = {
        path: item.path,
        name: item.name,
        bizId: item.path
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
    }
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
