<template>
  <el-card class="box-card">
    <el-table :data="tableData" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="name" label="短信类型">
      </el-table-column>
      <el-table-column align="center" prop="name" label="发送日期">
      </el-table-column>
      <el-table-column align="center" prop="name" label="接收人">
      </el-table-column>
      <el-table-column align="center" prop="name" label="发送内容">
      </el-table-column>
      <el-table-column align="center" prop="name" label="手机号">
      </el-table-column>
      <el-table-column align="center" prop="name" label="状态">
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
const pageSize = 10 // 每页的数量
const pageNumber = 1 // 当前页
export default {
  name: 'MessageRecharge',
  data() {
    return {
      tableData: [],
      pageNumber: pageNumber,
      pageSize: pageSize,
      total: 0 // 总条数,
    }
  },
  methods: {
    // 查询数据
    async getQueryData() {
      const data = {
        pageNumber: this.pageNumber,
        pageSize: pageSize
      }
      const res = await this.getRequestShort('admin/message/page', data)
      if (res.status !== 200) return false
      res.pageModel.records.forEach(o => {
        o.status = o.status === 1 ? (o.status = true) : (o.status = false)
      })
      // console.log('res.pageModel.records', res.pageModel)
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords
    },
    // 分页事件
    onCurrentChange(val) {
      this.pageNumber = val
      this.getQueryData()
    }
  },
  mounted() {
    this.getQueryData()
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
</style>
