import { getRequest, postRequest } from '@/utils/api'
import { Message, MessageBox } from 'element-ui'
import qs from 'qs'

export async function getQueryData(type) {
  const res = await getRequest(`admin/metamodel/${type}/query`)
  if (res.status !== 200) {
    return false
  }
  const { pageModel } = res
  pageModel.records.forEach(o => {
    o.status === 1 ? o.status = true : o.status = false
  })
  return pageModel.records
}

export async function postSetData(type, data) {
  const res = await postRequest(`admin/metamodel/${type}/command`, qs.stringify(data))
  if (res.status !== 200) {
    Message({
      duration: 10000,
      type: 'success',
      message: '操作失败'
    })
    return false
  }
  Message({
    duration: 10000,
    type: 'success',
    message: '操作成功'
  })
  return true
}

export async function postDeleteData(data) {
  const res = await MessageBox('此操作将永久删除该数据, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async() => {
      const res = await postRequest('admin/metamodel/delete/command', qs.stringify(data))
      if (res.status !== 200) {
        return false
      }
      Message({
        duration: 10000,
        type: 'success',
        message: '删除成功!'
      })
      return true
    })
    .catch(() => {
      Message({
        duration: 10000,
        type: 'info',
        message: '已取消删除'
      })
      return false
    })
  return res
}

export async function postSetZeropointData(type, data, model) {
  const res = await postRequest(`admin/metamodel/${model}/${type}/command`, qs.stringify(data))
  if (res.status !== 200) {
    Message({
      duration: 10000,
      type: 'success',
      message: '操作失败'
    })
    return false
  }
  Message({
    duration: 10000,
    type: 'success',
    message: '操作成功'
  })
  return true
}

export async function postDeleteZeropointData(data, model) {
  const res = await MessageBox('此操作将永久删除该数据, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async() => {
      const res = await postRequest(`admin/metamodel/${model}/delete/command`, qs.stringify(data))
      if (res.status !== 200) {
        return false
      }
      Message({
        duration: 10000,
        type: 'success',
        message: '删除成功!'
      })
      return true
    })
    .catch(() => {
      Message({
        duration: 10000,
        type: 'info',
        message: '已取消删除'
      })
      return false
    })
  return res
}
