<template>
  <div class="type-page">
    <div class="form">
      <el-form ref="form" :rules="rules" :model="form" class="demo-form-inline">
        <el-form-item label="设置类型" prop="messageType">
          <el-radio-group v-model="form.messageType">
            <el-radio label="remind">提醒服务</el-radio>
            <el-radio label="market">营销活动</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.messageType === 'remind'" label="活动名称" prop="name">
          <el-input style="width: 190px" v-model="form.name" placeholder="活动名称"></el-input>
        </el-form-item>
        <el-form-item v-if="form.messageType === 'market'" label="任务名称" prop="name">
          <el-input style="width: 190px" v-model="form.name" placeholder="任务名称"></el-input>
        </el-form-item>
        <el-form-item v-if="form.messageType!==''" label-width="75px" :label="(form.messageType === 'remind' ? '活动' : '任务') + '说明'">
          <el-input style="width: 500px" type="textarea" v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="选择模板" v-if="form.messageType !== ''" prop="templateBizId">
          <el-select class="select" @change="templateBizIdChange" v-model="form.templateBizId" placeholder="请选择">
            <el-option v-for="item in templateBizIdData" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.messageType !== ''" class="form-item-box" label="发送对象" prop="sendConditionCategory">
          <el-radio-group v-model="form.sendConditionCategory">
            <el-radio v-show="type.includes('banquet')" label="1">宴会</el-radio>
            <el-radio v-show="type.includes('dinner')" label="2">零点</el-radio>
            <el-radio v-show="type.includes('meeting')" label="3">会议</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.messageType !== '' && form.sendConditionCategory" class="form-item-box" :required="true" label-width="40px" prop="messageReceivers">
          <el-checkbox class="checkbox-all" :indeterminate="isIndeterminateMessage" v-model="messageReceiversAll" @change="handleCheckAllChangeMessage">全部</el-checkbox>
          <el-checkbox-group class="checkbox-group" @change="checkboxGroupChangeMessage" v-model="form.messageReceivers">
            <el-checkbox label="1">男</el-checkbox>
            <el-checkbox label="2">女</el-checkbox>
            <div v-show="form.sendConditionCategory === '1'" style="margin-left: 30px">
              <el-checkbox label="3">新郎</el-checkbox>
              <el-checkbox label="4">新娘</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="号码导入" v-if="form.messageType === 'market'" prop="templateBizId">
          <el-upload name="files" :data="uploadData" :headers="uploadHeaders" :action="action + 'attachment/upload'" :on-success="handleAvatarSuccess" :file-list="fileList">
            <el-button size="small" type="primary">号码导入(只能上传excel文件/.txt文件)</el-button>
          </el-upload>
        </el-form-item>
        <!--        <el-form-item v-if="form.messageType === 'market'">-->
        <!--          <div class="el-upload__tip">只能上传excel文件/.txt文件</div>-->
        <!--        </el-form-item>-->
        <el-form-item v-if="form.messageType !== ''" :label="(form.messageType === 'remind' ? '开始执行' : '发送') + '时间'" prop="startDate">
          <el-date-picker :picker-options="pickerOptions" v-model="form.startDate" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.messageType === 'remind'" label="说明：可用于激活沉睡用户或高频次消费用户，任务将循环发送，直到手动停止。"></el-form-item>
        <div v-if="form.sendConditionCategory === '2' && form.messageType === 'remind'">
          <el-form-item label="客户选择" prop="sendCondition">
            <el-radio-group v-model="form.sendCondition">
              <el-radio :label="1">沉睡客户</el-radio>
              <el-radio :label="2">高频次消费用户</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.messageType === 'remind' && form.sendCondition === 1" label-width="0px">
            <div class="sendList">
              <div class="sendBox">
                <i style="color: red; margin: 0 4px">*</i>
                <div>距【上次预定】超过</div>
                <el-input class="input" type="number" v-model="form.beforeDays"></el-input>
                <div>天</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="form.messageType === 'remind' && form.sendCondition === 2" label-width="0px">
            <div class="sendList">
              <div class="sendBox">
                <i style="color: red; margin: 0 4px">*</i>
                <div>近</div>
                <el-input class="input" type="number" v-model="form.lastDays"></el-input>
                <div>天内预定</div>
                <el-input class="input" type="number" v-model="form.reserveNumbers"></el-input>
                <div>次</div>
              </div>
            </div>
          </el-form-item>
        </div>
        <el-form-item v-if="(form.sendConditionCategory === '1' || form.sendConditionCategory === '3') && form.messageType === 'remind'" label-width="0px">
          <div class="sendList">
            <div class="sendBox">
              <i style="color: red; margin: 0 4px">*</i>
              <div>距【举办日期】</div>
              <el-input class="input" type="number" v-model="form.beforeReservedDays"></el-input>
              <div>天</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import rules from '@/utils/rules'
import { baseUrl } from '@/utils/api'
let token = ''
if (window.localStorage.getItem('token')) {
  token = window.localStorage.getItem('token').toString()
}
let reminderOptions = []
const genderOptions = ['1', '2']
export default {
  name: 'SendSettings',
  data() {
    return {
      type: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      },
      rules,
      value: '',
      form: {
        category: '',
        sendConditionCategory: '',
        messageType: '',
        sendCondition: '',
        name: '',
        description: '',
        startDate: '',
        beforeDays: '',
        beforeReservedDays: '',
        lastDays: '',
        reserveNumbers: '',
        messageReceivers: []
      },
      tableData: [],
      templateBizIdData: [],
      categories: [], // 宴会类型
      options: [
        {
          value: '名称',
          label: '名称'
        }
      ],
      messageReceiversAll: false,
      isIndeterminateMessage: true,
      genderAll: false,
      isIndeterminateGender: true,
      action: baseUrl,
      uploadHeaders: {
        'Access-Control-Allow-Origin': '*',
        accessToken: 'Bearer ' + token
      },
      uploadData: {
        phoneImport: 'Y'
      },
      fileList: []
    }
  },
  watch: {
    'form.sendConditionCategory': function() {
      if (this.form.sendConditionCategory === '1') {
        this.form.category = 'banquet'
      } else if (this.form.sendConditionCategory === '2') {
        this.form.category = 'dinner'
      } else {
        this.form.category = 'meeting'
      }
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      if (res.status !== 200) {
        return false
      }
      this.$message.success('上传成功!')
      this.form.attachmentBizId = res.pageModel.records[0].bizId
      this.fileList = [
        {
          name:
            res.pageModel.records[0].originalFileName +
            `(${res.pageModel.records[0].extra.number}条)`,
          url: res.pageModel.records[0].originalFileViewUrl
        }
      ]
    },
    // 查询宴会类型
    async getBanquetType() {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        status: '-1'
      }
      const res = await this.getRequest('admin/category/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      this.categories = pageModel.records.map(o => {
        return { value: o.bizId, label: o.name, bizId: o.bizId }
      })
    },
    // // 删除
    // removeDomain (index) {
    //   if (this.form.messageSendContent.length === 1) {
    //     return false
    //   }
    //   this.form.messageSendContent.splice(index, 1)
    // },
    // // 增加发送数据
    // messageSendContent () {
    //   if (this.form.messageSendContent && this.form.messageSendContent.length > 0) {
    //     for (let i = 0; i < this.form.messageSendContent.length; i++) {
    //       if (this.form.category === 'market') {
    //         if (this.form.messageSendContent[i].holiday && this.form.messageSendContent[i].holiday.length === 0) {
    //           this.$message({
    //             duration: 10000,
    //             message: '请完善当前信息'
    //           })
    //           return false
    //         }
    //       }
    //       if (this.form.messageSendContent[i].beforeDays === '') {
    //         this.$message({
    //           duration: 10000,
    //           message: '请完善当前信息'
    //         })
    //         return false
    //       }
    //       if (this.form.messageSendContent[i].templateBizId === '') {
    //         this.$message({
    //           duration: 10000,
    //           message: '请完善当前信息'
    //         })
    //         return false
    //       }
    //     }
    //   }
    //   if (this.form.messageSendContent.length >= 9) {
    //     this.$message({
    //       duration: 10000,
    //       message: '最多可添加10条'
    //     })
    //     return false
    //   }
    //   this.form.messageSendContent.push({
    //     key: Date.now(),
    //     beforeDays: '',
    //     holiday: [],
    //     templateBizId: ''
    //   })
    // },
    // 发送对象选择事件
    handleCheckAllChangeMessage(val) {
      if (val) {
        reminderOptions = ['1', '2']
        if (this.form.sendConditionCategory !== '2') {
          reminderOptions = ['1', '2', '3', '4']
        }
      } else {
        reminderOptions = []
      }
      this.form.messageReceivers = reminderOptions
      this.isIndeterminateMessage = false
    },
    // 发送对象选择事件
    checkboxGroupChangeMessage(value) {
      let nums = 2
      if (this.form.sendConditionCategory !== '2') {
        nums = 4
      }
      const checkedCount = value.length
      this.messageReceiversAll = checkedCount === nums
      this.isIndeterminateMessage = checkedCount > 0 && checkedCount < nums
    },
    // 性别选择事件
    handleCheckAllChangeGender(val) {
      this.form.gender = val ? genderOptions : []
      this.isIndeterminateGender = false
    },
    // 发送性别选择事件
    checkboxGroupChangeGender(value) {
      const checkedCount = value.length
      this.genderAll = checkedCount === 2
      this.isIndeterminateGender = checkedCount > 0 && checkedCount < 2
    },
    // 创建任务计划
    onSubmit() {
      // if (this.form.messageSendContent && this.form.messageSendContent.length > 0) {
      //   for (let i = 0; i < this.form.messageSendContent.length; i++) {
      //     if (this.form.category === 'market') {
      //       if (this.form.messageSendContent[i].holiday && this.form.messageSendContent[i].holiday.length === 0) {
      //         this.$message({
      //           duration: 10000,
      //           message: '请选择节假日'
      //         })
      //         return false
      //       }
      //     }
      //     if (this.form.messageSendContent[i].beforeDays === '') {
      //       this.$message({
      //         duration: 10000,
      //         message: '请填写距举办时间还剩多少天'
      //       })
      //       return false
      //     }
      //     if (this.form.messageSendContent[i].templateBizId === '') {
      //       this.$message({
      //         duration: 10000,
      //         message: '请选择发送模板类型'
      //       })
      //       return false
      //     }
      //   }
      // }
      this.$refs.form.validate(async flage => {
        if (flage) {
          const data = {
            ...this.form
          }
          if (data.messageReceivers && data.messageReceivers.length > 0) {
            data.messageReceivers = data.messageReceivers.join(';')
          }
          let type = 'add'
          if (this.form.bizId) {
            type = 'update'
          }
          const res = await this.postRequestShort(
            `admin/message/template/settings/${type}/command`,
            this.qs.stringify(data)
          )
          if (res.status !== 200) return false
          this.$message({
            duration: 10000,
            type: 'success',
            message: '创建成功'
          })
          if (this.$refs.form) {
            this.$refs.form.resetFields()
          }
          const menu = {
            path: '/shortmessage/mission-plan',
            name: '短信营销',
            bizId: '/shortmessage/mission-plan'
          }
          // 修改选中菜单
          this.$store.commit('selectMenu', menu)
          await this.$router.push(menu.path)
        }
      })
    },
    // 宴会类型选择事件
    banquetCategoryBizIdChange() {
      const d = this.categories.find(o => {
        return o.bizId === this.form.banquetCategoryBizId
      })
      if (d) {
        this.form.banquetCategoryName = d.label
      }
    },
    // 短信模版选择事件
    templateBizIdChange() {
      const d = this.templateBizIdData.find(o => {
        return o.bizId === this.form.templateBizId
      })
      if (d) {
        this.form.templateName = d.label
      }
    },
    // 查询短信模板数据
    async getQueryMessage() {
      // const data = {
      //   category: this.form.category
      // }
      // const res = await this.getRequestShort(
      //   'admin/message/template/pageable',
      //   data
      // )
      const res = {
        pageModel: {
          pageNumber: 0,
          pageSize: 15,
          totalRecords: 1,
          totalPages: 1,
          sortField: null,
          order: null,
          records: [
            {
              bizId: '1693825514860445696',
              name: '123'
            }
          ],
          queryModel: null,
          entityModel: null
        }
      }
      if (res.status !== 200) return false
      if (
        res.pageModel &&
        res.pageModel.records &&
        res.pageModel.records.length > 0
      ) {
        this.templateBizIdData = res.pageModel.records.map(o => {
          return {
            value: o.bizId,
            label: o.name,
            bizId: o.bizId
          }
        })
      }
    },
    // 查询数据
    async getQuerySetingMessage(data) {
      // const res = await this.getRequestShort(
      //   'admin/message/template/settings/load',
      //   data
      // )
      // if (res.status !== 200) return false
      const res = {
        pageModel: {
          pageNumber: 1,
          pageSize: 2147483647,
          totalRecords: 1,
          totalPages: 1,
          sortField: null,
          order: null,
          records: [
            {
              bizId: '1535511663401238528',
              name: '6.11营销活动测试',
              messageType: 'market',
              templateBizId: '1535509127583760384',
              startDate: '2022-06-11 14:39:52',
              description: null,
              messageReceivers: '1;2'
            }
          ],
          queryModel: null,
          entityModel: null
        }
      }
      if (res.pageModel.records && res.pageModel.records.length > 0) {
        const records = res.pageModel.records[0]
        this.form.bizId = records.bizId
        this.form.messageType = records.messageType
        this.form.sendCondition = records.sendCondition + ''
        this.form.sendConditionCategory = records.sendConditionCategory + ''
        this.form.templateBizId = records.templateBizId
        this.form.beforeDays = records.beforeDays
        this.form.beforeReservedDays = records.beforeReservedDays
        this.form.lastDays = records.lastDays
        this.form.reserveNumbers = records.reserveNumbers
        this.form.name = records.name || ''
        this.form.description = records.description || ''
        this.form.startDate = records.startDate || ''
        this.form.messageReceivers = records.messageReceivers
          ? records.messageReceivers.split(';')
          : []
      }
    }
  },
  mounted() {
    const accountType = JSON.parse(window.localStorage.getItem('accountType'))
    const type = []
    if (accountType) {
      if (accountType.banquet === 1) {
        type.push('banquet')
      }
      if (accountType.dinner === 1) {
        type.push('dinner')
      }
      if (accountType.meeting === 1) {
        type.push('meeting')
      }
    }
    this.type = type
    this.getQueryMessage()
    if (this.$store.state.missionPlan) {
      const row = this.$store.state.missionPlan
      const data = {
        bizId: row.bizId,
        // hotelBizId: this.$store.state.hotellist[0].bizId,
        category: row.category,
        messageType: row.messageType
      }
      this.getQuerySetingMessage(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-upload__tip {
  padding-left: 10px;
}
.type-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .form {
    width: 103%;
    height: 100%;
    overflow: auto;
    .demo-form-inline {
      width: 600px;
    }
    .form-item-box {
      display: flex;
      .checkbox-all {
        display: inline-block;
        float: left;
        margin-right: 20px;
      }
      .checkbox-group {
        display: flex;
        float: left;
      }
    }
    .sendList {
      display: flex;
      //flex-direction: column;
      width: 700px;
      flex-wrap: wrap;
      .sendBox {
        display: flex;
        align-items: center;
        width: 600px;
        margin: 0px 0;
        .input {
          width: 100px;
          margin: 0 10px;
        }
        .select {
          width: 150px;
          margin: 0 10px;
        }
      }
      .sendAdd {
        height: 30px;
        margin-top: 10px;
      }
    }
  }
}
</style>
