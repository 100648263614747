<template>
  <div class="areamanage-page">
    <div class="buttongroup">
      <el-button
        @click="centerDialogVisible = true"
        type="primary"
        icon="el-icon-plus"
        >添加</el-button
      >
      <el-button type="primary" icon="el-icon-close">删除</el-button>
    </div>
    <el-table
      :data="[
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄',
        },
      ]"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      ref="multipleTable"
      border
    >
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column align="center" prop="date" label="编码">
      </el-table-column>
      <el-table-column align="center" prop="name" label="名称">
      </el-table-column>
      <el-table-column align="center" prop="address" label="状态">
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          ></el-button>
          <el-button
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加角色 -->
    <el-dialog
      :close-on-click-modal="false"
      class="updatecode"
      title="添加角色"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form label-width="80px" :model="formLabelAlign">
        <el-form-item label="机构名称">
          <el-input disabled v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="角色名称">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" v-model="formLabelAlign.phone"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用">
          <el-switch v-model="formLabelAlign.delivery"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >增 加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AreaManage",
  data() {
    return {
      form: {
        id: null,
        type: "",
        name: "",
        code: "",
        area: "",
        system: "",
      },
      centerDialogVisible: false,
      formLabelAlign: {
        name: "",
        phone: "",
        code: "",
        password: "",
        wxh: "",
      },
    };
  },
  methods: {
    //
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.areamanage-page {
  padding: 10px;
  box-sizing: border-box;
  .buttongroup {
    margin-bottom: 10px;
  }
}
</style>
