<template>
  <div class="organizationmanage-page">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>菜单管理</span>
      </div>
      <div class="card">
        <div class="leftcard">
          <el-button @click="append(data, 1)" type="primary" icon="el-icon-plus">机构</el-button>
          <!-- <el-tree draggable ref="tree" :data="data" check-strictly icon-class="form" show-checkbox node-key="id" default-expand-all :props="defaultProps" :expand-on-click-node="false" :render-content="renderContent" @check-change="threeCheck" @node-click="handleNodeClick">
          </el-tree> -->
        </div>
        <div class="rightcard">
          <el-card>
            <div slot="header" class="clearfix">
              <span>{{ form.label || "机构编辑" }}</span>
            </div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="机构名称">
                <el-input v-model="form.label"></el-input>
              </el-form-item>
              <el-form-item label="机构编码">
                <el-input v-model="form.code"></el-input>
              </el-form-item>
              <el-form-item label="排 序 码">
                <el-input v-model="form.sortcode"></el-input>
              </el-form-item>
              <el-form-item label="机构描述">
                <el-input type="textarea" v-model="form.description"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="onMenuUpdate" type="primary">保存</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'OrganizationManage',
  data() {
    return {
      data: [],
      form: {
        sortcode: null,
        name: ''
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isSettingvalue: '', // 当前要设置的值
      multipleSelection: []
    }
  },
  methods: {
    async append(data, value = 0) {
      const command = {
        parentBizId: '',
        code: '',
        name: '机构',
        tabIndex: '',
        description: ''
      }
      if (value === 0) {
        command.parentBizId = data.bizId
      } else {
        command.parentBizId = null
      }
      const res = await this.postRequest(
        'ogz/org/add/command',
        this.qs.stringify(command)
      )
      if (res.status !== 200) {
        return false
      }
      this.getOrgData()
    },
    edit(data) {
      this.$refs.tree.setCheckedNodes([data])
      this.form = data
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(response => {
          const parent = node.parent
          const children = parent.data.children || parent.data
          // console.log('children', children, parent, data)
          const datas = {
            bizId: data.bizId
          }
          this.postRequest(
            'ogz/org/delete/command',
            this.qs.stringify(datas)
          ).then(res => {
            if (res.status !== 200) {
              return false
            }
            this.$message({
              duration: 10000,
              type: 'success',
              message: '删除成功!'
            })
            this.getOrgData()
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // renderContent(h, { node, data, store }) {
    //   return (
    //     <span class="custom-tree-node">
    //       <span class="node-li">{node.label}</span>
    //       <span>
    //         <el-button
    //           size="mini"
    //           type="text"
    //           on-click={() => this.append(data)}
    //         >
    //           <i class="el-icon-plus"></i>
    //         </el-button>
    //         <el-button size="mini" type="text" on-click={() => this.edit(data)}>
    //           <i class="el-icon-edit"></i>
    //         </el-button>
    //         <el-button
    //           size="mini"
    //           type="text"
    //           on-click={() => this.remove(node, data)}
    //         >
    //           <i class="el-icon-delete"></i>
    //         </el-button>
    //       </span>
    //     </span>
    //   )
    // },
    // 树单选
    threeCheck(data, checked, node) {
      if (checked) {
        this.form = data
        this.$refs.tree.setCheckedNodes([data])
      }
    },
    // 设置里表格多选框改变事件
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleNodeClick(data) {
      // console.log(data)
    },
    // 打开设置窗口
    async onOpenSetting(value) {
      this.isSettingvalue = value
      this.dialogVisible = true
    },
    // 刷新菜单
    async getOrgData() {
      const data = {
        id: ''
      }
      const res = await this.getRequest('ogz/org/tree', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.data = records
    },
    // 修改菜单
    async onMenuUpdate() {
      this.form.name = this.form.label
      const res = await this.postRequest(
        'ogz/org/update/command',
        this.qs.stringify(this.form)
      )
      if (res.status !== 200) {
        return false
      }
      this.$message({
        duration: 10000,
        type: 'success',
        message: '修改成功!'
      })
      this.getOrgData()
    }
  },
  mounted() {
    this.getOrgData()
  }
}
</script>

<style lang="sass">
.organizationmanage-page
.box-card
    height: 100%
    .clearfix
        font-size: 16px
    .custom-tree-node
        flex: 1
        display: flex
        align-items: center
        justify-content: space-between
        font-size: 14px
        padding-right: 20px
    .node-li
        display: inline-block
        margin-right: 20px
    .card
        flex: 1
        display: flex
        justify-content: space-between
        height: 100%
    .leftcard
        width: 20%
    .rightcard
        flex: 1
        height: auto
.pagination
    margin-top: 20px
    margin-bottom: -20px
    display: flex
    justify-content: flex-end
    .elpagination
        padding: 0
        margin-right: -5px
.search
    display: flex
    justify-content: flex-start
    margin-bottom: 10px
    margin-top: -30px
    .search-input
        width: 30%
        margin-right: 10px
</style>
