<template>
  <div class="barrage-activity-page">
    <div class="buttongroup">
      <el-button @click="addClick" type="primary" icon="el-icon-plus">添加</el-button>
      <!--      <el-button @click="bigScreenPreview" type="primary">大屏幕预览</el-button>-->
    </div>
    <el-table :data="tableData" height="85%" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="name" label="活动名称">
      </el-table-column>
      <el-table-column align="center" prop="" label="收集客资">
      </el-table-column>
      <el-table-column align="center" prop="createdAt" label="创建时间">
      </el-table-column>
      <el-table-column align="center" prop="createdBy" label="创建人">
      </el-table-column>
      <el-table-column align="center" prop="hallName" label="所属厅">
      </el-table-column>
      <el-table-column align="center" prop="categoryName" label="宴会类型">
      </el-table-column>
      <el-table-column width="100px" align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.publishStatus === 0">未发布</span>
          <span v-if="scope.row.publishStatus === 1">已发布</span>
          <span v-if="scope.row.publishStatus === 2">已结束</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="bigScreenPreview(scope.row)" icon="el-icon-view" type="text" size="small"></el-button>
          <el-button @click="handleClick(scope.row)" icon="el-icon-edit" type="text" size="small"></el-button>
          <el-button @click="postDeleteData(scope.row)" type="text" icon="el-icon-delete" size="small"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
const pageSize = 10 // 每页的数量
const pageNumber = 1 // 当前页
export default {
  name: 'BarrageActivity',
  data() {
    return {
      tableData: [{}],
      pageNumber: pageNumber,
      pageSize: pageSize,
      total: 0 // 总条数,
    }
  },
  methods: {
    // 大屏幕预览
    bigScreenPreview(row) {
      window.sessionStorage.setItem('BigScreenPreview', JSON.stringify(row))
      setTimeout(() => {
        const routeData = this.$router.resolve({ path: '/screen/big-screen' })
        window.open(routeData.href, '_blank')
      }, 100)
    },
    addClick(row) {
      this.$store.commit('setActivitylist', null)
      this.updateClick()
    },
    handleClick(row) {
      this.$store.commit('setActivitylist', row)
      this.updateClick()
    },
    updateClick(type) {
      const menu = {
        path: '/screen/create-barrage',
        name: '创建活动',
        bizId: '/screen/create-barrage'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', menu)
      this.$router.push(menu.path)
      // this.$router.push(menu.path + `?type=${type}`)
    },
    // 查询数据
    async getQueryData() {
      const data = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      }
      const res = await this.getRequestShort('screen/pageable', data)
      if (res.status !== 200) return false
      res.pageModel.records.forEach(o => {
        o.status = o.status === 1 ? (o.status = true) : (o.status = false)
      })
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords
    },
    // 删除数据
    postDeleteData(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: row.bizId
          }
          const res = await this.postRequestShort(
            'screen/delete/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '删除成功!'
          })
          this.getQueryData()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页事件
    onCurrentChange(val) {
      this.pageNumber = val
      this.getQueryData()
    }
  },
  mounted() {
    this.getQueryData()
  }
}
</script>

<style lang="scss" scoped>
.barrage-activity-page {
  padding: 20px;
  box-sizing: border-box;
  .buttongroup {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
