<template>
  <div class="login-page">
    <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
      <h3 class="loginTitle">鹿小乙后台管理系统</h3>
      <el-form-item prop="loginUsername">
        <el-input v-model="loginForm.loginUsername" type="text" placeholder="请输入用户名" />
      </el-form-item>
      <el-form-item prop="phoneNumber">
        <el-input v-model="loginForm.phoneNumber" type="text" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item prop="verificationCode">
        <div class="bind_code margin_top">
          <el-input class="bind_code_input code" v-model="loginForm.verificationCode" type="text" placeholder="请输入验证码" />
          <el-button @click.native.prevent="bindforgetSendCode" class="bind_code_gain" :disabled="disabled" size="mini">{{ btnText }}</el-button>
        </div>
      </el-form-item>
      <!--      <el-checkbox v-model="checked" style="margin-bottom: 10px;text-align: left">记住密码</el-checkbox>-->
      <el-button type="primary" style="width: 100%; margin-top: 20px;" @click="fnLogin">登录</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      captchaUrl: '/captcha?time=' + new Date(),
      type: '',
      btnText: '发送验证码',
      disabled: false,
      checked: true,
      loginForm: {
        phoneNumber: '', // 手机号
        loginUsername: '', // 登录用户名
        verificationCode: '1234' // 验证码
      },
      rules: {
        loginUsername: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    fnLogin() {
      this.$refs.loginForm.validate(async data => {
        if (data) {
          const res = await this.postRequest(
            'open/login/phone/validate',
            this.qs.stringify(this.loginForm)
          )
          if (res.status !== 200) {
            this.$message.error(res.message)
            return false
          }
          if (res.body) {
            // 存储bizId
            window.localStorage.setItem('bizId', res.body.bizId)
            // 存储token
            window.localStorage.setItem('token', res.body.token)
            // 存储sessionId
            window.localStorage.setItem('sessionId', res.body.sessionId)
            // 存储applicationName
            window.localStorage.setItem(
              'applicationName',
              res.body.applicationName
            )
            // 存储酒店信息
            if (res.body.orgs) {
              window.localStorage.setItem('orgs', JSON.stringify(res.body.orgs))
              this.$store.commit('setHotelList', res.body.orgs)
            }
            const info = {
              name: res.body.userName || '管理员',
              loginUsername: res.body.loginUsername || 'admin',
              phoneNumber: res.body.phoneNumber || ''
            }
            window.localStorage.setItem('info', JSON.stringify(info))
            // 跳转首页
            const path = this.$route.query.redirect
            this.$router.replace(
              path === '/' || path === undefined ? '/home' : path
            )
          }
        } else {
          this.$message.error('请输入所有信息!')
          return false
        }
      })
    },
    // 发送验证码
    bindforgetSendCode() {
      // 手机号 为空的话
      this.$refs.loginForm.validateField('phoneNumber', async errorMessage => {
        if (!errorMessage) {
          const data = {
            phoneNumber: this.loginForm.phoneNumber
          }
          const res = await this.postRequest(
            'open/login/phone/verificationCode',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            return false
          }
          this.$message('验证码已发送，请注意查收')
          this.disabled = true
          this.btnText = '请稍候...'
          setTimeout(() => {
            this.doLoop(180)
          }, 500)
        } else {
          return false
        }
      })
    },
    // 手机验证码的倒计时
    doLoop: function(seconds) {
      seconds = seconds || 180
      this.btnText = '倒计时' + seconds + '秒'
      const countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = '倒计时' + seconds + '秒'
          --seconds
        } else {
          this.btnText = '获取验证码'
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },
    fnUpdateCaptcha() {
      this.captchaUrl = '/captcha?time=' + new Date()
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/bg-login.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-top: 1px solid transparent;
  box-sizing: border-box;
  .loginContainer {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 200px auto;
    width: 350px;
    padding: 30px 35px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    .loginTitle {
      margin: 0 auto 40px auto;
      text-align: center;
    }
    .el-input__inner {
      height: 44px !important;
      background: #f7f7f7;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      outline: none;
      padding: 0 100px 0 10px;
    }
  }
  .bind_code {
    position: relative;
    .bind_code_gain {
      position: absolute;
      right: 2px;
      box-shadow: 1px 1px 4px #ccc;
      border: 0;
      font-size: 13px;
      color: #20aee5;
      cursor: pointer;
      top: 2px;
      padding-left: 10px;
      //border-left: 1px solid #d8d8d8;
    }
  }
  .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
