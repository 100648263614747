import { render, staticRenderFns } from "./MessageRechargeList.vue?vue&type=template&id=44284478&scoped=true&"
import script from "./MessageRechargeList.vue?vue&type=script&lang=js&"
export * from "./MessageRechargeList.vue?vue&type=script&lang=js&"
import style0 from "./MessageRechargeList.vue?vue&type=style&index=0&id=44284478&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44284478",
  null
  
)

export default component.exports