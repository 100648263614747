  <!-- 4. 收费服务 -->
<template>
  <el-card class="type-page">
    <div class="buttongroup">
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" height="66vh" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <!-- <el-table-column align="center" prop="createdAt" label="创建日期">
      </el-table-column> -->
      <el-table-column align="center" prop="name" label="服务名称">
      </el-table-column>
      <el-table-column align="center" prop="price" label="金额">
      </el-table-column>
      <el-table-column align="center" prop="tabIndex" label="排序">
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          {{scope.row.state==1?'启用':'禁用'}}
        </template>
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="postEditData(scope.row)"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加收费服务 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" :title="titleName + '收费服务'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form ref="form" :rules="rules" label-width="100px" :model="form">
        <el-form-item prop="name" label="服务名称：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color: red">示例：设备费、礼仪费、车队费等用餐外的其他收费项目</span>
        </el-form-item>
        <el-form-item label="价格(元)：">
          <el-input type="number" v-model="form.price"></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color: red">注：请输入单个服务价格，预定时可选数量，此价格参与订单结算，预定时可自由修改</span>
        </el-form-item>
        <el-form-item label="排序：" prop="tabIndex">
          <el-input-number style="width:250px" v-model="form.tabIndex" :min="1" :max="9999" :step="1" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item label="是否启用：" prop="state">
          <el-select style="width:250px" v-model="form.state" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">{{ titleName }}</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>


<script>
import rules from '@/utils/rules'
export default {
  name: 'ProviderService',
  data() {
    return {
      rules,
      titleName: '添加',
      form: {
        dataCode: 'providerService',
        code: '',
        name: '',
        state: 1,
        hotelId: '',
        tabIndex: '',
        className: '收费服务',
        price: '',
        applicationName: ''
      },
      tableData: [],
      centerDialogVisible: false,
      search: {
        pageSize: 10,
        pageNumber: 1,
        dataCode: 'providerService',
        applicationName: ''
      },
      org: '',
      options: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '禁用'
        }
      ]
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    this.search.applicationName = this.org.applicationName
    this.getQueryData()
  },
  methods: {
    // 查询数据
    getQueryData() {
      this.getRequest('data-metamodel/getPage', this.search).then(res => {
        this.tableData = []
        this.total = 0
        if (res.code == 200) {
          this.total = res.data.total
          this.tableData = res.data.records
        }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    // 打开添加对话框
    onAddClick() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.titleName = '添加'
      this.form = {
        dataCode: 'providerService',
        code: '',
        name: '',
        state: 1,
        hotelId: '',
        tabIndex: '',
        className: '收费服务',
        price: '',
        applicationName: ''
      }
      this.centerDialogVisible = true
    },
    // 打开修改
    postEditData(row) {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.getRequest('data-metamodel/getById/' + row.id, { id: row.id }).then(
        res => {
          // console.log('查询酒店结果：' + JSON.stringify(res))
          if (res.code == 200) {
            this.titleName = '修改'
            this.form = res.data
            this.centerDialogVisible = true
          }
        }
      )
    },
    // 提交填写、修改
    onSetData() {
      this.form.hotelId = this.org.id
      this.form.applicationName = this.org.applicationName
      this.$refs.form.validate(async flage => {
        if (flage) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          if (this.form.price != '') {
            const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
            if (reg.test(this.form.price) == false) {
              this.$message('请输入正确的金额!')
              return
            }
          }

          if (this.titleName === '添加') {
            this.post(`data-metamodel/add`, this.form)
              .then(res => {
                loading.close()
                if (res.code == 200) {
                  this.centerDialogVisible = false
                  this.getQueryData()
                  this.$message.success({
                    duration: 2000,
                    message: '添加成功!'
                  })
                } else {
                  // message
                  this.$notify({
                    title: '提示',
                    message: res.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })
              .catch(() => {
                loading.close()
              })
          } else {
            this.post(`data-metamodel/edit`, this.form)
              .then(res => {
                loading.close()
                if (res.code == 200) {
                  this.getQueryData()
                  this.centerDialogVisible = false
                  this.$message.success({
                    duration: 2000,
                    message: '修改成功!'
                  })
                } else {
                  // message
                  this.$notify({
                    title: '提示',
                    message: res.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        }
      })
    },
    // 删除数据
    async postDeleteData(row) {
      this.$confirm('请确认是否要删除当前标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('data-metamodel/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getQueryData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
