<template>
  <div class="reserve-page">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form" v-loading="loading">
      <div class="form-box">
        <el-form-item label="客户姓名" prop="customerName">
          <el-input style="width: 150px" v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label-width="20px" prop="genderSelectedBizId">
          <el-radio-group v-model="form.genderSelectedBizId">
            <el-radio v-for="item in genders" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系方式" prop="customerPhoneNumber">
          <el-input style="width: 150px" v-model="form.customerPhoneNumber"></el-input>
        </el-form-item>
      </div>
      <div class="form-box">
        <!-- <el-form-item label="宴会大厅" prop="hallBizId">
          <el-select style="width: 150px" v-model="form.hallBizId" placeholder="宴会厅" @change="onBallroomChange">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="宴会类型" prop="categorySelectedBizId">
          <el-select style="width: 150px" v-model="form.categorySelectedBizId" @change="onBallroomTypeChange" placeholder="类型">
            <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="宴会日期" prop="reserveDate">
          <el-date-picker style="width: 150px" :picker-options="pickerOptions" v-model="form.reserveDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label-width="20px" prop="startTimeQuantumSelectedBizId">
          <el-radio-group v-model="form.startTimeQuantumSelectedBizId">
            <el-radio v-for="item in startTimeQuantums" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box" v-if="bindAttributes">
        <div style="display: flex" v-for="(item, index) in bindAttributes" :key="index">
          <el-form-item :label="item.bindAttributeName1">
            <el-input style="width: 150px" v-model="item.bindAttributeValue1"></el-input>
          </el-form-item>
          <el-form-item :label="item.bindAttributeName2">
            <el-input style="width: 150px" v-model="item.bindAttributeValue2"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="预定桌数" prop="reserveTableNumber">
          <el-input style="width: 150px" v-model="form.reserveTableNumber"></el-input>
        </el-form-item>
        <el-form-item label="浮动桌数">
          <el-input style="width: 150px" v-model="form.backupTableNumber"></el-input>
        </el-form-item>
        <el-form-item v-if="form.categorySelectedBizId" label="预定餐标">
          <!--filterable-->
          <el-select allow-create filterable default-first-option style="width: 150px" v-model="form.mealStandardSelectedBizId" placeholder="餐标">
            <el-option v-for="item in mealStandards" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户经理" prop="customerManagerSelectedBizId">
          <el-select style="width: 150px" v-model="form.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId" :closable="false"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="收费服务">
          <el-button @click="appreciationServeVisible = true" type="text">添加</el-button>
        </el-form-item>
        <!--        <el-form-item label="关联雅间">-->
        <!--          <el-button @click="elegantRoomVisible = true" type="text">关联</el-button>-->
        <!--        </el-form-item>-->
      </div>
      <div class="form-box form-box-none form-box-margintop" v-if="payMode.length > 0">
        <div class="payway" v-for="(i, index) in form.paymentJson" :key="index">
          <el-form-item label-width="10px" prop="region">
            <el-radio-group style="width: 150px" @change="onPayChange" v-model="i.verifyMode">
              <el-radio v-for="item in payments" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="payway-item">
            <template v-if="i.verifyMode === 'deposits'">
              <el-form-item label="定金金额" prop="region">
                <el-input type="number" style="width: 100px" v-model="i.amount"></el-input>
                元
              </el-form-item>
              <el-form-item label="支付方式" prop="region">
                <el-select style="width: 100px" v-model="i.payModeSelectedBizId" placeholder="支付方式">
                  <el-option v-for="item in payMode" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付项目" prop="region">
                <el-select style="width: 100px" v-model="i.payCategorySelectedBizId" placeholder="服务类型">
                  <el-option v-for="item in payCategories" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="收据编号" prop="region">
                <el-input v-model="i.receiptCode" placeholder="收据编号"></el-input>
              </el-form-item>
              <el-form-item v-show="!i.bizId" label-width="20px">
                <i @click="removePaymentJson(index)" class="el-icon-circle-close"></i>
              </el-form-item>
            </template>
            <template v-if="i.verifyMode === 'guarantors'">
              <el-form-item label="担保金额" prop="region">
                <el-input style="width: 100px" v-model="i.amount"></el-input>
              </el-form-item>
              <el-form-item label="担保类型" prop="region">
                <el-select style="width: 100px" v-model="i.guarantorCategorySelectedBizId" placeholder="担保类型">
                  <el-option v-for="item in guarantorCategory" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="担保人" prop="region">
                <el-select style="width: 100px" v-model="i.customerGuarantorSelectedBizId" placeholder="担保人">
                  <el-option v-for="item in customerGuarantor" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="!i.bizId" label-width="20px">
                <i @click="removePaymentJson(index)" class="el-icon-circle-close"></i>
              </el-form-item>
            </template>
          </div>
        </div>
        <el-button @click="onAddconfirm" class="addpayway" type="text" size="mini">+</el-button>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="客户来源">
          <el-radio-group style="width: 80vw" v-model="form.customerSourceSelectedBizId">
            <el-radio style="margin: 5px 10px" v-for="item in customerSources" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-box form-box-margintop form-box-select">
        <el-form-item label="占用宴会厅">
          <el-select multiple style="min-width: 300px" v-model="form.bandRoom" placeholder="宴会厅" @change="onBallRoomTagChange">
            <el-option v-for="item in hallsTag" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="关联雅间">
          <el-select multiple style="min-width: 300px" v-model="form.balls" placeholder="雅间名称">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item">
            </el-option>
          </el-select>
        </el-form-item> -->
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="详情备注">
          <el-input type="textarea" style="width: 800px" :rows="2" v-model="form.description"></el-input>
        </el-form-item>
      </div>
      <div class="form-box form-box-margintop">
        <el-form-item label="上传合同">
          <el-upload
            :multiple="true"
            name="file"
            :data="uploadData"
            :file-list="fileList"
            :headers="uploadHeaders"
            :action="action + 'sys/upload/uploadFile2HotelFolder'"
            list-type="picture-card"
            :on-success="handleSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i style="font-size: 20px" class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </div>
      <br />
      <div class="form-box-margintop" style="width: 100%; margin-bottom: 20px">
        <el-form-item style="text-align: center">
          <!-- 保存本意是更新 -->
          <el-button type="primary" v-if="(level === 1 && !isReserve) || level === 2" @click="submitForm_edit('form')">保存</el-button>
          <el-button type="primary" v-if="level === 0 && !isReserve" @click="submitForm('form', 0)">预留</el-button>
          <el-button type="primary" v-if="level === 0 && isReserve" @click="submitForm('form', 0)">预定</el-button>
          <el-button type="primary" v-if="level === 0 && isReserve" @click="submitForm('form', 1)">预定并短信</el-button>
          <el-button type="primary" v-if="level === 1 && isReserve" @click="submitForm_edit('form', 0)">转预订</el-button>
          <el-button type="primary" v-if="level === 1 && isReserve" @click="submitForm_edit('form', 1)">转预定并短信</el-button>
          <el-button type="primary" v-if="level === 0" @click="resetForm">重置</el-button>
        </el-form-item>
      </div>
      <br />
      <br />
    </el-form>
    <!--弹窗选择增值服务-->
    <el-dialog :close-on-click-modal="false" title="添加增值服务" :visible.sync="appreciationServeVisible">
      <el-table :data="providerServices" :summary-method="getSummaries" show-summary style="width: 100%">
        q
        <el-table-column prop="name" label="服务类型" align="center" width="180"></el-table-column>
        <el-table-column prop="price" label="单价" align="center" width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" placeholder="请输入单价"></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center" label="数量">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.num" size="mini" :min="0" :max="10"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="amount" align="center" label="总价">
          <template slot-scope="scope">
            {{ (scope.row.amount = scope.row.price * scope.row.num) }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appreciationServeVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAppreciationServeClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗选择关联雅间-->
    <el-dialog :close-on-click-modal="false" title="关联雅间" :visible.sync="elegantRoomVisible">
      <p>关联雅间</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="elegantRoomVisible = false">取 消</el-button>
        <el-button type="primary" @click="onElegantRoomClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import { baseUrl, imageUrl } from '../../utils/api'

let records = {} // 酒店信息
let token = ''
let oldForm = null
if (window.localStorage.getItem('token')) {
  token = window.localStorage.getItem('token').toString()
}
let org = {}
if (window.localStorage.getItem('orgs')) {
  org = JSON.parse(window.localStorage.getItem('orgs'))[0]
}
export default {
  name: 'Reserve',
  data() {
    return {
      level: 0,
      isReserve: 0,
      page_state: '',
      hall_names: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      },
      action: baseUrl,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadHeaders: {
        'Access-Control-Allow-Origin': '*',
        accessToken: 'Bearer ' + token
      },
      uploadData: {
        num: 1,
        hotelId: org.id
      },
      fileList: [],
      lock: '',
      hallsTag: [],
      form: {
        // 表单提交数据
        bizId: null,
        backupTableNumber: null, // 预备桌数
        hotelBizId: null, // 酒店的bizId
        categorySelectedBizId: '', // 选择的宴会类型的bizId
        dateFormat: '1', // 时间格式，时间还是时间段  1:时间  2: 时间段
        paymentJson: [
          {
            verifyMode: null
          }
        ], // 确认方式
        description: '', // 备注
        genderSelectedBizId: null, // 选择的性别的bizId
        mealStandardSelectedBizId: null, // 选择的餐标的bizId
        customerSourceSelectedBizId: null, // 选择的客户来源的bizId
        hallBizId: null, // 宴会厅的bizId
        providerServiceJson: [], // 增值服务
        customerName: null, // 客户姓名
        reserveTableNumber: null, // 预定桌数
        customerManagerSelectedBizId: null, // 选择的客户经理的bizId
        bindAttributes: '', // 宴会类型所绑定的属性
        startTimeQuantumSelectedBizId: null, // 选择的是上午还是晚上的 bizId
        customerPhoneNumber: null, // 客户电话
        reserveDate: null, // 预订日期的开始时间
        bandRoom: []
      },
      oldHall: [],
      rules: {
        // 验证规则
        hallBizId: [
          {
            required: true,
            message: '请选择宴会厅',
            trigger: 'blur'
          }
        ],
        categorySelectedBizId: [
          {
            required: true,
            message: '请选择宴会类型',
            trigger: 'change'
          }
        ],
        reserveDate: [
          {
            required: true,
            message: '请选择宴会日期',
            trigger: 'change'
          }
        ],
        startTimeQuantumSelectedBizId: [
          {
            required: true,
            message: '请选择宴会时间',
            trigger: 'change'
          }
        ],
        customerName: [
          {
            required: true,
            message: '请填写客户姓名',
            trigger: 'blur'
          }
        ],
        genderSelectedBizId: [
          {
            required: true,
            message: '请选择客户性别',
            trigger: 'blur'
          }
        ],
        customerPhoneNumber: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '长度11个字符'
          }
        ],
        reserveTableNumber: [
          {
            required: true,
            message: '请填写预定桌数',
            trigger: 'blur'
          }
        ],
        mealStandardSelectedBizId: [
          {
            required: true,
            message: '请选择餐标',
            trigger: 'change'
          }
        ],
        customerManagerSelectedBizId: [
          {
            required: true,
            message: '请选择客户经理',
            trigger: 'blur'
          }
        ]
      },
      appreciationServeVisible: false, // 增值服务弹窗是否可见
      elegantRoomVisible: false, // 关联雅间弹窗是否可见
      customerManagers: [], // 客户经理
      customerSources: [], // 客户来源
      providerServices: [], // 增值服务
      genders: [
        {
          label: '先生',
          value: 1
        },
        {
          label: '女士',
          value: 2
        }
      ], // 客户性别
      categories: [], // 婚宴类型
      bindAttributes: [], // 婚宴类型人员数据
      mealStandards: [], // 餐标
      startTimeQuantums: [
        {
          label: '中午',
          value: 'AM'
        },
        {
          label: '晚上',
          value: 'PM'
        }
      ], // 宴会日期或会议日期开始时间
      endTimeQuantums: [], // 会议日期结束时间
      paymentJson: [], // 确认方式列表
      payments: [], // 确认方式
      payMode: [], // 支付方式
      payCategories: [], // 支付类型
      guarantorCategory: [], // 担保类型
      customerGuarantor: [], // 担保人
      referBallRoomList: [],
      ballroomList: [],
      hall: [],
      refeData: [],
      status: 1,
      loading: false,
      halls: [],
      refeType: false,
      local_hall: [],
      pagemodel: {}
    }
  },
  computed: {},
  methods: {
    // 房间号的合计去掉
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
        } else if (index === 3) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = 'N/A'
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 餐标改变事件
    async mealStandardChange(e) {
      for (let i = 0; i < this.mealStandards.length; i++) {
        if (this.mealStandards[i].bizId === e) return false
      }
      const data = {
        hotelBizId: this.form.hotelBizId,
        categoryBizId: this.form.categorySelectedBizId,
        bizId: this.form.hallBizId,
        price: e
      }
      const res = await this.postRequest('admin/hall/mealStandard/add', this.qs.stringify(data))
      if (res.status !== 200) {
        this.$message.error(res.message)
        return false
      }
      //   console.log('res', res)
    },
    // 删除确认方式
    removePaymentJson(index) {
      console.log(index)
      if (index === 0) {
        this.form.paymentJson[index].verifyMode = null
        this.isReserve = 0
      } else {
        this.form.paymentJson.splice(index, 1)
      }
    },
    // 增值服务确认事件
    onAppreciationServeClick() {
      this.appreciationServeVisible = false
      const providerServices = this.providerServices.map(o => {
        return {
          price: o.price,
          num: o.num,
          bizId: o.id
        }
      })
      this.form.providerServiceJson = providerServices.filter(val => val.num > 0)
    },
    // 关联雅间确认事件
    onElegantRoomClick() {
      this.elegantRoomVisible = true
    },
    // 确认提交
    submitForm(form, v) {
      const index = this.form.paymentJson.length - 1
      const paymentJson = this.form.paymentJson[index]
      switch (paymentJson.verifyMode) {
        case 1:
          if (!paymentJson.amount || !paymentJson.payCategorySelectedBizId || !paymentJson.payModeSelectedBizId) {
            this.$message('请完善预收款信息')
            return false
          }
          break
        case 2:
          if (!paymentJson.amount || !paymentJson.customerGuarantorSelectedBizId || !paymentJson.guarantorCategorySelectedBizId) {
            this.$message('请完善担保人信息')
            return false
          }
          break
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          this.loading = true
          // 数据提交
          const data = {
            ...this.form,
            hotelBizId: org.id,
            sendMessage: v
          }
          if (this.bindAttributes && this.bindAttributes.length > 0) {
            this.bindAttributes.forEach(i => {
              var val1 = ''
              var val2 = ''
              if (i.bindAttributeValue1 != undefined && i.bindAttributeValue1 != null) {
                val1 = i.bindAttributeValue1
              }
              if (i.bindAttributeValue2 != undefined && i.bindAttributeValue2 != null) {
                val2 = i.bindAttributeValue2
              }
              data.bindAttributes += `${i.id},${val1},${val2};`
            })
          }
          if (data.attachmentJson) {
            const atta = data.attachmentJson.map(r => {
              return r.id
            })
            data.contract = atta.join(',')
            data.attachmentJson = ''
          }

          this.post('banquet/reserved', data).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                message: '预定成功,自动跳转到销控表',
                type: 'success'
              })
              setTimeout(e => {
                const submenu = {
                  path: '/banquet/salescontrol-table',
                  name: '宴会预定',
                  bizId: '/banquet/salescontrol-table'
                }
                // 加入keepalive缓存
                this.$store.commit('addKeepAliveCache', '宴会销控表')
                // 修改选中菜单
                this.$store.commit('selectMenu', submenu)
                this.$router.push('/banquet/salescontrol-table')
              }, 300)
            } else {
              this.$message.error('预定失败!' + res.msg)
            }
          })
        } else {
          this.$message('信息填写完整后，可预定!')
          return false
        }
      })
    },
    submitForm_edit(form, v) {
      const index = this.form.paymentJson.length - 1
      const paymentJson = this.form.paymentJson[index]
      switch (paymentJson.verifyMode) {
        case 1:
          if (!paymentJson.amount || !paymentJson.payCategorySelectedBizId || !paymentJson.payModeSelectedBizId) {
            this.$message('请完善预收款信息')
            return false
          }
          break
        case 2:
          if (!paymentJson.amount || !paymentJson.customerGuarantorSelectedBizId || !paymentJson.guarantorCategorySelectedBizId) {
            this.$message('请完善担保人信息')
            return false
          }
          break
      }
      if (this.level == 2 && this.form.paymentJson[index].verifyMode == null) {
        this.$message('预收款信息或担保人信息不能为空')
        return false
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          this.loading = true
          // 数据提交
          const data = {
            ...this.form,
            hotelBizId: org.id,
            sendMessage: v
          }
          if (this.bindAttributes && this.bindAttributes.length > 0) {
            this.bindAttributes.forEach(i => {
              var val1 = ''
              var val2 = ''
              if (i.bindAttributeValue1 != undefined && i.bindAttributeValue1 != null) {
                val1 = i.bindAttributeValue1
              }
              if (i.bindAttributeValue2 != undefined && i.bindAttributeValue2 != null) {
                val2 = i.bindAttributeValue2
              }
              data.bindAttributes += `${i.id},${val1},${val2};`
            })
          }
          if (data.attachmentJson) {
            const atta = data.attachmentJson.map(r => {
              return r.id
            })
            data.contract = atta.join(',')
            data.attachmentJson = ''
          }

          this.post('banquet/editReserved', data).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                message: '保存成功,自动跳转到销控表',
                type: 'success'
              })
              setTimeout(e => {
                const submenu = {
                  path: '/banquet/salescontrol-table',
                  name: '宴会预定',
                  bizId: '/banquet/salescontrol-table'
                }
                // 加入keepalive缓存
                this.$store.commit('addKeepAliveCache', '宴会销控表')
                // 修改选中菜单
                this.$store.commit('selectMenu', submenu)
                this.$router.push('/banquet/salescontrol-table')
              }, 300)
            } else {
              this.$message.error('预定失败!' + res.msg)
            }
          })
        } else {
          this.$message('信息填写完整后，可预定!')
          return false
        }
      })
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      if (!this.form.attachmentJson) {
        this.form.attachmentJson = []
      }
      if (res.code !== 200) {
        this.$message.error('合同上传失败!')
        let index = 0
        for (const i in fileList) {
          if (fileList[i] == file) {
            index = i
            break
          }
        }
        fileList.splice(index, 1)
        // this.$refs.uploadFile.clearFiles();
        return false
      }
      this.$message.success('合同上传成功!')
      const data = {
        id: res.data.id,
        suffix: res.data.folderId,
        name: res.data.name,
        previewUrl: res.data.url
      }
      this.form.attachmentJson.push(data)
    },
    handleRemove(file, fileList) {
      const attachments = []
      const fl = fileList.map(r => {
        return {
          id: r.response.data.id,
          suffix: r.response.data.folderId,
          name: r.response.data.name,
          previewUrl: r.response.data.url
        }
      })
      this.form.attachmentJson = fl
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    // 获取宴会厅客户经理
    async getCustomerManager() {
      const res = await this.getRequest('sys/user/loadCostumerManager', {})
      if (res.code !== 200) {
        return false
      }
      this.customerManagers = res.data.map(o => {
        if (o.id === window.localStorage.getItem('bizId')) {
          this.form.customerManagerSelectedBizId = window.localStorage.getItem('bizId')
        }
        return { value: o.id, label: o.name, bizId: o.id }
      })
    },
    // 宴会厅类型改变事件
    onBallroomTypeChange(e) {
      this.form.mealStandardSelectedBizId = ''
      const category = this.categories.find(r => {
        if (r.id === e) {
          return r
        }
      })
      this.bindAttributes = ''
      if (category.bindAttribute) {
        console.log(category.bindAttribute)
        this.bindAttributes = category.bindAttribute
      }
    },
    onBallRoomTagChange(e) {
      const arr2 = e.slice(0)
      const rooms = this.BallRoomPop(arr2, this.local_hall)
      this.form.bandRoom = []
      this.hallsTag = rooms.map(r => {
        var disabled = false
        if (e.includes(r.id)) {
          this.form.bandRoom.push(r.id)
          disabled = r.id === this.lock
        }
        return {
          label: r.name,
          value: r.id,
          disabled
        }
      })
    },
    BallRoomPop(e, rooms) {
      if (e.length > 0) {
        var key = e.pop()
        if (key == undefined) {
          return rooms
        }
        const hall = this.local_hall.find(r => {
          if (r.id === key) {
            return r
          }
        })
        const repels = hall.repels.split(',')
        const ci = rooms.filter(i => {
          if (i.id !== this.form.hallBizId && !repels.includes(i.id)) {
            return true
          }
        })
        return this.BallRoomPop(e, ci)
      } else {
        return rooms
      }
    },
    // 初始化宴会类型
    initBallroomType(typeData) {
      const { bindAttributes, reserveDate, mealStandards } = typeData
      // 显示新郎和新娘
      this.bindAttributes = ''
      if (bindAttributes) {
        this.bindAttributes = bindAttributes
      }
      if (reserveDate) {
        // 宴会开始时间
        this.startTimeQuantums = reserveDate.startTimeQuantums.map(o => {
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
        if (reserveDate.endTimeQuantums && reserveDate.endTimeQuantums.length > 0) {
          // 宴会结束时间
          this.endTimeQuantums = reserveDate.endTimeQuantums.map(o => {
            return {
              value: o.code,
              label: o.name,
              bizId: o.bizId
            }
          })
        }
      }
      if (mealStandards && mealStandards.length > 0) {
        // 餐标
        this.mealStandards = mealStandards.map(o => {
          return {
            value: o.code,
            label: o.name,
            bizId: o.bizId
          }
        })
      }
    },
    onPayChange(e) {
      // console.log(this.form.paymentJson)
      console.log(this.form.paymentJson.length)
      if (this.form.paymentJson.length > 0 && (this.level == 0 || this.level == 1)) {
        this.isReserve = 1
      }
    },
    // 添加确认方式
    onAddconfirm() {
      const index = this.form.paymentJson.length - 1
      const paymentJson = this.form.paymentJson[index]
      switch (paymentJson.verifyMode) {
        case 'deposits':
          if (!paymentJson.amount || !paymentJson.payCategorySelectedBizId || !paymentJson.payModeSelectedBizId) {
            this.$message('请完善当前信息')
            return false
          } else {
            // 全部有值
            const confirmObject = {
              id: uuid(),
              verifyMode: null,
              payCategorySelectedBizId: '',
              payModeSelectedBizId: '',
              receiptCode: '',
              amount: ''
            }
            this.form.paymentJson.push(confirmObject)
          }
          break
        case 'guarantors':
          if (!paymentJson.amount || !paymentJson.customerGuarantorSelectedBizId || !paymentJson.guarantorCategorySelectedBizId) {
            this.$message('请完善当前信息')
            return false
          } else {
            // 全部有值
            const confirmObject = {
              id: uuid(),
              verifyMode: null,
              customerGuarantorSelectedBizId: '',
              guarantorCategorySelectedBizId: '',
              amount: ''
            }
            this.form.paymentJson.push(confirmObject)
          }
          break
      }
    },

    async init() {
      const res = await this.getRequest('banquet/loadmeta', { hotelId: org.id })
      if (res.code !== 200) {
        this.$message.error('预定初始化信息失败!')
        return
      }
      // 宴会厅信息存储
      this.local_hall = res.data.halls
      const pm2s = res.data.payments
      if (pm2s && pm2s.length > 0) {
        this.payments = pm2s.map(r => {
          return {
            value: r.type,
            label: r.name,
            bizId: r.bizId
          }
        })
      }
      if (res.data.categories) {
        this.categories = res.data.categories
      }

      if (res.data.mealStandard) {
        this.mealStandards = res.data.mealStandard
      }

      if (res.data.customerSource) {
        this.customerSources = res.data.customerSource.map(r => {
          if (r.deleted === 0) {
            return {
              value: r.id,
              label: r.name
            }
          }
        })
      }
      if (res.data.customerGuarantor) {
        this.customerGuarantor = res.data.customerGuarantor.map(r => {
          if (r.deleted === 0 && r.state === 1) {
            return {
              value: r.id,
              label: r.name
            }
          }
        })
      }
      if (res.data.payMode) {
        this.payMode = res.data.payMode.map(r => {
          if (r.deleted === 0 && r.state === 1) {
            return {
              value: r.id,
              label: r.name
            }
          }
        })
      }
      if (res.data.payCategory) {
        this.payCategories = res.data.payCategory.map(r => {
          if (r.deleted === 0 && r.state === 1) {
            return {
              value: r.id,
              label: r.name
            }
          }
        })
        this.guarantorCategory = res.data.payCategory.map(r => {
          if (r.deleted === 0 && r.state === 1) {
            return {
              value: r.id,
              label: r.name
            }
          }
        })
      }
      if (res.data.providerService) {
        this.providerServices = res.data.providerService.map(r => {
          if (r.deleted === 0 && r.state === 1) {
            return {
              ...r,
              num: 0,
              amount: 0
            }
          }
        })
      }
      this.pagemodel = this.$store.state.banquetReservation
      const { hallBizId, bizId, startDate, timeQuantumType } = this.$store.state.banquetReservation

      if (bizId) {
        this.page_state = 'update'
        // 修改
        const { bizId, hallBizId, hotelBizId } = this.$store.state.banquetReservation
        const data = {
          hotelBizId,
          hallBizId,
          bizId
        }
        // 查询用户信息
        const local = await this.getRequest('banquet/loadDetails', data)
        if (local.code !== 200) {
          return false
        }
        const record = local.data
        const hallr = record.halls.map(r => {
          return r.id
        })
        this.onBallRoomTagChange(hallr)
        this.form.bizId = record.id
        this.form.genderSelectedBizId = record.gender
        this.form.categorySelectedBizId = record.categoryId
        this.onBallroomTypeChange(record.categoryId)
        this.form.startTimeQuantumSelectedBizId = record.reserveTimeQuantum
        this.form.reserveDate = record.reserveDate
        this.form.customerName = record.customerName
        this.form.customerPhoneNumber = record.customerPhoneNumber
        this.form.reserveTableNumber = record.reserveTableNumber
        this.form.backupTableNumber = record.backupTableNumber
        this.form.mealStandardSelectedBizId = record.mealStandard
        this.form.customerSourceSelectedBizId = record.customerSourcesId
        this.form.description = record.contentBack
        if (record.services.length > 0) {
          this.providerServices.forEach(pro => {
            record.services.forEach(r => {
              if (pro.id === r.metamodelId) {
                pro.num = r.num
              }
            })
          })
        }
        // 合同文件
        if (record.files) {
          const fl = record.files.map(r => {
            return {
              id: r.id,
              suffix: r.folderId,
              name: r.name,
              previewUrl: r.url
            }
          })
          this.form.attachmentJson = fl
        }
        // 附加信息
        const attr = JSON.parse(JSON.stringify(this.bindAttributes))
        if (record.attributes.length > 0) {
          for (var i = 0; i < record.attributes.length; i++) {
            const this_attr = record.attributes[i]
            attr.forEach(item => {
              if (item.id === this_attr.bindId) {
                item.bindAttributeValue1 = record.attributes[i].name ? record.attributes[i].name : ''
                item.bindAttributeValue2 = record.attributes[i].value ? record.attributes[i].value : ''
              }
            })
          }
        }
        this.bindAttributes = attr
        // deposits//guarantors
        if (record.paymentList != undefined && record.paymentList != null && record.paymentList.length > 0) {
          this.form.paymentJson = record.paymentList
        }
        // 1 预留 2 预定
        this.level = record.orderStatus
      } else {
        // 新增
        this.level = 0
        this.page_state = 'insert'
        this.form.startTimeQuantumSelectedBizId = timeQuantumType
        this.form.reserveDate = startDate
        this.form.genderSelectedBizId = 1
        this.lock = hallBizId
        const arr = [hallBizId]
        this.onBallRoomTagChange(arr)
      }
    }
  },

  mounted() {
    oldForm = null
    this.getCustomerManager()
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.reserve-page {
  box-sizing: border-box;
  padding: 5px;
  overflow: auto;

  .form {
    box-sizing: border-box;
    height: 100%;
    width: 102%;
    overflow: auto;
    padding-right: 15px;

    .form-box {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      background: #f7f7f7;
      margin: 0;
    }

    .el-form-item {
      margin: 10px 0;
      ::v-deep .el-select {
        .el-select__tags {
          .el-tag {
            box-sizing: border-box;
            padding: 0px 7px;
          }
          .el-icon-close {
            display: none !important;
          }
        }
      }
    }

    .form-box-margintop {
      margin-top: 12px;
    }
    .form-box-select {
      .el-select .el-tag__close el-icon-close {
        display: none !important;
      }
    }

    .form-box-none {
      display: block;
      display: flex;
      //justify-content: space-between;
      align-items: center;

      .payway {
        display: flex;

        //width: 80%
        .payway-item {
          display: flex;
        }
      }

      .addpayway {
        margin-left: 20px;
        font-size: 30px;
        margin-top: -4px;
      }
    }
  }

  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }

  /deep/ .el-upload {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
  }

  /deep/ .el-radio {
    margin-right: 5px;
  }
}
</style>
