<template>
  <div class="customer-information-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item style="width: 150px">
          <el-input v-model="ruleForm.phoneNumber" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManagerBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 250px; margin-left: 30px">
          <el-button size="mini" @click="getCustomerDate" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px; " size="mini" @click="exportExcel">导出</el-button>
          <el-button @click="resetForm" size="mini" type="info">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" id="selectTable" :data="tableData" height="66vh" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="createTime" sortable align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="name" align="center" label="姓名">
      </el-table-column>
      <el-table-column prop="gender" align="center" label="性别" :formatter="formatGender">
      </el-table-column>
      <el-table-column prop="phoneNumber" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="banquetTotalOrderNumber" align="center" label="累计预定">
      </el-table-column>
      <el-table-column prop="banquetTotalOrderEffectiveNumber" align="center" label="有效预定">
      </el-table-column>
      <!-- <el-table-column prop="customerSource" align="center" label="客户来源">
      </el-table-column> -->
      <el-table-column prop="customerManager" label="客户经理" align="center" filter-placement="bottom-end">
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { export_json_to_excel } from '@/utils/Export2Excel'
const pageSize = 10 // 每页的数量
let pageNum = 1 // 当前页
export default {
  name: 'CustomerInformation',
  data() {
    return {
      list: [],
      loading: false,
      elegantRoomVisible: false,
      ruleForm: {
        // 查询表单
        htoelId: this.$store.state.hotellist[0].bizId,
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        source: 'banquet'
      },
      form: {},
      tableData: [],
      customerManagers: [],
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 重置按钮
    resetForm() {
      this.ruleForm = {
        // 查询表单
        htoelId: this.$store.state.hotellist[0].bizId,
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        source: 'banquet'
      }
      this.getCustomerDate()
    },
    // 导出
    // exportExcel () {
    //   htmlToExcel.getExcel('#selectTable', '宴会客户信息')
    // },
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
          //  this.exportWord()
        })
        .catch(() => {})
    }, // 导出数据
    getExpportData: function() {
      var self = this
      //   debugger
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      //   this.url = process.env.VUE_APP_BASE_API + 'InsureController/GetVInsures'
      //   this.$axios
      //     .post(this.url, obj)

      if (this.list.length > 0) {
        require.ensure([], () => {
          const tHeader = [
            '序号',
            '创建时间',
            '姓名',
            '性别',
            '联系电话',
            '累计预定',
            '有效预定',
            '客户来源',
            '客户经理'
          ]
          const filterVal = [
            'index',
            'createdAt',
            'name',
            'gender',
            'phoneNumber',
            'totalOrderNumber',
            'totalSumAmount',
            'customerSource',
            'customerManager'
          ]
          var templist = this.list
          var i = 1
          templist.forEach(element => {
            element.index = i++
          })
          const data = self.formatJson(filterVal, templist)
          export_json_to_excel(tHeader, data, '宴会客户信息')
        })
      }
      // 关闭等待
      loading.close()
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    handleEdit(index, row) {
      this.elegantRoomVisible = true
      // console.log(index, row)
    },
    formatter(row, column) {
      return row.address
    },
    async getCustomerDate() {
      this.tableData = []
      const data = {
        pageSize: pageSize,
        pageNumber: pageNum,
        ...this.ruleForm
      }
      const res = await this.getRequest('data-customer/getPage', data)
      if (res.code !== 200) {
        return false
      }
      this.tableData = res.data.records
      this.loading = false
      this.total = res.data.total
    },


    // 分页事件
    onCurrentChange(val) {
      pageNum = val
      this.getCustomerDate()
    },
    // // 获取宴会厅客户经理
    // async getCustomerManager () {
    //   const res = await this.getRequest('admin/metamodel/customerManager/query', {})
    //   if (res.status !== 200) {
    //     return false
    //   }
    //   const { records } = res.pageModel
    //   this.customerManagers = records.map(o => {
    //     return { value: o.code, label: o.name, bizId: o.bizId, categories: o.categories }
    //   })
    // }
    // 获取宴会厅客户经理
    async initCustomerManager() {
      const res = await this.getRequest('sys/user/loadCostumerManager', {})
      this.customerManagers = []
      if (res.code == 200) {
        console.log(res)
        this.customerManagers = res.data
      }
    },
    formatGender(row){
      var val =  ''
      switch(row.gender){
        case 1:
          val='男'
          break;
        case 2:
          val='女'
          break;
      }
      return val 
    }
  },
  mounted() {
    this.getCustomerDate()
    this.initCustomerManager()
  }
}
</script>

<style lang="scss" scoped>
.customer-information-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }
  .table-expand {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 5px 20px;
    .table-expand-item {
      width: 40%;
      margin: 0;
    }
    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}
</style>
