<template>
  <div class="reservation-order-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item>
          <el-input style="width: 150px" v-model="ruleForm.name" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.hallBizId" placeholder="宴会厅" @change="onBallroomChange">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.categorySelectedBizId" placeholder="宴会类型">
            <el-option v-for="item in categories" :key="item.value" :label="item.name" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="width: 300px;">
          <el-button @click="getReserveOrders" size="mini" type="primary">搜索</el-button>
          <el-button type="primary" style="margin: 0 30px; " size="mini" @click="exportExcel">导出</el-button>
          <el-button @click="resetForm" size="mini" type="info">重置</el-button>
          <!--          <el-button type="primary">发送到邮箱</el-button>-->
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" id="selectTable" :data="tableData" height="66vh" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户姓名">
      </el-table-column>
      <el-table-column prop="customerPhoneNumber" align="center" label="客户电话">
      </el-table-column>
      <el-table-column prop="" align="center" label="定金金额">
        <template slot-scope="scope">
          {{scope.row.deposit.amount}}
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="定金类型">
        <template slot-scope="scope">
          {{scope.row.deposit.payCategory}}
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="支付方式">
        <template slot-scope="scope">
          {{scope.row.deposit.payMode}}
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="收据编号">
        <template slot-scope="scope">
          {{scope.row.deposit.receiptCode}}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="createdAt"-->
      <!--        align="center"-->
      <!--        label="操作时间">-->
      <!--      </el-table-column>-->
      <el-table-column prop="hallName" align="center" label="宴会厅">
      </el-table-column>
      <el-table-column prop="categoryName" align="center" label="宴会类型">
      </el-table-column>
      <el-table-column prop="banquetDate" align="center" label="举办时间">
      </el-table-column>
      <el-table-column prop="customerManagerName" label="客户经理" align="center">
      </el-table-column>
      <el-table-column align="center" label="定金状态" prop="orderStatusName">
      </el-table-column>
      <!--      <el-table-column-->
      <!--        align="center"-->
      <!--        label="详情">-->
      <!--        <template  slot-scope="scope">-->
      <!--          <el-button size="mini" @click="getBallroomData(scope.row)" type="text">详情</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import htmlToExcel from '@/utils/htmlToExcel'
import { export_json_to_excel } from '@/utils/Export2Excel'

const pageSize = 10 // 每页的数量
let pageNum = 1 // 当前页
const data = {}
export default {
  name: 'PrepaidFee',
  data() {
    return {
      loading: true,
      elegantRoomVisible: false,
      ruleForm: {
        // 查询表单
        hotelBizId: this.$store.state.hotellist[0].bizId,
        hallBizId: '',
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: ''
      },
      details: {},
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 导出
    // exportExcel() {
    //   htmlToExcel.getExcel('#selectTable', '预收款统计')
    // },
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
        })
        .catch(() => {})
    },
    // 导出数据-- 执行导出
    getExpportData: function() {
      var self = this
      // --页面查询条件
      const data = {
        pageSize: 100000,
        pageNumber: 1,
        ...this.ruleForm,
        paymentVerifyMode: 1
      }
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.getRequest('banquet/reserve/orders', data)
        .then(res => {
          // 关闭等待
          loading.close()
          // --查询完成
          if (res.status === 200 && res.pageModel.records.length > 0) {
            require.ensure([], () => {
              const tHeader = [
                '序号',
                '客户姓名',
                '联系电话',
                '定金金额',
                '定金类型',
                '支付方式',
                '收据编号',
                '宴会厅',
                '宴会类型',
                '举办时间',
                '客户经理',
                '定金状态'
              ]
              const filterVal = [
                'index',
                'customerName',
                'customerPhoneNumber',
                'amount',
                'payCategory',
                'payMode',
                'receiptCode',
                'hallName',
                'categoryName',
                'banquetDate',
                'customerManagerName',
                'orderStatusName'
              ]
              var templist = res.pageModel.records
              var i = 1
              templist.forEach(element => {
                element.index = i++
                // if (element.reserveTableNumber) {
                //   if (element.backupTableNumber !== undefined || element.backupTableNumber !== null || element.backupTableNumber !== '') {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌/备' + element.backupTableNumber + '桌'
                //   } else {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌'
                //   }
                // } else {
                //   element.reserveTableNumber = ''
                // }
                element.amount = element.deposit.amount
                element.payCategory = element.deposit.payCategory
                element.payMode = element.deposit.payMode
                element.receiptCode = element.deposit.receiptCode
              })
              const data = self.formatJson(filterVal, templist)
              export_json_to_excel(tHeader, data, '预收款统计')
            })
          } else {
            this.$message({
              message: '没有有效数据!',
              duration: 2000,
              type: 'warning'
            })
          }
        })
        .catch(res => {
          // 关闭等待
          this.$message('请与管理员联系')
          loading.close()
        })
    },
    // 导出数据-- 处理数据
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 重置按钮
    resetForm() {
      this.ruleForm = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        dateFormat: ''
      }
      this.getReserveOrders()
    },
    // 查询预定订单数据 banquetDate
    async getReserveOrders() {
      this.tableData = []
      // const data = {
      //   pageSize: pageSize,
      //   pageNumber: pageNum,
      //   ...this.ruleForm,
      //   paymentVerifyMode: 1,
      //   sortField: 'banquetDate'
      // }
      // console.log('查询条件：' + JSON.stringify(data))
      // const res = await this.getRequest('banquet/reserve/orders', data)
      // if (res.status !== 200) {
      //   return false
      // }
      // const { pageModel } = res
      this.tableData = [
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: 'asda',
          customerPhoneNumber: '12312345678',
          customerManagerName: '应用市场测试',
          banquetDate: '2023-10-29(晚上)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '礼仪定金',
            amount: 123,
            payMode: '微信',
            receiptCode: '123'
          }
        },
        {
          hallName: '峨眉厅',
          categoryName: '婚宴',
          customerName: '刘',
          customerPhoneNumber: '15548870706',
          customerManagerName: '简冉宴会',
          banquetDate: '2023-02-07(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '礼仪定金',
            amount: 3000,
            payMode: '支付宝',
            receiptCode: '965855'
          }
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '孙建宏',
          customerPhoneNumber: '15548870706',
          customerManagerName: '简冉宴会',
          banquetDate: '2022-09-28(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '礼仪定金',
            amount: 2000,
            payMode: '支付宝',
            receiptCode: '6985'
          }
        },
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: '张建林',
          customerPhoneNumber: '15548870706',
          customerManagerName: '简冉宴会',
          banquetDate: '2022-09-23(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '餐标定金',
            amount: 2000,
            payMode: '微信',
            receiptCode: '2541'
          }
        },
        {
          hallName: '泰山厅',
          categoryName: '婚宴',
          customerName: '李',
          customerPhoneNumber: '15847526358',
          customerManagerName: '文静宴会',
          banquetDate: '2022-09-05(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '餐标定金',
            amount: 1,
            payMode: '微信',
            receiptCode: ''
          }
        },
        {
          hallName: '泰山厅',
          categoryName: '婚宴',
          customerName: '李',
          customerPhoneNumber: '15847526358',
          customerManagerName: '文静宴会',
          banquetDate: '2022-09-03(晚上)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '餐标定金',
            amount: 500,
            payMode: '微信',
            receiptCode: ''
          }
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '李',
          customerPhoneNumber: '18731163050',
          customerManagerName: '文静宴会',
          banquetDate: '2022-08-18(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '餐标定金',
            amount: 158,
            payMode: '微信',
            receiptCode: ''
          }
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '简冉',
          customerPhoneNumber: '15548870706',
          customerManagerName: '简冉宴会',
          banquetDate: '2022-07-09(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '礼仪定金',
            amount: 2500,
            payMode: '支付宝',
            receiptCode: '255664'
          }
        },
        {
          hallName: '嵩山厅',
          categoryName: '婚宴',
          customerName: '尼古拉耶维奇',
          customerPhoneNumber: '15555555555',
          customerManagerName: '应用市场测试',
          banquetDate: '2022-06-29(晚上)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '礼仪定金',
            amount: 78906,
            payMode: '现金',
            receiptCode: 'HY098765432LXY'
          }
        },
        {
          hallName: '衡山厅',
          categoryName: '婚宴',
          customerName: '王建国',
          customerPhoneNumber: '15548870706',
          customerManagerName: '简冉宴会',
          banquetDate: '2022-06-21(中午)',
          orderStatusName: '已定',
          deposit: {
            payCategory: '礼仪定金',
            amount: 5000,
            payMode: '支付宝',
            receiptCode: '5874'
          }
        }
      ]
      this.loading = false
      // 总数
      this.total = 25
    },
    // 获取宴会厅类型
    async getBallroomType() {
      // const res = await this.getRequest('hall/all', {
      //   hotelBizId: this.$store.state.hotellist[0].bizId
      // })
      // if (res.status !== 200) {
      //   return false
      // }
      const records = [
        {
          bizId: '1506458829716455424',
          code: '1506458829594820608',
          name: '泰山厅'
        },
        {
          bizId: '1506458938390872064',
          code: '1506458938298597376',
          name: '衡山厅'
        },
        {
          bizId: '1506459335776010240',
          code: '1506459335675346944',
          name: '嵩山厅'
        },
        {
          bizId: '1506459436456083456',
          code: '1506459436363808768',
          name: '峨眉厅'
        },
        {
          bizId: '1620355331190161408',
          code: '1620355331173384192',
          name: '5厅'
        },
        {
          bizId: '1620355414291906560',
          code: '1620355414275129344',
          name: '6厅'
        },
        {
          bizId: '1620355459863019520',
          code: '1620355459850436608',
          name: '7厅'
        },
        {
          bizId: '1630479347313803264',
          code: '1630479347280248832',
          name: '1'
        },
        {
          bizId: '1630479378943049728',
          code: '1630479378473287680',
          name: '4'
        },
        {
          bizId: '1630479411604094976',
          code: '1630479411566346240',
          name: '7'
        },
        {
          bizId: '1630479462762020864',
          code: '1630479462732660736',
          name: '8'
        },
        {
          bizId: '1630479496081571840',
          code: '1630479496048017408',
          name: '9'
        },
        {
          bizId: '1630479528432238592',
          code: '1630479528398684160',
          name: '11'
        },
        {
          bizId: '1630479563437899776',
          code: '1630479563387568128',
          name: '45'
        },
        {
          bizId: '1630479644392161280',
          code: '1630479644354412544',
          name: '457'
        }
      ]
      this.ballroomList = records.map(o => {
        return {
          value: o.code,
          label: o.name,
          bizId: o.bizId
          // categories: o.categories
        }
      })
    },
    async getCategories() {
      // const data = {
      //   pageNumber: 1,
      //   pageSize: 100,
      //   status: '-1'
      // }
      // const res = await this.getRequest('admin/category/pageable', data)
      // if (res.status !== 200) {
      //   return false
      // }
      const pageModel = {
        records: [
          {
            bizId: '1506458169033883648',
            status: 1,
            name: '婚宴'
          },
          {
            bizId: '1506458236251799552',
            status: 1,
            name: '生日宴'
          },
          {
            bizId: '1506458278048038912',
            status: 1,
            name: '会议'
          },
          {
            bizId: '1506457147787968512',
            status: 1,
            name: null
          },
          {
            bizId: '1531534033127735296',
            status: 0,
            name: null
          },
          {
            bizId: '1630478974746361856',
            status: 1,
            name: '1'
          },
          {
            bizId: '1630478993352294400',
            status: 1,
            name: '11'
          },
          {
            bizId: '1630479010335031296',
            status: 1,
            name: '12'
          },
          {
            bizId: '1630479027103858688',
            status: 1,
            name: '13'
          },
          {
            bizId: '1630479043155460096',
            status: 1,
            name: '214'
          },
          {
            bizId: '1630479068681994240',
            status: 1,
            name: '123'
          },
          {
            bizId: '1630479083680825344',
            status: 1,
            name: '45'
          },
          {
            bizId: '1630479101384982528',
            status: 1,
            name: '4165'
          },
          {
            bizId: '1630479128530518016',
            status: 1,
            name: '14'
          },
          {
            bizId: '1630479142585630720',
            status: 1,
            name: '4'
          },
          {
            bizId: '1630479156384890880',
            status: 1,
            name: '47'
          },
          {
            bizId: '1630479176798568448',
            status: 1,
            name: '26'
          },
          {
            bizId: '1630479191721902080',
            status: 1,
            name: '28'
          }
        ]
      }
      pageModel.records.forEach(o => {
        o.status === 1 ? (o.status = true) : (o.status = false)
      })
      pageModel.records = pageModel.records.filter(
        item => item.name != undefined && item.name != null
      )
      this.categories = pageModel.records
    },
    // 获取宴会厅客户经理
    async getCustomerManager() {
      // const data = {
      //   pageSize: 100,
      //   pageNumber: 1,
      //   adminUser: 0
      // }
      // const res = await this.getRequest('ogz/user/pageable', data)
      // if (res.status !== 200) {
      //   return false
      // }
      const records = [
        {
          bizId: '1506456747324211200',

          loginUsername: '简冉宴会'
        },
        {
          bizId: '1506457147787968512',

          loginUsername: '韩总宴会'
        },
        {
          bizId: '1507629343495421952',

          loginUsername: '权限测试'
        },
        {
          bizId: '1511905657291276288',

          loginUsername: '应用市场测试'
        },
        {
          bizId: '1515887195305541632',

          loginUsername: '宴会杜总'
        },
        {
          bizId: '1531534033127735296',

          loginUsername: '阿木汗'
        },
        {
          bizId: '1566971049428385792',

          loginUsername: '文静宴会'
        }
      ]
      this.customerManagers = records.map(o => {
        return { value: o.bizId, label: o.loginUsername, bizId: o.bizId }
      })
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.ruleForm.categorySelectedBizId = ''
      this.categories = []
      const records = this.ballroomList.filter(i => {
        return i.bizId === e
      })
      const { categories } = records[0]
      this.categories = categories.map(o => {
        return { value: o.code, name: o.name, label: o.name, bizId: o.bizId }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      pageNum = val
      this.getReserveOrders()
    },
    // 查看详情
    getBallroomData(row) {
      // console.log('查看详情')
    },
    // 转为预定
    onReserve() {
      this.$router.push({
        path: '/banquet/reserve',
        query: {
          ...data
        }
      })
    }
  },
  mounted() {
    this.getReserveOrders()
    this.getBallroomType()
    this.getCategories()
    this.getCustomerManager()
    if (this.$route.query.q) {
      const { hallBizId, bizId } = this.$store.state.banquetReservation
      const row = {
        hallBizId,
        bizId
      }
      this.getBallroomData(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-order-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .order-details {
    height: auto;
    margin-top: -30px;
    margin-bottom: -20px;

    .customer-details {
      height: 120px;
      margin-bottom: 20px;
      .title {
        //margin: 5px 0;
        //margin-top: -10px;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
        background: #f7f6f6;
      }
    }
    .reserved-details {
      height: auto;
      .title {
        //margin: 5px 0;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
      }
      .box-card {
        //border: 1px solid #ccc;
        border-radius: 5px;
        //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 0px 5px;
        .box-card-img {
          width: 100px;
          height: 100px;
          margin-right: 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
