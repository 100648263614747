<template>
  <div class="page">
    <div style="height: 100%; overflow: auto;">
      <el-table max-height="600" :span-method="objectSpanMethod" v-loading="loading" show-summary :data="tableData" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
        <el-table-column width="100px" prop="reservedDate" align="center" label="日期">
        </el-table-column>
        <el-table-column prop="reserveTimeName" align="center" label="时段">
        </el-table-column>
        <el-table-column prop="number_seat" align="center" label="入座数">
        </el-table-column>
        <el-table-column prop="number_unreserve" align="center" label="退定数">
        </el-table-column>
        <el-table-column prop="number_complete" align="center" label="翻台数">
        </el-table-column>
        <el-table-column prop="banquetDate" align="center" label="空台率">
          <template slot-scope="scope">
            <span v-if="scope.row.number_reserve!==0">{{Number((scope.row.number_reserve - scope.row.number_unreserve) / scope.row.number_reserve).toFixed(2) * 100}}%</span>
            <span v-else>100%</span>
          </template>
        </el-table-column>
        <el-table-column prop="newCustomer" align="center" label="新客">
        </el-table-column>
        <el-table-column prop="customer" align="center" label="老客">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DinnerMonthly',
  data() {
    return {
      date: {},
      tableData: [], // 宴会预留订单数据
      loading: true
    }
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    _currentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      this.date = {
        year,
        month
      }
    },
    // 查询预定订单数据
    async getReserveOrders() {
      this._currentDate()
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        ...this.date
      }
      const res = await this.getRequest(
        'statistics/dinner/month/statistics',
        data
      )
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      this.loading = false
      const dataList = []
      pageModel.records = pageModel.records.forEach(o => {
        const data1 = {
          ...o.noon,
          reservedDate: `${o.year}-${o.month}-${o.day}`,
          reserveTimeName: '中午'
        }
        const data2 = {
          ...o.night,
          reservedDate: `${o.year}-${o.month}-${o.day}`,
          reserveTimeName: '晚上'
        }
        dataList.push(data1, data2)
      })
      this.tableData = dataList
    }
  },
  mounted() {
    this.getReserveOrders()
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 20px;
  box-sizing: border-box;
}
</style>
