<!-- 2. 宴会类型 -->
<template>
  <el-card class="type-page">
    <div class="buttongroup">
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <!-- <el-table-column align="center" prop="createdAt" label="创建日期">
      </el-table-column> -->
      <el-table-column align="center" prop="name" label="名称">
      </el-table-column>
      <el-table-column align="center" prop="tabIndex" label="排序">
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          {{scope.row.status==1?'启用':'禁用'}}
        </template>
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="postEditData(scope.row)"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>
    <!-- 添加 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" :title="titleName + '宴会类型'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form ref="form" :rules="rules" label-width="100px" :model="form">
        <el-form-item prop="name" label="类型名称">
          <el-input v-model="form.name" maxlength="25"></el-input>
        </el-form-item>
        <el-form-item label-width="100px">
          <span style="color: red">示例：婚宴；生日宴；谢师宴；年会；会议</span>
        </el-form-item>
        <el-form-item label="排序：" prop="tabIndex">
          <el-input-number style="width:250px" v-model="form.tabIndex" :min="1" :max="9999" :step="1" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-select style="width:250px" v-model="form.status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="30px">
          <el-button :disabled="form.bindAttribute && form.bindAttribute.length>=2" @click="addDomain" size="mini" type="primary">添加字段</el-button>
        </el-form-item>
        <el-form-item v-for="(domain, index) in form.bindAttribute" :label="'字段' + (index + 1)" :key="domain.key">
          <div class="form-item-box">
            <el-input style="width: 150px" v-model="domain.bindAttributeName1"></el-input>
            <el-input style="width: 150px" v-model="domain.bindAttributeName2"></el-input>
            <el-button @click.prevent="removeDomain(domain)">删除</el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">{{titleName}}</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import rules from '@/utils/rules'
export default {
  name: 'banquetType',
  data() {
    return {
      rules,
      titleName: '添加',
      form: {
        name: '',
        selected: 1,
        hotelId: '',
        applicationName: '',
        tabIndex: '',
        status: 1,
        bindAttribute: []
      },
      tableData: [],
      centerDialogVisible: false,
      total: 0,
      search: {
        pageSize: 10,
        pageNumber: 1,
        hotelId: '',
        applicationName: ''
      },
      org: '',
      options: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '禁用'
        }
      ]
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    this.search.hotelId = this.org.id
    this.search.applicationName = this.org.applicationName
    this.getQueryData()
  },
  methods: {
    // 查询数据
    getQueryData() {
      this.getRequest('data-category/getPage', this.search).then(res => {
        this.tableData = []
        this.total = 0
        if (res.code == 200) {
          this.total = res.data.total
          this.tableData = res.data.records
        }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    // 打开添加对话框
    onAddClick() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.titleName = '添加'
      this.form = {
        name: '',
        selected: 1,
        hotelId: '',
        applicationName: '',
        tabIndex: '',
        status: 1,
        bindAttribute: []
      }
      this.centerDialogVisible = true
    },
    // 打开修改
    postEditData(row) {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.getRequest('data-category/getDtailById/' + row.id, {
        id: row.id
      }).then(res => {
        // console.log('查询酒店结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.titleName = '修改'
          this.form = res.data
          this.centerDialogVisible = true
        }
      })
    },
    // 提交填写、修改
    onSetData() {
      this.form.hotelId = this.org.id
      this.form.applicationName = this.org.applicationName

      if (this.form.bindAttribute && this.form.bindAttribute.length > 0) {
        var index = 0
        for (var i = 0; i < this.form.bindAttribute.length; i++) {
          if (
            this.form.bindAttribute[i].bindAttributeName1 == '' ||
            this.form.bindAttribute[i].bindAttributeName1 == ''
          ) {
            index = i + 1
            break
          }
        }
        if (index > 0) {
          this.$notify({
            title: '提示',
            message: '字段' + index + '的参数不能为空',
            type: 'error',
            duration: 2000
          })
          return
        }
      }

      this.$refs.form.validate(async flage => {
        if (flage) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          if (this.titleName === '添加') {
            this.post(`data-category/add`, this.form)
              .then(res => {
                loading.close()
                if (res.code == 200) {
                  this.centerDialogVisible = false
                  this.getQueryData()
                  this.$message.success({
                    duration: 2000,
                    message: '添加成功!'
                  })
                } else {
                  // message
                  this.$notify({
                    title: '提示',
                    message: res.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })
              .catch(() => {
                loading.close()
              })
          } else {
            this.post(`data-category/edit`, this.form)
              .then(res => {
                loading.close()
                if (res.code == 200) {
                  this.getQueryData()
                  this.centerDialogVisible = false
                  this.$message.success({
                    duration: 2000,
                    message: '修改成功!'
                  })
                } else {
                  // message
                  this.$notify({
                    title: '提示',
                    message: res.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        }
      })
    },
    // 删除数据
    async postDeleteData(row) {
      this.$confirm('请确认是否要删除当前标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('data-category/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getQueryData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    addDomain() {
      this.form.bindAttribute.push({
        hotelId: this.search.hotelId,
        applicationName: this.search.applicationName,
        bindAttributeName1: '姓名',
        bindAttributeName2: '电话',
        key: Date.now()
      })
    },
    // 动态删除属性
    removeDomain(item) {
      const index = this.form.bindAttribute.indexOf(item)
      if (index !== -1) {
        this.form.bindAttribute.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .form-item-box {
    display: flex;
    justify-content: space-between;
  }
}
</style>
