<template>

  <div class="hotel-page">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="酒店基础信息设置" name="first">
        <el-form ref="form" class="form" :model="form" label-width="100px">
          <div class="title">酒店基础信息设置</div>
          <!-- /cors/banquet/reserve/attachment/upload -->
          <el-upload class="avatar-uploader" name="files" :data="uploadData" :headers="uploadHeaders" :action="action"
            :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img width="100px" height="100px" v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

          <div class="hint">点击头像更换Logo</div>
          <el-form-item label="酒店全称:">
            {{form.name}}
            <!-- <el-input :disabled="true" v-model="form.name" placeholder="请输入酒店名称"></el-input> -->
          </el-form-item>
          <el-form-item label="酒店简称:">
            <el-input v-model="form.shortName" placeholder="请输入酒店简称"></el-input>
          </el-form-item>
          <el-form-item label="酒店电话:">
            <el-input v-model="form.telephone" placeholder="请输入酒店简称"></el-input>
          </el-form-item>
          <el-form-item label="酒店地址经度:">
            <el-input v-model="form.longitude" placeholder="范围为-180~180" type="number" class="texttel"></el-input>
          </el-form-item>
          <el-form-item label="酒店地址纬度:">
            <el-input v-model="form.latitude" placeholder="范围为-90~90" type="number" class="texttel"></el-input>
          </el-form-item>
          <!--        <el-form-item>-->
          <!--          <span style="color: red">注：将用于发送预定短信给客户展示</span>-->
          <!--        </el-form-item>-->
          <el-form-item label="详细地址:">
            <el-input v-model="form.address" placeholder="请输入酒店地址"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="color: red">注：将用于发送短信的地址展示</span>
          </el-form-item>
        </el-form>
        <el-button class="btn" @click="hotelUpdate" type="primary">保存</el-button>
      </el-tab-pane>

      <el-tab-pane label="结束时间设置" name="second">
        <div class="carry">
          <div class="item">
            <div class="item-title">结束时间设置说明:</div>
            <div style="width: 500px; font-size: 14px; margin-left: 10px">
              <div>请注意，结束时间不一定和您酒店的闭餐时间相同。中午的结束时间将是晚上预定的开始时间，晚上的结束时间将是中午预定的开始时间。</div>
              <br>
              <div v-if="false">当过了这个时间后，之前日期的宴会厅或是雅间将不能被预定。宴会的预留订单（未付定金订单）、零点未入座订单过此时间将被清除。宴会已定订单和零点入座订单将不能被修改。</div>
              <br>
              <div style="color: red;">请注意修改结束时间24小时后才能生效。</div>
            </div>
          </div>
          <el-form ref="form" :model="form" label-width="80px">
            <div v-if="type.includes('banquet')" class="item">
              <div class="item-title">宴会结束时间设定</div>
              <el-form-item label="中午">
                <el-time-select :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00'
              }" format="HH:mm" v-model="form.banquetNoonDayEndTime" value-format="HH:mm"
                  placeholder="选择中午时间"></el-time-select>
              </el-form-item>
              <el-form-item label="晚上">
                <el-radio-group style="margin-right: 20px" v-model="form.banquetNightDay">
                  <el-radio :label="1">当日</el-radio>
                  <el-radio :label="2">次日</el-radio>
                </el-radio-group>
                <el-time-select :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00'
              }" format="HH:mm" v-model="form.banquetNightDayEndTime" value-format="HH:mm"
                  placeholder="选择晚上时间"></el-time-select>
              </el-form-item>
            </div>
            <div v-if="type.includes('dinner')" class="item">
              <div class="item-title">零点结束时间设定</div>
              <el-form-item label="中午">
                <el-time-select :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00'
              }" format="HH:mm" v-model="form.dinnerNoonDayEndTime" value-format="HH:mm"
                  placeholder="选择中午时间"></el-time-select>
              </el-form-item>
              <el-form-item label="晚上">
                <el-radio-group style="margin-right: 20px" v-model="form.dinnerNightDay">
                  <el-radio :label="1">当日</el-radio>
                  <el-radio :label="2">次日</el-radio>
                </el-radio-group>
                <el-time-select :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00'
              }" format="HH:mm" v-model="form.dinnerNightDayEndTime" value-format="HH:mm"
                  placeholder="选择晚上时间"></el-time-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <el-button class="btn" @click="hotelUpdate" type="primary">保存</el-button>
      </el-tab-pane>

      <el-tab-pane label="酒店分享描述" name="third">
        <div class="fuwenbenkuangs">
          <!-- <div class="title">酒店分享描述</div> -->
          <Tinymce ref="text" v-model="form.content" class="myQuillEditor" height="390" :options="editorOption" />
          <el-button class="btn" @click="hotelUpdate" type="primary">保存</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- <div class="content"> -->
    <!-- 左边 -->

    <!-- <div class="fgx"></div> -->
    <!-- 中间 -->

    <!-- 右边 -->

    <!-- </div> -->

  </div>
</template>

<script>
  import {
    uploadFile,
    imageUrl
  } from '@/utils/api'
  import Tinymce from '@/components/Tinymce'
  import axios from 'axios'
  let token = ''
  if (window.localStorage.getItem('token')) {
    token = window.localStorage.getItem('token').toString()
  }
  export default {
    name: 'Hotel',
    components: {
      Tinymce
    },
    data() {
      return {
        activeName: 'first',
        type: [],
        action: uploadFile,
        uploadHeaders: {
          'Access-Control-Allow-Origin': '*',
          accessToken: 'Bearer ' + token
        },
        form: {
          content: '',
          name: '',
          address: '',
          shortName: '',
          logoUrl: 'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
          hotelNavigationUrl: '',
          contactTelNumber: '',
          longitude: '',
          latitude: '',
          telephone: ''
        },
        uploadData: {},
        editorOption: {},
        search: {
          hotelId: ''
        }
      }
    },
    created() {},
    mounted() {
      var orgs = window.localStorage.getItem('orgs')
      if (orgs != undefined && orgs != null) {
        var hotel = JSON.parse(orgs)
        this.search.hotelId = hotel[0].id
        // 判定酒店类型
        const accountType = JSON.parse(window.localStorage.getItem('accountType'))
        const type = []
        if (accountType) {
          if (accountType.banquet === 1) {
            type.push('banquet')
          }
          if (accountType.dinner === 1) {
            type.push('dinner')
          }
          if (accountType.meeting === 1) {
            type.push('meeting')
          }
        }
        this.type = type
        this.hotelQuery()
      } else {
        // 跳转登录
        // -- 没有默认酒店，跳转到登录页面
        this.$router.push('/login')
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      // 查看酒店信息
      hotelQuery() {
        //  console.log('this.search:', this.search)
        this.getRequest('data-hotel/getById/' + this.search.hotelId, {
          id: this.search.hotelId
        }).then(res => {
          //   console.log('查询结果：' + JSON.stringify(res))
          if (res.code == 200) {
            var logoUrl = ''
            if (res.data.logoUrl !== undefined && res.data.logoUrl !== null) {
              if (res.data.logoUrl.indexOf('http') != -1) {
                logoUrl = res.data.logoUrl
              } else {
                logoUrl = imageUrl + res.data.logoUrl
              }
            } else {
              logoUrl =
                ' https://cdn.pixabay.com/photo/2021/10/01/03/46/goat-6671275__340.jpg'
            }
            this.form = {
              id: res.data.id,
              banquetNoonDayEndTime: res.data.banquetNoonDayEndTime || '', // 宴会的中午结转时间（默认：16:00）
              banquetNightDayEndTime: res.data.banquetNightDayEndTime || '', // 宴会的晚上结转时间（默认：23:00）
              banquetNightDay: res.data.banquetNightDay || '', // 宴会1:当日; 2:次日 Integer
              dinnerNoonDayEndTime: res.data.dinnerNoonDayEndTime || '', // 零点的中午结转时间（默认：16:00）
              dinnerNightDay: res.data.dinnerNightDay || '', // 零点1:当日; 2:次日
              address: res.data.address || '', // 酒店地址
              dataCode: res.data.dataCode || '', //
              dinnerNightDayEndTime: res.data.dinnerNightDayEndTime || '', // 零点的晚上结转时间（默认：23:00）
              logoUrl: logoUrl, // 酒店logo地址
              name: res.data.name || '', // 酒店名称
              shortName: res.data.shortName || '', // 酒店简称
              latitude: res.data.latitude || '', // 地图纬度
              longitude: res.data.longitude || '', // 地图经度
              content: res.data.content || '', // 酒店描述详情
              telephone: res.data.telephone || '', // 酒店手机电话
              description: res.data.description || '', // 描述说明
              contactTelNumber: res.data.contactTelNumber || '', // 酒店座机电话
              hotelNavigationUrl: res.data.hotelNavigationUrl || '', // 酒店地址导航链接
              phoneNumber: res.data.phoneNumber || '', // 酒店联系人手机号
              contactUserName: res.data.contactUserName || '', // 酒店联系人
              state: res.data.state || '' // 酒店类型 4零点 5宴会 6会议
            }
            //  console.log('酒店结果：' + JSON.stringify(this.form))
          }
        })
      },

      unescapeHTML(a) {
        a = '' + a
        return a
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&amp;/g, '&')
          .replace(/&quot;/g, '"')
          .replace(/&apos;/g, "'")
      },
      // 修改酒店
      hotelUpdate() {
        this.post('data-hotel/editmyself', this.form).then(res => {
          if (res.code == 200) {
            //  console.log(JSON.stringify(res))
            this.hotelQuery()
            this.$message({
              duration: 2000,
              type: 'success',
              message: '保存成功,需要重新登录方可生效。'
            })
          } else {
            this.$notify({
              title: '提示',
              message: res.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
      },

      handleAvatarSuccess(res, file) {
        if (res.code !== 200) {
          return false
        } else {
          this.form.logoUrl = imageUrl + res.data
        }
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!')
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG && isLt2M
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fuwenbenkuangs {
    margin: 20px;
    height: 100%;
  }

  .fuwenbenkuangs .title {
    margin-bottom: 10px;
  }

  /deep/.avatar-uploader {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 20px;
    background: #ccc;

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }

    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
  }

  .hotel-page {
    width: auto;
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow: hidden;

    .hint {
      text-align: center;
      margin: 20px 0;
      font-size: 14px;
      color: #999;
    }

    .content {
      width: 102%;
      height: 90%;
      // display: flex;
      align-items: center;
      overflow: auto;

      .form {
        width: 500px;
        height: 100%;
      }

      .fgx {
        width: 1px;
        height: 100%;
        background: #f3f3f3;
        margin: 0 50px;
      }

      .carry {
        //   height: 100%;

        .title {
          margin-bottom: 10px;
        }

        .item-title {
          padding: 10px;
          font-size: 14px;
        }

        .item {
          background: #f6f6f6;
          margin-bottom: 20px;
          padding: 10px 30px;
        }
      }
    }

    .btn {
      width: 500px;
      padding: 10px 0;
      margin-top: 20px;
      margin-left: 300px;
    }

    /deep/.texttel input::-webkit-outer-spin-button,
    /deep/.texttel input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    /deep/.texttel input[type='number'] {
      -moz-appearance: textfield;
    }
  }
</style>