<template>
  <div>
    <!-- 宴会详情信息-->
    <el-dialog :close-on-click-modal='false' title="订单详情信息" width="80%" :visible.sync="elegantRoomVisible">
      <div class="order-details" ref="imageWrapper">
        <el-card class="customer-details">
          <h4 class="title">客户信息</h4>
          <el-row>
            <el-col :span="5">
              【客户姓名】
              {{ details.customerName }}
              <i class="el-icon-user-solid" v-if="details.genders === '先生'" style="color: #00B7FF"></i>
              <i class="el-icon-user-solid" v-if="details.genders === '女士'" style="color: pink"></i>
            </el-col>
            <el-col :span="9">【联系电话】{{ details.customerPhoneNumber }}</el-col>
            <el-col :span="10">【客户来源】 {{ details.customerSources }}</el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="10">【客户经理】 {{ details.customerManagers }}</el-col>
          </el-row>
        </el-card>
        <el-card class="reserved-details">
          <h4 class="title">预定信息</h4>
          <el-row>
            <el-col :span="7">【宴会信息】
              {{ details.hallName }} / {{ details.hallCategories }} /
              {{ details.reserveTableNumber }}人
            </el-col>
            <el-col :span="10">【会议时间】
              {{ details.startDate }} {{details.startTimeQuantums}}-{{ details.endDate }} {{details.endTimeQuantums}}
            </el-col>
            <!--            <el-col :span="10">【关联雅间】无</el-col>-->
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="14">
              <h4 style="font-weight: normal">【餐标信息】<span v-if="details.mealStandards">{{details.mealStandards.name}}</span></h4>
            </el-col>
            <!--            <el-col :span="7">【预留时间】{{ details.createdAt }}</el-col>-->
            <el-col :span="10">
              <h4 style="font-weight: normal">【收费服务】</h4>
              <div class="box-card">
                <div style="display: inline-block; margin-right: 10px;: " v-for="item in details.providerServices" :key="item.bizId">
                  {{ item.name }}：{{ item.price }}元*{{item.num}}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="14">【详情备注】 {{details.description}}</el-col>
            <el-col :span="10">
              <h4 style="font-weight: normal">【定金方式】</h4>
              <div class="box-card">
                <div v-for="(item, index) in details.payments" :key="index+101">
                  <div v-if="item.type === '预收款'">{{ shou[index] }}次定金方式：{{item.type}}/定金金额：{{item.amount}}元/支付项目：{{item.payCategories}}/{{item.payModes}}/订单编号:{{item.receiptCode}}</div>
                  <div v-if="item.type === '担保人'">{{ shou[index] }}次定金方式：{{item.type}}/担保金额：{{item.amount}}元/支付项目：{{item.payCategories}}/担保人：{{item.customerGuarantors}}</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px;">
            <el-col :span="24">
              <h4 style="font-weight: normal">【合同信息】</h4>
              <div class="box-card">
                <viewer :images="details.images">
                  <img class="box-card-img" v-for="item in details.attachments" :key="item.bizId" :src="imageUrl + item.previewUrl" />
                </viewer>
              </div>
            </el-col>
            <el-col v-if="details.retainCreatedAt" :span="7">【预留时间】{{ details.retainCreatedAt }}</el-col>
            <el-col v-if="details.reserveCreatedAt" :span="7">【预定时间】{{ details.reserveCreatedAt }}</el-col>
          </el-row>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: flex-end">
        <div v-if="details.orderStatus !== 5">
          <el-button v-if="details.status === 1" @click="retiredRoom" type="primary">取消预留</el-button>
          <el-button v-if="details.status === 1" @click="revise" type="primary">转预定</el-button>
          <el-button v-if="details.status === 2" @click="revise" type="primary">修改</el-button>
          <el-button v-if="details.status === 2" @click="unReserved" type="primary">退定</el-button>
        </div>
        <el-button style="margin-left: 15px" type="primary" @click="elegantRoomVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { imageUrl } from '@/utils/api'
// import html2canvas from 'html2canvas'
export default {
  name: 'MeetingDetails',
  data() {
    return {
      shou: ['首', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      elegantRoomVisible: false,
      details: {},
      imageUrl,
      data: {}
    }
  },
  props: {
    zhi: Object
  },
  inject: ['initAria'],
  mounted() {},
  methods: {
    // 查看详情
    async getDetails(row) {
      this.row = {
        ...row
      }
      this.elegantRoomVisible = true
      this.details = {
        customerName: '', // 客户姓名
        // genders: genders[0].name,
        genders: '', // 客户性别
        customerPhoneNumber: '', // 客户手机号
        customerSources: '', // 客户来源
        bindAttributes: [
          {
            // 客户信息
            bindAttributeName1: '',
            bindAttributeName2: '',
            bindAttributeValue1: '',
            bindAttributeValue2: ''
          }
        ],
        customerManagers: '', // 客户经理
        hallName: '', // 宴会厅名称
        hallCategories: '', // 宴会厅类型
        reserveTableNumber: '', // 预定桌数
        backupTableNumber: '', // 浮动桌数
        startDate: '', // 宴会时间
        endDate: '', // 宴会时间
        startTimeQuantums: '', // 宴会时间段
        endTimeQuantums: '',
        mealStandards: {
          // 餐标
          name: '',
          center: ''
        }, // 餐标
        providerServices: [], // 增值服务
        description: '', // 详情备注
        attachments: [] // 合同
      }
      this.data = {
        hotelBizId: row.hotelBizId,
        hallBizId: row.hallBizId,
        bizId: row.bizId,
        retainBizId: row.retainBizId
      }
      const res = await this.getRequest('banquet/reserve/load', this.data)
      if (res.status !== 200) {
        return false
      }
      const records = res.pageModel.records[0]
      let genders = []
      if (records.genders) {
        genders = records.genders.filter(i => {
          return i.selected === 1
        })
        genders.length > 0
          ? (this.details.genders = genders[0].name)
          : (this.details.genders = '')
      }
      let customerSources = []
      if (records.customerSources) {
        customerSources = records.customerSources.filter(i => {
          return i.selected === 1
        })
        customerSources.length > 0
          ? (customerSources = customerSources[0].name)
          : (customerSources = '')
      }
      let customerManagers = []
      if (records.customerManagers) {
        customerManagers = records.customerManagers.filter(i => {
          return i.selected === 1
        })
        customerManagers.length > 0
          ? (customerManagers = customerManagers[0].name)
          : (customerManagers = '')
      } else {
        customerManagers = ''
      }
      let hallCategoriesAll = []
      if (records.hall && records.hall.categories) {
        hallCategoriesAll = records.hall.categories.filter(i => {
          return i.selected === 1
        })
      }
      let providerServices = []
      if (records.providerServices) {
        providerServices = records.providerServices.filter(
          o => o.selected === 1
        )
      }
      if (hallCategoriesAll.length > 0) {
        const { reserveDate, mealStandards } = hallCategoriesAll[0]
        let bindAttributes = hallCategoriesAll[0].bindAttributes
        let startTimeQuantums = reserveDate.startTimeQuantums.filter(i => {
          return i.selected === 1
        })
        startTimeQuantums.length > 0
          ? (startTimeQuantums = startTimeQuantums[0].name)
          : (startTimeQuantums = '')
        let endTimeQuantums = reserveDate.endTimeQuantums.filter(i => {
          return i.selected === 1
        })
        if (endTimeQuantums && endTimeQuantums.length > 0) {
          endTimeQuantums = reserveDate.endTimeQuantums.filter(i => {
            return i.selected === 1
          })
          endTimeQuantums.length > 0
            ? (endTimeQuantums = endTimeQuantums[0].name)
            : (endTimeQuantums = '')
        }
        let mealStandardsname = ''
        if (mealStandards && mealStandards.length > 0) {
          mealStandardsname = mealStandards.filter(i => {
            return i.selected === 1
          })
          mealStandardsname.length > 0
            ? (mealStandardsname = mealStandards[0].name)
            : (mealStandardsname = '')
        }
        if (bindAttributes === null) {
          bindAttributes = []
        }
        if (records.attachments && records.attachments.length > 0) {
          records.images = records.attachments.map(o => {
            return o.previewUrl
          })
        }
        // console.log('reserveDate.endDate', reserveDate.endDate)
        this.details = {
          status: records.status || '',
          genders: genders[0].name,
          customerName: records.customerName,
          customerPhoneNumber: records.customerPhoneNumber,
          customerSources,
          bindAttributes: bindAttributes || [],
          customerManagers,
          hallName: records.hall.name,
          hallCategories: hallCategoriesAll[0].name,
          reserveTableNumber: records.reserveTableNumber,
          backupTableNumber: records.backupTableNumber,
          startDate: reserveDate.startDate
            ? reserveDate.startDate.substr(0, 10)
            : '',
          endDate: reserveDate.endDate ? reserveDate.endDate.substr(0, 10) : '',
          startTimeQuantums,
          endTimeQuantums,
          mealStandards: {
            name: mealStandardsname
          },
          providerServices,
          description: records.description || '无',
          attachments: records.attachments,
          images: records.images,
          retainCreatedAt: records.retainCreatedAt,
          reserveCreatedAt: records.reserveCreatedAt,
          orderStatus: records.orderStatus || ''
        }
        this.elegantRoomVisible = true
      }
      const payments = []
      if (records.payments && records.payments.length > 0) {
        records.payments.forEach(o => {
          if (o.verifyMode === 1) {
            o.deposits.forEach(i => {
              const data = {
                type: '预收款',
                amount: i.amount,
                receiptCode: i.receiptCode
              }
              for (let j = 0; j < i.payCategories.length; j++) {
                if (i.payCategories[j].selected === 1) {
                  data.payCategories = i.payCategories[j].name
                  break
                }
              }
              for (let j = 0; j < i.payModes.length; j++) {
                if (i.payModes[j].selected === 1) {
                  data.payModes = i.payModes[j].name
                  break
                }
              }
              payments.push(data)
            })
          } else {
            o.guarantors.forEach(i => {
              const data = {
                type: '担保人',
                amount: i.amount
              }
              for (let j = 0; j < i.customerGuarantors.length; j++) {
                if (i.customerGuarantors[j].selected === 1) {
                  data.customerGuarantors = i.customerGuarantors[j].name
                  break
                }
              }
              for (let j = 0; j < i.payCategories.length; j++) {
                if (i.payCategories[j].selected === 1) {
                  data.payCategories = i.payCategories[j].name
                  break
                }
              }
              payments.push(data)
            })
          }
        })
        this.details.payments = payments
      }
    },
    // 取消预留
    retiredRoom() {
      this.$confirm('确定取消预留吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          const { bizId, hallBizId, hotelBizId, retainBizId } = this.row
          const data = {
            hotelBizId,
            hallBizId,
            bizId,
            retainBizId
          }
          const res = await this.postRequest(
            'banquet/reserve/delete/process',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message(res.message)
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '取消预留成功!'
          })
          this.elegantRoomVisible = false
          this.initAria()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消预留'
          })
        })
    },
    // 修改
    revise() {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '宴会预定')
      const submenu = {
        path: '/meeting/reserve',
        name: '宴会预定',
        bizId: '/meeting/reserve'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$store.commit('setBanquetReservation', this.data)
      this.$router.push({
        path: '/meeting/reserve'
      })
    },
    // 退定
    unReserved(row) {
      this.$confirm('您确定要退定吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { bizId, hallBizId, hotelBizId } = this.row
          const data = {
            hotelBizId,
            hallBizId,
            bizId,
            userCode: JSON.parse(localStorage.getItem('info')).phoneNumber
          }
          const res = await this.postRequest(
            'banquet/reserve/unreserved/process',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message(res.message)
            return false
          }
          this.elegantRoomVisible = false
          this.initAria()
          this.$message({
            duration: 10000,
            type: 'success',
            message: '退定成功!'
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消退定'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details {
  height: auto;
  margin-top: -30px;
  margin-bottom: -20px;
  .customer-details {
    height: 120px;
    margin-bottom: 20px;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
      background: #f7f6f6;
    }
  }
  .reserved-details {
    height: auto;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
    }
    .box-card {
      border-radius: 5px;
      padding: 0px 5px;
      .box-card-img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 5px;
      }
    }
  }
}
</style>
