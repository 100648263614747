<template>
  <el-card class="type-page">
    <div class="buttongroup">
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="name" label="名称">
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-switch @change="onStatusChange(scope.row)" v-model="scope.row.status"></el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="postEditData(scope.row)"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加角色 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" :title="titleName + '时间类型'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form label-width="80px" :model="form">

        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="展示">
          <el-switch v-model="form.status"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">{{titleName}}</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getQueryData,
  postDeleteData,
  postSetData
} from '../../../utils/postForm'
export default {
  name: 'Gender',
  data() {
    return {
      titleName: '添加',
      form: {
        dataCode: 'gender',
        code: '',
        name: '',
        status: true
      },
      tableData: [],
      centerDialogVisible: false
    }
  },
  methods: {
    // 查询数据
    async getQueryData() {
      const res = await getQueryData('gender')
      this.tableData = res
    },
    // 添加按钮事件
    onAddClick() {
      this.titleName = '添加'
      this.form = {
        dataCode: 'gender',
        code: '',
        name: '',
        status: true
      }
      this.centerDialogVisible = true
    },
    // 设置数据
    async onSetData() {
      this.form.status ? (this.form.status = 1) : (this.form.status = 0)
      let type = ''
      if (this.titleName === '添加') {
        type = 'add'
      } else {
        type = 'update'
        const form = this.form
        this.form = {
          'data.status': form.status,
          ...form
        }
        delete this.form.status
      }
      const res = await postSetData(type, this.form)
      res ? this.getQueryData() : this.$message('请重试')
      this.centerDialogVisible = false
    },
    // 修改数据
    postEditData(row) {
      this.titleName = '修改'
      const { bizId, code, name, modelBizId, status } = row
      this.form = {
        dataCode: 'gender',
        'data.bizId': bizId,
        bizId: modelBizId,
        code,
        name,
        status
      }
      this.centerDialogVisible = true
    },
    // 修改状态
    onStatusChange(row) {
      this.titleName = '修改'
      const { bizId, code, name, modelBizId, status } = row
      this.form = {
        dataCode: 'gender',
        'data.bizId': bizId,
        bizId: modelBizId,
        code,
        name,
        status
      }
      this.onSetData()
    },
    // 删除数据
    async postDeleteData(row) {
      const data = {
        'data.bizId': row.bizId,
        bizId: row.modelBizId
      }
      const res = await postDeleteData(data)
      if (res) {
        this.getQueryData()
      }
    }
  },
  mounted() {
    this.getQueryData()
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
