<template>
  <div class="barrage-activity-page">
    <div class="buttongroup">
      <el-button @click="addClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" height="85%" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="group" label="弹幕组">
      </el-table-column>
      <el-table-column align="center" prop="name" label="内容">
      </el-table-column>
      <el-table-column align="center" prop="createdAt" label="创建时间">
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" icon="el-icon-edit" type="text" size="small"></el-button>
          <el-button @click="postDeleteData(scope.row)" type="text" icon="el-icon-delete" size="small"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>
    <!-- 添加角色 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" title="添加弹幕内容" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form ref="form" :rules="rules" label-width="80px" :model="form">
        <!--        <el-form-item label="类型" prop='category'>-->
        <!--          <el-select v-model="form.category" placeholder="选择类型">-->
        <!--            <el-option-->
        <!--              v-for="item in categoryData"-->
        <!--              :key="item.bizId"-->
        <!--              :label="item.label"-->
        <!--              :value="item.bizId">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item label="弹幕组" prop="group">
          <el-input v-model="form.group"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import rules from '@/utils/rules'
const pageSize = 10 // 每页的数量
const pageNumber = 1 // 当前页
export default {
  name: 'BarrageList',
  data() {
    return {
      rules,
      centerDialogVisible: false,
      // categoryData: [],
      form: {
        dataCode: 'barrage',
        group: '',
        name: ''
      },
      tableData: [{}],
      pageNumber: pageNumber,
      pageSize: pageSize,
      total: 0 // 总条数,
    }
  },
  methods: {
    addClick(row) {
      this.form = {
        dataCode: 'barrage',
        group: '',
        name: ''
      }
      this.centerDialogVisible = true
    },
    // 修改
    handleClick(row) {
      this.form = {
        dataCode: 'barrage',
        group: row.group,
        name: row.name,
        bizId: row.bizId
      }
      this.centerDialogVisible = true
    },
    // 查询数据
    async getQueryData() {
      const data = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      }
      const res = await this.getRequestShort(
        'data/metadata/barrage/pageable',
        data
      )
      if (res.status !== 200) return false
      res.pageModel.records.forEach(o => {
        o.status = o.status === 1 ? (o.status = true) : (o.status = false)
      })
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords
    },
    // 删除数据
    postDeleteData(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: row.bizId
          }
          const res = await this.postRequestShort(
            'data/metadata/delete/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '删除成功!'
          })
          await this.getQueryData()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页事件
    onCurrentChange(val) {
      this.pageNumber = val
      this.getQueryData()
    },
    // 设置数据
    onSetData() {
      this.$refs.form.validate(async flage => {
        if (flage) {
          let type = 'add'
          if (this.form.bizId) {
            type = 'update'
          }
          const data = { ...this.form }
          delete data.arrayDate
          const res = await this.postRequest(
            `data/metadata/${type}/command`,
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message(res.message)
            return false
          }
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          if (type === 'update') {
            this.centerDialogVisible = false
          }
          await this.getQueryData()
        }
      })
    },
    // 查询类型数据
    async getQueryCategoryData() {
      const data = {
        pageNumber: this.pageNumber,
        pageSize: pageSize,
        status: '-1'
      }
      const res = await this.getRequest('admin/category/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      // if (pageModel.records.length === 0) return false
      this.categoryData = pageModel.records.map(o => {
        return {
          bizId: o.bizId,
          label: o.name,
          value: o.bizId
        }
      })
    }
  },
  mounted() {
    this.getQueryData()
  }
}
</script>

<style lang="scss" scoped>
.barrage-activity-page {
  padding: 20px;
  box-sizing: border-box;
  .buttongroup {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
