<template>
  <div class="mission-plan-page">
    <div class="buttongroup">
      <el-button @click="addClick" type="primary" icon="el-icon-plus">添加</el-button>
      <!--      <el-button @click="updateClick('remind')" type="primary" icon="el-icon-plus">添加服务提醒</el-button>-->
      <!--      <el-button @click="updateClick('market')" type="primary" icon="el-icon-plus">添加营销短信</el-button>-->
    </div>
    <el-table :data="tableData" height="85%" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="name" label="服务名称">
      </el-table-column>
      <el-table-column align="center" prop="createdAt" label="创建时间">
      </el-table-column>
      <el-table-column align="center" prop="messageType" label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.messageType === 'remind'">提醒服务</span>
          <span v-if="scope.row.messageType === 'market'">营销活动</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="description" label="备注">
      </el-table-column>
      <el-table-column width="100px" align="center" label="状态">
        <template slot-scope="scope">
          <el-switch @change="onStatusChange(scope.row)" v-model="scope.row.status"></el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" icon="el-icon-edit" type="text" size="small"></el-button>
          <el-button @click="postDeleteData(scope.row)" type="text" icon="el-icon-delete" size="small"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
const pageSize = 10 // 每页的数量
const pageNumber = 1 // 当前页
export default {
  name: 'MissionPlan',
  data() {
    return {
      tableData: [],
      pageNumber: pageNumber,
      pageSize: pageSize,
      total: 0 // 总条数,
    }
  },
  methods: {
    addClick(row) {
      this.$store.commit('setMissionPlan', null)
      this.updateClick()
    },
    handleClick(row) {
      this.$store.commit('setMissionPlan', row)
      this.updateClick()
    },
    updateClick(type) {
      const menu = {
        path: '/shortmessage/send-settings',
        name: '任务计划',
        bizId: '/shortmessage/send-settings'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', menu)
      this.$router.push(menu.path)
      // this.$router.push(menu.path + `?type=${type}`)
    },
    // 查询数据
    async getQueryData() {
      // const data = {
      //   pageSize: this.pageSize,
      //   pageNumber: this.pageNumber
      // }
      // const res = await this.getRequestShort(
      //   'admin/message/template/settings/pageable',
      //   data
      // )
      // if (res.status !== 200) return false
      // res.pageModel.records.forEach(o => {
      //   o.status = o.status === 1 ? (o.status = true) : (o.status = false)
      // })
      const res = {
        pageModel: {
          pageNumber: 1,
          pageSize: 10,
          totalRecords: 6,
          totalPages: 1,
          sortField: null,
          order: null,
          records: [
            {
              createdAt: '2022-06-11 14:45:25',
              status: 1,
              name: '6.11营销活动测试',
              messageType: 'market',
              description: null
            },
            {
              createdAt: '2022-06-13 15:36:01',
              status: 1,
              name: '6.13营销活动测试1',
              messageType: 'market',
              description: null
            },
            {
              createdAt: '2022-06-14 08:52:26',
              status: 1,
              name: '6.13营销短信测试2',
              messageType: 'market',
              description: '12355'
            },
            {
              createdAt: '2022-06-14 08:58:10',
              status: 1,
              name: '6.14提醒短信测试',
              messageType: 'remind',
              description: null
            },
            {
              createdAt: '2022-06-14 11:33:21',
              status: 1,
              name: '6.14营销短信测试',
              messageType: 'market',
              description: null
            },
            {
              createdAt: '2022-06-14 11:42:03',
              status: 1,
              name: '6.14营销短信测试2',
              messageType: 'market',
              description: null
            }
          ],
          queryModel: null,
          entityModel: null
        }
      }
      res.pageModel.records.map(i => {
        i.status = i.status === 1
        return i
      })
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords
    },
    // 删除数据
    postDeleteData(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: row.bizId
          }
          const res = await this.postRequestShort(
            'admin/message/template/settings/delete/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '删除成功!'
          })
          this.getQueryData()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页事件
    onCurrentChange(val) {
      this.pageNumber = val
      this.getQueryData()
    }
  },
  mounted() {
    this.getQueryData()
  }
}
</script>

<style lang="scss" scoped>
.mission-plan-page {
  padding: 20px;
  box-sizing: border-box;
  .buttongroup {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
