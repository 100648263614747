<template>
  <!-- 零点订单查看 -->
  <div>
    <el-dialog title="订单详情信息" :close-on-click-modal="false" width="1300px" :visible.sync="elegantRoomVisible">
      <div v-if="details" class="order-details" ref="imageWrapper">
        <el-card el-card class="customer-details">
          <h4 class="title">客户信息</h4>
          <el-row>
            <el-col :span="3">【客户姓名】</el-col>
            <el-col :span="4">
              {{ details.customerName }}
              <i class="el-icon-user-solid" :style="details.genderName === '先生' ? 'color: #00B7FF' : 'color: pink'"></i>
            </el-col>
            <el-col :span="9">{{ details.customerPhoneNumber }}</el-col>
          </el-row>
        </el-card>
        <el-card el-card class="customer-details">
          <h4 class="title">预定信息</h4>
          <el-row>
            <el-col :span="9">【雅间信息】{{ details.roomName }}</el-col>
            <el-col :span="7">【预计人数】{{ details.estimatedPersonNum }}人</el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              【到店日期】
              <span>{{ details.reserveDate }}</span>
              <span style="margin-left: 10px">{{ details.reserveTimeName }}</span>
            </el-col>
            <!--            {{details.arrivedDatenl}}-->
            <el-col :span="7">【就餐金额】{{ details.reserveMoney ? details.reserveMoney : details.actualAmount }}元</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              【到店时间】
              <span>{{ details.arrivedTimeName }}</span>
            </el-col>
          </el-row>
        </el-card>
        <el-card el-card class="customer-details">
          <h4 class="title">其他信息</h4>
          <el-row>
            <el-col :span="24">【关联雅间】{{ details.referRoomNames && details.referRoomNames.length > 0 ? details.referRoomNames : '' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="9">【客户经理】{{ details.customerManager }}</el-col>
            <el-col :span="7">【客户来源】{{ details.customerSources }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="24">【详情备注】{{ details.remark }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="9">【预定时间】{{ details.createTime }}</el-col>
            <el-col :span="7">【订餐类型】 {{ details.tagName }}</el-col>
          </el-row>
        </el-card>
      </div>
      <div class="btn no-print" v-if="displayBtn">
        <el-button @click="retiredRoom" type="primary">退定</el-button>
        <!-- <el-button @click="revise" type="primary">修改</el-button> -->
        <el-button @click="dialogVisible = true" type="primary">换台</el-button>
        <el-button :disabled="details.orderStatus !== 3 || (details.orderStatus === 3 && details.returnVisit === 1)" @click="retrunVisit" type="primary">回访</el-button>
        <el-button :disabled="details.orderStatus !== 7" @click="overturn" type="primary">翻台</el-button>
        <el-button :disabled="details.orderStatus !== 3" @click="takeSeat" type="primary">入座</el-button>
        <el-button @click="editNotes" type="success" icon="el-icon-edit">修改备注</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onPrint" style="color: #409EFF" type="text">导出</el-button>
        <el-button type="primary" @click="elegantRoomVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="换台" :close-on-click-modal="false" width="1300px" :visible.sync="dialogVisible">
      <el-card class="box-card ballroom">
        <div class="roombox" v-for="item in roomList" :key="item.dinnerZoneBizId">
          <div v-if="item.datass.length > 0">
            <h3>{{ item.dinnerZoneName }}</h3>
            <div class="roomlist">
              <div class="room-iten" v-for="j in item.datass" :key="j.id">
                <div @click="activeIdClick(j.id)" class="info" :style="activeId === j.id ? 'background: #15D37D; color: #ffffff' : false">
                  <div style="font-size: 14px; overflow: hidden; height: 20px;">{{ j.name }}</div>
                  <div style="font-size:14px; overflow: hidden; height: 20px;">{{ j.rangePersonName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeChannel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改备注" :close-on-click-modal="false" width="700px" :visible.sync="descVisible">
      <el-form :model="form2" ref="form2" label-width="100px" class="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="到店时间：" prop="reserveTimeType">
              <el-select style="width: 150px" v-model="form2.arrivedTime" placeholder="选择时间">
                <el-option v-for="item in arrivedTimeList" :key="item.value" :label="item.label" :value="item.bizId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="就餐金额：" prop="reserveMoney">
              <el-input style="width:150px" maxlength="9" v-model="form2.reserveMoney" placeholder=""></el-input>
              <span style="margin-left:10px">元</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注：" prop="reserveTimeType">
              <el-input v-model="form2.description" maxlength="255" placeholder="修改备注" type="textarea" :rows="4"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="descVisible = false">取 消</el-button>
        <el-button type="primary" @click="ChangeRemark">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="确定翻台吗？" :close-on-click-modal="false" width="600px" :visible.sync="fantaiVisible">
      <el-form :model="form2" ref="form2" label-width="100px" class="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户姓名：" prop="title">
              {{ form2.customerName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户电话：" prop="title">
              {{ form2.customerPhoneNumber }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="预定雅间：" prop="title">
              {{ form2.roomName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计人数：" prop="title">
              {{ form2.estimatedPersonNum }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="实到人数：" prop="title">
              <el-input style="width:150px" maxlength="3" v-model="form2.realPersonNum" placeholder=""></el-input>
              <span style="margin-left:10px">人</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="就餐费用：" prop="title">
              <el-input style="width:150px" maxlength="9" v-model="form2.actualAmount" placeholder=""></el-input>
              <span style="margin-left:10px">元</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fantaiVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMoney">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import sloarToLunar from '@/utils/rili'
// import zeropointData from "./details/zerpointDeat"
let remainingRoom = {}
export default {
  name: 'ZeropointDetails',
  data() {
    return {
      data: {},
      activeId: '',
      elegantRoomVisible: false,
      dialogVisible: false,
      details: {},
      roomList: [],
      arrivedTimeList: [],
      displayBtn: false,
      descVisible: false,
      fantaiVisible: false,
      form2: {
        roomName: '',
        description: '',
        id: '',
        hotelId: '',
        customerName: '',
        customerPhoneNumber: '',
        actualAmount: '', // 翻台用
        reserveMoney: '', // 修改订单用
        realPersonNum: '',
        estimatedPersonNum: '',
        arrivedTime: '',
        arrivedTimeName: ''
      }
    }
  },
  inject: ['initAria'],
  methods: {
    // 选择退定雅间
    activeIdClick(bizId) {
      this.activeId = bizId
    },
    // 换台
    changeChannel() {
      const data = {
        toRoomBizId: this.activeId,
        bizId: this.data.id
      }
      this.post('business/dinner/changeStation', data).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            message: '换台成功',
            type: 'success'
          })
          this.dialogVisible = false
          this.initAria()
          this.elegantRoomVisible = false
        } else {
          this.$message(res.msg)
        }
      })
    },
    // 退定方法
    retiredRoom() {
      this.$confirm('确定退定吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          this.putRequest('business/dinner/unsubscribe/' + this.data.id).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '退定成功!'
              })
              this.elegantRoomVisible = false
              this.initAria()
            } else {
              this.$message({
                duration: 10000,
                type: 'error',
                message: res.msg
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消退定'
          })
        })
    },
    // 执行翻台
    addMoney() {
      // 验证人数和钱
      if (this.form2.realPersonNum != '') {
        // 判定数字 /^[0-9]*$/
        const reg = /^[0-9]*$/
        if (reg.test(this.form2.realPersonNum) == false) {
          this.$message('请输入正确的人数!')
          return
        }
      }
      if (this.form2.actualAmount != '') {
        const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
        if (reg.test(this.form2.actualAmount) == false) {
          this.$message('请输入正确的金额!')
          return
        }
      }
      // 翻台
      this.post('business/dinner/turnTable', this.form2).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            type: 'success',
            message: '已翻台!'
          })
          this.fantaiVisible = false
          this.elegantRoomVisible = false
          this.initAria()
        } else {
          this.$message(res.msg)
        }
      })
    },
    // 翻台
    overturn() {
      this.form2 = {
        id: this.data.id,
        roomName: this.data.room,
        description: this.data.remark,
        hotelId: this.data.hotelId,
        customerName: this.data.customerName,
        customerPhoneNumber: this.data.customerPhoneNumber,
        estimatedPersonNum: this.data.estimatedPersonNum,
        actualAmount: this.data.actualAmount == undefined ? this.data.reserveMoney : this.data.actualAmount,
        realPersonNum: this.data.estimatedPersonNum + '',
        arrivedTime: this.data.arrivedTime,
        arrivedTimeName: this.data.arrivedTimeName
      }
      this.fantaiVisible = true
    },
    retrunVisit() {
      this.$confirm('是否更新客户回访状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          const param = {
            id: this.data.id
          }
          this.post('business/dinner/visit', param).then(res => {
            if (res.code === 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '回访状态已更新!'
              })
              this.initAria()
            }
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消回访'
          })
        })
    },
    // 修改
    revise() {
      const data = {
        ...this.data,
        remainingRoom
      }
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '零点预定')
      // 添加tags标签
      const submenu = {
        path: '/zeropoint/reserve',
        name: '零点预定',
        bizId: '/zeropoint/reserve'
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$store.commit('setZeropointReservation', data)
      this.$router.push('/zeropoint/reserve')
    },
    // 入座
    takeSeat() {
      this.$confirm('确定顾客已入座吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(async () => {
          this.putRequest('business/dinner/inseta/' + this.data.id).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '客户已入座!'
              })
              this.elegantRoomVisible = false
              this.initAria()
            } else {
              this.$message(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消入座'
          })
        })
    },
    // 打印
    onPrint() {
      this.$print(this.$refs.imageWrapper)
      this.elegantRoomVisible = false
    },
    // 查询零点详情
    getDetails(row, roomList, times, remainingRooms) {
      // console.log('times:' + JSON.stringify(times))

      // -- 类型
      remainingRoom = remainingRooms
      this.details = {}
      this.elegantRoomVisible = true
      this.getRequest('business/dinner/getDinnerReservedDetail', {
        id: row.id
      }).then(res => {
        if (res.code !== 200) {
          return false
        }
        // console.log('查询结果：' + JSON.stringify(res.data))
        this.data = res.data
        const records = res.data
        this.details = {
          customerName: records.customerName || '',
          customerPhoneNumber: records.customerPhoneNumber || '',
          genderName: records.genderName || '',
          roomName: records.room || '',
          remark: records.remark || '',
          orderStatusName: records.orderStatusName || '',
          reserveTimeName: records.reserveTimeName || '',
          estimatedPersonNum: records.estimatedPersonNum || '',
          arrivedDate: records.arrivedDate,
          arrivedTime: records.arrivedTime,
          arrivedTimeName: records.arrivedTimeName,
          referRoomNames: '',
          reserveDate: records.reserveDate,
          orderStatus: records.orderStatus,
          referRoomBizIds: records.referRoomBizIds || [],
          createTime: records.createTime,
          customerManager: records.customerManager || '',
          customerSources: records.customerSources || '',
          tagName: records.tagName || '',
          reserveMoney: records.reserveMoney || '',
          returnVisit: records.returnVisit || ''
        }
        console.log(this.details);
        this.details.referRoomBizIds.push(records.roomId)
        const date = records.reserveDate.split('-')
        const date1 = sloarToLunar(date[0], date[1], date[2])
        this.details.reserveDatenl = date1.lunarYear + '年' + date1.lunarMonth + '月' + date1.lunarDay
        this.details.referRoomNames = []

        if (records.referList && records.referList.length > 0) {
          for (let i = 0; i < records.referList.length; i++) {
            this.details.referRoomNames.push(records.referList[i].room)
          }
          this.details.referRoomNames = this.details.referRoomNames.join(' / ')
        }
        // if (records.customerSources) {
        //   const customerSources = records.customerSources.filter(i => {
        //     return i.selected === 1
        //   })
        //   customerSources.length > 0
        //     ? (this.details.customerSources = customerSources[0].name)
        //     : (this.details.customerSources = '')
        // }
        // if (records.customerManagers) {
        //   const customerManagers = records.customerManagers.filter(i => {
        //     return i.selected === 1
        //   })
        //   customerManagers.length > 0
        //     ? (this.details.customerManagers = customerManagers[0].name)
        //     : (this.details.customerManagers = '')
        // }
        roomList.forEach(o => {
          o.datass = []
          if (o.datas && o.datas.length > 0) {
            o.datas.forEach(m => {
              if (!remainingRoom[records.reserveTimeType].includes(m.id)) {
                o.datass.push(m)
              }
            })
          }
        })
        this.roomList = roomList
        let newDate
        if (this.details.reserveTimeName === '中午') {
          this.getQueryData(1)
          newDate = new Date(this.details.reserveDate + ' ' + times.jzHoursMonth).getTime()
        } else {
          this.getQueryData(2)
          newDate = new Date(this.details.reserveDate + ' ' + times.jzHoursMonthNight).getTime()
          if (times.dinnerNightDay === 2) {
            newDate = newDate + 1000 * 60 * 60 * 24
          }
        }
        this.displayBtn = false
        if (newDate > Date.now()) {
          this.displayBtn = true
        }
      })
    },
    editNotes() {
      // console.log('this.data:' + JSON.stringify(this.data))
      this.form2 = {
        id: this.data.id,
        roomName: this.data.room,
        description: this.data.remark,
        hotelId: this.data.hotelId,
        customerName: this.data.customerName,
        customerPhoneNumber: this.data.customerPhoneNumber,
        reserveMoney: this.data.reserveMoney == undefined ? '' : this.data.reserveMoney,
        arrivedTime: this.data.arrivedTimeId,
        arrivedTimeName: this.data.arrivedTimeName
      }
      // console.log(this.form2)
      this.descVisible = true
    },
    ChangeRemark() {
      // 获取arrivedTimeName
      for (var i = 0; i < this.arrivedTimeList.length; i++) {
        if (this.form2.arrivedTime == this.arrivedTimeList[i].bizId) {
          this.form2.arrivedTimeName = this.arrivedTimeList[i].label
          break
        }
      }
      if (this.form2.arrivedTimeName == '') {
        this.$message('请选择预计到店时间!')
        return
      }
      if (this.form2.reserveMoney != '') {
        const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
        if (reg.test(this.form2.reserveMoney) == false) {
          this.$message('请输入正确的金额!')
          return
        }
      }
      console.log('提交：' + JSON.stringify(this.form2))
      // 修改备注
      this.post('business/dinner/editNotes', this.form2).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            type: 'success',
            message: '修改成功!'
          })
          this.descVisible = false
          this.initAria()
          this.elegantRoomVisible = false
        } else {
          this.$message('修改失败!')
        }
      })
    },
    // 查询到店时间
    getQueryData(type) {
      this.getRequest('dinner-metamodel/getAll').then(res => {
        // console.log('查询到店时间：' + JSON.stringify(res))
        if (res.code !== 200) {
          return false
        }
        var rc = ''
        if (type === 1) {
          rc = res.data.dinnerNoonArriveTimeQuantum
        } else {
          rc = res.data.dinnerNightArriveTimeQuantum
        }
        this.arrivedTimeList = rc.map(o => {
          return {
            bizId: o.id,
            label: o.name,
            value: o.name
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details {
  height: auto;
  .customer-details {
    height: auto;
    margin-bottom: 20px;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
      background: #f7f6f6;
    }
  }
  .reserved-details {
    height: auto;
    .title {
      padding: 2px 10px;
      background: #f7f6f6;
    }
    .box-card {
      border-radius: 5px;
      padding: 0px 5px;
      .box-card-img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 5px;
      }
    }
  }
}
.btn {
  width: 100%;
  padding: 10px 0;
  background: #f9f9f9;
  margin: 20px auto;
  display: flex;
  justify-content: space-evenly;
  button {
    padding: 10px 40px;
  }
}
.ballroom {
  width: 100%;
  overflow: auto;
  border-radius: 5px;
  padding: 0 20px;
  .roombox {
    margin-bottom: 20px;
    h3 {
      margin-bottom: 10px;
      padding-bottom: 5px;
      padding-left: 5px;
      font-size: 16px;
      border-bottom: 1px solid #eeeeeeee;
    }
    .roomlist {
      display: flex;
      flex-wrap: wrap;
      justify-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .room-iten {
        width: 80px;
        height: 50px;
        margin-right: 20px;
        border-radius: 3px;
        position: relative;
        border: 1px solid #eee;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        overflow: hidden;
        .info {
          width: 100%;
          margin: 0 auto;
          height: 50px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .info:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
