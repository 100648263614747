<template>
  <div class="send-list-page">
    <div class="list">
      <div class="list-item">
        <h3>短信充值账户</h3>
        <div class="item-box">
          <h4>请将充值金额转账到以下账户</h4>
          <div style="color: #0b6ad8;">
            <div>企业名称：北京域马科技有限公司</div>
            <div>账户号码：0200004609200572349</div>
            <div>开户银行：中国工商银行股份有限公司北京公主坟支行</div>
          </div>
        </div>
      </div>
      <div class="list-item">
        <h3>短信充值套餐</h3>
        <div class="item-box">
          <div>
            <div>特惠充值</div>
            <div>充值500元</div>
            <div>到账条数：6250条</div>
            <div>每条金额：0.08元</div>
          </div>
        </div>
      </div>
      <div class="list-item">
        <h3>联系客服<span style="color: red">(打款后请及时告知客服)</span></h3>
        <div class="item-box item-box3">
          <div>
            <img src="../../assets/images/ewm.png" alt="">
            <div class="text">客服二维码</div>
          </div>
          <div>
            <div class="phone">400-9988-364</div>
            <div class="text">客服热线</div>
          </div>
        </div>
      </div>
      <div class="list-item">
        <h3>短信充值记录</h3>
        <div class="item-box">
          <div v-if="messageList && messageList.length > 0">
            <div v-for="item in messageList" :key="item.bizId">充值{{item.amount}}元，购买{{item.totalRecords}}条</div>
          </div>
        </div>
      </div>
    </div>
    <div class="top">当前短信剩余条数：{{ totalRemaining }}条 <span style="margin: 0 10px;">/</span>当前已消费短信数：{{ usedRecords }}条</div>
    <el-table height="50%" :data="tableData" style="width: 100%" ref="multipleTable" border>
      <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
      <el-table-column align="center" prop="categoryName" label="短信类型">
      </el-table-column>
      <el-table-column align="center" prop="shortNum" label="发送条数">
      </el-table-column>
      <el-table-column align="center" prop="sendDt" label="发送日期">
      </el-table-column>
      <el-table-column align="center" prop="customerName" label="接收人">
      </el-table-column>
      <el-table-column width="800px" align="center" prop="sendContent" label="发送内容">
      </el-table-column>
      <el-table-column align="center" prop="phoneNumber" label="手机号">
      </el-table-column>
      <el-table-column align="center" width="100px" label="状态">
        <template slot-scope="scope">
          {{scope.row.status === 1 ? '成功' : '失败'}}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SendList',
  data() {
    return {
      tableData: [],
      messageList: [],
      total: 0, // 总条数,
      totalRecords: 0, // 总短信条数
      usedRecords: 0, // 使用条数
      totalRemaining: 0, // 剩余条数
      search: {
        pageNumber: 1,
        pageSize: 10, // 一页要显示的条数
        hotelId: this.$store.state.hotellist[0].bizId,
        category: 'notlogin',
        phoneNumber: '',
        startDate: '',
        endDate: '',
        customerSource: '',
        customerManagerBizId: ''
      }
    }
  },
  methods: {
    // 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    // 查询短信记录
    getQueryData() {
      this.getRequest('message/getPage', this.search).then(res => {
        if (res.code == 200) {
          //   console.log('短信记录：' + JSON.stringify(res))
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      })
    },
    // 查询充值记录
    getQueryMessage() {
      this.messageList = []
      this.getRequest('account-recharge/getList', {
        hotelId: window.localStorage.getItem('bizId')
      }).then(res => {
        //  console.log('充值记录：' + JSON.stringify(res))
        if (res.code == 200) {
          res.data.forEach(o => {
            o.status = o.status === 1 ? (o.status = true) : (o.status = false)
          })
          if (res.data && res.data.length > 0) {
            res.data.forEach(o => {
              this.totalRecords += o.totalRecords
              this.usedRecords += o.usedRecords
            })
            this.messageList = res.data
            this.totalRemaining = this.totalRecords - this.usedRecords
          }
        }
      })
    }
  },
  created() {
    this.getQueryData()
    this.getQueryMessage()
  }
}
</script>

<style lang="scss" scoped>
.send-list-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  .list {
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: space-between;
    .list-item {
      width: 24%;
    }
    h3 {
      text-align: center;
    }
    .item-box {
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 15px;
      box-sizing: border-box;
      padding: 20px;
      height: 200px;
    }
    .item-box3 {
      display: flex;
      justify-content: space-evenly;
      img {
        width: 140px;
        height: 140px;
      }
      .phone {
        width: 140px;
        height: 140px;
        text-align: center;
        line-height: 150px;
        border-radius: 5px;
        font-size: 20px;
      }
      .text {
        text-align: center;
      }
    }
    .list-item:nth-last-child(2) {
      width: 25%;
    }
  }
  .top {
    margin: 10px 0;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
