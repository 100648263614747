<template>
  <div class="Usergroupmanage-page">
    <el-card class="card">
      <div class="buttongroup">
        <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
        <el-button type="primary" icon="el-icon-close">批量删除</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" row-key="id" @selection-change="handleSelectionChange" border lazy :load="load" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="code" label="编码">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 1 ? "启用" : "禁用" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="description" label="描述">
        </el-table-column>
        <el-table-column align="center" prop="address" width="150px" label="操作">
          <template slot-scope="scope">
            <el-button icon="el-icon-edit" size="mini" type="primary" @click="postEditGroup(scope.row)"></el-button>
            <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteGroup(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </el-card>
    <!-- 添加用户组 -->
    <el-dialog :close-on-click-modal="false" class="updatecode" :title="title + '用户组'" :visible.sync="centerDialogVisible" width="30%" center>
      <el-form label-width="100px" :model="roleForm">
        <el-form-item label="用户组名称">
          <el-input v-model="roleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="roleForm.tabIndex"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用">
          <el-switch v-model="roleForm.status"></el-switch>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" v-model="roleForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddRole">{{ title }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const id = 111
const pageSize = 10 // 每页的数量
let pageNumber = 1 // 当前页
export default {
  name: 'UserGroupManage',
  data() {
    return {
      centerDialogVisible: false,
      title: '添加',
      roleForm: {
        bizid: null,
        code: '',
        name: '',
        tabIndex: '',
        status: true,
        description: ''
      },
      total: 0, // 总条数
      pageSize: pageSize, // 一页要显示的条数
      tableData: []
    }
  },
  methods: {
    load(tree, treeNode, resolve) {
      // console.log(tree, '2222', treeNode, '1111', resolve)
      setTimeout(() => {
        resolve([
          {
            id: tree.id + id + 100,
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          },
          {
            id: tree.id + id + 200,
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }
        ])
      }, 200)
    },
    //
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onAddClick() {
      this.title = '添加'
      this.roleForm = {
        code: '',
        name: '',
        tabIndex: '',
        status: true,
        description: ''
      }
      this.centerDialogVisible = true
    },
    // 查询所有角色
    async getGroupData() {
      // const data = {
      //   pageSize: pageSize,
      //   pageNumber: pageNumber
      // }
      // const res = await this.getRequest('ogz/group/pageable', data)
      // if (res.status !== 200) {
      //   return false
      // }

      const res = {
        pageModel: {
          pageNumber: 1,
          pageSize: 10,
          totalRecords: 1,
          totalPages: 0,
          sortField: null,
          order: null,
          records: [
            {
              name: '模拟数据',
              code: '编码模拟',
              status: 1,
              description: '鹿小乙模拟数据描述',
              tabIndex: 0
            }
          ],
          queryModel: null,
          entityModel: null
        }
      }
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords
    },
    async postAddRole() {
      this.roleForm.status
        ? (this.roleForm.status = 1)
        : (this.roleForm.status = 0)
      let url = ''
      if (this.title === '添加') {
        url = 'ogz/group/add/command'
      } else {
        url = 'ogz/group/update/command'
      }
      const res = await this.postRequest(url, this.qs.stringify(this.roleForm))
      if (res.status !== 200) {
        return false
      }
      this.$message({
        duration: 10000,
        type: 'success',
        message: this.title + '成功'
      })
      this.getGroupData()
      this.centerDialogVisible = false
    },
    postEditGroup(row) {
      this.title = '修改'
      // console.log('res', row)
      const { code, name, tabIndex, description, bizId } = row
      row.status === 1 ? (row.status = true) : (row.status = false)
      this.roleForm = {
        bizId,
        code,
        name,
        tabIndex,
        status: row.status,
        description
      }
      this.centerDialogVisible = true
    },
    postDeleteGroup(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: row.bizId
          }
          const res = await this.postRequest('ogz/role/delete/command', data)
          if (res.status !== 200) {
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '删除成功!'
          })
          this.getRoleData()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页事件
    onCurrentChange(val) {
      pageNumber = val
      this.getRoleData()
    }
  },
  mounted() {
    this.getGroupData()
  }
}
</script>

<style lang="scss" scoped>
.Usergroupmanage-page {
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
  .buttongroup {
    margin-bottom: 20px;
  }
}
</style>
