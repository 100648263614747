<template>
  <div class="rolemanage-page">
    <el-card class="right-card">
      <div class="buttongroup">
        <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" ref="multipleTable" border>
        <el-table-column type="index" label="序号" align="center" width="55">
        </el-table-column>
        <el-table-column align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="code" label="编码">
        </el-table-column>
        <!--        <el-table-column-->
        <!--          align="center"-->
        <!--          prop="status"-->
        <!--          label="状态">-->
        <!--          <template slot-scope="scope">-->
        <!--            {{scope.row.status === 1 ? '启用' : '禁用'}}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column align="center" prop="description" label="描述">
        </el-table-column>
        <el-table-column align="center" prop="address" width="100px" label="操作">
          <template slot-scope="scope">
            <!--            <el-button-->
            <!--              icon="el-icon-edit"-->
            <!--              size="mini"-->
            <!--              type="primary"-->
            <!--              @click="postEditRole(scope.row)"></el-button>-->
            <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteRole(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </el-card>
    <!-- 添加角色 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" title='添加常用语' :visible.sync="centerDialogVisible" width="30%" center>
      <el-form label-width="80px" :model="roleForm">
        <el-form-item label="编码">
          <el-input v-model="roleForm.code"></el-input>
        </el-form-item>
        <el-form-item label="中文名称">
          <el-input v-model="roleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="分组">
          <el-input v-model="roleForm.group"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用">
          <el-switch v-model="roleForm.status"></el-switch>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" v-model="roleForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddRole">添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const pageSize = 10 // 每页的数量
let pageNumber = 1 // 当前页
export default {
  name: 'CommonWords',
  data() {
    return {
      tableData: [],
      centerDialogVisible: false,
      roleForm: {
        dataCode: 'messageTemplate'
      },
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 查询所有角色
    async getRoleData() {
      const data = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        defaultApplicationName: 'lxy'
      }
      const res = await this.getRequestShort(
        'data/metadata/messageTemplate/pageable',
        data
      )
      if (res.status !== 200) {
        return false
      }
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords
    },
    onAddClick() {
      this.centerDialogVisible = true
    },
    async postAddRole() {
      const data = {
        ...this.roleForm
      }
      data.status ? (data.status = '1') : (data.status = '0')
      const res = await this.postRequestShort(
        'data/metadata/add/command',
        this.qs.stringify(data)
      )
      if (res.status !== 200) {
        return false
      }
      this.$message({
        duration: 10000,
        type: 'success',
        message: '添加成功'
      })
      this.getRoleData()
      this.centerDialogVisible = false
    },
    postDeleteRole(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = {
            bizId: row.bizId
          }
          const res = await this.postRequest(
            'data/metadata/delete/command',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            return false
          }
          this.$message({
            duration: 10000,
            type: 'success',
            message: '删除成功!'
          })
          this.getRoleData()
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页事件
    onCurrentChange(val) {
      pageNumber = val
      this.getRoleData()
    },
    // 获取机构信息
    async getOgzData() {
      const res = await this.getRequest('ogz/org/tree')
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.data = records
    }
  },
  mounted() {
    this.getRoleData()
    this.getOgzData()
  }
}
</script>

<style lang="scss" scoped>
.rolemanage-page {
  padding: 10px;
  box-sizing: border-box;
  .right-card {
    width: 100%;
    height: 100%;
    position: relative;
    .buttongroup {
      margin-bottom: 10px;
    }
    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}
</style>
