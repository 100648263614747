<!-- 此页已不用 -->
<template>
  <div>
    菜单管理
  </div>
</template>

<script>
export default {
  name: 'Menu'
}
</script>

<style scoped>
</style>
