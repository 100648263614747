<template>
  <el-card>
    <el-select style="width: 150px" @change="selectChange" v-model="type" placeholder="类型">
      <el-option v-for="item in categories" :key="item.value" :label="item.label" :value="item.bizId">
      </el-option>
    </el-select>
    <div class="set">
      <!--      <el-transfer :titles="['全选', '已选择']" v-model="value" :data="data">-->
      <!--        <el-button-->
      <!--          class="transfer-footer"-->
      <!--          slot="right-footer"-->
      <!--          style="margin-left: 50%; transform: translate(-50%)"-->
      <!--          size="small"-->
      <!--          type="primary"-->
      <!--          @click="onTemplateAdd"-->
      <!--        >保存</el-button>-->
      <!--      </el-transfer>-->
      <div class="checkbox">
        <div class="check">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group class="ckeckgroup" v-model="value" @change="handleCheckedCitiesChange">
            <el-checkbox class="ckeckgroup-item" v-for="item in data" :label="item.key" :key="item.key">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <el-button class="btn" @click="onTemplateAdd" type="primary">保存</el-button>
      </div>
      <img height="800px" src="../../../assets/images/pageSet.png" alt="">
    </div>
  </el-card>
</template>

<script>
import { getRequest, postRequest } from '@/utils/api'
let dataArray
export default {
  name: 'Page',
  data() {
    const generateData = _ => {
      const array = [
        ['性别', 'genderEnabled'],
        ['支付方式', 'paymentEnabled'],
        ['客户经理', 'customerManagerEnabled'],
        ['客户来源', 'customerSourceEnabled'],
        ['增值服务', 'providerServiceEnabled']
      ]
      const data = []
      array.forEach(i => {
        data.push({
          key: i[1],
          label: i[0]
        })
      })
      return data
    }
    return {
      data: generateData(),
      isIndeterminate: true,
      checkAll: true,
      categories: [],
      value: [],
      type: ''
    }
  },
  methods: {
    handleCheckAllChange(val) {
      this.value = val
      if (this.value) {
        this.value = this.data.map(o => {
          return o.key
        })
      } else {
        this.value = []
      }
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.value.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.value.length
    },
    selectChange() {
      this.value = []
      this.checkAll = false
      const zhi = dataArray.find(o => o.hallBizId === this.type)
      if (zhi === undefined) {
        return false
      }
      const {
        genders,
        payments,
        customerSources,
        customerManagers,
        providerServices
      } = zhi
      if (genders && genders.length > 0) {
        this.value.push('genderEnabled')
      }
      if (payments && payments.length > 0) {
        this.value.push('paymentEnabled')
      }
      if (customerSources && customerSources.length > 0) {
        this.value.push('customerSourceEnabled')
      }
      if (customerManagers && customerManagers.length > 0) {
        this.value.push('customerManagerEnabled')
      }
      if (providerServices && providerServices.length > 0) {
        this.value.push('providerServiceEnabled')
      }
      this.checkAll = this.data.length === this.value.length
    },
    // 获取初始化数据
    async getTemplate() {
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId
      }
      const res = await getRequest('admin/dinner/template/settings', data)
      if (res.status !== 200) {
        return false
      }
      dataArray = res.pageModel.records
    },
    // 修改或添加数据
    async onTemplateAdd() {
      const template = {}
      if (this.value.length > 0 && this.type !== '') {
        this.value.forEach(i => {
          template[i] = 1
        })
        const data = {
          hotelBizId: this.$store.state.hotellist[0].bizId,
          hallBizId: this.type,
          ...template
        }
        const res = await postRequest(
          'admin/dinner/template/add/command',
          this.qs.stringify(data)
        )
        if (res.status !== 200) {
          return false
        }
        this.$message({
          duration: 10000,
          type: 'success',
          message: '操作成功'
        })
      } else {
        this.$message('请选择数据和宴会厅')
      }
    },
    // 获取宴会厅类型
    async getBallroomType() {
      const res = await this.getRequest('hall/all', {
        hotelBizId: this.$store.state.hotellist[0].bizId
      })
      if (res.status !== 200) {
        return false
      }
      let { records } = res.pageModel
      records = records.map(o => {
        return { value: o.code, label: o.name, bizId: o.bizId }
      })
      this.categories = records
    }
  },
  mounted() {
    this.getBallroomType()
    this.getTemplate()
  }
}
</script>

<style lang="scss" scoped>
.set {
  display: flex;
  width: 80%;
  //margin: 0 auto;
  margin-top: -100px;
  justify-content: space-around;
  align-items: center;
  .checkbox {
    //border: 2px solid #c0ccda;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .check {
      width: 200px;
      height: auto;
      min-height: 400px;
      .ckeckgroup {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .ckeckgroup-item {
          margin-top: 10px;
        }
      }
    }
    .btn {
      float: right;
    }
  }
}
</style>
