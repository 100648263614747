<template>
  <div class="reservation-order-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item>
          <el-input style="width: 150px" v-model="ruleForm.name" placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 150px" v-model="ruleForm.phone" placeholder="请输入客户电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 150px" v-model="ruleForm.skr" placeholder="收款人"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="width: 300px;">
          <el-button @click="getReserveOrders" size="mini" type="primary">搜索</el-button>
          <el-button type="primary" style="margin: 0 30px; " size="mini" @click="exportExcel">导出</el-button>
          <el-button type="primary">发送到邮箱</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" id="selectTable" :data="tableData" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户姓名">
      </el-table-column>
      <el-table-column prop="customerPhoneNumber" align="center" label="客户电话">
      </el-table-column>
      <el-table-column prop="" align="center" label="收款时间">
      </el-table-column>
      <el-table-column prop="" align="center" label="收款人">
      </el-table-column>
      <el-table-column prop="" align="center" label="实收金额">
      </el-table-column>
      <el-table-column prop="" align="center" label="预收合计">
      </el-table-column>
      <el-table-column prop="createdAt" align="center" label="优惠金额">
      </el-table-column>
      <el-table-column prop="hallName" align="center" label="总金额">
      </el-table-column>
      <el-table-column prop="customerManagerName" label="客户经理" align="center">
      </el-table-column>
      <el-table-column align="center" label="详情">
        <template slot-scope="scope">
          <el-button size="mini" @click="getBallroomData(scope.row)" type="text">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import htmlToExcel from '@/utils/htmlToExcel'

const pageSize = 10 // 每页的数量
let pageNumber = 1 // 当前页
const data = {}
export default {
  name: 'DailyIncome',
  data() {
    return {
      loading: true,
      elegantRoomVisible: false,
      ruleForm: {
        // 查询表单
        hotelBizId: this.$store.state.hotellist[0].bizId,
        hallBizId: '',
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: ''
      },
      details: {},
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 导出
    exportExcel() {
      htmlToExcel.getExcel('#selectTable', '预收款统计')
    },
    // 查询预定订单数据
    async getReserveOrders() {
      this.tableData = []

      this.tableData = [
        {
          createdAt: '2022-03-26 11:22:36',
          customerName: '李',
          customerPhoneNumber: '15548870706',
          customerManagerName: '简然宴会'
        },
        {
          createdAt: '2022-03-26 11:25:17',

          customerName: '刘',

          customerPhoneNumber: '15548870706',

          customerManagerName: '简然宴会'
        },
        {
          createdAt: '2022-04-08 12:25:50',

          customerName: '李',

          customerPhoneNumber: '13666666666',

          customerManagerName: '应用市场测试'
        },
        {
          createdAt: '2022-04-13 17:49:32',

          customerName: '小小',

          customerPhoneNumber: '13545588588',

          customerManagerName: '应用市场测试'
        },
        {
          createdAt: '2022-04-26 10:05:33',

          customerName: '李',

          customerPhoneNumber: '15247525888',

          customerManagerName: '文静宴会'
        },
        {
          createdAt: '2022-04-26 10:06:00',

          customerName: '张',

          customerPhoneNumber: '15247586352',

          customerManagerName: '文静宴会'
        },
        {
          createdAt: '2022-04-28 09:57:29',

          customerName: '测试',

          customerPhoneNumber: '13588885888',

          customerManagerName: '应用市场测试'
        },
        {
          createdAt: '2022-05-07 19:07:39',

          customerName: '刘',

          customerPhoneNumber: '15548870706',

          customerManagerName: '简冉宴会'
        },
        {
          createdAt: '2022-05-07 19:08:05',

          customerName: '李',

          customerPhoneNumber: '15548870706',

          customerManagerName: '简冉宴会'
        },
        {
          createdAt: '2022-05-07 19:08:41',

          customerName: '李',

          customerPhoneNumber: '15548870706',

          customerManagerName: '简冉宴会'
        }
      ]
      this.loading = false
      // 总数
      this.total = 20
    },
    // 获取宴会厅类型
    async getBallroomType() {
      const records = [
        {
          bizId: '1506458829716455424',
          code: '1506458829594820608',
          name: '泰山厅'
        },
        {
          bizId: '1506458938390872064',
          code: '1506458938298597376',
          name: '衡山厅'
        },
        {
          bizId: '1506459335776010240',
          code: '1506459335675346944',
          name: '嵩山厅'
        },
        {
          bizId: '1506459436456083456',
          code: '1506459436363808768',
          name: '峨眉厅'
        },
        {
          bizId: '1620355331190161408',
          code: '1620355331173384192',
          name: '5厅'
        },
        {
          bizId: '1620355414291906560',
          code: '1620355414275129344',
          name: '6厅'
        },
        {
          bizId: '1620355459863019520',
          code: '1620355459850436608',
          name: '7厅'
        },
        {
          bizId: '1630479347313803264',
          code: '1630479347280248832',
          name: '1'
        },
        {
          bizId: '1630479378943049728',
          code: '1630479378473287680',
          name: '4'
        },
        {
          bizId: '1630479411604094976',
          code: '1630479411566346240',
          name: '7'
        },
        {
          bizId: '1630479462762020864',
          code: '1630479462732660736',
          name: '8'
        },
        {
          bizId: '1630479496081571840',
          code: '1630479496048017408',
          name: '9'
        },
        {
          bizId: '1630479528432238592',
          code: '1630479528398684160',
          name: '11'
        },
        {
          bizId: '1630479563437899776',
          code: '1630479563387568128',
          name: '45'
        },
        {
          bizId: '1630479644392161280',
          code: '1630479644354412544',
          name: '457'
        }
      ]
      this.ballroomList = records.map(o => {
        return {
          value: o.code,
          label: o.name,
          bizId: o.bizId,
          categories: o.categories
        }
      })
    },
    // 获取宴会厅客户经理
    async getCustomerManager() {
      // const res = await this.getRequest(
      //   'admin/metamodel/customerManager/query',
      //   {
      //     hotelBizId: this.$store.state.hotellist[0].bizId
      //   }
      // )
      // if (res.status !== 200) {
      //   return false
      // }
      const { records } = {
        pageNumber: 1,
        pageSize: 2147483647,
        totalRecords: 0,
        totalPages: 0,
        sortField: null,
        order: null,
        records: [],
        queryModel: null,
        entityModel: null
      }
      this.customerManagers = records.map(o => {
        return {
          value: o.code,
          label: o.name,
          bizId: o.bizId,
          categories: o.categories
        }
      })
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.categories = []
      const records = this.ballroomList.filter(i => {
        return i.bizId === e
      })
      const { categories } = records[0]
      this.categories = categories.map(o => {
        return { value: o.code, label: o.name, bizId: o.bizId }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      pageNumber = val
      this.getReserveOrders()
    },
    // 查看详情
    getBallroomData(row) {
      // console.log('查看详情')
    },
    // 转为预定
    onReserve() {
      this.$router.push({
        path: '/dinner/reserve',
        query: {
          ...data
        }
      })
    }
  },
  mounted() {
    this.getReserveOrders()
    this.getBallroomType()
    this.getCustomerManager()
    if (this.$route.query.q) {
      const { hallBizId, bizId } = this.$store.state.banquetReservation
      const row = {
        hallBizId,
        bizId
      }
      this.getBallroomData(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-order-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .order-details {
    height: auto;
    margin-top: -30px;
    margin-bottom: -20px;
    .customer-details {
      height: 120px;
      margin-bottom: 20px;
      .title {
        //margin: 5px 0;
        //margin-top: -10px;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
        background: #f7f6f6;
      }
    }
    .reserved-details {
      height: auto;
      .title {
        //margin: 5px 0;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
      }
      .box-card {
        //border: 1px solid #ccc;
        border-radius: 5px;
        //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 0px 5px;
        .box-card-img {
          width: 100px;
          height: 100px;
          margin-right: 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
