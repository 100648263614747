<!--零点--零点订单 【dinner_ok】 -->
<template>
  <div class="reservation-order-page">
    <el-form :model="search" ref="search">
      <div class="form-box">
        <el-form-item>
          <el-input v-model="search.name" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="search.roomId" placeholder="雅间">
            <el-option v-for="item in ballroomList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="search.orderStatus" placeholder="订单状态">
            <el-option v-for="item in orderStart" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- <el-input v-model="search.customerSources" placeholder="客户来源"></el-input> -->
          <el-select style="width: 150px" v-model="search.customerSources" placeholder="客户来源">
            <el-option v-for="item in customerSourcesArry" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="search.customerManagerId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="排序" style=" width: 260px;">
          <el-radio-group v-model="search.start">
            <el-radio label="createdAt">预定时间</el-radio>
            <el-radio label="reserveDate">到店日期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="search.startTime" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="search.endTime" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style=" width: 300px;">
          <el-button size="mini" @click="query()" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px;" size="mini" @click="exportExcel">导出</el-button>
          <el-button @click="resetForm" size="mini" type="info">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" :data="tableData" id="selectTable" height="80%" :default-sort="{ prop: 'date', order: 'descending' }" stripe style="width: 100%">
      <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
      <el-table-column prop="createTime" align="center" sortable label="预定时间">
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户姓名">
        <template slot-scope="scope">
          <span>{{ scope.row.customerName }}</span>
          <i class="el-icon-user-solid" :style="scope.row.genderName === '先生' ? 'color: #00B7FF' : 'color: pink'"></i>
        </template>
      </el-table-column>
      <el-table-column prop="customerPhoneNumber" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="reserveDate" align="center" sortable label="到店日期">
      </el-table-column>
      <el-table-column prop="arrivedTimeName" align="center" label="到店时间">
      </el-table-column>
      <el-table-column prop="room" align="center" label="雅间">
      </el-table-column>
      <el-table-column prop="reserveTableNumber" align="center" label="预计人数">
        <template slot-scope="scope">
          {{ scope.row.estimatedPersonNum }}人
        </template>
      </el-table-column>
      <el-table-column prop="orderStatusName" align="center" label="订单状态">
      </el-table-column>
      <el-table-column prop="customerSources" align="center" label="客户来源">
      </el-table-column>
      <el-table-column prop="customerManager" label="客户经理" align="center">
      </el-table-column>
      <el-table-column align="center" label="详情">
        <template slot-scope="scope">
          <el-button size="mini" @click="getBallroomData(scope.row)" type="text">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
    </el-pagination>
    <!-- 宴会详情信息-->
    <zeropoint-details ref="details"></zeropoint-details>
  </div>
</template>

<script>
import ZeropointDetails from '@/components/order/DinnerZeropointDetails'
import htmlToExcel from '@/utils/htmlToExcel'
import { export_json_to_excel } from '@/utils/Export2Excel'

let jzHoursMonth = ''
let jzHoursMonthNight = ''
let dinnerNightDay = 1
const remainingRoom = {}
export default {
  name: 'ReservationOrder',
  data() {
    const orderStart = [
      // { bizId: 1, label: '预留', value: 1 },
      // { bizId: 2, label: '取消预留', value: 2 },
      { bizId: 3, label: '已定', value: 3 },
      { bizId: 4, label: '退定', value: 4 },
      { bizId: 5, label: '完成', value: 5 },
      // { bizId: 6, label: '换台', value: 6 },
      { bizId: 7, label: '入座', value: 7 }
      // { bizId: 8, label: '锁台', value: 8 }
    ]
    return {
      list: [],
      orderStart,
      loading: false,
      search: {
        pageNumber: 1,
        pageSize: 10,
        // 查询表单
        hotelBizId: this.$store.state.hotellist[0].bizId,
        name: '',
        roomId: '',
        start: 'createdAt',
        customerSources: '',
        orderStatus: '',
        startTime: '',
        endTime: '',
        customerManagerId: ''
      },
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 雅间
      categories: [], // 订单状态
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      customerSourcesArry: []
    }
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 重置按钮查询
    resetForm() {
      this.search = {
        pageNumber: 1,
        pageSize: 10,
        // 查询表单
        hotelBizId: this.$store.state.hotellist[0].bizId,
        name: '',
        roomId: '',
        orderStatus: '',
        start: 'createdAt',
        customerSources: '',
        startTime: '',
        endTime: '',
        customerManagerId: ''
      }
      this.getReserveOrders()
    },
    // 直接查询
    query() {
      this.search.pageNumber = 1
      // console.log(this.search);
      this.getReserveOrders()
    },
    // 分页查询
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getReserveOrders()
    },
    // 导出数据--打开确认对话框
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
        })
        .catch(() => {})
    },
    // 导出数据-- 执行导出
    getExpportData: function() {
      var self = this
      // --页面查询条件
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.getRequest('dinner-reserve/findList', this.search)
        .then(res => {
          //  console.log('导出结果：' + JSON.stringify(res))
          // 关闭等待
          loading.close()
          // --查询完成
          if (res.code === 200 && res.data.length > 0) {
            require.ensure([], () => {
              const tHeader = [
                '序号',
                '预定时间',
                '客户姓名',
                '联系电话',
                '到店日期',
                '到店时间',
                '雅间',
                '预计人数',
                '订单状态',
                '客户来源',
                '客户经理',
                '备注'
              ]
              const filterVal = [
                'index',
                'createTime',
                'customerName',
                'customerPhoneNumber',
                'reserveDate',
                'arrivedTimeName',
                'room',
                'reserveTableNumber',
                'orderStatusName',
                'customerSources',
                'customerManager',
                'remark'
              ]
              var templist = res.data
              var i = 1
              templist.forEach(element => {
                element.index = i++
              })
              const data = self.formatJson(filterVal, templist)
              export_json_to_excel(tHeader, data, '零点订单')
            })
          } else {
            this.$message({
              message: '数据出錯，请联系管理员',
              duration: 2000,
              type: 'warning'
            })
          }
        })
        .catch(res => {
          // 关闭等待
          this.$message('请与管理员联系')
          loading.close()
        })
    },
    // 导出数据-- 处理数据
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 查询预定订单数据
    getReserveOrders(num = 0) {
      this.loading = true
      this.getRequest('dinner-reserve/findPageList', this.search)
        .then(res => {
          // console.log('列表查询结果：' + JSON.stringify(res))
          this.loading = false
          if (res.code == 200 && res.data && res.data.total > 0) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取宴会厅客户经理
    getCustomerManager() {
      // 2. 获取宴会厅客户经理
      this.getRequest('sys/user/loadCostumerManager').then(res => {
        if (res.code == 200) {
          this.customerManagers = res.data.map(o => {
            return { value: o.id, label: o.username, bizId: o.id }
          })
        }
      })
    },
    // 获取客户来源
    getCustomerSource() {
      // 获取客户来源
      this.getRequest('message-metadata/getCustomerSource').then(res => {
        // console.log('结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.customerSourcesArry = res.data
        }
      })
    },
    // 查看详情
    getBallroomData(row) {
      const times = {
        jzHoursMonth,
        dinnerNightDay,
        jzHoursMonthNight
      }
      this.$refs.details.getDetails(row, [], times, remainingRoom)
    },
    // 初始化销控表数据
    initAria(reserveDate) {
      // 暂不处理
    },
    getRoomList() {
      this.getRequest('dinner-room/loadDinnerRoomOnly').then(res => {
        if (res.code == 200) {
          this.ballroomList = res.data
        }
      })
    }
  },
  components: {
    ZeropointDetails
  },
  created() {
    // 加载当前酒店
    this.search.hotelBizId = this.$store.state.hotellist[0].bizId
    this.getCustomerSource()
  },
  provide() {
    return {
      initAria: this.initAria
    }
  },
  mounted() {
    // 查询列表
    this.getReserveOrders()
    // 查询客户经理
    this.getCustomerManager()
    // 查询雅间信息
    this.getRoomList()
  }
}
</script>

<style lang="scss" scoped>
.reservation-order-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
}

.form-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;

  .el-form-item {
    margin-bottom: 15px;
    margin-right: 20px;
  }
}

.pagination {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.order-details {
  height: auto;
  margin-top: -30px;
  margin-bottom: -20px;

  .customer-details {
    height: 120px;
    margin-bottom: 20px;
  }

  .title {
    //margin: 5px 0;
    //margin-top: -10px;
    padding: 2px 10px;
    background: #f7f6f6;
    //border: 1px solid #d4d4d4;
    background: #f7f6f6;
  }

  .reserved-details {
    height: auto;
  }

  .title {
    //margin: 5px 0;
    padding: 2px 10px;
    background: #f7f6f6;
    //border: 1px solid #d4d4d4;
  }

  .box-card {
    //border: 1px solid #ccc;
    border-radius: 5px;
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 0px 5px;

    .box-card-img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      border-radius: 5px;
    }
  }
}
</style>
