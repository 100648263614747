<template>
  <div>
    其他设置客户设置
  </div>
</template>

<script>
export default {
  name: 'Customer'
}
</script>

<style scoped>
</style>
