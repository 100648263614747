<template>
  <div class="order-placed-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.hallBizId" placeholder="宴会厅" @change="onBallroomChange">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="categorySelectedBizId">
          <el-select style="width: 150px" v-model="ruleForm.categorySelectedBizId" placeholder="类型">
            <el-option v-for="item in categories" :key="item.value" :label="item.name" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.status" placeholder="订单状态">
            <el-option label="已定" value="3"></el-option>
            <el-option label="退定" value="4"></el-option>
            <el-option label="完成" value="5"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item label="排序" style=" width: 260px;">
          <el-radio-group v-model="ruleForm.start">
            <el-radio label="createdAt">预留时间</el-radio>
            <el-radio label="banquetDate">宴会时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style=" width: 300px;">
          <el-button size="mini" @click="getReserveOrders" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px; " size="mini" @click="exportExcel">导出</el-button>
          <el-button @click="resetForm" size="mini" type="info">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" id="selectTable" :data="tableData" height="66vh" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="createdAt" sortable align="center" label="预定时间">
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户姓名">
      </el-table-column>
      <el-table-column prop="customerPhoneNumber" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="categoryName" align="center" label="宴会类型">
      </el-table-column>
      <el-table-column width="240px" prop="banquetDate" sortable align="center" label="会议时间">
      </el-table-column>
      <el-table-column prop="hallName" align="center" label="宴会厅">
      </el-table-column>
      <el-table-column prop="reserveTableNumber" align="center" label="人数">
        <template slot-scope="scope">
          {{scope.row.reserveTableNumber}}人
        </template>
      </el-table-column>
      <el-table-column prop="customerManagerName" label="客户经理" align="center">
      </el-table-column>
      <el-table-column align="center" label="状态" prop="orderStatusName">
      </el-table-column>
      <el-table-column align="center" label="详情">
        <template slot-scope="scope">
          <el-button size="mini" @click="getBallroomData(scope.row)" type="text">详情</el-button>
          <el-button size="mini" @click="unReserved(scope.row)" type="text">退定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
    </el-pagination>
    <MeetingDetails ref="child"></MeetingDetails>
  </div>
</template>

<script>
import htmlToExcel from '@/utils/htmlToExcel'
import MeetingDetails from '@/components/order/MeetingDetails'
import { export_json_to_excel } from '@/utils/Export2Excel'
const pageSize = 10 // 每页的数量
let pageNum = 1 // 当前页
// let data = {}
export default {
  name: 'OrderPlaced',
  data() {
    return {
      loading: false,
      elegantRoomVisible: false,
      ruleForm: {
        // 查询表单
        start: 'createdAt',
        hotelBizId: this.$store.state.hotellist[0].bizId,
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        dateFormat: 2
      },
      details: {},
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  components: {
    MeetingDetails
  },
  provide() {
    return {
      initAria: this.initAria
    }
  },
  methods: {
    initAria() {
      //  console.log('1')
    },
    // 重置按钮
    resetForm() {
      this.ruleForm = {
        start: 'createdAt',
        hotelBizId: this.$store.state.hotellist[0].bizId,
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        dateFormat: 2
      }
      this.getReserveOrders()
    },
    // 导出
    // exportExcel() {
    //   htmlToExcel.getExcel('#selectTable', '会议已定订单')
    // },
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
        })
        .catch(() => {})
    },
    // 导出数据-- 执行导出
    getExpportData: function() {
      var self = this
      // --页面查询条件
      const data = {
        reservedCategory: 'meeting',
        sortField: this.ruleForm.start,
        status: 2,
        pageSize: 100000,
        pageNumber: 1,
        ...this.ruleForm
      }
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.getRequest('banquet/reserve/orders', data)
        .then(res => {
          // 关闭等待
          loading.close()
          // --查询完成
          if (res.status === 200 && res.pageModel.records.length > 0) {
            require.ensure([], () => {
              const tHeader = [
                '序号',
                '预定时间',
                '客户姓名',
                '联系电话',
                '宴会类型',
                '会议时间',
                '宴会厅',
                '人数',
                '餐标',
                '押金方式',
                '担保人',
                '押金金额',
                '客户经理',
                '状态',
                '收费服务',
                '收费服务小计',
                '备注'
              ]
              const filterVal = [
                'index',
                'createdAt',
                'customerName',
                'customerPhoneNumber',
                'categoryName',
                'banquetDate',
                'hallName',
                'reserveTableNumber',
                'mealStandard',
                'paytype',
                'customerGuarantor',
                'amount',
                'customerManagerName',
                'orderStatusName',
                'provider',
                'price',
                'description'
              ]
              var templist = res.pageModel.records
              var i = 1
              templist.forEach(element => {
                element.index = i++
                // if (element.reserveTableNumber) {
                //   if (element.backupTableNumber !== undefined || element.backupTableNumber !== null || element.backupTableNumber !== '') {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌/备' + element.backupTableNumber + '桌'
                //   } else {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌'
                //   }
                // } else {
                //   element.reserveTableNumber = ''
                // }
                if (element.deposit != null) {
                  element.paytype = '定金'
                  element.amount = element.deposit.amount
                  element.payMode = element.deposit.payMode
                  element.receiptCode = element.deposit.receiptCode
                } else if (element.guarantor != null) {
                  element.paytype = '担保'
                  element.amount = element.guarantor.amount
                  element.customerGuarantor =
                    element.guarantor.customerGuarantor
                }
                var provider = ''
                var price = 0
                if (
                  element.providerServices != undefined &&
                  element.providerServices != null
                ) {
                  element.providerServices.forEach(pro => {
                    if (
                      pro.num != undefined &&
                      pro.num != null &&
                      pro.num > 0
                    ) {
                      provider =
                        provider +
                        pro.name +
                        ':' +
                        pro.price +
                        '*' +
                        pro.num +
                        ';'
                      price = price + pro.amount
                    }
                  })
                }
                element.provider = provider
                element.price = price
              })
              const data = self.formatJson(filterVal, templist)
              export_json_to_excel(tHeader, data, '宴会已定订单')
            })
          } else {
            this.$message({
              message: '没有有效数据!',
              duration: 2000,
              type: 'warning'
            })
          }
        })
        .catch(res => {
          // 关闭等待
          this.$message('请与管理员联系')
          loading.close()
        })
    },
    // 导出数据-- 处理数据
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 查询预定订单数据
    async getReserveOrders() {
      const data = {
        reservedCategory: 'meeting',
        sortField: this.ruleForm.start,
        status: 2,
        pageSize: pageSize,
        pageNumber: pageNum,
        ...this.ruleForm
      }
      const res = await this.getRequest('banquet/reserve/orders', data)
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      this.tableData = pageModel.records
      this.loading = false
      this.total = pageModel.totalRecords
    },
    // 获取宴会厅类型
    async getBallroomType() {
      const res = await this.getRequest('hall/all', {
        reservedCategory: 'meeting',
        hotelBizId: this.$store.state.hotellist[0].bizId
      })
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.ballroomList = records.map(o => {
        return {
          value: o.code,
          label: o.name,
          bizId: o.bizId,
          categories: o.categories
        }
      })
    },
    async getCategories() {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        status: '-1'
      }
      const res = await this.getRequest('admin/category/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      pageModel.records.forEach(o => {
        o.status === 1 ? (o.status = true) : (o.status = false)
      })
      let list = pageModel.records.filter(
        item => item.reserveDate.dateFormat === 2
      )
      list = list.filter(item => item.name != undefined && item.name != null)
      this.categories = list
    },
    // 获取宴会厅客户经理
    // async getCustomerManager () {
    //   const res = await this.getRequest('admin/metamodel/customerManager/query', {
    //     hotelBizId: this.$store.state.hotellist[0].bizId
    //   })
    //   if (res.status !== 200) {
    //     return false
    //   }
    //   const { records } = res.pageModel
    //   this.customerManagers = records.map(o => {
    //     return { value: o.code, label: o.name, bizId: o.bizId, categories: o.categories }
    //   })
    // },
    // 获取宴会厅客户经理
    async getCustomerManager() {
      const data = {
        pageSize: 100,
        pageNumber: 1,
        adminUser: 0
      }
      const res = await this.getRequest('ogz/user/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.customerManagers = records.map(o => {
        return { value: o.bizId, label: o.loginUsername, bizId: o.bizId }
      })
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.ruleForm.categorySelectedBizId = ''
      this.categories = []
      const records = this.ballroomList.filter(i => {
        return i.bizId === e
      })
      const { categories } = records[0]
      this.categories = categories.map(o => {
        return { value: o.code, name: o.name, label: o.name, bizId: o.bizId }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      pageNum = val
      this.getReserveOrders()
    },
    // 查看详情
    getBallroomData(row) {
      this.$refs.child.getDetails(row)
    },
    // 退订
    unReserved(row) {
      //  console.log('res', row)
      this.$confirm('您确定要退订吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { bizId, hallBizId, hotelBizId } = row
          const data = {
            hotelBizId,
            hallBizId,
            bizId,
            userCode: JSON.parse(localStorage.getItem('info')).phoneNumber
          }
          const res = await this.postRequest(
            'banquet/reserve/unreserved/process',
            this.qs.stringify(data)
          )
          if (res.status !== 200) {
            this.$message(res.message)
            return false
          }
          this.getReserveOrders()
          this.$message({
            duration: 10000,
            type: 'success',
            message: '退订成功!'
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消退订'
          })
        })
    }
  },
  mounted() {
    this.getReserveOrders()
    this.getBallroomType()
    this.getCategories()
    this.getCustomerManager()
  }
}
</script>

<style lang="scss" scoped>
.order-placed-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>
