<template>
  <div class="customer-information-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item style="width: 150px">
          <el-input v-model="ruleForm.phoneNumber" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManagerBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 250px; margin-left: 30px">
          <el-button size="mini" @click="getCustomerDate" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px; " size="mini" @click="exportExcel">导出</el-button>
          <el-button @click="resetForm" size="mini" type="info">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" :data="tableData" id="selectTable" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="createdAt" sortable align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="name" align="center" label="姓名">
      </el-table-column>
      <el-table-column prop="gender" align="center" label="性别">
      </el-table-column>
      <el-table-column prop="phoneNumber" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="totalOrderNumber" align="center" label="累计预定">
      </el-table-column>
      <el-table-column prop="totalSumAmount" align="center" label="有效预定">
      </el-table-column>
      <el-table-column prop="customerSource" align="center" label="客户来源">
      </el-table-column>
      <el-table-column prop="customerManager" label="客户经理" align="center" filter-placement="bottom-end">
      </el-table-column>
      <el-table-column type="expand" align="center" label="详情">
        <!--        <template slot-scope="props">-->
        <!--          <el-form class="table-expand">-->
        <!--            <el-form-item class="table-expand-item" label="单位:">-->
        <!--              <span>{{props.row.company || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item class="table-expand-item" label="职务:">-->
        <!--              <span>{{props.row.jobTitle || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item class="table-expand-item" label="生日:">-->
        <!--              <span>{{props.row.birthday || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item class="table-expand-item" label="住址:">-->
        <!--              <span>{{props.row.address || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item class="table-expand-item" label="喜好:">-->
        <!--              <span>{{props.row.taste || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item class="table-expand-item" label="忌口:">-->
        <!--              <span>{{props.row.dataCode || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item class="table-expand-item" label="备注:">-->
        <!--              <span>{{ props.row.description || '无'}}</span>-->
        <!--            </el-form-item>-->
        <!--          </el-form>-->
        <!--        </template>-->
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
    </el-pagination>
    <!-- 弹窗选择关联雅间-->
    <el-dialog :close-on-click-modal='false' title="订单详情信息" width="80%" top="10vh" :visible.sync="elegantRoomVisible">
      <div class="order-details">
        <el-card class="customer-details">
          <h4 class="title">客户信息</h4>
          <el-row>
            <el-col :span="3">【客户姓名】</el-col>
            <el-col :span="2">简冉 <i class="el-icon-edit"></i></el-col>
            <el-col :span="11">15590877890</el-col>
            <el-col :span="8">【客户来源】 自然进店</el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="5" style="padding-left: 10px">新郎: 阿布 15590877890</el-col>
            <el-col :span="11">新郎: 阿布 15590877890</el-col>
            <el-col :span="8">【客户经理】 张学聪</el-col>
          </el-row>
        </el-card>
        <el-card class="reserved-details">
          <h4 class="title">预定信息</h4>
          <el-row>
            <el-col :span="9">【宴会信息】 玫瑰厅/婚宴/28桌/浮动9桌</el-col>
            <el-col :span="7">【宴会时间】：2021年10月21日中午</el-col>
            <el-col :span="8">【关联雅间】：香格里拉、百年好合、花好月圆</el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="15">
              <h4 style="font-weight: normal">【餐标信息】2119元婚宴餐标</h4>
              <div class="box-card">
                <div>凉菜：焖黄鳝、豆鼓鲇鱼、锅烧鲇鱼、烀皮甲鱼、锅烧鲤鱼、抓炒鲤鱼、软炸里脊、软炸鸡</div>
                <div>热菜：鱼香肉丝、宫保鸡丁、清蒸八宝猪、江米酿鸭子、罐儿野鸡、罐儿鹌鹑、炝虾仁儿、
                  清蒸哈什蚂、烩鸭腰儿、烩鸭条儿、清拌鸭丝儿、黄心管儿、焖白鳝</div>
                <div>汤品：麻酥油卷儿、熘鲜蘑、熘鱼脯儿、熘鱼片儿、熘鱼肚儿</div>
                <div>糕点：醋熘肉片儿、熘白蘑、烩三鲜</div>
                <div>主食：盐水肘花儿</div>
              </div>
            </el-col>
            <el-col :span="1" style="opacity: 0">-</el-col>
            <el-col :span="8">
              <h4 style="font-weight: normal">【增值服务】 19800元</h4>
              <div class="box-card">
                <div>主持：2000元*1</div>
                <div>车队：500元*6</div>
                <div>舞台布置：4000元*1</div>
                <div>婚礼策划：900元*1</div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="24">【详情备注】 10月26日0-24时，31个省（自治区、直辖市）和新疆生产建设兵团报告新增确诊病例59例，其中本土病例50例。无新增死亡病例，无新增疑似病例。</el-col>
          </el-row>
          <el-row style="margin-top: 5px;">
            <el-col :span="24">
              <h4 style="font-weight: normal">【合同信息】</h4>
              <div class="box-card">
                <img class="box-card-img" src="../../assets/images/bg-login.jpg" v-for="o in 4" :key="o" />
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary">导 出</el-button>
        <el-button type="primary">转为预定</el-button>
        <el-button @click="elegantRoomVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import htmlToExcel from '@/utils/htmlToExcel'
import { export_json_to_excel } from '@/utils/Export2Excel'

const pageSize = 10 // 每页的数量
let pageNum = 1 // 当前页
export default {
  name: 'CustomerInformation',
  data() {
    return {
      loading: false,
      elegantRoomVisible: false,
      ruleForm: {
        // 查询表单
        hotelBizId: this.$store.state.hotellist[0].bizId,
        customerSearcher: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        source: 'meeting'
      },
      form: {},
      tableData: [],
      customerManagers: [],
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 重置按钮
    resetForm() {
      this.ruleForm = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        customerSearcher: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: '',
        source: 'meeting'
      }
      this.getCustomerDate()
    },
    // 导出
    // exportExcel() {
    //   htmlToExcel.getExcel('#selectTable', '会议客户信息')
    // },
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
        })
        .catch(() => {})
    },
    // 导出数据-- 执行导出
    getExpportData: function() {
      var self = this
      // --页面查询条件
      const data = {
        reservedCategory: 'meeting',
        pageSize: 1000000,
        pageNum: 1,
        ...this.ruleForm
      }
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.getRequest('customer/pages', data)
        .then(res => {
          // 关闭等待
          loading.close()
          // --查询完成
          if (res.status === 200 && res.pageModel.records.length > 0) {
            require.ensure([], () => {
              const tHeader = [
                '序号',
                '创建时间',
                '姓名',
                '性别',
                '联系电话',
                '累计预定',
                '有效预定',
                '客户来源',
                '客户经理'
              ]
              const filterVal = [
                'index',
                'createdAt',
                'name',
                'gender',
                'phoneNumber',
                'totalOrderNumber',
                'totalSumAmount',
                'customerSource',
                'customerManager'
              ]
              var templist = res.pageModel.records
              var i = 1
              templist.forEach(element => {
                element.index = i++
                // if (element.reserveTableNumber) {
                //   if (element.backupTableNumber !== undefined || element.backupTableNumber !== null || element.backupTableNumber !== '') {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌/备' + element.backupTableNumber + '桌'
                //   } else {
                //     element.reserveTableNumber = element.reserveTableNumber + '桌'
                //   }
                // } else {
                //   element.reserveTableNumber = ''
                // }
              })
              const data = self.formatJson(filterVal, templist)
              export_json_to_excel(tHeader, data, '会议客户信息')
            })
          } else {
            this.$message({
              message: '没有有效数据!',
              duration: 2000,
              type: 'warning'
            })
          }
        })
        .catch(res => {
          // 关闭等待
          this.$message('请与管理员联系')
          loading.close()
        })
    },
    // 导出数据-- 处理数据
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    handleEdit(index, row) {
      this.elegantRoomVisible = true
      // console.log(index, row)
    },
    formatter(row, column) {
      return row.address
    },
    async getCustomerDate() {
      this.tableData = []
      const data = {
        reservedCategory: 'meeting',
        pageSize: pageSize,
        pageNumber: pageNum,
        ...this.ruleForm
      }
      const res = await this.getRequest('customer/pages', data)
      if (res.status !== 200) {
        return false
      }
      this.tableData = res.pageModel.records
      this.loading = false
      this.total = res.pageModel.totalRecords
    },
    // 分页事件
    onCurrentChange(val) {
      pageNum = val
      this.getCustomerDate()
    },
    // // 获取宴会厅客户经理
    // async getCustomerManager () {
    //   const res = await this.getRequest('admin/metamodel/customerManager/query', {})
    //   if (res.status !== 200) {
    //     return false
    //   }
    //   const { records } = res.pageModel
    //   this.customerManagers = records.map(o => {
    //     return { value: o.code, label: o.name, bizId: o.bizId, categories: o.categories }
    //   })
    // }
    // 获取宴会厅客户经理
    async getCustomerManager() {
      const data = {
        pageSize: 100,
        pageNumber: 1,
        adminUser: 0
      }
      const res = await this.getRequest('ogz/user/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.customerManagers = records.map(o => {
        return { value: o.bizId, label: o.loginUsername, bizId: o.bizId }
      })
    }
  },
  mounted() {
    this.getCustomerDate()
    this.getCustomerManager()
  }
}
</script>

<style lang="scss" scoped>
.customer-information-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }
  .table-expand {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 5px 20px;
    .table-expand-item {
      width: 40%;
      margin: 0;
    }
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>
