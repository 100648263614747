<template>
  <div class="reservation-order-page">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="form-box">
        <el-form-item>
          <el-input v-model="ruleForm.name" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="ruleForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="categorySelectedBizId">
          <el-select style="width: 150px" v-model="ruleForm.categorySelectedBizId" placeholder="雅间">
            <el-option v-for="item in categories" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.hallBizId" placeholder="订单状态" @change="onBallroomChange">
            <el-option v-for="item in ballroomList" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="ruleForm.customerManagerSelectedBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style=" width: 300px;">
          <el-button size="mini" @click="getReserveOrders" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px;" size="mini" @click="onExport">导出</el-button>
        </el-form-item>
      </div>
      <!--      <div class="form-box">-->
      <!--        <el-form-item label="排序" style=" width: 260px;">-->
      <!--          <el-radio-group v-model="ruleForm.start">-->
      <!--            <el-radio label="createdAt">预留日期</el-radio>-->
      <!--            <el-radio label="banquetDate">宴会日期</el-radio>-->
      <!--          </el-radio-group>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item style=" width: 300px;">-->
      <!--          <el-button size="mini" @click="getReserveOrders" type="warning" >筛选</el-button>-->
      <!--          <el-button type="primary" style="margin: 0 30px;" size="mini" @click="onExport" >导出</el-button>-->
      <!--&lt;!&ndash;          <el-button @click="resetForm" size="mini" type="text">重置</el-button>&ndash;&gt;-->
      <!--        </el-form-item>-->
      <!--      </div>-->
    </el-form>
    <el-table v-loading="loading" :data="tableData" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="reserveDate" sortable align="center" label="预定时间">
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户姓名">
        <template slot-scope="scope">
          <span>{{scope.row.customerName}}</span>
          <i class="el-icon-user-solid" :style="scope.row.genderName === '男' ? 'color: #00B7FF' : 'color: pink'"></i>
        </template>
      </el-table-column>
      <el-table-column prop="customerPhoneNumber" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="arrivedDate" sortable align="center" label="到店时间">
      </el-table-column>
      <el-table-column prop="roomName" align="center" label="雅间">
      </el-table-column>
      <el-table-column prop="reserveTableNumber" align="center" label="预计人数">
        <template slot-scope="scope">
          {{scope.row.estimatedPersonNum}}人
        </template>
      </el-table-column>
      <el-table-column prop="referRoomNames" align="center" label="关联雅间">
      </el-table-column>
      <el-table-column prop="orderStatusName" align="center" label="订单状态">
      </el-table-column>
      <el-table-column prop="customerManagerName" label="客户经理" align="center">
      </el-table-column>
      <el-table-column align="center" label="详情">
        <template slot-scope="scope">
          <el-button size="mini" @click="getBallroomData(scope.row)" type="text">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
    </el-pagination>
    <!-- 宴会详情信息-->
    <zeropoint-details ref="details"></zeropoint-details>
  </div>
</template>

<script>
import ZeropointDetails from '@/components/order/ZeropointDetails'
const pageSize = 10 // 每页的数量
let pageNum = 1 // 当前页
export default {
  name: 'ReservationOrder',
  data() {
    return {
      loading: true,
      ruleForm: {
        // 查询表单
        start: 'createdAt',
        hotelBizId: this.$store.state.hotellist[0].bizId,
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: ''
      },
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 重置按钮
    resetForm() {
      this.ruleForm = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        categorySelectedBizId: '',
        startDate: '',
        endDate: '',
        customerManagerSelectedBizId: ''
      }
      this.getReserveOrders()
    },
    // 导出
    onExport() {
      this.$message('这么几条数据导出干嘛，过几天再导。')
    },
    // 筛选桌数条件
    filterTableNumber(value, row) {
      return parseInt(row.reserveTableNumber) > value
    },
    // 查询预定订单数据
    async getReserveOrders() {
      this.tableData = []
      const data = {
        sortField: this.ruleForm.start,
        pageSize: pageSize,
        pageNumber: pageNum,
        ...this.ruleForm
      }
      const res = await this.getRequest('dinner/reserve/orders', data)
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      this.tableData = pageModel.records
      this.loading = false
      this.total = pageModel.totalRecords
    },
    // 获取宴会厅客户经理
    // async getCustomerManager () {
    //   const res = await this.getRequest('admin/metamodel/customerManager/query', {
    //     hotelBizId: this.$store.state.hotellist[0].bizId
    //   })
    //   if (res.status !== 200) {
    //     return false
    //   }
    //   const { records } = res.pageModel
    //   this.customerManagers = records.map(o => {
    //     return { value: o.code, label: o.name, bizId: o.bizId, categories: o.categories }
    //   })
    // },
    // 获取宴会厅客户经理
    async getCustomerManager() {
      const data = {
        pageSize: 100,
        pageNumber: 1,
        adminUser: 0
      }
      const res = await this.getRequest('ogz/user/pageable', data)
      if (res.status !== 200) {
        return false
      }
      const { records } = res.pageModel
      this.customerManagers = records.map(o => {
        return { value: o.bizId, label: o.loginUsername, bizId: o.bizId }
      })
    },
    // 宴会厅改变事件
    onBallroomChange(e) {
      this.ruleForm.categorySelectedBizId = ''
      this.categories = []
      const records = this.ballroomList.filter(i => {
        return i.bizId === e
      })
      const { categories } = records[0]
      this.categories = categories.map(o => {
        return { value: o.code, label: o.name, bizId: o.bizId }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      pageNum = val
      this.getReserveOrders()
    },
    // 查看详情
    async getBallroomData(row) {
      this.$refs.details.getDetails(row)
    }
  },
  components: {
    ZeropointDetails
  },
  mounted() {
    this.getReserveOrders()
    this.getCustomerManager()
  }
}
</script>

<style lang="scss" scoped>
.reservation-order-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
  .form-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    .el-form-item {
      margin-bottom: 15px;
      margin-right: 20px;
    }
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .order-details {
    height: auto;
    margin-top: -30px;
    margin-bottom: -20px;
    .customer-details {
      height: 120px;
      margin-bottom: 20px;
      .title {
        //margin: 5px 0;
        //margin-top: -10px;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
        background: #f7f6f6;
      }
    }
    .reserved-details {
      height: auto;
      .title {
        //margin: 5px 0;
        padding: 2px 10px;
        background: #f7f6f6;
        //border: 1px solid #d4d4d4;
      }
      .box-card {
        //border: 1px solid #ccc;
        border-radius: 5px;
        //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 0px 5px;
        .box-card-img {
          width: 100px;
          height: 100px;
          margin-right: 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
