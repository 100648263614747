<template>
  <div>
    销售业绩统计
  </div>
</template>

<script>
export default {
  name: 'SalesPerformance'
}
</script>

<style scoped>
</style>
