<template>
  <div class="rolemanage-page">
    <el-card class="left-card">
      <el-tree ref="tree" :data="data" show-checkbox check-strictly icon-class="form" node-key="id" default-expand-all :expand-on-click-node="false" @check-change="threeCheck">
      </el-tree>
    </el-card>
    <el-card class="right-card">
      <el-table height="70vh" :data="tableData" @selection-change="handleSelectionChange" ref="multipleTable" border @cell-click="onCellClick">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column width="100px" align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column align="center" label="菜单">
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.menus" :key="index">
              <el-tag style="background: yellow; margin: 2px">{{item.name}}</el-tag>
              <el-tag style="background: #eee; margin: 2px" v-for="(m,indexs) in item.children" :key="indexs">
                {{m.name}}
              </el-tag>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </el-card>
    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="菜单配置" name="first">
          <div class="trees">
            <el-tree class="tree" ref="tree" :data="data2" :default-checked-keys="checkedArray" icon-class="form" :show-checkbox="true" node-key="bizId" :default-expand-all="true" :props="defaultProps" @check="selectCheck">
            </el-tree>
          </div>
          <el-button @click="onUserAuthorize" type="primary">修改</el-button>
        </el-tab-pane>
        <el-tab-pane label="数据配置" name="second">数据配置</el-tab-pane>
        <el-tab-pane label="工作流配置" name="third">工作流配置</el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
const pageSize = 10 // 每页的数量
let pageNumber = 1 // 当前页
let halfCheckedKeys = []
export default {
  name: 'Role',
  data() {
    return {
      data2: [],
      activeName: 'first',
      checkedArray: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      data: [],
      tableData: [],
      roleSelection: [],
      total: 0, // 总条数
      pageSize: pageSize // 一页要显示的条数
    }
  },
  methods: {
    // 刷新菜单
    async getMentTree() {
      // const data = {
      //   id: '',
      //   lazyLoad: !true
      // }
      // const res = await this.getRequest('menu/tree', data)
      // if (res.status !== 200) {
      //   return false
      // }
      const res = [
        {
          label: '宴会',
          children: [
            {
              label: '宴会销控表'
            },
            {
              label: '宴会预定'
            },
            {
              label: '宴会预留订单'
            },
            {
              label: '宴会已定订单'
            },
            {
              label: '宴会客户信息'
            }
          ]
        },
        {
          label: '零点',
          children: [
            {
              label: '零点销控表'
            },
            {
              label: '零点预定'
            },
            {
              label: '零点订单'
            },
            {
              label: '零点客户信息'
            }
          ]
        },
        {
          label: '会议',
          children: [
            {
              label: '会议销控表'
            },
            {
              label: '会议预留订单'
            },
            {
              label: '会议已定订单'
            },
            {
              label: '会议客户信息'
            },
            {
              label: '会议预定'
            }
          ]
        },
        {
          label: '数据',
          children: [
            {
              label: '宴会类型统计'
            },
            {
              label: '宴会渠道来源'
            },
            {
              label: '宴会厅使用率'
            },
            {
              label: '增值服务'
            },
            {
              label: '宴会日报'
            },
            {
              label: '宴会月报'
            },
            {
              label: '客户经理业绩排行'
            }
          ]
        },
        {
          label: '财务',
          children: [
            {
              label: '收入日报统计'
            },
            {
              label: '收入月报统计'
            },
            {
              label: '预收款统计'
            },
            {
              label: '担保人统计'
            },
            {
              label: '销售业绩统计'
            },
            {
              label: '收入统计'
            }
          ]
        },
        {
          label: '菜单',
          children: [
            {
              label: '菜单编辑'
            }
          ]
        },
        {
          label: '机构',
          children: [
            {
              label: '区域管理'
            },
            {
              label: '机构类型'
            },
            {
              label: '机构管理'
            },
            {
              label: '角色管理'
            },
            {
              label: '用户管理'
            },
            {
              label: '用户组管理'
            }
          ]
        },
        {
          label: '设置',
          children: [
            {
              label: '全局设置'
            },
            {
              label: '职位权限设置'
            },
            {
              label: '员工管理'
            },
            {
              label: '宴会类型'
            },
            {
              label: '餐标管理'
            },
            {
              label: '宴会厅管理'
            },
            {
              label: '收费服务'
            },
            {
              label: '餐区管理'
            },
            {
              label: '人数区间'
            },
            {
              label: '雅间管理'
            },
            {
              label: '客户来源'
            },
            {
              label: '头像密码设置'
            },
            {
              label: '客户设置'
            },
            {
              label: '酒店设置'
            },
            {
              label: '菜单管理'
            },
            {
              label: '支付项目'
            },
            {
              label: '时间设置'
            },
            {
              label: '担保人'
            },
            {
              label: '担保类型'
            },
            {
              label: '支付方式'
            },
            {
              label: '客户经理'
            },
            {
              label: '客户性别'
            },
            {
              label: '确认方式'
            },
            {
              label: '预定页面设置'
            },
            {
              label: '确认方式设置'
            },
            {
              label: '权限设置'
            },
            {
              label: '时间设置'
            },
            {
              label: '菜品管理'
            }
          ]
        },
        {
          label: '账户',
          children: [
            {
              label: '账户管理'
            },
            {
              label: '短信充值'
            },
            {
              label: '短信充值记录'
            },
            {
              label: '认证'
            }
          ]
        },
        {
          label: '权限',
          children: [
            {
              label: '角色管理'
            },
            {
              label: '用户组管理'
            }
          ]
        },
        {
          label: '短信',
          children: [
            {
              label: '短信模板配置'
            },
            {
              label: '添加模版'
            },
            {
              label: '常用语维护'
            },
            {
              label: '短信设置'
            },
            {
              label: '发送记录'
            },
            {
              label: '短信营销'
            }
          ]
        },
        {
          label: '应用',
          children: [
            {
              label: '全局应用'
            }
          ]
        },
        {
          label: '大屏互动',
          children: [
            {
              label: '活动列表'
            },
            {
              label: '弹幕列表'
            },
            {
              label: '关系列表'
            },
            {
              label: '创建活动'
            }
          ]
        },
        {
          label: '大屏幕',
          children: []
        }
      ]

      //   const { records } = res.pageModel
      //   this.data2 = records
      this.data2 = res
    },
    // 复选框选择事件
    selectCheck(data, type) {
      this.checkedArray = type.checkedKeys
      halfCheckedKeys = type.halfCheckedKeys
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    // 树单选
    threeCheck(data, checked, node) {
      if (checked) {
        this.$refs.tree.setCheckedNodes([data])
        this.$refs.tree.setCheckedNodes([data])
        this.form = Object.assign(this.form, data)
      }
    },
    // 选择角色
    handleSelectionChange(val) {
      this.roleSelection = val
    },
    // 查询所有角色
    async getRoleData() {
      // const data = {
      //   pageSize: pageSize,
      //   pageNumber: pageNumber
      // }
      // const res = await this.getRequest('ogz/role/pageable', data)
      // if (res.status !== 200) {
      //   return false
      // }

      const res = {
        pageModel: {
          pageNumber: 1,
          pageSize: 10,
          totalRecords: 207,
          totalPages: 21,
          sortField: null,
          order: null,
          records: [
            {
              name: '管理员',
              menus: [
                {
                  name: '菜单',
                  children: [
                    {
                      name: '菜单编辑'
                    }
                  ]
                },
                {
                  name: '权限',
                  children: [
                    {
                      name: '角色管理'
                    },
                    {
                      name: '用户组管理'
                    }
                  ]
                },
                {
                  name: '机构',
                  children: [
                    {
                      name: '机构管理'
                    },
                    {
                      name: '角色管理'
                    },
                    {
                      name: '用户管理'
                    },
                    {
                      name: '用户组管理'
                    }
                  ]
                },
                {
                  name: '账户',
                  children: [
                    {
                      name: '账户管理'
                    },
                    {
                      name: '短信充值'
                    }
                  ]
                }
              ]
            },
            {
              name: '酒店',
              menus: [
                {
                  name: '宴会',
                  children: [
                    {
                      name: '宴会销控表'
                    },
                    {
                      name: '宴会预定'
                    },
                    {
                      name: '宴会预留订单'
                    },
                    {
                      name: '宴会已定订单'
                    },
                    {
                      name: '宴会客户信息'
                    }
                  ]
                },
                {
                  name: '零点',
                  children: [
                    {
                      name: '零点销控表'
                    },
                    {
                      name: '零点预定'
                    },
                    {
                      name: '零点订单'
                    },
                    {
                      name: '零点客户信息'
                    }
                  ]
                },
                {
                  name: '会议',
                  children: [
                    {
                      name: '会议销控表'
                    },
                    {
                      name: '会议预留订单'
                    },
                    {
                      name: '会议已定订单'
                    },
                    {
                      name: '会议客户信息'
                    },
                    {
                      name: '会议预定'
                    }
                  ]
                },
                {
                  name: '数据',
                  children: [
                    {
                      name: '宴会类型统计'
                    },
                    {
                      name: '宴会渠道来源'
                    },
                    {
                      name: '宴会厅使用率'
                    },
                    {
                      name: '增值服务'
                    },
                    {
                      name: '宴会日报'
                    },
                    {
                      name: '宴会月报'
                    },
                    {
                      name: '客户经理业绩排行'
                    }
                  ]
                },
                {
                  name: '财务',
                  children: [
                    {
                      name: '收入日报统计'
                    },
                    {
                      name: '收入月报统计'
                    },
                    {
                      name: '预收款统计'
                    },
                    {
                      name: '担保人统计'
                    },
                    {
                      name: '销售业绩统计'
                    },
                    {
                      name: '收入统计'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                },
                {
                  name: '大屏幕',
                  children: []
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                },
                {
                  name: '大屏互动',
                  children: [
                    {
                      name: '活动列表'
                    },
                    {
                      name: '弹幕列表'
                    },
                    {
                      name: '创建活动'
                    }
                  ]
                }
              ]
            },
            {
              name: '运营管理员',
              menus: [
                {
                  name: '账户',
                  children: [
                    {
                      name: '账户管理'
                    },
                    {
                      name: '短信充值'
                    },
                    {
                      name: '短信充值记录'
                    }
                  ]
                }
              ]
            },
            {
              name: '角色-零点',
              menus: [
                {
                  name: '零点',
                  children: [
                    {
                      name: '零点销控表'
                    },
                    {
                      name: '零点预定'
                    },
                    {
                      name: '零点订单'
                    },
                    {
                      name: '零点客户信息'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '常用语维护'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                }
              ]
            },
            {
              name: '角色-宴会',
              menus: [
                {
                  name: '宴会',
                  children: [
                    {
                      name: '宴会销控表'
                    },
                    {
                      name: '宴会预定'
                    },
                    {
                      name: '宴会预留订单'
                    },
                    {
                      name: '宴会已定订单'
                    },
                    {
                      name: '宴会客户信息'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '常用语维护'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                },
                {
                  name: '财务',
                  children: [
                    {
                      name: '预收款统计'
                    },
                    {
                      name: '担保人统计'
                    }
                  ]
                }
              ]
            },
            {
              name: '角色-会议',
              menus: [
                {
                  name: '会议',
                  children: [
                    {
                      name: '会议销控表'
                    },
                    {
                      name: '会议预留订单'
                    },
                    {
                      name: '会议已定订单'
                    },
                    {
                      name: '会议客户信息'
                    },
                    {
                      name: '会议预定'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '常用语维护'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                }
              ]
            },
            {
              name: '管理员',
              menus: [
                {
                  name: '零点',
                  children: [
                    {
                      name: '零点销控表'
                    },
                    {
                      name: '零点预定'
                    },
                    {
                      name: '零点订单'
                    },
                    {
                      name: '零点客户信息'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                },
                {
                  name: '大屏幕',
                  children: []
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                }
              ]
            },
            {
              name: '前台订餐',
              menus: [
                {
                  name: '零点',
                  children: [
                    {
                      name: '零点销控表'
                    },
                    {
                      name: '零点预定'
                    },
                    {
                      name: '零点订单'
                    },
                    {
                      name: '零点客户信息'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                },
                {
                  name: '大屏幕',
                  children: []
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                }
              ]
            },
            {
              name: '后厨查看',
              menus: [
                {
                  name: '零点',
                  children: [
                    {
                      name: '零点销控表'
                    },
                    {
                      name: '零点预定'
                    },
                    {
                      name: '零点订单'
                    },
                    {
                      name: '零点客户信息'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                },
                {
                  name: '大屏幕',
                  children: []
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                }
              ]
            },
            {
              name: '前厅经理',
              menus: [
                {
                  name: '零点',
                  children: [
                    {
                      name: '零点销控表'
                    },
                    {
                      name: '零点预定'
                    },
                    {
                      name: '零点订单'
                    },
                    {
                      name: '零点客户信息'
                    }
                  ]
                },
                {
                  name: '设置',
                  children: [
                    {
                      name: '全局设置'
                    },
                    {
                      name: '职位权限设置'
                    },
                    {
                      name: '员工管理'
                    },
                    {
                      name: '宴会类型'
                    },
                    {
                      name: '餐标管理'
                    },
                    {
                      name: '宴会厅管理'
                    },
                    {
                      name: '收费服务'
                    },
                    {
                      name: '餐区管理'
                    },
                    {
                      name: '人数区间'
                    },
                    {
                      name: '雅间管理'
                    },
                    {
                      name: '客户来源'
                    },
                    {
                      name: '头像密码设置'
                    },
                    {
                      name: '客户设置'
                    },
                    {
                      name: '酒店设置'
                    },
                    {
                      name: '菜单管理'
                    },
                    {
                      name: '支付项目'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '担保人'
                    },
                    {
                      name: '担保类型'
                    },
                    {
                      name: '支付方式'
                    },
                    {
                      name: '客户经理'
                    },
                    {
                      name: '客户性别'
                    },
                    {
                      name: '确认方式'
                    },
                    {
                      name: '预定页面设置'
                    },
                    {
                      name: '确认方式设置'
                    },
                    {
                      name: '权限设置'
                    },
                    {
                      name: '时间设置'
                    },
                    {
                      name: '菜品管理'
                    }
                  ]
                },
                {
                  name: '短信',
                  children: [
                    {
                      name: '短信模板配置'
                    },
                    {
                      name: '添加模版'
                    },
                    {
                      name: '常用语维护'
                    },
                    {
                      name: '短信设置'
                    },
                    {
                      name: '发送记录'
                    },
                    {
                      name: '短信营销'
                    }
                  ]
                },
                {
                  name: '应用',
                  children: [
                    {
                      name: '全局应用'
                    }
                  ]
                }
              ]
            }
          ],
          queryModel: null,
          entityModel: null
        }
      }
      this.tableData = res.pageModel.records
      this.total = res.pageModel.totalRecords

      //   const lets =[]
      //   res.map(i=>{
      //       let obj ={

      //       }
      //       obj.name = i.name
      //       obj.menus = []

      //       i.menus.map(s=>{
      //         let objss = {}
      //         objss.name = s.name
      //         objss.children=[]
      //         obj.menus.push(objss)
      //         s.children.map(z=>{

      //           let obja = {}
      //           obja.name = z.name
      //           objss.children.push(obja)
      //         })
      //       })
      //     lets.push(obj)
      //   })
      //   console.log(JSON.stringify(lets))
    },
    async postAddRole() {
      this.roleForm.status
        ? (this.roleForm.status = 1)
        : (this.roleForm.status = 0)
      let url = ''
      if (this.title === '添加') {
        url = 'ogz/role/add/command'
      } else {
        url = 'ogz/role/update/command'
      }
      const res = await this.postRequest(url, this.qs.stringify(this.roleForm))
      if (res.status !== 200) {
        return false
      }
      this.$message({
        duration: 10000,
        type: 'success',
        message: this.title + '成功'
      })
      this.getRoleData()
      this.centerDialogVisible = false
    },
    // 分页事件
    onCurrentChange(val) {
      pageNumber = val
      this.getRoleData()
    },
    // 获取机构信息
    async getOgzData() {
      // const res = await this.getRequest('ogz/org/tree')
      // if (res.status !== 200) {
      //   return false
      // }
      // const { records } = res.pageModel

      const records = [
        {
          label: '北京域马'
        }
      ]
      this.data = records
    },
    // 给用户设置角色或用户组 role设置角色 group用户组
    async onUserAuthorize() {
      if (this.checkedArray.length <= 0) {
        this.$message('请选择要设置的菜单')
        return false
      }
      if (this.roleSelection.length <= 0) {
        this.$message('请选择要设置的角色')
        return false
      }
      let roleBizId = ''
      this.roleSelection.map(o => {
        roleBizId += o.bizId + ';'
      })
      const data = {
        bizId: this.checkedArray.join(';') + ';' + halfCheckedKeys.join(';'),
        assign: 'role',
        roleBizId: roleBizId.slice(0, -1)
      }
      const res = await this.postRequest(
        'menu/authorize/command',
        this.qs.stringify(data)
      )
      if (res.status !== 200) {
        return false
      }
      this.$message({
        duration: 10000,
        type: 'success',
        message: '设置成功'
      })
      halfCheckedKeys = []
      this.roleSelection = []
      this.getRoleData()
    },
    // 表格点击事件
    onCellClick(row) {
      this.checkedArray = []
      row.menus.forEach(o => {
        o.children.forEach(m => {
          this.checkedArray.push(m.bizId)
        })
      })
    }
  },
  mounted() {
    this.getMentTree()
    this.getRoleData()
    this.getOgzData()
  }
}
</script>

<style lang="scss" scoped>
.rolemanage-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}
.left-card {
  width: 15%;
}
.right-card {
  width: 60%;
  height: 100%;
  position: relative;
  margin: 0 10px;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
.box-card {
  width: 30%;
  height: 100%;
  overflow: hidden;
  .trees {
    width: ceil(101%);
    height: 68vh;
    box-sizing: border-box;
    padding-bottom: 20px;
    overflow: auto;
    margin-bottom: 10px;
  }
}
</style>
