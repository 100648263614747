<!--应用--设置-- 员工设置 【dinner_ok】 -->
<template>
  <div class="usermanage-page">
    <div class="buttongroup">
      <el-input style="width: 250px; margin-right: 10px" v-model="search.name" clearable placeholder="请输入员工姓名"></el-input>
      <el-input style="width: 150px; margin-right: 10px" v-model="search.phone" clearable placeholder="请输入员工电话"></el-input>
      <el-button @click="qurey" type="primary">搜索</el-button>
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" height="85%" style="width: 100%" v-loading="Loading" ref="multipleTable" border>
      <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
      <!-- <el-table-column align="center" prop="name" label="姓名">
      </el-table-column> -->
      <el-table-column align="center" prop="username" label="登录名称"></el-table-column>
      <el-table-column align="center" prop="genderCode" label="性别">
        <template slot-scope="scope">
          <span v-if="scope.row.genderCode === 1">男</span>
          <span v-if="scope.row.genderCode === 2">女</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="phone" label="联系方式"></el-table-column>
      <el-table-column align="center" label="职位权限">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.roles" :key="item.Id" style="margin: 0 2px" type="">
            <!-- {{item.name}} -->
            {{ item.name == '角色-零点' ? '酒店管理员' : item.name == '角色-宴会' ? '酒店管理员' : item.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态">
        <template slot-scope="scope">
          <!-- <el-switch v-if="scope.row.userType !== 1" v-model="scope.row.status"></el-switch> -->
          {{ scope.row.status == 1 ? '正常' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createdAt" label="创建日期">
        <template slot-scope="scope">
          {{ parseTime(scope.row.createTime, '{y}-{m}-{d}') }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" width="120" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.userType !== 1" icon="el-icon-edit" size="mini" type="primary" @click="postEditUser(scope.row)"></el-button>
          <el-button v-if="scope.row.userType !== 1" icon="el-icon-delete" size="mini" type="danger" @click="postDeleteUser(scope.row)"></el-button>
          <span v-if="scope.row.userType == 1">管理账户</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="first-pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange"></el-pagination>

    <!-- 添加/修改用户 -->
    <el-dialog :close-on-click-modal="false" :title="title + '员工'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form ref="accountForm" :rules="rules" label-width="120px" :model="accountForm">
        <!-- <el-form-item label="员工姓名：" prop="name">
          <el-input v-model="accountForm.name" maxlength="25"></el-input>
        </el-form-item> -->
        <el-form-item label="员工用户名：" prop="username">
          <el-input v-model="accountForm.username" maxlength="25"></el-input>
        </el-form-item>
        <el-form-item label="登陆密码：" prop="password">
          <el-input  v-model="accountForm.password" maxlength="25"></el-input>
        </el-form-item>
        <!-- <el-form-item label="重复密码：" prop="repassword">
          <el-input  type="password"  v-model="accountForm.repassword" maxlength="25"></el-input>
        </el-form-item> -->

        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="accountForm.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="phone">
          <el-input v-model="accountForm.verifyCode" maxlength="6"></el-input>
          <el-button :disabled="isCodeDisabled"  @click="sendVerifyCode">{{codeButtonName}}</el-button>
        </el-form-item>
        <el-form-item label="员工性别：" prop="genderCode">
          <el-radio-group v-model="accountForm.genderCode">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="权限分配：" prop="roleId">
          <el-select v-model="accountForm.roleId" placeholder="选择权限">
            <el-option v-for="item in optionsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否禁用" prop="status">
          <el-switch v-model="accountForm.status"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddUser">{{ title }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Staff',
  data() {
    var validatePhone = (rule, value, callback) => {
      var regex = /^1[3456789]\d{9}$/
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!regex.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        // if (this.accountForm.phone !== '') {
        //   this.$refs.accountForm.validateField('phone')
        // }
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        // if (this.accountForm.password !== '') {
        //   this.$refs.accountForm.validateField('password')
        // }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.accountForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      type: [],
      optionsList: [{ key: 1, label: '2', value: '2' }],
      data: [],
      isSettingvalue: '',
      roleSelection: [],
      userSelection: [],
      dialogVisible: false,
      centerDialogVisible: false,
      selectedRole: {},
      countNum: 0,
      isCodeDisabled: true,
      timeNum:60,
      codeButtonName:'获取验证码',
      title: '添加',
      form: {
        loginUsername: '',
        phoneNumber: ''
      },
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        phone: '',
        hotelId: '',
        hotelName: ''
      },
      Loading: false,
      accountForm: {
        name: '', // 姓名
        username: '', // 用户名
        password: '', // 密码
        userType: 0, // 用户类型 1主账号 0子账号
        phone: '', // 联系电话
        certificates: '', // 身份证号
        enabled: 0, // 是否禁用
        status: 1, // 用户状态
        photoUrl: '', // 头像图片
        genderCode: 1, // 性别 1男 2女
        roleId: '',
        hotelId: '' // 酒店id
      },
      options: [],
      total: 0, // 总条数
      tableData: [],
      roleData: [], // 角色列表
      totalRole: 0, // 角色总条数
      rules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        state: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        genderCode: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        status: [{ required: true, message: ' ', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择分配权限', trigger: 'change' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        // repassword: [
        //   { required: true, message: '请确认密码', trigger: 'blur' },
        //   { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
        //   { validator: validatePass2, trigger: 'blur', required: true }
        // ]
      }
    }
  },
  created() {},
  watch: {
    'accountForm.phone':{
        handler(newValue,oldValue){
          var regex = /^1[3456789]\d{9}$/
          if(regex.test(newValue)){
            this.isCodeDisabled = false
          } else{
            this.isCodeDisabled = true
          }
        }
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    // console.log('公司：' + JSON.stringify(orgs))
    if (orgs != undefined && orgs != null) {
      var hotel = JSON.parse(orgs)
      this.search.hotelId = hotel[0].id
      this.getRoleData()
      this.getUserData()
    } else {
      // 跳转登录
      // -- 没有默认酒店，跳转到登录页面
      this.$router.push('/login')
    }
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getUserData()
    },
    qurey() {
      this.search.pageSize = 10
      this.getUserData()
    },
    sendVerifyCode() {
      if (!this.accountForm.phone || this.accountForm.phone === '') {
        this.$message.error('请输入手机号')
        return
      }

      if (!this.accountForm.username || this.accountForm.username === '') {
        this.$message.error('请输入姓名')
        return
      }
			const data = {
				phone: this.accountForm.phone,
				username: this.accountForm.username
			}
      this.post('message/registerVerificationCode',data).then(response=>{
        if (response.code !== 200) {
            this.$message.error(response.msg)
						return false
					}
          this.$message.success('验证码发送成功')
					// 获取验证码按钮不可用
					this.isCodeDisabled = true;
					// 验证获取倒计时
					const time = setInterval(() => {
						this.timeNum--
						this.codeButtonName = `已发送(${this.timeNum})秒`
						if (this.timeNum <= 0) {
							clearInterval(time)
							this.timeNum = 60
							this.codeButtonName = '获取验证码'
							this.isCodeDisabled = data.phone.length !== 11;
						}
					}, 1000)
      })

    },
    // 1. 查询当前酒店所有角色
    getRoleData() {
      this.getRequest('sys/role/getByHotel/' + this.search.hotelId, {
        hotelid: this.search.hotelId
      }).then(res => {
        if (res.code == 200 && res.data && res.data.length > 0) {
          var new_list = []
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].name == '角色-零点' || res.data[i].name == '角色-宴会') {
              res.data[i].name = '酒店管理员'
              break
            } else {
              new_list.push(res.data[i])
            }
          }
          this.optionsList = res.data
        } else {
          this.optionsList = []
        }
      })
    },
    // 分页查询 查询所有用户
    getUserData() {
      this.Loading = true
      this.getRequest('sys/user/getPage', this.search)
        .then(res => {
          // console.log('查询结果：' + JSON.stringify(res))
          this.Loading = false
          if (res.code == 200) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.Loading = false
        })
    },
    // 打开添加对话框 添加按钮事件
    onAddClick() {
      this.title = '添加'
      this.accountForm = {
        name: '', // 姓名
        username: '', // 用户名
        password: '', // 密码
        repassword: '', // 密码
        userType: 0, // 用户类型 1主账号 0子账号
        phone: '', // 联系电话
        certificates: '', // 身份证号
        enabled: 0, // 是否禁用
        status: 1, // 用户状态
        photoUrl: '', // 头像图片
        genderCode: 1, // 性别 1男 2女
        roleId: '',
        hotelId: this.search.hotelId, // 酒店id
        verifyCode:''
      }
      if (this.$refs.accountForm) {
        this.$refs.accountForm.resetFields()
      }
      this.centerDialogVisible = true
    },
    // 提交添加、修改
    postAddUser() {
      this.$refs.accountForm.validate(flage => {
        if (flage) {
          let url = ''
          // ---------------------------------
          if (this.title === '添加') {
            url = 'sys/user/admin/regist'
          } else {
            url = 'sys/user/admin/edit'
          }
          // ---------------------------------
          this.accountForm.status ? (this.accountForm.status = 1) : (this.accountForm.status = 0)
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.accountForm.name = this.accountForm.username
          this.post(url, this.accountForm)
            .then(res => {
              loading.close()
              if (res.code == 200) {
                this.$message({
                  duration: 10000,
                  type: 'success',
                  message: this.title + '成功'
                })
                this.getUserData()
                this.centerDialogVisible = false
              } else {
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
                return false
              }
            })
            .catch(() => {
              loading.close()
            })
        }
      })
    },
    // 打开修改对话框--修改用户
    postEditUser(row) {
      // console.log('row', row)
      this.getRequest('sys/user/getById/' + row.id, {
        id: row.id
      }).then(res => {
        // console.log('修改用户：' + JSON.stringify(res))
        if (res.code == 200) {
          this.title = '修改'
          if (res.data.status == 1) {
            res.data.status = true
          } else {
            res.data.status = false
          }
          res.data.roleId = row.roles[0].id
          res.data.roleIdold = row.roles[0].id + ''
          this.accountForm = res.data
          // console.log('accountForm:' + JSON.stringify(this.accountForm))
          this.centerDialogVisible = true
          // --
          if (this.$refs.accountForm) {
            this.$refs.accountForm.resetFields()
          }
        }
      })
    },
    // 删除用户
    postDeleteUser(row) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('sys/user/delByIds/' + row.id, {
            id: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功!'
              })
              this.getUserData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.usermanage-page {
  padding: 10px;
  box-sizing: border-box;
  .buttongroup {
    margin-bottom: 10px;
  }
  .first-pagination {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .elpagination {
      padding: 0;
      margin-right: -5px;
    }
  }
}
</style>
