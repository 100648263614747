<template>
  <div class="usermanage-page">
    <el-card class="right-card">
      <div class="buttongroup">
        <el-select v-model="search.hotelName" clearable filterable remote reserve-keyword placeholder="请选择酒店" :remote-method="remoteMethod" :loading="loading">
          <el-option v-for="item in jiuDian" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <el-input style="width:200px;margin-left:10px" maxlength="50" placeholder="登录名称" clearable v-model="search.name"></el-input>
        <el-input style="width:200px;margin-left:10px" maxlength="11" placeholder="电话" clearable v-model="search.phone"></el-input>
        <el-button style="margin-left:10px" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
        <el-button style="margin-left:10px" @click="onAddClick" type="primary" icon="el-icon-plus">系统用户添加</el-button>
        <el-button style="margin-left:10px" @click="onAddHotelClick" type="primary" icon="el-icon-plus">客服酒店添加</el-button>
        <!-- <el-button type="primary" icon="el-icon-close" @click="onOpenSetting('delete')">批量删除</el-button>
        <el-button type="primary" icon="el-icon-user" @click="onOpenSetting('role')">设置角色</el-button> -->
      </div>
      <el-table :data="tableData" style="width: 100%" ref="multipleTable" v-loading="Loading" border>
        <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
        <el-table-column width="240px" align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column width="240px" align="center" prop="username" label="登录名">
        </el-table-column>
        <el-table-column width="140px" align="center" prop="phone" label="手机号">
        </el-table-column>
        <el-table-column align="center" label="角色">
          <template slot-scope="scope">
            <!-- <el-tag v-for="item in scope.row.roles" :key="item.Id" @close="onUserDelete(scope.row.id, item.id, 'role')" style="margin: 0 2px" closable type="">
              {{ item.name }}</el-tag> -->
            <el-tag v-for="item in scope.row.roles" :key="item.Id" style="margin: 0 2px" type="">
              {{ item.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="300px" align="center" label="机构">
          <template slot-scope="scope">
            <span v-for="item in scope.row.orgs" :key="item.Id">
              <template v-if="item.children && item.children.length > 0">
                <el-tag style="margin: 2px" v-for="i in item.children" :key="i.id">{{ item.name }}/{{ i.name }}</el-tag>
              </template>
              <el-tag style="margin: 2px" v-if="!item.children || item.children.length === 0">{{ item.name }}</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="100px" align="center" prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 1 ? "启用" : "禁用" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" width="120" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.id!=='1'&&scope.row.id!=='2'" icon="el-icon-edit" size="mini" type="primary" @click="postEditUser(scope.row)"></el-button>
            <el-button v-if="scope.row.id!=='1'" icon="el-icon-delete" size="mini" type="danger" @click="postDeleteUser(scope.row)"></el-button>
            <span v-if="scope.row.id=='1'">超级管理员</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="first-pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </el-card>

    <!-- 添加用户 -->
    <el-dialog :close-on-click-modal="false" :title="title + '用户'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form label-width="120px" :model="userForm">
        <el-form-item label="选择酒店：">
          <el-select style="width:100%" v-model="userForm.hotelId" placeholder="请选择酒店" @change="getRoleData" :disabled="title!=='添加酒店客服'" filterable clearable>
            <el-option v-for="item in jiuDianS" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="选择角色：" v-if="title!=='添加酒店客服'">
          <el-select style="width:100%" v-model="userForm.roleIds" placeholder="请选择角色店" multiple clearable>
            <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户名称：">
          <el-input v-if="title!=='添加酒店客服'" v-model="userForm.name"></el-input>
          <span v-else>{{userForm.name}}</span>
        </el-form-item>
        <el-form-item label="登录名：">
          <el-input v-if="title!=='添加酒店客服'" v-model="userForm.username"></el-input>
          <span v-else>{{userForm.username}}</span>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-if="title!=='添加酒店客服'" v-model="userForm.phone"></el-input>
          <span v-else>{{userForm.phone}}</span>
        </el-form-item>
        <el-form-item label="是否禁用：">
          <el-switch v-if="title!=='添加酒店客服'" v-model="userForm.status"></el-switch>
          <span v-else>{{userForm.status==1?'启用':'禁用'}}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" v-loading="FormLoading" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="title!=='添加酒店客服'" @click="postAddUser">{{ title }}</el-button>
        <el-button type="primary" v-if="title=='添加酒店客服'" @click="postAddHotel">提交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'UserManage',
  data() {
    return {
      centerDialogVisible: false,
      title: '添加',
      userForm: {
        name: '', // 姓名
        username: '', // 用户名
        password: '', // 密码
        userType: 0, // 用户类型 1主账号 0子账号
        phone: '', // 联系电话
        certificates: '', // 身份证号
        enabled: 0, // 是否禁用
        status: 1, // 用户状态
        photoUrl: '', // 头像图片
        genderCode: 1, // 性别 1男 2女
        roleIds: '',
        departmentJson: '', // 临时保存角色
        hotelId: '' // 酒店id
      },
      options: [],
      total: 0, // 总条数
      tableData: [],
      roleData: [], // 角色列表
      loading: false,
      jiuDian: [],
      jiuDianS: [],
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotel_id: '',
        hotelName: ''
      },
      Loading: false,
      FormLoading: false
    }
  },
  mounted() {
    this.getUserData()
    this.bindJiuDian()
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 1.1 查询酒店
    bindJiuDian() {
      this.getRequest('data-hotel/getList').then(res => {
        // console.log('查询酒店结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.jiuDianS = res.data
        }
      })
    },
    // 1.2 查询酒店
    remoteMethod(e) {
      //   console.log('条件：' + e)
      this.getRequest('data-hotel/getList', { name: e, limt: 10 }).then(res => {
        // console.log('查询酒店结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.jiuDian = res.data
        }
      })
    },
    // 2. 查询
    query() {
      this.search.pageNumber = 1
      this.getUserData()
    },
    // 3. 查询所有用户
    getUserData(value) {
      this.Loading = true
      this.getRequest('sys/user/getPage', this.search)
        .then(res => {
          //  console.log('查询结果：' + JSON.stringify(res))
          this.Loading = false
          if (res.code == 200) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.Loading = false
        })
    },
    // 4.1 打开添加对话框--添加按钮事件
    onAddClick() {
      this.title = '添加'
      this.getRoleData('1')
      this.userForm = {
        name: '', // 姓名
        username: '', // 用户名
        password: '', // 密码
        userType: 0, // 用户类型 1主账号 0子账号===>这里只添加子账号。主账户需要再创建酒店的时候添加
        phone: '', // 联系电话
        certificates: '', // 身份证号
        enabled: 0, // 是否禁用
        status: 1, // 用户状态
        photoUrl: '', // 头像图片
        genderCode: 1, // 性别 1男 2女
        roleIds: '',
        departmentJson: '', // 临时保存角色
        hotelId: '1', // 酒店id
        roleId: null
      }
      this.centerDialogVisible = true
    },
    // 4.2 打开客服添加酒店
    onAddHotelClick() {
      this.title = '添加酒店客服'
      this.userForm = {
        id: '2',
        name: '鹿小乙客服', // 姓名
        username: '鹿小乙客服', // 用户名
        password: '', // 密码
        userType: 0, // 用户类型 1主账号 0子账号===>这里只添加子账号。主账户需要再创建酒店的时候添加
        phone: '18311078029', // 联系电话
        certificates: '', // 身份证号
        enabled: 0, // 是否禁用
        status: 1, // 用户状态
        photoUrl: '', // 头像图片
        genderCode: 1, // 性别 1男 2女
        roleIds: '',
        departmentJson: '', // 临时保存角色
        hotelId: '', // 酒店id
        roleId: 2
      }
      this.centerDialogVisible = true
    },
    // 5.1 提交添加、修改
    postAddUser() {
      if (
        this.userForm.hotelId == undefined ||
        this.userForm.hotelId == null ||
        this.userForm.hotelId == ''
      ) {
        this.$notify({
          title: '提示',
          message: '请选择酒店',
          type: 'error',
          duration: 2000
        })
        return
      }
      if (
        this.userForm.roleIds == undefined ||
        this.userForm.roleIds == null ||
        this.userForm.roleIds == ''
      ) {
        this.$notify({
          title: '提示',
          message: '请选择角色',
          type: 'error',
          duration: 2000
        })
        return
      }
      let url = ''
      if (this.title === '添加') {
        url = 'sys/user/admin/add'
      } else if (this.title === '修改') {
        url = 'sys/user/admin/edit'
      }
      this.userForm.departmentJson = this.userForm.roleIds.join(',')
      this.userForm.status
        ? (this.userForm.status = 1)
        : (this.userForm.status = 0)
      this.FormLoading = true
      // console.log('提交：' + JSON.stringify(this.userForm))
      this.post(url, this.userForm)
        .then(res => {
          this.FormLoading = false
          if (res.code == 200) {
            this.$message({
              duration: 10000,
              type: 'success',
              message: this.title + '成功'
            })
            this.getUserData()
            this.centerDialogVisible = false
          } else {
            this.$notify({
              title: '提示',
              message: res.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
        .catch(err => {
          this.FormLoading = false
        })
    },
    // 5.2 提交添加酒店客服
    postAddHotel() {
      if (this.userForm.hotelId == '1') {
        this.$notify({
          title: '提示',
          message: '管理后台非酒店性质，不许添加',
          type: 'error',
          duration: 2000
        })
      } else {
        var url = 'sys/user/admin/kefuedit'
        this.FormLoading = true
        // console.log('提交：' + JSON.stringify(this.userForm))
        this.post(url, this.userForm)
          .then(res => {
            this.FormLoading = false
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: this.title + '成功'
              })
              this.getUserData()
              this.centerDialogVisible = false
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
          .catch(err => {
            this.FormLoading = false
          })
      }
    },
    // 6. 打开对话框--修改用户
    postEditUser(row) {
      console.log('选择酒店：' + JSON.stringify(row))
      this.getRoleData(row.hotelId)
      this.getRequest('sys/user/getById/' + row.id, {
        id: row.id
      }).then(res => {
        // console.log('修改用户：' + JSON.stringify(res))
        if (res.code == 200) {
          this.title = '修改'
          res.data.roleIds = res.data.departmentJson.split(',')
          res.data.status === 1
            ? (res.data.status = true)
            : (res.data.status = false)
          res.data.roleId = null
          this.userForm = res.data
          this.centerDialogVisible = true
        }
      })
    },
    // 删除用户
    postDeleteUser(row) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (row.id == 2) {
            this.getRequest('sys/user/delHotelById', {
              id: row.id,
              hotelId: row.hotelId
            }).then(res => {
              if (res.code == 200) {
                this.$message({
                  duration: 10000,
                  type: 'success',
                  message: '删除成功!'
                })
                this.getUserData()
              } else {
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
          } else {
            this.deleteRequest('sys/user/delByIds/' + row.id, {
              id: row.id
            }).then(res => {
              if (res.code == 200) {
                this.$message({
                  duration: 10000,
                  type: 'success',
                  message: '删除成功!'
                })
                this.getUserData()
              } else {
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getUserData()
    },
    // 查询所有角色
    getRoleData(hotelid) {
      this.roleData = []
      this.getRequest('sys/role/getRoleByHotel/' + hotelid, {
        hotelid: hotelid
      }).then(res => {
        // console.log('查询角色结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.roleData = res.data
        }
      })
    },
    // 删除用户的角色或者用户组
    onUserDelete(userid, id, type) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // console.log('确定')
          this.loading = true
          this.deleteRequest(
            'sys/role/delByUserAndId?userid=' + userid + '&id=' + id,
            {
              userid: userid,
              id: id
            }
          )
            .then(res => {
              this.loading = false
              if (res.code == 200) {
                this.$message({
                  duration: 10000,
                  type: 'success',
                  message: '角色删除成功！'
                })
                this.getUserData()
              } else {
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
            .catch(err => {
              this.loading = false
            })
        })
        .catch(() => {
          this.$message({
            duration: 10000,
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.usermanage-page {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .left-card {
    width: 15%;
  }
  .right-card {
    width: 100%;
    position: relative;
    .buttongroup {
      margin-bottom: 10px;
    }
    .first-pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .elpagination {
      padding: 0;
      margin-right: -5px;
    }
  }
}
</style>
