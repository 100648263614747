<template>
  <div class="message-recharge-page">
    <el-form class="form" ref="RechargeForm" :rules="rules" :model="RechargeForm" label-width="80px">
      <el-form-item label="酒店名称">
        <!-- <el-input v-model="RechargeForm.accountName" :disabled="true"></el-input> -->
        {{RechargeForm.accountName}}
      </el-form-item>
      <el-form-item label="充值金额" prop="amount">
        <el-input-number style="width:200px" v-model="RechargeForm.amount" :min="0" :max="99999" label="充值金额"></el-input-number><span style="margin-left:10px">元</span>
      </el-form-item>
      <el-form-item label="充值条数" prop="totalRecords">
        <el-input-number style="width:200px" v-model="RechargeForm.totalRecords" :min="1" :max="999999" :step="1" step-strictly label="充值条数"></el-input-number><span style="margin-left:10px">条</span>
      </el-form-item>
      <el-form-item label="充值平台" prop="providerCode">
        <el-select v-model="RechargeForm.providerCode" placeholder="请选择:">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="rechargeClick" type="primary">充值</el-button>
        <el-button @click="getQueryData" type="primary">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="box-card">
      <span>充值总条数：{{tongji.total}} 条</span><span style="margin-left:30px">已使用总条数：{{tongji.usedTotal}} 条</span> <span style="color:red;font-size:12px;float:right">注：仅允许修改最后的一条记录，且短信池已被使用的充值记录不允许删除</span>
      <el-table :data="tableData" style="width: 100%;margin-top:10px" ref="multipleTable" border>
        <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="accountName" label="酒店名称">
        </el-table-column>
        <el-table-column width="150px" align="center" prop="createTime" label="充值时间">
          <template slot-scope="scope">
            {{parseTime(scope.row.createTime,'{y}-{m}-{d} {h}:{i}:{s}')}}
          </template>
        </el-table-column>
        <el-table-column width="100px" align="center" prop="amount" label="充值金额(元)">
        </el-table-column>
        <el-table-column width="100px" align="center" prop="totalRecords" label="充值条数">
        </el-table-column>
        <el-table-column width="100px" align="center" prop="usedRecords" label="已使用条数">
        </el-table-column>
        <!-- <el-table-column width="150px" align="center" prop="bizId" label="订单编号">
        </el-table-column> -->
        <el-table-column width="100px" align="center" prop="providerName" label="充值平台">
        </el-table-column>
        <el-table-column align="center" prop="address" width="180px" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.accountName!=='鹿小乙运营后台'&&editIndex==scope.row.id" size="mini" type="primary" @click="editRecord(scope.row)">修改数量</el-button>
            <el-button v-if="scope.row.accountName!=='鹿小乙运营后台'&& scope.row.usedRecords==0" size="mini" type="danger" @click="deleteRecord(scope.row)">删 除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 修改短信次数 -->
    <el-dialog :close-on-click-modal="false" title="修改短信次数" :visible.sync="DialogVisible" width="30%" center>
      <el-form ref="RechargeEditForm" :rules="rules" label-width="80px" :model="RechargeEditForm">
        <el-form-item label="酒店名称" prop="name">
          {{RechargeEditForm.name}}
        </el-form-item>
        <el-form-item label="充值金额" prop="amount">
          <el-input-number style="width:200px" v-model="RechargeEditForm.amount" :min="1" :max="99999" label="充值金额"></el-input-number><span style="margin-left:10px">元</span>
        </el-form-item>
        <el-form-item label="充值条数" prop="totalRecords">
          <el-input-number style="width:200px" v-model="RechargeEditForm.totalRecords" :min="1" :max="999999" :step="1" step-strictly label="充值条数"></el-input-number><span style="margin-left:10px">条</span>
        </el-form-item>
        <el-form-item label="已用条数" prop="totalRecords">
          {{ RechargeEditForm.usedRecords }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postEdit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import rules from '@/utils/rules'

export default {
  name: 'MessageRecharge',
  data() {
    return {
      rules,
      RechargeForm: {
        accountBizId: '',
        applicationName: '',
        accountName: '',
        amount: '50',
        totalRecords: '625',
        providerCode: ''
      },
      tableData: [],
      total: 0, // 总条数,
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotelId: '',
        hotelName: ''
      },
      options: [
        {
          value: 'chaoxin',
          label: '超信'
        }
        // {
        //   value: 'chuanglan',
        //   label: '蓝创智云'
        // }
      ],
      DialogVisible: false,
      RechargeEditForm: {},
      editIndex: '',
      tongji: { total: 0, usedTotal: 0 }
    }
  },
  mounted() {
    // 传入参数
    const res = this.$store.state.messageRecharge
    console.log('res', JSON.stringify(res))

    this.RechargeForm = {
      hotelId: res.id, // 酒店id
      accountName: res.name, // 酒店名称
      amount: 50, // 充值金额（元）
      totalRecords: 625, // 充值条数
      providerCode: 'chaoxin', // 短信服务商类型（chaoxin,chuanglan）
      // providerName: '超信', // 短信服务商名称
      payMode: res.bizId // 支付方式
    }
    this.search.hotelId = this.RechargeForm.hotelId
    if (this.$refs.RechargeForm) {
      this.$refs.RechargeForm.resetFields()
    }
    // 查询充值列表
    this.getQueryData()
    this.tongJi_duanxin()
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 查询数据
    getQueryData() {
      this.tableData = []
      console.log('查询条件：' + JSON.stringify(this.search))
      this.getRequest('account-recharge/getPage', this.search).then(res => {
        //   console.log('查询结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.tableData = res.data.records
          this.total = res.data.total
          this.editIndex = this.tableData[0].id
        } else {
          this.tableData = []
          this.total = 0
        }
      })
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    // 充值
    rechargeClick() {
      this.$confirm('请确认是否要提交短信充值记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs.RechargeForm.validate(flage => {
            if (flage) {
              this.post('account-recharge/add', this.RechargeForm).then(res => {
                if (res.code == 200) {
                  this.$message({
                    duration: 10000,
                    type: 'success',
                    message: '充值成功'
                  })
                  this.getQueryData()
                  this.tongJi_duanxin()
                } else {
                  this.$notify({
                    title: '提示',
                    message: res.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 打开修改
    editRecord(row) {
      // --
      this.getRequest('account-recharge/getById/' + row.id, {
        id: row.id
      }).then(res => {
        if (res.code == 200) {
          this.RechargeEditForm = res.data
          this.DialogVisible = true
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 提交修改
    postEdit() {
      if (
        this.RechargeEditForm.totalRecords < this.RechargeEditForm.usedRecords
      ) {
        this.$notify({
          title: '提示',
          message: '短信充值数量不允许小于已使用数量！',
          type: 'error',
          duration: 2000
        })
        return false
      }
      this.post('account-recharge/edit', this.RechargeEditForm).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            type: 'success',
            message: '修改成功'
          })
          this.getQueryData()
          this.tongJi_duanxin()
          this.DialogVisible = false
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 删除
    deleteRecord(row) {
      if (row.usedRecords > 0) {
        this.$notify({
          title: '提示',
          message: '短信池内短信已经被使用，不允许删除！',
          type: 'error',
          duration: 2000
        })
      }
      this.$confirm('请确认是否要删除当前记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('account-recharge/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getQueryData()
              this.tongJi_duanxin()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    tongJi_duanxin() {
      // findTotal
      this.getRequest('account-recharge/findTotal/' + this.search.hotelId, {
        hotelid: this.search.hotelId
      }).then(res => {
        if (res.code == 200) {
          this.tongji = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.message-recharge-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .form {
    width: 35%;
  }
  .box-card {
    width: 60%;
    .pagination {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}
</style>
