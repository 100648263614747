<template>
  <div class="salespersons-page">
    <div class="date">
      <i class="el-icon-d-arrow-left" @click="onDateChange(-1, 'year')"></i>
      <i class="el-icon-arrow-left" @click="onDateChange(-1, 'month')"></i>
      <span @click="selectDate" class="text">{{ date.year }}-{{ date.month }}</span>
      <i class="el-icon-arrow-right" @click="onDateChange(1, 'month')"></i>
      <i class="el-icon-d-arrow-right" @click="onDateChange(1, 'year')"></i>
    </div>
    <div class="ballroom">
      <div class="ballroom-data week">
        <div class="week-title">宴会厅</div>
        <div class="week-list">
          <div :class="item.class" class="week-list-name" v-for="item in weekList" :key="item.id">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ballroom-data datacenter">
        <div class="ballroom-name">
          <div class="ballroom-name-item" v-for="item in recordslist" :key="item.bizId">
            {{ item.hallName }}
          </div>
        </div>
        <div class="ballroom-list">
          <div class="ballroom-list-item" v-for="item in recordslist" :key="item.id">
            <div class="uponday">
              <div v-for="i in item.calendar" :key="i.id">
                <div
                  v-for="(j, index) in i.dayInformations"
                  :key="index"
                  @click="onDayClick(item.hallId, i, j, index)"
                  :class="[i.week === '星期六' || i.week === '星期日' ? 'redcolor' : '', 'itemuponday', 'color' + j.selected]"
                >
                  <el-tooltip :content="item.hallName + '' + i.month + '月' + i.day + '日(农历:' + i.chineseMonth + '月' + i.chineseDay + ')' + i.week" placement="top">
                    <!-- 数字信息 -->
                    <!-- 普通状态 并且已经预定 -->
                    <span v-if="j.timeQuantum === 'AM' && (j.selected === '' || j.selected === '2' || j.selected === '1' || j.selected === '0')">{{ i.day }}</span>
                    <!-- 图标形式 -->
                    <span v-if="j.timeQuantum === 'AM' && j.selected === '3'" class="el-icon-goods" style="font-size: 20px"></span>
                    <!-- 大写数字 -->
                    <!-- 普通状态 并且已经预定 -->
                    <span v-if="j.timeQuantum === 'PM' && (j.selected === '0' || j.selected === '' || j.selected === '2' || j.selected === '1')">{{ i.chineseDay }}</span>
                    <!-- 图标形式 -->
                    <span v-if="j.timeQuantum === 'PM' && j.selected === '3'" class="el-icon-goods" style="color: #000; font-size: 20px"></span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="state">
      <div class="state-group">
        <div class="group-item state-hint">状态提示:</div>
        <div class="group-item state-0">未定</div>
        <div class="group-item state-1">预留</div>
        <div class="group-item state-2">已定</div>
      </div>
    </div>
    <el-dialog title="预留详情列表" :visible.sync="reservedDialogVisible" width="1000px">
      <h3 style="text-align: center">{{ reservedDate }}</h3>
      <div class="reserved-list">
        <div v-for="(item, index) in retainsList" :key="item.bizId" class="list-item">
          <div class="number">{{ index + 1 }}</div>
          <div class="center">
            <div>
              【客户姓名】{{ item.customerName }}
              <span>{{ item.categoryName }}</span>
            </div>
            <div>【预留时间】{{ item.createTime }}</div>
            <div>
              【客户经理】{{ item.customerManager }}
              <el-button @click="getDetails(item)" class="btn" type="text">详情</el-button>
            </div>
          </div>
          <div v-show="item.customerManager === info.loginUsername" class="sign">
            我
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reservedDialogVisible = false">取消</el-button>
        <el-button @click="booking" type="primary">预定</el-button>
      </span>
    </el-dialog>
    <Details ref="child"></Details>
    <Calendar ref="calendar"></Calendar>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import Details from '@/components/order/Details'
import Calendar from '@/components/order/Calendar'
import { socketUrl, wss } from '../../utils/api'

import datas from './json/salesContotab'
import roomtype from './json/roomtype'

const date = new Date()
let data = {}
let loading
let query = {}
export default {
  name: 'SalesControlTable',
  data() {
    return {
      info: {},
      reserved: {},
      reservedDialogVisible: false,
      reservedDate: '',
      reservedDateTime: 2,
      date: {
        year: '',
        month: '',
        day: ''
      },
      details: {},
      weekList: [], // 被选择月星期列表，默认当前月
      recordslist: [], // 数据列表
      retainsList: [], // 预留列表
      hallBizId: '',
      org: '',
      reservedHall: ''
    }
  },
  components: {
    Details,
    Calendar
  },
  provide() {
    return {
      initAria: this.initAria,
      changeDate: this.changeDate
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    if (window.localStorage.getItem('info')) {
      this.info = JSON.parse(window.localStorage.getItem('info'))
    }
    // console.log(datas.records[0].calendars)
    this.initDate()
    this.initWeek()
    this.initAria()
    this.getBallroomType()
    // websocket启动
    // this.createWebSocket()
  },
  methods: {
    // 选择日期
    selectDate() {
      const data = this.date.year + '-' + this.date.month
      this.$refs.calendar.getDetails(data)
    },
    getDetails(details) {
      this.$refs.child.getDetails(details, this.reservedHall)
    },
    // 查看详情列表
    getBallroomData(row) {
      this.$refs.child.getDetails(row, this.reservedHall)
    },
    // 初始化日期，选择日期
    initDate() {
      if (data && data.year) {
        this.date = {
          ...data
        }
      } else {
        this.date = {
          year: date.getFullYear().toString(),
          month: (date.getMonth() + 1).toString().padStart(2, '0'),
          day: date
            .getDate()
            .toString()
            .padStart(2, '0')
        }
      }
    },
    // 日期改变事件
    onDateChange(val, type) {
      if (type === 'year') {
        this.date.year = (parseInt(this.date.year) + parseInt(val)).toString()
      } else {
        if (parseInt(this.date.month) === 12 && val === 1) {
          this.date.year = (parseInt(this.date.year) + 1).toString()
          this.date.month = '01'
        } else if (parseInt(this.date.month) === 1 && val === -1) {
          this.date.year = (parseInt(this.date.year) - 1).toString()
          this.date.month = '12'
        } else {
          this.date.month = (parseInt(this.date.month) + parseInt(val)).toString().padStart(2, '0')
        }
      }
      data = {
        year: this.date.year,
        month: this.date.month,
        day: this.date.day
      }
      this.initWeek()
      this.initAria()
    },
    // 日历日期改变
    changeDate(year, month) {
      this.date.year = year
      this.date.month = month
      this.initWeek()
      this.initAria()
    },
    // 初始化当月或者被选择的星期
    initWeek() {
      this.weekList = []
      const weekArray = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const week = new Date(this.date.year, parseInt(this.date.month) - 1, 1).getDay()
      const days = new Date(this.date.year, this.date.month, 0).getDate()
      for (let i = 0; i < days; i++) {
        this.weekList.push({
          id: uuid(),
          name: weekArray[(i + week) % 7],
          class: weekArray[(i + week) % 7] === '周六' || weekArray[(i + week) % 7] === '周日' ? 'red' : 'block'
        })
      }
    },
    // 初始化销控表数据
    async initAria() {
      this.reservedDialogVisible = false
      loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        hotelId: this.org.id,
        year: this.date.year,
        month: this.date.month
      }
      const res = await this.getRequest('banquet/calendars', data)
      if (res.code !== 200) {
        return false
      }
      loading.close()
      const records = res.data
      records.forEach(i => {
        i.calendar.forEach(m => {
          if (m.chineseMonth === '正') {
            m.chineseMonth = '一'
          }
          if (m.chineseMonth === '冬') {
            m.chineseMonth = '十一'
          }
          if (m.chineseMonth === '腊') {
            m.chineseMonth = '十二'
          }
        })
      })
      this.recordslist = records
    },
    // 获取宴会厅类型
    async getBallroomType() {
      let records = roomtype.records
      records = records.map(o => {
        return { value: o.code, label: o.name, bizId: o.bizId }
      })
      this.$store.commit('setBallroomList', records)
    },
    // 点击事件
    onDayClick(hallBizId, val, startTimeQuantumSelected, index) {
      /*  hallBizId  id
      @ reserveStatus 根据 返回的值 来判断 点击的状态
        reserveStatus  = 0 房间没有别预定的状态
        reserveStatus  = 1 房间已经被预定 并没有支付 定金
        reserveStatus  = 2 房间已经被预定 且支付定金（包含普通房间和关联房间  具体的关联显示问题 在details 组件中）
      */

      // val.month = (val.month + '').padStart(2, '0')
      // val.day = (val.day + '').padStart(2, '0')
      const date = `${val.year}-${val.month}-${val.day}`
      const ckTime = new Date(date).getTime()
      const dqTime = new Date().getTime() - 86400000
      if (ckTime <= dqTime && startTimeQuantumSelected.selected === '') {
        this.$message('已经过去的日期不允许预定！')
        return false
      }

      if (startTimeQuantumSelected.selected === '3') {
        this.$message('该宴会厅不可预定')
        return false
      }
      // 修改选中菜单
      query = {
        hallBizId: hallBizId,
        bizId: startTimeQuantumSelected.reserved,
        startDate: date,
        startTimeQuantumSelectedBizId: startTimeQuantumSelected.id,
        timeQuantumType: startTimeQuantumSelected.timeQuantum
      }

      const timeName = startTimeQuantumSelected.timeQuantum == 'AM' ? '中午' : '晚上'
      this.reservedDateTime = timeName
      this.reservedDate = query.startDate + '(' + val.chineseMonth + '月' + val.chineseDay + ')' + val.week + '-' + timeName
      this.$store.commit('setBanquetReservation', query)
      if (startTimeQuantumSelected.selected === '' || startTimeQuantumSelected.selected === '0') {
        // 没预定的
        // 添加tags标签
        const submenu = {
          path: '/banquet/reserve',
          name: '宴会预定',
          bizId: '/banquet/reserve'
        }
        this.selectMenu(submenu)
        this.$router.push({
          path: submenu.path,
          query: {
            q: 1
          }
        })
      } else if (startTimeQuantumSelected.selected === '1') {
        // 预留
        // this.retainsList = startTimeQuantumSelected.retains
        this.getReserveDataList(startTimeQuantumSelected.id, 1, hallBizId)
      } else if (startTimeQuantumSelected.selected === '2') {
        // 预定
        this.getReserveDataList(startTimeQuantumSelected.id, 2, hallBizId)
      }
    },
    getReserveDataList(bizId, val, hallBizId) {
      this.reservedHall = hallBizId
      this.retainsList = []
      this.getRequest('banquet/getReserveList', { bizId: bizId }).then(res => {
        if (res.code === 200) {
          if (val === 1) {
            this.retainsList = res.data.retain
            this.reservedDialogVisible = true
          } else {
            this.getBallroomData(res.data.reserved)
            this.elegantRoomVisible = true
          }
        }
      })
    },
    // 点击菜单 - 传入name，添加到keepalive缓存页面
    selectMenu(item) {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', item.name)
      // 添加tags标签
      const submenu = {
        path: item.path,
        name: item.name,
        bizId: item.path
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
    },
    booking() {
      const querys = {
        hallBizId: query.hallBizId,
        startDate: query.startDate,
        startTimeQuantumSelectedBizId: query.startTimeQuantumSelectedBizId,
        timeQuantumType: query.timeQuantumType
      }
      this.$store.commit('setBanquetReservation', querys)
      // 添加tags标签
      const submenu = {
        path: '/banquet/reserve',
        name: '宴会预定',
        bizId: '/banquet/reserve'
      }
      this.selectMenu(submenu)
      this.$router.push({
        path: submenu.path,
        query: {
          q: 1
        }
      })
    }
  },
  created() {
    // console.log('离开标记', this.socketLeaveFlag)
  }
}
</script>

<style lang="scss" scoped>
.salespersons-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: #f7f7f7;
  position: relative;

  .date {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;

    //margin-top: 10px;
    i {
      text-shadow: 2px 2px 2px #ccc;
      margin: 5px 30px 0;
      cursor: pointer;
    }

    .text {
      font-weight: bold;
      font-size: 28px;
      cursor: pointer;
    }
  }

  .ballroom {
    width: 100%;
    height: 80%;
    overflow-y: auto;
    border-radius: 5px;
    margin-top: 10px;
    padding-right: 20px;
    padding-bottom: 50px;

    .ballroom-data {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
    }

    .datacenter {
      max-height: 70vh;
      height: auto;
      overflow-y: auto;
      width: calc(100% + 37px);
      padding-right: 20px;

      .ballroom-name {
        width: 100px;
        height: auto;
        box-sizing: border-box;
        background: #fff;

        .ballroom-name-item {
          width: 100%;
          text-align: center;
          height: 77px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          padding-right: 10px;
          box-sizing: border-box;
          border-bottom: 1px dashed #ccc;
          color: #666;
          font-weight: bold;
        }
      }

      .ballroom-list {
        flex: 1;
        height: auto;

        .ballroom-list-item {
          border-bottom: 1px dashed #ccc;
          padding: 5px 0;

          .uponday,
          .downday {
            display: flex;
            flex: 1;
            justify-content: space-between;
            font-size: 12px;
            cursor: pointer;

            .itemuponday {
              width: 30px;
              height: 30px;
              line-height: 30px;
              margin-bottom: 3px;
              text-align: center;
              background-color: #e5e5e5;
              color: #333;
              cursor: pointer;
            }

            .itemuponday:hover {
              background: #15d37d;
              color: #fff;
            }

            .itemdownday {
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background-color: #e5e5e5;
            }

            .itemdownday:hover {
              background: #15d37d;
              color: #fff;
            }

            .redcolor {
              color: red;
            }

            .color0 {
              background-color: #e9e9e9;
            }

            .color1 {
              background-color: #ffca00;
            }

            .color2 {
              background-color: #15d37d;
            }
          }
        }
      }
    }

    .week {
      height: 30px;
      line-height: 30px;
      width: calc(100% + 17px);

      .week-title {
        width: 100px;
        font-size: 13px;
        border-right: 1px solid #fff;
      }

      .week-list {
        flex: 1;
        display: flex;
        justify-content: space-between;

        .red {
          color: red;
        }

        .week-list-name {
          width: 30px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .state {
    width: 100%;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 17px;

    .state-group {
      width: 260px;
      display: flex;
      font-size: 13px;
      justify-content: space-between;

      .group-item {
        padding: 2px 10px;
        border-radius: 3px;
        color: #333;
      }

      .state-hint {
        color: #ffdd00;
      }

      .state-0 {
        background: #e5e5e5;
      }

      .state-1 {
        background: #ffdd00;
      }

      .state-2 {
        background: #15d37d;
      }
    }
  }

  .reserved-list {
    width: 90%;
    height: auto;
    min-height: 300px;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .list-item {
      width: 400px;
      height: 100px;
      background: #f9f9f9;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      display: flex;
      position: relative;
      overflow: hidden;
      border-radius: 5px;

      .number {
        width: 90px;
        padding: 0 5px;
        height: 100%;
        text-align: center;
        line-height: 100px;
        font-size: 50px;
        background: #15d37d;
        color: #f9f9f9;
      }

      .center {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .btn {
          float: right;
          padding-right: 30px;
        }
      }

      .sign {
        position: absolute;
        right: 0;
        background: #ffdd00;
        transform: rotate(45deg) translate(16px, -24px);
        font-size: 20px;
        color: #f9f9f9;
        padding: 2px 30px;
      }
    }
  }
}
</style>
