<!-- 此页已不用 -->
<template>
  <div class="dishes-page">菜品设置</div>
</template>

<script>
export default {
  name: 'Dishes'
}
</script>

<style lang="scss" scoped>
.dishes-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
}
</style>
