<template>
  <el-card class="type-page">
    <div class="buttongroup">
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table :data="tableData" :row-style="{height:'6.2vh'}" height="66vh" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建日期">
      </el-table-column>
      <el-table-column align="center" prop="rangePersonName" label="名称">
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-switch @change="onStatusChange(scope.row)" v-model="scope.row.status"></el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="postEditData(scope.row)"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="query.pageSize" @current-change="onCurrentChange">
    </el-pagination>
    <!-- 人数区间 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" :title="titleName + '人数区间'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form ref="form" :rules="rules" label-width="80px" :model="form">
        <el-form-item label="名称" prop="rangePersonName">
          <el-input v-model="form.rangePersonName"></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color: red">示例：6-8人、8-10人、15人以上</span>
        </el-form-item>
        <el-form-item label="排序" prop="tabIndex">
          <el-input v-model="form.tabIndex"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select style="width:250px" v-model="form.status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">{{titleName}}</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import rules from '@/utils/rules'
export default {
  name: 'Bumberpeople',
  data() {
    return {
      org: '',
      rules,
      titleName: '添加',
      form: {
        hotelId: '',
        applicationName: '',
        tabIndex: '',
        rangePersonName: '',
        status: 1
      },
      query: {
        pageSize: 10,
        pageNumber: 1,
        hotelId: '',
        applicationName: ''
      },
      total: 0,
      tableData: [],
      centerDialogVisible: false,
      options: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '禁用'
        }
      ]
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    this.query.hotelId = this.org.hotelId
    this.query.applicationName = this.org.applicationName
    this.getData()
  },
  methods: {
    // 查询数据
    getData() {
      this.getRequest('dinner-range/getPage', this.query).then(res => {
        this.tableData = []
        if (res.code == 200) {
          var result = res.data.records.map(r => {
            r.status = r.status === 1
            return r
          })
          this.tableData = result
          this.total = res.data.total
        }
      })
    },
    // .2 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getData()
    },
    // 添加按钮事件
    onAddClick() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.titleName = '添加'
      this.form = {
        hotelId: this.org.id,
        applicationName: this.org.applicationName,
        tabIndex: '',
        rangePersonName: '',
        status: 1
      }
      this.centerDialogVisible = true
    },
    // 修改数据
    postEditData(row) {
      this.titleName = '修改'
      this.getRequest(`dinner-range/getById/` + row.id, { id: row.id }).then(
        res => {
          if (res.code == 200) {
            this.form = res.data
            this.centerDialogVisible = true
          } else {
            this.$notify({
              title: '提示',
              message: res.msg,
              type: 'error',
              duration: 2000
            })
          }
        }
      )
    },
    // 设置数据
    onSetData() {
      this.form.hotelId = this.org.id
      this.form.applicationName = this.org.applicationName
      this.$refs.form.validate(async flage => {
        if (flage) {
          if (this.titleName === '添加') {
            // 添加
            this.post(`dinner-range/add`, this.form).then(res => {
              if (res.code == 200) {
                this.centerDialogVisible = false
                this.getData()
                this.$message.success({
                  duration: 2000,
                  message: '添加成功!'
                })
              } else {
                // message
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
          } else {
            this.post(`dinner-range/edit`, this.form).then(res => {
              //  console.log(res)
              if (res.code == 200) {
                this.getData()
                this.centerDialogVisible = false
                this.$message.success({
                  duration: 2000,
                  message: '修改成功!'
                })
              } else {
                // message
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
          }
        }
      })
    },
    // 修改状态
    onStatusChange(row) {
      this.titleName = '修改'
      const { id, status } = row
      var param = {
        id,
        status
      }
      param.status = param.status ? 1 : 0
      this.post(`dinner-range/editState`, param).then(res => {
        if (res.code == 200) {
          this.getData()
          this.centerDialogVisible = false
          this.$message.success({
            duration: 2000,
            message: '状态修改成功!'
          })
        } else {
          // message
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 删除数据
    async postDeleteData(row) {
      this.$confirm('请确认是否要删除当前区间信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('dinner-range/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 50px;
  }
}
</style>
