const arrayInput = [
  ['name', '名称'],
  ['price', '价格'],
  ['loginUsername', '登录名'],
  ['phoneNumber', '手机号'],
  ['maxTableNumber', '最大桌'],
  ['estimatedPersonNum', '预计人数'],
  ['customerName', '客户姓名'],
  ['customerPhoneNumber', '联系方式'],
  ['amount', '金额'],
  ['totalRecords', '条数'],
  ['department', '所属部门'],
  ['keyword', '互动关键词'],
  ['backgroundCode', '背景选择'],
  ['group', '弹幕组']
]
const arraySelect = [
  ['reserveDateFormat', '类型属性'],
  ['dinnerZoneBizId', '餐区类型'],
  ['rangePersonBizId', '人数区间'],
  ['category', '婚宴类型'],
  ['mealStandard', '餐标'],
  ['roomBizId', '雅间名称'],
  ['reserveDate', '预定日期'],
  ['reserveTime', '预定时间'],
  ['reserveTimeType', '预定时间'],
  ['arrivedTime', '到店时间'],
  ['genderSelectedBizId', '客户性别'],
  ['customerSourceSelectedBizId', '客户来源'],
  ['customerManagerSelectedBizId', '客户经理'],
  ['roleBizId', '用户角色'],
  ['templateBizId', '短信模版'],
  ['startDate', '开始执行时间'],
  ['receiveTime', '客户接收时间'],
  ['frequency', '发送频次'],
  ['startDate', '开始时间'],
  ['endDate', '结束时间'],
  ['banquetCategoryBizId', '宴会类型'],
  ['messageReceivers', '发送对象'],
  ['sendConditionCategory', '发送对象'],
  ['genderCode', '性别'],
  ['categories', '职能设定'],
  ['eventValue', '场景'],
  ['arrayDate', '生效时间'],
  ['banquetDate', '宴会举办日期'],
  ['timeQuantumCode', '宴会举办时间'],
  ['hallBizId', '所属宴会厅'],
  ['categoryBizId', '宴会类型'],
  ['barrageGroupName', '弹幕'],
  ['attachments', '上传图片'],
  ['accountType', '账户类型'],
  ['sendCondition', '客户选择'],
  ['messageType', '设置类型'],
  ['tagId', '订餐类型'],
  ['state', '状态'],
  ['status', '状态'],
  ['tabIndex', '排序'],
  
]
const rules = {}
arrayInput.forEach(i => {
  rules[i[0]] = { required: true, message: `请输入${i[1]}`, trigger: 'change' }
})
arraySelect.forEach(i => {
  rules[i[0]] = { required: true, message: `请选择${i[1]}`, trigger: 'change' }
})
export default rules
