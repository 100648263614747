<template>
  <div class="reservation-order-page">
    <el-form :model="search" ref="search">
      <div class="form-box">
        <el-form-item>
          <el-input v-model="search.phoneNumber" placeholder="客户姓名或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="search.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width: 150px" v-model="search.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <!-- <el-input v-model="search.customerSource" placeholder="客户来源"></el-input> -->
          <el-select style="width: 150px" v-model="search.customerSource" placeholder="客户来源">
            <el-option v-for="item in customerSourcesArry" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px" v-model="search.customerManagerBizId" placeholder="客户经理">
            <el-option v-for="item in customerManagers" :key="item.value" :label="item.label" :value="item.bizId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-box">
        <el-form-item style=" width: 300px;">
          <el-button size="mini" @click="query()" type="warning">筛选</el-button>
          <el-button type="primary" style="margin: 0 30px;" size="mini" @click="exportExcel">导出</el-button>
          <el-button @click="resetForm" size="mini" type="info">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-loading="loading" id="selectTable" :data="tableData" height="66vh" :default-sort="{prop: 'date', order: 'descending'}" stripe style="width: 100%">
      <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
      <el-table-column prop="createTime" sortable align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="name" align="center" label="姓名">
      </el-table-column>
      <el-table-column prop="gender" align="center" label="性别" :formatter="formatGender">
      </el-table-column>
      <el-table-column prop="phoneNumber" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="dinnerTotalOrderNumber" align="center" label="累计预定">
      </el-table-column>
      <el-table-column prop="dinnerTotalOrderEffectiveNumber" align="center" label="有效预定">
      </el-table-column>
      <el-table-column prop="dinnerTotalSumAmount" align="center" label="累计消费（元）">
      </el-table-column>
      <el-table-column prop="source" align="center" label="客户来源">
      </el-table-column>
      <el-table-column prop="customerManager" label="客户经理" align="center" filter-placement="bottom-end">
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
// import htmlToExcel from '@/utils/htmlToExcel'
import { export_json_to_excel } from '@/utils/Export2Excel'

export default {
  name: 'ReservationOrder',
  data() {
    return {
      list: [],
      loading: false,
      tableData: [], // 宴会预留订单数据
      ballroomList: [], // 宴会厅
      categories: [], // 宴会厅类型
      customerManagers: [], // 客户经理
      total: 0, // 总条数
      customerSourcesArry: [],
      search: {
        pageNumber: 1,
        pageSize: 10, // 一页要显示的条数
        phoneNumber: '',
        hotelId: this.$store.state.hotellist[0].bizId,
        startDate: '',
        endDate: '',
        customerSource: '',
        customerManagerBizId: ''
      }
    }
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 重置按钮
    resetForm() {
      this.search = {
        pageNumber: 1,
        pageSize: 10, // 一页要显示的条数
        phoneNumber: '',
        hotelId: this.$store.state.hotellist[0].bizId,
        startDate: '',
        endDate: '',
        customerSource: '',
        customerManagerBizId: ''
      }
      this.getData()
    },
    // 筛选查询
    query() {
      this.search.pageNumber = 1
      this.getData()
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getData()
    },
    // 执行查询
    getData() {
      console.log('查询条件：' + JSON.stringify(this.search))
      this.getRequest('data-customer/getPage', this.search).then(res => {
        console.log('查询结果：' + JSON.stringify(res))
        this.tableData = []
        this.total = 0
        if (res.code == 200) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    // 获取宴会厅客户经理
    getCustomerManager() {
      // 2. 获取宴会厅客户经理
      this.getRequest('sys/user/loadCostumerManager').then(res => {
        if (res.code == 200) {
          this.customerManagers = res.data.map(o => {
            return { value: o.id, label: o.username, bizId: o.id }
          })
        }
      })
    },
    // 获取客户来源
    getCustomerSource() {
      // 获取客户来源
      this.getRequest('message-metadata/getCustomerSource').then(res => {
        // console.log('结果：' + JSON.stringify(res))
        if (res.code == 200) {
          this.customerSourcesArry = res.data
        }
      })
    },
    // 查看详情
    getBallroomData(row) {
      this.$refs.details.getDetails(row)
    },
    formatGender(row) {
      var name = ''
      switch (row.gender) {
        case 1:
          name = '男'
          break
        case 2:
          name = '女'
          break
      }
      return name
    },
    // 打开导出对话框
    exportExcel() {
      this.$confirm('确定要导出当前数据？', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getExpportData()
        })
        .catch(() => {})
    },
    // 导出数据
    getExpportData: function() {
      var self = this
      // --导出过程中等待动作
      const loading = this.$loading({
        lock: true,
        text: '正在导出，请稍等......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.getRequest('data-customer/getList', this.search)
        .then(res => {
          //   console.log('导出结果：' + JSON.stringify(res))
          // 关闭等待
          loading.close()
          if (res.code === 200 && res.data.length > 0) {
            require.ensure([], () => {
              const tHeader = [
                '序号',
                '创建时间',
                '姓名',
                '性别',
                '联系电话',
                '累计预定',
                '有效预定',
                '客户来源',
                '累计消费（元）',
                '客户经理'
              ]
              const filterVal = [
                'index',
                'createTime',
                'name',
                'gender',
                'phoneNumber',
                'dinnerTotalOrderNumber',
                'dinnerTotalOrderEffectiveNumber',
                'dinnerTotalSumAmount',
                'source',
                'customerManager'
              ]
              var templist = res.data
              var i = 1
              templist.forEach(element => {
                element.index = i++
                element.gender = element.gender == '1' ? '男' : '女'
              })
              const data = self.formatJson(filterVal, templist)
              export_json_to_excel(tHeader, data, '零点客户信息')
            })
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    }
  },
  mounted() {
    this.getData()
    this.getCustomerManager()
    this.getCustomerSource()
  }
}
</script>

<style lang="scss" scoped>
.reservation-order-page {
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  position: relative;
}
.form-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  .el-form-item {
    margin-bottom: 15px;
    margin-right: 20px;
  }
}
.pagination {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.order-details {
  height: auto;
  margin-top: -30px;
  margin-bottom: -20px;
  .customer-details {
    height: 120px;
    margin-bottom: 20px;
  }
  .title {
    //margin: 5px 0;
    //margin-top: -10px;
    padding: 2px 10px;
    background: #f7f6f6;
    //border: 1px solid #d4d4d4;
    background: #f7f6f6;
  }
  .reserved-details {
    height: auto;
  }
  .title {
    //margin: 5px 0;
    padding: 2px 10px;
    background: #f7f6f6;
    //border: 1px solid #d4d4d4;
  }
  .box-card {
    //border: 1px solid #ccc;
    border-radius: 5px;
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 0px 5px;
    .box-card-img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      border-radius: 5px;
    }
  }
}
</style>
