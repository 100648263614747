<template>
  <el-card class="box-card">
    <div class="buttongroup">
      <el-input style="width:300px;margin-left:10px" maxlength="50" placeholder="企业名称" clearable v-model="search.hotelName"></el-input>
      <el-date-picker style="margin-left:10px" v-model="selectDate" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" class="filter-item" @change="selectTime" />

      <el-button style="margin-left:10px" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
    </div>
    <el-table v-loading="Loading" :data="tableData" style="width: 100%;margin-top:20px" ref="multipleTable" border>
      <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
      <el-table-column align="center" prop="accountName" label="商户">
      </el-table-column>
      <el-table-column align="center" prop="amount" label="充值金额">
      </el-table-column>
      <el-table-column align="center" prop="totalRecords" label="短息充值数量">
      </el-table-column>
      <el-table-column style="width:120px" align="center" prop="usedRecords" label="已使用条数">
      </el-table-column>
      <el-table-column align="center" prop="providerName" label="短信平台">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="充值时间">
        <template slot-scope="scope">
          {{parseTime(scope.row.createTime,'{y}-{m}-{d} {h}:{i}:{s}')}}
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="address" width="250" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.accountName!=='鹿小乙运营后台'" size="mini" type="primary" @click="editRecord(scope.row)">修改短信数量</el-button>
          <el-button v-if="scope.row.accountName!=='鹿小乙运营后台'&& scope.row.usedRecords==0" size="mini" type="danger" @click="deleteRecord(scope.row)">删 除</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="pagination">
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </div>

    <!-- 修改短信次数 -->
    <el-dialog :close-on-click-modal="false" title="修改短信次数" :visible.sync="DialogVisible" width="30%" center>
      <el-form ref="RechargeEditForm" :rules="rules" label-width="80px" :model="RechargeEditForm">
        <el-form-item label="酒店名称" prop="name">
          {{RechargeEditForm.accountName}}
        </el-form-item>

        <el-form-item label="充值金额" prop="amount">
          <el-input-number style="width:200px" v-model="RechargeEditForm.amount" :min="1" :max="99999" label="充值金额"></el-input-number><span style="margin-left:10px">元</span>
        </el-form-item>
        <el-form-item label="充值条数" prop="totalRecords">
          <el-input-number style="width:200px" v-model="RechargeEditForm.totalRecords" :min="1" :max="999999" :step="1" step-strictly label="充值条数"></el-input-number><span style="margin-left:10px">条</span>
        </el-form-item>
        <el-form-item label="已用条数" prop="totalRecords">
          {{ RechargeEditForm.usedRecords }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postEdit">提交</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: 'MessageRechargeList',
  data() {
    return {
      Loading: false,
      tableData: [],
      total: 0, // 总条数,
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotelId: '',
        hotelName: '',
        startTime: '', // 时间
        endTime: ''
      },
      selectDate: undefined,
      DialogVisible: false,
      RechargeEditForm: {}
    }
  },
  mounted() {
    this.getQueryData()
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 搜索条件时间
    selectTime(obj) {
      if (obj == null) {
        this.search.startTime = ''
        this.search.endTime = ''
      }
    },
    query() {
      this.search.pageNumber = 1
      this.getQueryData()
    },
    // 查询数据
    getQueryData() {
      this.tableData = []
      // 查询前时间条件
      if (this.selectDate !== undefined && this.selectDate !== null) {
        this.search.startTime = this.selectDate[0]
        this.search.endTime = this.selectDate[1]
      }
      this.Loading = true
      this.getRequest('account-recharge/getPage', this.search)
        .then(res => {
          this.Loading = false
          //    console.log('查询结果：' + JSON.stringify(res))
          if (res.code == 200) {
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.Loading = true
        })
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    editRecord(row) {
      // --
      this.getRequest('account-recharge/getById/' + row.id, {
        id: row.id
      }).then(res => {
        if (res.code == 200) {
          this.RechargeEditForm = res.data
          this.DialogVisible = true
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    postEdit() {
      if (
        this.RechargeEditForm.totalRecords < this.RechargeEditForm.usedRecords
      ) {
        this.$notify({
          title: '提示',
          message: '短信充值数量不允许小于已使用数量！',
          type: 'error',
          duration: 2000
        })
        return false
      }
      this.post('account-recharge/edit', this.RechargeEditForm).then(res => {
        if (res.code == 200) {
          this.$message({
            duration: 10000,
            type: 'success',
            message: '修改成功'
          })
          this.getQueryData()
          this.DialogVisible = false
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    deleteRecord(row) {
      if (row.usedRecords > 0) {
        this.$notify({
          title: '提示',
          message: '短信池内短信已经被使用，不允许删除！',
          type: 'error',
          duration: 2000
        })
      }
      this.$confirm('请确认是否要删除当前记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('account-recharge/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getQueryData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
</style>
