<template>
  <div class="account-page">
    <el-card class="card">
      <div class="buttongroup">
        <el-input style="width:300px;margin-left:10px" maxlength="50" placeholder="企业名称" clearable v-model="search.orgName"></el-input>
        <el-input style="width:200px;margin-left:10px" maxlength="50" placeholder="登录名称" clearable v-model="search.name"></el-input>
        <el-input style="width:200px;margin-left:10px" maxlength="50" placeholder="电话" clearable v-model="search.phone"></el-input>
        <el-select style="width:120px;margin-left:10px" v-model="search.state" placeholder="账号类型" clearable>
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select style="width:120px;margin-left:10px" v-model="search.status" placeholder="状态" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button style="margin-left:10px" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
        <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
      </div>
      <el-table height="65vh" v-loading="showLoad" element-loading-background="rgba(0, 0, 0, 0.5)" element-loading-text="数据正在加载中" element-loading-spinner="el-icon-loading" :data="tableData" style="width: 100%" ref="multipleTable" border>
        <el-table-column type="index" align="center" label="序号" :index="indexMethod" width="80px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="name" label="酒店名称">
        </el-table-column>
        <!-- <el-table-column align="center" prop="shortName" label="酒店别名">
        </el-table-column> -->
        <el-table-column align="center" prop="userName" label="管理员登录名">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="管理员手机号">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="账号类型">
          <template slot-scope="scope">
            {{scope.row.state==4?'零点':scope.row.state==5?'宴会':scope.row.state==6?'会议':'管理'}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="shengyu" label="短信剩余">
        </el-table-column>
        <el-table-column align="center" prop="effectiveTime" label="有效期">
          <template slot-scope="scope">
            {{parseTime(scope.row.effectiveTime,'{y}-{m}-{d}')}}
          </template>
        </el-table-column>
        <el-table-column width="100px" align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <!-- {{scope.row.status=='1'?'正常':scope.row.status=='2'?'欠费':'禁用'}} -->
            <span style="color:green" v-if="scope.row.status=='1'">正常</span>
            <span style="color:orange" v-else-if="scope.row.status=='2'"> 欠费</span>
            <span style="color:red" v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" width="250" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.name!='鹿小乙运营后台'" size="mini" type="text" @click="monitor(scope.row)">变更状态</el-button>
            <el-button v-if="scope.row.name!='鹿小乙运营后台'" size="mini" type="text" @click="postEditUser(scope.row)">修改账户</el-button>
            <el-button v-if="scope.row.name!='鹿小乙运营后台'" size="mini" type="text" @click="messageRecharge(scope.row)">短信充值</el-button>
            <span v-if="scope.row.name=='鹿小乙运营后台'">运营后台</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
      </el-pagination>
    </el-card>

    <!-- 添加账户 -->
    <el-dialog :close-on-click-modal="false" :title="title + '账户'" :visible.sync="centerDialogVisible" width="30%" center>
      <el-form ref="accountForm" :rules="rules" label-width="80px" :model="accountForm">
        <el-form-item label="酒店名称" prop="name">
          <el-input v-model="accountForm.name"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="accountForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="accountForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="账号类型" prop="state">
          <el-radio-group v-model="accountForm.state">
            <el-radio :label="4">零点</el-radio>
            <el-radio :label="5">宴会</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="有效期" prop="effectiveTime">
          <el-date-picker v-model="accountForm.effectiveTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择有效截止日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="是否禁用">
          <el-switch v-model="accountForm.status"></el-switch>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddUser">{{ title }}</el-button>
      </span>
    </el-dialog>

    <!-- 修改酒店状态 -->
    <el-dialog :close-on-click-modal="false" title="修改酒店状态" :visible.sync="stateDialogVisible" width="30%" center>
      <el-form ref="stateForm" :rules="rules" label-width="80px" :model="stateForm">
        <el-form-item label="酒店名称" prop="name">
          <!-- <el-input v-model="stateForm.name"></el-input> -->
          {{stateForm.name}}
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="stateForm.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="-1">禁用</el-radio>
            <el-radio :label="2">欠费</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postState">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Account',
  data() {
    return {
      showLoad: false,
      data: [],
      status: -1,
      typeOptions: [
        {
          value: 4,
          label: '零点'
        },
        {
          value: 5,
          label: '宴会'
        },
        {
          value: -1,
          label: '其他'
        }
      ],
      options: [
        // {
        //   value: -1,
        //   label: '全部'
        // },
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '禁用'
        }
      ],
      isSettingvalue: '',
      userSelection: [],
      dialogVisible: false,
      centerDialogVisible: false,
      stateDialogVisible: false,
      stateForm: {},
      title: '添加',
      accountForm: {
        name: '',
        userName: '',
        phone: '',
        state: 4
      },
      form: {},
      total: 0, // 总条数
      search: {
        pageSize: 10,
        pageNumber: 1,
        name: '',
        hotel_id: '',
        hotelName: ''
      },
      tableData: [],
      messageAccountVisble: false,
      rules: {
        name: [
          { required: true, message: '酒店名称不能为空', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '管理员登录名不能为空', trigger: 'blur' }
        ],
        state: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [
          { required: true, message: '管理员电话不能为空', trigger: 'blur' }
        ],
        effectiveTime: [
          { required: true, message: '有效截止日期不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    // 查询翻页
    this.getUserData()
  },
  methods: {
    // 0. 页码
    indexMethod(index) {
      var pagestart = (this.search.pageNumber - 1) * this.search.pageSize
      var pageindex = index + 1 + pagestart
      return pageindex
    },
    // 1. 搜索事件
    query() {
      this.search.pageNumber = 1
      this.getUserData()
    },
    // 2. 翻页查询--查询所有用户
    getUserData() {
      this.showLoad = true
      this.getRequest('data-hotel/getPage', this.search)
        .then(res => {
          // console.log('酒店：' + JSON.stringify(res))
          this.showLoad = false
          if (res.code == 200 && res.data && res.data.total > 0) {
            res.data.records.forEach(element => {
              // console.log('element.effectiveTime:' + element.effectiveTime)
              //   element.effectiveTime = this.parseTime(
              //     element.effectiveTime,
              //     '{y}-{m}-{d} {h}:{i}:{s}'
              //   )
            })
            this.tableData = res.data.records
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .catch(err => {
          this.showLoad = false
        })
    },
    // 3.1 打开修改状态
    monitor(row) {
      this.stateForm = row
      this.stateDialogVisible = true
    },
    // 3.2 提交修改状态
    postState() {
      // status -1禁用、1正常、2欠费
      this.showLoad = true
      this.post(`data-hotel/changeState`, this.stateForm)
        .then(res => {
          this.showLoad = false
          if (res.code == 200) {
            this.$message({
              duration: 10000,
              type: 'success',
              message: '修改成功'
            })
            this.getUserData()
            this.stateDialogVisible = false
          } else {
            this.$notify({
              title: '提示',
              message: res.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
        .catch(err => {
          this.showLoad = false
        })
    },
    // 4. 打开添加对话框--添加按钮事件
    onAddClick() {
      this.title = '添加'
      this.accountForm = {
        name: '',
        userName: '',
        phone: '',
        state: 4
      }
      this.centerDialogVisible = true
      if (this.$refs.accountForm) {
        this.$refs.accountForm.resetFields()
      }
    },
    // 5.提交添加和修改
    postAddUser() {
      this.$refs.accountForm.validate(flage => {
        if (flage) {
          if (this.title != '添加') {
            this.toPost()
          } else {
            this.getRequest('data-hotel/phoneExit/' + this.accountForm.phone, {
              phone: this.accountForm.phone
            }).then(res => {
              // console.log('查询exsit:' + JSON.stringify(res))
              if (res.code == 200) {
                if (res.data == 'ok') {
                  this.toPost()
                } else {
                  this.$confirm(res.data + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
                    .then(() => {
                      this.toPost()
                    })
                    .catch(() => {
                      // this.$message({
                      //   type: 'info',
                      //   message: '已取消操作'
                      // })
                    })
                }
              }
            })
          }
        }
      })
    },
    // 5.1 提交添加和修改
    toPost() {
      const url = this.title === '添加' ? 'data-hotel/add' : 'data-hotel/edit'
      this.post(url, this.accountForm).then(res => {
        if (res.code == 200) {
          //  console.log('提交结果:' + JSON.stringify(res))

          this.centerDialogVisible = false
          this.$message({
            duration: 10000,
            type: 'success',
            message: this.title + '成功'
          })
          this.getUserData()
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 6. 打开修改对话框--修改用户
    postEditUser(row) {
      this.accountForm = {
        name: '',
        userName: '',
        phone: '',
        state: 4
      }
      this.title = '修改'
      this.getRequest('data-hotel/getById/' + row.id, { id: row.id })
        .then(res => {
          //   console.log('修改：' + JSON.stringify(res))
          if (res.code == 200) {
            res.data.userName = row.userName
            res.data.phone = row.phone
            this.accountForm = res.data
            this.centerDialogVisible = true
            if (this.$refs.accountForm) {
              this.$refs.accountForm.resetFields()
            }
          }
        })
        .catch(err => {})
    },
    // 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getUserData()
    },
    // 短信充值
    messageRecharge(row) {
      // 1. 页面传参
      this.$store.commit('setMessageRecharge', row)
      const submenu = {
        path: '/account/message-recharge',
        name: '短信充值',
        bizId: '/account/message-recharge'
      }
      // 2. 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', '短信充值')
      // 3.修改选中菜单
      this.$store.commit('selectMenu', submenu)
      // 4. 跳转页面
      this.$router.push(submenu.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.account-page {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    .buttongroup {
      margin-bottom: 10px;
      //   display: flex;
      //   justify-content: space-between;
    }
    .first-pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .elpagination {
      padding: 0;
      margin-right: -5px;
    }
  }
}
</style>
