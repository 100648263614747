<template>
  <div class="page">
    <div class="table">
      <el-table max-height="600" show-summary :data="tableData">
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="name" label="客户经理">
        </el-table-column>
        <el-table-column align="center" prop="number_reserve" label="预定数">
        </el-table-column>
        <el-table-column align="center" prop="number_unreserve" label="退订数">
        </el-table-column>
        <el-table-column align="center" prop="number_reserve_user" label="散客订单">
        </el-table-column>
        <el-table-column align="center" prop="number_reserve" label="有效预定">
          <template slot-scope="scope">
            <span>{{Number(scope.row.number_reserve - scope.row.number_reserve_user - scope.row.number_unreserve)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="saturday" label="贡献率">
          <template slot-scope="scope">
            <span>{{Number(scope.row.total / scope.row.count).toFixed(2) * 100}}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-form :model="form">
        <div class="form-box">
          <el-form-item label="年份" style="width: 140px">
            <el-date-picker style="width: 100px" format="yyyy" value-format="yyyy" v-model="form.year1" type="year" placeholder="选择年">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="月份" style="width: 140px">
            <el-select style="width: 100px" v-model="form.month1" placeholder="月份">
              <el-option v-for="item in monthData" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <span style="padding: 0 20px">VS</span>
          <el-form-item label="年份" style="width: 140px">
            <el-date-picker style="width: 100px" format="yyyy" value-format="yyyy" v-model="form.year2" type="year" placeholder="选择年">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="月份" style="width: 140px">
            <el-select style="width: 100px" v-model="form.month2" placeholder="月份">
              <el-option v-for="item in monthData" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 40px;">
            <el-button @click="onCompared" type="primary">对比</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div class="echarts" id="main"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DinnerUsageRate',
  data() {
    const monthData = [
      { key: '1', label: '1', value: '01' },
      { key: '2', label: '2', value: '02' },
      { key: '3', label: '3', value: '03' },
      { key: '4', label: '4', value: '04' },
      { key: '5', label: '5', value: '05' },
      { key: '6', label: '6', value: '06' },
      { key: '7', label: '7', value: '07' },
      { key: '8', label: '8', value: '08' },
      { key: '9', label: '9', value: '09' },
      { key: '10', label: '10', value: '10' },
      { key: '11', label: '11', value: '11' },
      { key: '12', label: '12', value: '12' }
    ]
    return {
      tableData: [],
      monthData,
      form: {},
      yearData: [],
      record: {}
    }
  },
  methods: {
    myEcharts() {
      const myChart = this.$echarts.init(document.getElementById('main'))
      // 配置图表
      const option = {
        legend: {},
        xAxis: {
          type: 'category',
          axisTick: { show: false },
          data: this.record.twoValuexAxis
        },
        yAxis: { type: 'value' },
        series: [
          {
            name: this.record.oneMonth,
            type: 'bar',
            label: { show: true },
            data: this.record.oneValueseries
          },
          {
            name: this.record.twoMonth,
            type: 'bar',
            label: { show: true },
            data: this.record.twoValueseries
          }
        ]
      }
      myChart.setOption(option)
    },
    async init() {
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId
      }
      const res = await this.getRequest(
        'statistics/dinner/customer/manager/statistics',
        data
      )
      if (res.status !== 200) {
        return false
      }
      const { pageModel } = res
      this.tableData = pageModel.records
    },
    async onCompared() {
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        ...this.form
      }
      const res = await this.getRequest(
        'statistics/dinner/room/reserve/statistics',
        data
      )
      if (res.status !== 200) {
        return false
      }
      const record = res.pageModel.records[0]
      const oneValue = record.one.values
      record.oneMonth = record.one.year + '-' + record.one.month
      record.oneValuexAxis = []
      record.oneValueseries = []
      if (oneValue && oneValue.length > 0) {
        oneValue.forEach(o => {
          record.oneValuexAxis.push(o.name)
          record.oneValueseries.push(o.value)
        })
      }
      const twoValue = record.two.values
      record.twoMonth = record.two.year + '-' + record.two.month
      record.twoValuexAxis = []
      record.twoValueseries = []
      if (twoValue && twoValue.length > 0) {
        twoValue.forEach(o => {
          record.twoValuexAxis.push(o.name)
          record.twoValueseries.push(o.value)
        })
      }
      this.record = record
      await this.myEcharts()
    }
  },
  mounted() {
    const date = new Date()
    this.form = {
      year1: date.getFullYear() - 1 + '',
      month1: (date.getMonth() + 1 + '').padStart(2, '0'),
      year2: date.getFullYear() + '',
      month2: (date.getMonth() + 1 + '').padStart(2, '0')
    }
    this.init()
    this.onCompared()
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  .table {
    width: 50%;
    padding: 0 20px;
  }
  .right {
    width: 50%;
    height: 100%;
    padding: 0 20px;
    border-left: 1px solid #ccc;
    .form-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;
      .el-form-item {
        margin-bottom: 1px;
        margin-right: 10px;
      }
    }
    .echarts {
      margin: 0 auto;
      width: 600px;
      height: 400px;
      margin-top: 50px;
    }
  }
}
</style>
