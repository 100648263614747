<template>
  <div class="setup-page">
    <!--    <div style="color: red; font-size: 18px; margin-bottom: 20px">-->
    <!--      <i class="el-icon-warning-outline"></i>-->
    <!--      提示：首次设置信息，请按第一步、第二步、第三步、第四步中的顺序，依次添加信息。-->
    <!--    </div>-->
    <div class="center">
      <ul class="setup" v-for="item in setupdata" :key="item.id">
        <h4 class="title">{{item.name}}</h4>
        <li class="setup-item">
          <div v-for="(items, index) in item.children" :key="items.id">
            <div @click="onSettingRouter(items)" class="item-li" v-if="items.display !== false">
              <div :class="['sort', 'sort'+item.sort]">{{index+1}}</div>
              <div class="right">
                <h4>{{items.name}}</h4>
                <p>{{items.subtitle}}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { v1 as uuid } from 'uuid'
export default {
  name: 'Setup',
  data() {
    return {
      setupdata: []
    }
  },
  mounted() {
    this.getSteupData()
  },
  methods: {
    async getSteupData() {
      const res = await axios.get('jsop/setupData.json')
      //  console.log('res',res)
      if (res.status !== 200) {
        return false
      }
      res.data.forEach(i => {
        i.bizId = uuid()
        i.children.forEach(j => {
          j.bizId = uuid()
        })
      })
      const accountType = JSON.parse(window.localStorage.getItem('accountType'))
      const type = []
      if (accountType) {
        if (accountType.banquet === 1) {
          type.push('banquet')
          type.push('dinner')
        }
        if (accountType.dinner === 1) {
          type.push('dinner')
        }
        // if (accountType.meeting === 1) {
        //   type.push('meeting')
        // }
      }
      res.data = res.data.filter(o => {
        return o.type === '1' || type.includes(o.type)
      })
      this.setupdata = res.data
    },
    onSettingRouter(menu) {
      this.selectMenu(menu)
      this.$router.push(menu.path)
    },
    // 点击菜单 - 传入name，添加到keepalive缓存页面
    selectMenu(item) {
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', item.name)
      // 添加tags标签
      const submenu = {
        path: item.path,
        name: item.name,
        bizId: item.path
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #333;
}
.setup-page {
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  overflow-y: hidden;
  .center {
    height: calc(100% + 20px);
    width: calc(100% + 37px);
    overflow-y: auto;
    .setup {
      margin-bottom: 20px;
    }
    .setup-item {
      display: flex;
      flex-wrap: wrap;
      .item-li {
        width: 350px;
        height: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        background: #f7f8fa;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .sort {
          width: 60px;
          height: 60px;
          box-sizing: border-box;
          color: #fff;
          text-align: center;
          line-height: 60px;
          font-size: 38px;
        }
        .sort1 {
          background: #ff7077;
        }
        .sort2 {
          background: #ffca00;
        }
        .sort3 {
          background: #15d37d;
        }
        .sort4 {
          background: #6badff;
        }
        .right {
          width: 230px;
          height: 60px;
          font-size: 14px;
          padding: 5px 0;
          box-sizing: border-box;
          line-height: 28px;
          h4,
          p {
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: auto;
            height: 28px;
          }
        }
      }
    }
  }
}
</style>
