<template>
  <div class="page" :style="note">
    <h3 :class="['marquee_title', 'marquee_title' + row.backgroundCode]">{{ row.name }}</h3>
    <div class="qrcode">
      <vue-qr :logo-src="logoSrc" :size="100" :margin="0" :text="appSrc" colorDark="#000000" colorLight="#ffffff" />
    </div>
    <div class="box">
      <div class="left">
        <div class="marquee_box" ref="marquee_box">
          <ul class="marquee_list" :class="{marquee_top:animate}">
            <li v-for="(item,index) in list" :key="index" :class="'li' + row.backgroundCode">
              <div style="margin-right: 10px">
                <img border="1" width="50px" height="50px" style="margin-top: 5px" :src="item.url" alt="">
              </div>
              <div class="center">
                <div>{{ item.loginUsername }}</div>
                <div style="overflow: hidden; height: 30px;">{{item.content}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div style="height: 80%; width: 100%; overflow: hidden">
          <el-carousel indicator-position="outside" height="100%" style="height: 100%">
            <el-carousel-item v-for="item in vfImages" :key="item">
              <div style="height: 100%; display: flex; justify-content: center;">
                <img height="100%" :src="item">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { baseUrl } from '../../utils/api'
export default {
  name: 'BigScreen',
  data() {
    return {
      logoSrc:
        'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
      appSrc: '',
      note: {
        backgroundImage: '',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      animate: false,
      showNum: 5, // 可见列表条数
      list: [
        {
          bizId: 1,
          url:
            'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
          loginUsername: '鹿小乙',
          position: 'left',
          createAt: '12:26',
          type: 'text',
          content: '百年好合，天长地久'
        },
        {
          bizId: 2,
          url:
            'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
          loginUsername: '鹿小乙',
          position: 'left',
          createAt: '12:26',
          type: 'text',
          content: '大吉大利，今晚吃鸡'
        },
        {
          bizId: 3,
          url:
            'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
          loginUsername: '鹿小乙',
          position: 'left',
          createAt: '12:26',
          type: 'text',
          content: '阖家欢乐，幸福常久'
        },
        {
          bizId: 4,
          url:
            'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
          loginUsername: '鹿小乙',
          position: 'left',
          createAt: '12:26',
          type: 'text',
          content: '新婚快乐，万事如意'
        },
        {
          bizId: 4,
          url:
            'http://59.110.7.224//images/thumbnail/4c400b8264a72b44f442479825f6ed2d/thumbnail_biz_1359121498082313932.png',
          loginUsername: '鹿小乙',
          position: 'left',
          createAt: '12:26',
          type: 'text',
          content: '酒店宴会预定管理,就找鹿小乙'
        }
      ],
      row: {},
      vfOptions: {
        autoplay: true
      },
      vfImages: [],
      vfTransitions: ['fade', 'Kenburn', 'Swipe', 'Slide'],
      vfCaptions: []
    }
  },
  mounted() {
    // 可见数据高度
    this.$refs.marquee_box.style.height = this.showNum * 100 + 'px'
    const row = JSON.parse(window.sessionStorage.getItem('BigScreenPreview'))
    if (!row) {
      this.$message('当前活动有问题')
      return false
    }
    this.note.backgroundImage = `url(${row.backgroundUrl})`
    // 页面显示
    setInterval(this.showMarquee, 2000)
    const data = `screenBizId=${row.bizId}&applicationName=${row.applicationName}&hotelBizId=${row.hotelBizId}&hotelName=${row.hotelName}`
    this.creatQrCode(data)
    this.row = {
      ...row
    }
    this.vfImages = row.attachments.map(o => {
      return o.previewUrl
    })
    this.initWebSocket()
  },
  destroyed() {
    this.websock.close() // 离开路由之后断开websocket连接
  },
  components: {
    VueQr
  },
  methods: {
    creatQrCode(data) {
      try {
        // const text = `${baseUrl}screen/mobile/barrage?${data}`
        this.appSrc = `${baseUrl}screen/mobile/barrage?${data}`
        // const qrcode = new QRCode('qrcode', {
        //   text, // 需要转换为二维码的内容
        //   width: 100,
        //   height: 100,
        //   colorDark: '#000000',
        //   colorLight: '#ffffff'
        //   // correctLevel: QRCode.CorrectLevel.H
        // })
      } catch (e) {
        // console.log('e', e)
      }
    },
    // 处理数组方法
    showMarquee() {
      this.animate = true
      this.list.push(this.list[0])
      setTimeout(() => {
        this.list.shift()
        this.animate = false
      }, 1500)
    },
    initWebSocket() {
      // 初始化weosocket
      const query = `client='001'&sessionId=${window.localStorage.getItem(
        'sessionId'
      )}&applicationName=${this.row.applicationName}&hotelBizId=${
        this.row.hotelBizId
      }&screenBizId=${this.row.bizId}`
      const wsuri =
        'ws://59.110.7.224/lxy-screen-wall/websocket/screen/wall?' + query
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // console.log('建立连接')
    },
    websocketonerror() {
      // 连接建立失败重连
      this.initWebSocket()
    },
    websocketonmessage(e) {
      // 数据接收
      const redata = JSON.parse(e.data)
      this.list.push(redata.content)
    },
    websocketclose(e) {
      // 关闭
      // console.log('断开连接', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  background-size: 100%;
  position: relative;
  .marquee_title {
    width: 100%;
    height: 100px;
    line-height: 200px;
    text-align: center;
    font-size: 40px;
    letter-spacing: 5px;
    text-shadow: 5px 5px 10px #333;
    align-items: center;
    color: #fff;
  }
  .marquee_title3 {
    color: #000;
    text-shadow: 5px 5px 10px #eee;
  }
  .qrcode {
    position: absolute;
    top: 50px;
    right: 50px;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 3px 6px #999;
  }
  .box {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    .left {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      .marquee_box {
        position: relative;
        width: 70%;
        overflow: hidden;
        margin: 0 auto;
        .marquee_list {
          width: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }
        .marquee_top {
          transition: all 1s;
          margin-top: -100px;
        }
        .marquee_list {
          li {
            height: 90px;
            padding: 10px;
            font-size: 20px;
            display: flex;
            justify-items: center;
            margin: 10px 0;
            color: #fff;
            overflow: hidden;
          }
          .li2 {
            border-radius: 10px;
            border: 3px solid #ffdd00;
            color: #ffdd00;
          }
          .li1 {
            border-bottom: 2px solid #ffffff;
            color: #fff;
          }
          .li3 {
            border-bottom: 1px solid #eee;
            color: #333;
          }
        }
      }
    }
    .right {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
      justify-items: center;
    }
  }
}
</style>
