<!--设置--零点设置-- 时间设置 【dinner_ok】 -->
<template>
  <div class="type-page">
    <div>客人预计到店时间设置</div>
    <el-form class="form" ref="form" :model="form" label-width="80px">
      <div class="item">
        <el-form-item label-width="100px" label="中午到店时间">
          <el-input style="width: 220px" v-model="name1" placeholder="中午到店时间"></el-input>
          <el-button @click="addTime(1)" style="margin-left: 20px" type="primary">添加</el-button>
        </el-form-item>
        <!-- <el-form-item label-width="100px" label="">
          <el-input style="width: 220px" v-model="index1"></el-input>
        </el-form-item> -->
        <el-form-item label-width="100px">
          <span style="color: red">中午到店时间 例：11:30、12:00、12:30、13:00</span>
        </el-form-item>
        <div class="timeview">
          <el-tag @close="postDeleteData(tag)" style="margin: 5px" v-for="tag in ztags" :key="tag.id" closable>
            {{tag.name}}
          </el-tag>
        </div>
      </div>
      <div class="item">
        <el-form-item label-width="100px" label="晚上到店时间">
          <el-input style="width: 220px" v-model="name2" placeholder="晚上到店时间"></el-input>
          <el-button @click="addTime(2)" style="margin-left: 20px" type="primary">添加</el-button>
        </el-form-item>
        <!-- <el-form-item label-width="100px" label="">
          <el-input style="width: 220px" v-model="index2"></el-input>
        </el-form-item> -->
        <el-form-item label-width="100px">
          <span style="color: red">晚上到店时间 例：18:00、18:30、19:00、19:30</span>
        </el-form-item>
        <div class="timeview">
          <el-tag @close="postDeleteData(tag)" style="margin: 5px" v-for="tag in wtags" :key="tag.id" closable>
            {{tag.name}}
          </el-tag>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import rules from '@/utils/rules'
export default {
  name: 'DiningTime',
  data() {
    return {
      rules,
      form: {
        name: '',
        tabIndex: 0,
        hotelId: '',
        dataCode: '',
        applicationName: ''
      },
      search: {
        name: '',
        pageSize: 999,
        pageNumber: 1,
        hotelId: '',
        applicationName: ''
      },
      index1: 0,
      index2: 0,
      name1: '',
      name2: '',
      ztags: [],
      wtags: [],
      org: ''
    }
  },
  mounted() {
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    this.search.hotelId = this.org.id
    this.search.applicationName = this.org.applicationName
    this.getQueryData()
  },
  methods: {
    // 查询数据
    getQueryData() {
      this.getRequest('dinner-metamodel/getAll', this.search).then(res => {
        if (res.code == 200) {
          this.ztags = res.data.dinnerNoonArriveTimeQuantum || []
          this.wtags = res.data.dinnerNightArriveTimeQuantum || []
        }
      })
    },
    // 添加
    addTime(val) {
      if (val === 1) {
        if (this.name1) {
          // -- 验证时间格式
          // ^[0-2][0-9]:[0-5][0-9]$
          // 验证日期格式的正则表达式
          var dateRegex = /^[0-2][0-9]:[0-5][0-9]$/
          // 检查日期格式
          if (!dateRegex.test(this.name1)) {
            this.$message({
              duration: 10000,
              message: '时间格式不正确！'
            })
            return
          }
          // --
          const ztags = []
          this.ztags.forEach(o => {
            ztags.push(o.name)
          })
          if (ztags.indexOf(this.name1) === -1) {
            this.onSetData(this.name1, 1, this.index1 || 0)
            this.name1 = ''
            this.index1 = 0
          } else {
            this.$message({
              duration: 10000,
              message: '不可添加重复数据'
            })
          }
        }
      } else {
        if (this.name2) {
          // -- 验证时间格式
          // ^[0-2][0-9]:[0-5][0-9]$
          // 验证日期格式的正则表达式
          var dateRegex = /^[0-2][0-9]:[0-5][0-9]$/
          // 检查日期格式
          if (!dateRegex.test(this.name2)) {
            this.$message({
              duration: 10000,
              message: '时间格式不正确！'
            })
            return
          }
          const wtags = []
          this.wtags.forEach(o => {
            wtags.push(o.name)
          })
          if (wtags.indexOf(this.name2) === -1) {
            this.onSetData(this.name2, 2, this.index2 || 0)
            this.name2 = ''
            this.index2 = 0
          } else {
            this.$message({
              duration: 10000,
              message: '不可添加重复数据'
            })
          }
        }
      }
    },
    // 设置数据
    async onSetData(name, type, tabIndex) {
      const data = {
        name,
        tabIndex,
        dataCode: 'dinnerNoonArriveTimeQuantum',
        applicationName: this.org.applicationName,
        hotelId: this.org.id
      }
      if (type === 2) data.dataCode = 'dinnerNightArriveTimeQuantum'
      await this.post('dinner-metamodel/add', data).then(res => {
        if (res.code == 200) {
          this.$message.success({
            duration: 2000,
            message: '添加成功!'
          })
        } else {
          // message
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
      await this.getQueryData()
    },
    // 删除数据
    async postDeleteData(row) {
      this.$confirm('请确认是否要删除选中时间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('dinner-metamodel/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getQueryData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  .form {
    margin-top: 20px;
    display: flex;
    width: 100%;
    max-height: 90%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 0;
    .item {
      width: 500px;
      height: 100%;
      margin-right: 20px;
      .timeview {
        width: 100%;
        height: auto;
        min-height: 300px;
        box-sizing: border-box;
        padding: 20px;
        background: #f6f6f6;
      }
    }
  }
}
</style>
