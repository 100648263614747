<!--设置-- 客户来源 【dinner_ok】 -->
<template>
  <el-card class="type-page">
    <div class="buttongroup">
      <el-button @click="onAddClick" type="primary" icon="el-icon-plus">添加</el-button>
    </div>
    <el-table height="66vh" :row-style="{height: '6.2vh'}" :data="tableData" style="width: 100%" ref="multipleTable" border>
      <el-table-column align="center" label="序号" type="index">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建日期">
      </el-table-column>
      <el-table-column align="center" prop="name" label="名称">
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-switch v-if="scope.row.hotelId !== undefined" @change="onStatusChange(scope.row)" v-model="scope.row.state"></el-switch>
          <span v-else>系统默认</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="150px" label="操作">
        <template slot-scope="scope" v-if="scope.row.hotelId !== undefined">
          <el-button icon="el-icon-edit" size="mini" @click="postEditData(scope.row)"></el-button>
          <el-button icon="el-icon-delete" size="mini" type="danger" @click="postDeleteData(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="prev, pager, next" :total="total" :page-size="search.pageSize" @current-change="onCurrentChange">
    </el-pagination>
    <!-- 添加角色 -->
    <el-dialog :close-on-click-modal='false' class="updatecode" :title="titleName + '客户来源'" :visible.sync="centerDialogVisible" width="600px" center>
      <el-form ref="form" :rules="rules" label-width="80px" :model="form">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color: red">示例：自然进店、美团、鹿小乙、外销-王小丫、广告投放</span>
        </el-form-item>
        <!--        <el-form-item label="展示">-->
        <!--          <el-switch v-model="form.state"></el-switch>-->
        <!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSetData">{{titleName}}</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { postDeleteData, postSetData } from '../../../utils/postForm'
import rules from '@/utils/rules'
export default {
  name: 'CustomerSource',
  data() {
    return {
      rules,
      titleName: '添加',
      form: {
        dataCode: 'customerSource',
        code: '',
        name: '',
        state: 0,
        hotelId: '',
        className: '',
        applicationName: ''
      },
      tableData: [],
      centerDialogVisible: false,
      search: {
        pageSize: 10,
        pageNumber: 1,
        dataCode: 'customerSource',
        hotelId: '',
        applicationName: ''
      },
      org: ''
    }
  },
  created() {
    var orgs = window.localStorage.getItem('orgs')
    this.org = JSON.parse(orgs)[0]
    this.search.applicationName = this.org.applicationName
    this.search.hotelId = this.org.id
  },
  mounted() {
    this.getQueryData()
  },
  methods: {
    // 查询数据
    async getQueryData() {
      this.getRequest('data-metamodel/getPage', this.search).then(res => {
        this.tableData = []
        this.total = 0
        if (res.code == 200) {
          this.total = res.data.total
          this.tableData = res.data.records.map(r => {
            r.state = r.state === 0
            return r
          })
        }
      })
    },
    // 添加按钮事件
    onAddClick() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.titleName = '添加'
      this.form = {
        dataCode: 'customerSource',
        code: '',
        name: '',
        state: 0,
        hotelId: '',
        className: '客户来源',
        applicationName: ''
      }
      this.centerDialogVisible = true
    },
    // .2 分页事件
    onCurrentChange(val) {
      this.search.pageNumber = val
      this.getQueryData()
    },
    // 设置数据
    onSetData() {
      this.form.hotelId = this.org.id
      this.form.applicationName = this.org.applicationName
      this.$refs.form.validate(async flage => {
        if (flage) {
          if (this.titleName === '添加') {
            this.post(`data-metamodel/add`, this.form).then(res => {
              if (res.code == 200) {
                this.centerDialogVisible = false
                this.getQueryData()
                this.$message.success({
                  duration: 2000,
                  message: '添加成功!'
                })
              } else {
                // message
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
          } else {
            this.form.state = this.form.state ? 0 : 1
            this.post(`data-metamodel/edit`, this.form).then(res => {
              if (res.code == 200) {
                this.getQueryData()
                this.centerDialogVisible = false
                this.$message.success({
                  duration: 2000,
                  message: '修改成功!'
                })
              } else {
                // message
                this.$notify({
                  title: '提示',
                  message: res.msg,
                  type: 'error',
                  duration: 2000
                })
              }
            })
          }
        }
      })
    },
    // 修改数据
    postEditData(row) {
      this.titleName = '修改'
      const { id, code, name, modelBizId, state } = row
      this.form = {
        dataCode: 'customerSource',
        id,
        code,
        name,
        state
      }
      this.centerDialogVisible = true
    },
    // 修改状态
    onStatusChange(row) {
      this.titleName = '修改'
      const { id, state } = row
      var param = {
        id,
        state
      }
      param.state = param.state ? 0 : 1
      this.post(`data-metamodel/edit`, param).then(res => {
        if (res.code == 200) {
          this.getQueryData()
          this.centerDialogVisible = false
          this.$message.success({
            duration: 2000,
            message: '修改成功!'
          })
        } else {
          // message
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 删除数据
    async postDeleteData(row) {
      this.$confirm('请确认是否要删除当前客户来源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRequest('data-metamodel/delByIds/' + row.id, {
            ids: row.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                duration: 10000,
                type: 'success',
                message: '删除成功'
              })
              this.getQueryData()
            } else {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.type-page {
  width: 100%;
  height: 100%;
  position: relative;
  .buttongroup {
    margin-bottom: 10px;
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    bottom: 50px;
  }
}
</style>
